<template>
  <div class="en-multi-button">
    <div class="buttons">
      <div
        v-if="$slots.left"
        class="left"
      >
        <slot name="left">
        </slot>
      </div>
      <div
        v-for="button in processData"
        :key="button.index"
        class="image-and-text"
        :name="button[iconProp]"
        :fill="button[colorProp]"
        :size="size"
        @click.stop="buttonClick(button)"
      >
        <el-tooltip
          class="tool-tips"
          effect="dark"
          :content="button[nameProp]"
          :disabled="!button[nameProp] || !showToolTips"
        >
          <div class="button">
            <en-icon
              v-if="showButtonIcon"
              :name="button[iconProp]"
              :size="size"
              :style="{fill:button[colorProp]}"
            >
            </en-icon>
            <div
              v-if="showButtonName"
              class="text"
            >
              {{ button[nameProp] }}
            </div>
          </div>
        </el-tooltip>
      </div>
      <div
        v-if="$slots.right"
        class="right"
      >
        <slot name="right">
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MultiButton",
  props: {
    data: {
      type: Array,
      default: function() {
        return [];
      }
    },
    // 按钮的大小,支持small normal big 以及传入一个width
    size: {
      type: String,
      default: "small"
    },
    // 读取icon的指定key
    iconProp: {
      type: String,
      default: "icon"
    },
    // 读取name的指定key
    nameProp: {
      type: String,
      default: "name"
    },
    // 读取图片颜色的指定key
    colorProp: {
      type: String,
      default: "iconColor"
    },
    // 是否展示按钮名称(显示在页面上)
    showButtonName: {
      type: Boolean,
      default: false
    },
    // 是否展示按钮图标
    showButtonIcon: {
      type: Boolean,
      default: true
    },
    // 是否展示toolTips
    showToolTips: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    processData() {
      return this.data.map((data, index) => {
        data.index = index;
        return data;
      });
    }
  },
  methods: {
    buttonClick(button) {
      this.$emit("button-click", button.index, button);
    }
  }
};
</script>
<style lang="scss" scoped>
.en-multi-button {
  display: inline-block;
  .buttons {
    display: flex;
    padding-right: 15px;
    height: 100%;
    align-items: center;
    .left,
    .right {
      flex-shrink: 0;
      flex-grow: 0;
      margin-left: 15px;
    }
    .image-and-text {
      margin-left: 15px;
      .tool-tips {
        display: flex;
        align-items: center;
        line-height: 12px;
        cursor: pointer;
        .text {
          margin-left: 2px;
          padding: 0;
          font-size: 12px;
          flex-shrink: 0;
          flex-grow: 0;
        }
      }
    }
  }
}
</style>

