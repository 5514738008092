<template>
  <div class="en-user-logo" :style="bindStyle">
    <en-icon v-if="icon" :name="icon" :size="iconSize"></en-icon>
    <span v-else-if="!calculatedImageUrl || calculatedImageUrl.length === 0">{{ showName }}</span>
    <el-image v-else class="image" :src="calculatedImageUrl" fit="fill"></el-image>
  </div>
</template>
<script>
import md5 from "js-md5";

export default {
  name: "EnUserLogo",
  props: {
    size: {
      type: [String, Number],
      default: "50px"
    },
    imageUrl: {
      type: String,
      default: ""
    },
    userName: {
      type: String,
      default: "",
      required: true
    },
    icon: {
      type: String,
      default: ""
    }
  },
  computed: {
    // 转换过后的imageURl
    calculatedImageUrl() {
      if (!this.imageUrl || this.imageUrl.length === 0) {
        return "";
      }
      if (this.imageUrl.indexOf("http") !== 0) {
        let prefix = this.$store.getters.urlParam && this.$store.getters.urlParam.baseUrl;
        if (this.imageUrl.indexOf("/") !== 0) {
          prefix = prefix + "/";
        }
        return prefix + this.imageUrl;
      }
      return this.imageUrl;
    },
    iconSize() {
      return parseFloat(this.size, 10) * 0.6 + "px";
    },
    bindStyle() {
      const width = parseFloat(this.size, 10);
      let fontSize = parseFloat(this.size, 10) / 3.2;
      if (this.showName.length === 1) {
        fontSize = parseFloat(this.size, 10) / 2.2;
      }
      const style = {
        width: width + "px",
        height: width + "px",
        lineHeight: width + "px",
        borderRadius: width / 2 + "px",
        fontSize: fontSize + "px"
      };
      if (this.icon) {
        delete style["fontSize"];
      }
      if (this.imageUrl && this.imageUrl.length > 0) {
        return style;
      } else {
        return {
          "background-color": this.showColor,
          ...style
        };
      }
    },
    showColor() {
      const colorArray = ["#78cd60", "#fda800", "#73ade8"];
      /* eslint-disable */
      return colorArray[
      md5(this.userName)
        .substr(-1, 1)
        .charCodeAt() % 3
        ];
    },
    showName() {
      let name = this.userName;
      var zhcnReg = /[\u4e00-\u9fa5]/g;
      var numReg = /^[0-9]*$/;
      // var enReg = /^[A-Za-z]+$/
      if (zhcnReg.test(name) || numReg.test(name)) {
        if (name.length > 2) {
          name = name.substring(name.length - 2, name.length);
        }
      } else {
        name = name.split(" ");
        if (name.length === 1) {
          name = name[0].substring(0, 2);
        } else {
          name =
            name[name.length - 2].substring(0, 1) +
            name[name.length - 1].substring(0, 1);
        }
        name = name.toUpperCase();
      }
      return name;
    }
  }
};
</script>

<style lang="scss" scoped>
  .en-user-logo {
    text-align: center;
    color: white;
    font-weight: bold;
    display: inline-block;
    .en-icon {
      vertical-align: middle;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
</style>
