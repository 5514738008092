<template>
  <div class="en-and-or">
    <div class="content">
      <div class="left">
        <div class="icon">
          且
          <div
            class="hide"
            title="删除"
            @click.stop="deleteItem"
          >
            <en-icon
              name="shibai"
              size="18"
              style="color:red"
            >
            </en-icon>
          </div>
        </div>
        <div
          class="line-area"
          :style="{paddingTop:computedPadding,paddingBottom:computedPadding}"
        >
          <div
            v-for="item in lengthOfAnd"
            :key="item"
            class="line"
            :style="{height:computedLineHeight}"
          >
          </div>
        </div>
      </div>
      <div class="right">
        <slot></slot>
        <div
          class="add-button"
          @click.stop="add"
        >
          +
        </div>
      </div>
    </div>
    <el-divider>
      <div class="gray-color">
        ( 或 )
      </div>
    </el-divider>
  </div>
</template>

<script>
import thistype from "thistype"

export default {
  name: "EnAndOr",
  props: {
    lengthOfAnd: {
      type: Number,
      default: 2
    },
    padding: {
      type: [Number, String],
      default: 17
    },
    lineHeight: {
      type: [Number, String],
      default: 43
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    computedPadding() {
      if (thistype(this.padding, "number")) {
        return this.padding + "px"
      } else {
        return this.padding
      }
    },
    computedLineHeight() {
      if (thistype(this.lineHeight, "number")) {
        return this.lineHeight + "px"
      } else {
        return this.lineHeight
      }
    }
  },
  methods: {
    deleteItem() {
      this.$emit("deleteItem", this.index)
    },
    add() {
      this.$emit("add", this.index)
    }
  }
}
</script>
<style scoped lang="scss">
  .en-and-or {
    width: 100%;

    .content {
      width: 100%;
      overflow: auto;
      display: flex;
      justify-content: flex-start;

      .left {
        display: inline-block;
        vertical-align: middle;
        width: 90px;
        min-width: 90px;

        .icon {
          display: inline-block;
          background-color: #f9fafd;
          border: 1px solid #f3f3f3;
          color: #179eda;
          line-height: 34px;
          text-align: center;
          width: 34px;
          height: 34px;
          font-size: 12px;
          border-radius: 50%;
          vertical-align: middle;
          position: relative;

          &:hover .hide {
            display: block;
          }

          .hide {
            position: absolute;
            top: -8px;
            right: -4px;
            display: none;
            cursor: pointer;
          }
        }

        .line-area {
          display: inline-block;
          vertical-align: middle;
          margin-left: 24px;
          margin-right: 10px;

          .line {
            display: block;
            box-sizing: border-box;
            width: 20px;
            height: 38px;
            border: {
              left: 1px solid #dddddd;
              top: 1px solid #dddddd;
            }

            &:first-child {
              border-top-left-radius: 5px;
            }

            &:last-child {
              border-bottom-left-radius: 5px;
              border-bottom: 1px solid #dddddd;
            }
          }
        }
      }

      .right {
        display: inline-block;
        vertical-align: middle;

        .add-button {
          box-sizing: border-box;
          width: 72px;
          height: 32px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(232, 236, 242, 1);
          border-radius: 4px;
          line-height: 30px;
          text-align: center;
          font-size: 20px;
          color: #179eda;
          font-weight: bold;
          -webkit-user-select: none;
        }
      }
    }

    .gray-color {
      text-align: center;
      font-size: 12px;
      color: #aeaeae;
    }
  }
</style>
