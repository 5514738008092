<!--
 * @Author: zql
 * @Date: 2020-03-13 15:29:14
 * @LastEditTime: 2020-06-02 14:36:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \enfry-components\packages\components\en-result\src\noCustom\custom.vue
-->
<template>
  <div ref="NoCustom" class="nocustom">
    <img class="icon-img" :src="this.imgPath" :alt="this.title" />
    <div class="title" v-html="title"></div>
    <div class="subtitle" v-html="subTitle"></div>
  </div>
</template>

<script>
export default {
  name: "NoData",
  props: {
    type: { type: String, default: null },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: [String, Number],
      default: "normal"
    },
    imgPath: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    }
  }
};
</script>
<style lang="scss" scoped>
.nocustom {
  text-align: center;
}
</style>
