import XEUtils from "xe-utils/methods/xe-utils";

/**
 * project: enfry-components
 * fileName: business
 * Created by 徐俊 on 2020/08/06
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */
const businessFieldType = {
  default: 0, // 区域
  inputCommon: 1, // 输入框不限制
  multiText: 2, // 多行文本,根据编辑权限看是否可以输入
  inputNumber: 3, // 输入框数字
  inputAmount: 4, // 输入框金额
  select: 5, // 左右展示 + ">" 单选
  associatedData: 6, // 关联数据
  date: 7, // 日期 字符串
  checkBox: 8, // 复选 唯一，标记完成
  attachment: 9, // 附件
  newProgress: 10, // 进度 唯一
  detailParent: 11, // 明细或者子表
  multiSelect: 15, // 多选
  pureText: 16, // 纯文本
  jianGang: 18, // 兼岗
  shouFu: 19, // 收付信息
  onlyViewAssociatedData: 20, // 关联数据，直接能查看 弃用
  basicDataControl: 28, // 数据建模控件
  address: 29, // 地址
  subItem: 30, // 添加子项控件 唯一
  line: 31, // 分割线 App展示g高度为8的空白行
  superior: 33, // 所属上级
  tabControl: 34, // 开票选项卡
  subSet: 35, // 子集（特殊的二级明细区）
  imageAttachment: 36, // 图片附件
  cashObject: 37, // 收付对象 唯一
  associatedObject: 38, // 关联对象控件
  timeAxis: 39, // 时间轴控件
  ocr: 40,
  sign: 41, // 签到控件
  area: 42, // 区域控件
  textComposition: 43, // 图文合成
  seeFlow: 44, // 配置的流程
  multiTab: 45, // 页签
  mileStone: 46, // 里程碑
  // 以为为自定义的类型
  notifier: 8888, // 通知人
  flow: 8889, // 自由流
  comment: 8890, // 评论点赞
  approvalComment: 8891, // 审批概要
  log: 8892, // 日志
  approvalOpinion: 8894 // 审批意见
};

// 业务逻辑相关代码
export const Business = {
  businessFieldType,
  // 字段类型
  fieldType(headData) {
    return headData ? Number(headData.fieldType || headData.type) : 0;
  },
  // 获得el-date-picker类型
  elDateTypeWithTimeFormat(timeFormat) {
    let dateType;
    switch (Number(timeFormat)) {
      case 1:
      case 2:
      case 3:
        dateType = "monthrange";
        break;
      case 4:
      case 6:
      case 8:
        dateType = "daterange";
        break;
      case 5:
      case 7:
      case 9:
      case 10:
      case 11:
        dateType = "datetimerange";
        break;
      case 12:
        dateType = "yearrange";// TODO: 组件区间缺少年区间
        break;
      default:// 默认年月日(需求)
        dateType = "daterange";
        break;
    }
    return dateType;
  },
  // 格式
  dateFormatWithTimeFormat(timeFormat = 0) {
    const formatDateMap = {
      0: "yyyy-MM-dd",
      1: "yyyy-MM",
      2: "yyyy/MM",
      3: "yyyyMM",
      4: "yyyy/MM/dd",
      5: "yyyy/MM/dd HH:mm",
      6: "yyyyMMdd",
      7: "yyyyMMdd HH:mm",
      8: "yyyy-MM-dd",
      9: "yyyy-MM-dd HH:mm",
      10: "yyyy-MM-dd HH:mm:ss",
      11: "yyyy-MM-dd HH",
      12: "yyyy"
    };
    return formatDateMap[timeFormat];
  },
  endDateFormatWithTimeFormat(timeFormat) {
    let formatDate;
    switch (Number(timeFormat)) {
      case 5:
        formatDate = "yyyy-MM-dd HH:mm:59";
        break;
      case 7:
      case 9:
        formatDate = "yyyy-MM-dd HH:mm:59";
        break;
      case 10:
        formatDate = "yyyy-MM-dd HH:mm:ss";
        break;
      case 11:
        formatDate = "yyyy-MM-dd HH:59:59";
        break;
      default:
        formatDate = "yyyy-MM-dd 23:59:59";
        break;
    }
    return formatDate;
  },
  dateStringWithTimeFormat(date, timeFormat) {
    return XEUtils.toDateString(date, Business.dateFormatWithTimeFormat(timeFormat));
  },
  getOpTypeData(opType = 0) {
    const opTypeMap = {
      0: { op: "", name: "" },
      1: { op: " == ", name: "等于" },
      2: { op: " != ", name: "不等于" },
      3: { op: " in ", name: "包含" },
      4: { op: " nin", name: "不包含" },
      5: { op: " >  ", name: "大于" },
      6: { op: " >= ", name: "大于或等于" },
      7: { op: " <= ", name: "小于或等于" },
      8: { op: " <  ", name: "小于" },
      9: { op: "", name: "不包含于" },
      10: { op: "", name: "包含于" },
      11: { op: "", name: "属于" },
      12: { op: "", name: "不属于" },
      13: { op: "", name: "不为空" },
      14: { op: "", name: "为空" },
      15: { op: "", name: "时间段" }
    };
    return opTypeMap[opType] || { op: "", name: "" };
  }
};

export default Business;
