/* eslint-disable */
/*
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-24 15:32:22
 * @LastEditTime: 2020-04-28 17:22:11
 */
import { BussinessFieldType } from "../../../headerFiles/BusinessCommonHeader.js";
import deepcopy from "deepcopy";

const notificationConditionData = {
  props: { datas: Array },
  data() {
    return { conditionConfig: [] };
  },
  mounted() {
    this.conditionConfig = deepcopy(this.datas);
  },
  computed: {
    // 触发条件 第一个字段
    triggerOptions() {
      switch (this.type) {
        case "basic":
        case "business":
          {
            return [
              {
                value: "1",
                label: "操作按钮"
              },
              {
                value: "2",
                label: "业务字段"
              },
              {
                value: "3",
                label: "字段变化"
              }
            ];
          }
          break;
        case "flow":
          {
            return [
              {
                value: "1",
                label: "操作按钮"
              },
              {
                value: "2",
                label: "业务字段"
              }
            ];
          }
          break;
        case "notificationBasic":
        case "notificationBusiness":
        case "notificationFlow":
        case "notificationHR":
          return [
            {
              value: "1",
              label: "操作按钮"
            }
          ];
          break;
        default:
          return [];
      }
    },
    // 默认添加的按钮
    defaultAddButton() {
      switch (this.type) {
        case "basic":
        case "notificationBasic":
        case "business":
        case "notificationbBusiness":
          return {
            conditionType: "1",
            btnKey: "add",
            isAdd: false,
            andOr: "1"
          };
        case "flow":
        case "notificationFlow":
          return {
            conditionType: "1",
            btnKey: "submit",
            isAdd: false,
            andOr: "1"
          };
        default:
          return {};
      }
    },
    // 操作按钮的
    conditionButtons() {
      let btns = [];
      switch (this.type) {
        case "basic":
        case "notificationBasic":
          btns = [
            { value: "add", label: "新增" },
            { value: "edit", label: "编辑" },
            { value: "delete", label: "删除" },
            { value: "disable", label: "停用" },
            { value: "enable", label: "启用" }
          ];
          break;
        case "business":
        case "notificationbBusiness":
          btns = [
            { value: "add", label: "新增" },
            { value: "edit", label: "编辑" },
            { value: "delete", label: "删除" },
            { value: "recover", label: "恢复" }
          ];
          break;
        case "flow":
        case "notificationFlow":
          btns = [
            { value: "submit", label: "提交" },
            { value: "agree", label: "同意" },
            { value: "reject", label: "退回" },
            { value: "rebut", label: "驳回" },
            { value: "signed", label: "加签" },
            { value: "propose", label: "拟办" },
            { value: "stop", label: "终止" }
          ];
          break;
        default:
          btns = [];
          break;
      }
      return btns;
    },
    // 操作符
    operatorOptions() {
      return function(filedType) {
        switch (filedType) {
          case BussinessFieldType.date:
          case BussinessFieldType.inputNumber:
          case BussinessFieldType.inputAmount:
            {
              return [
                { value: "5", label: "大于" },
                { value: "6", label: "大于或等于" },
                { value: "8", label: "小于" },
                { value: "7", label: "小于或等于" },
                { value: "1", label: "等于" },
                { value: "2", label: "不等于" },
                { value: "13", label: "不为空" },
                { value: "14", label: "为空" }
              ];
            }
            break;
          case BussinessFieldType.select:
            return [
              { value: "9", label: "不包含于" },
              { value: "10", label: "包含于" },
              { value: "13", label: "不为空" },
              { value: "14", label: "为空" },
              { value: "3", label: "包含" },
              { value: "4", label: "不包含" },
              { value: "11", label: "属于" },
              { value: "12", label: "不属于" }
            ];
            break;
          case BussinessFieldType.inputCommon:
            return [
              { value: "1", label: "等于" },
              { value: "2", label: "不等于" },
              { value: "3", label: "包含" },
              { value: "4", label: "不包含" },
              { value: "13", label: "不为空" },
              { value: "14", label: "为空" }
            ];
          default:
            return [];
        }
      };
    },
    compareColumns() {
      return function(column) {
        // 排除当前字段, 把模板上的其他字段拿出来 还需要固定加上 固定数值/固定金额
        if (column.fieldType === BussinessFieldType.inputNumber) {
          return [
            {
              value: 1,
              label: "固定数值"
            }
          ];
        } else if (column.fieldType === BussinessFieldType.inputAmount) {
          return [
            {
              value: 1,
              label: "固定金额"
            }
          ];
        } else if (column.fieldType === BussinessFieldType.date) {
          return [
            {
              value: 1,
              label: "系统当前时间"
            },
            {
              value: 2,
              label: "固定时间"
            }
          ];
        }
      };
    }
  }
};

export default notificationConditionData;
