<!--
 * @Description: 通知对象
 * @Author: 袁银华
 * @LastEditors: 梁平贤
 * @Date: 2019-08-23 16:31:48
 * @LastEditTime: 2019-10-10 11:09:14
 -->
/* eslint-disable require-jsdoc */

<template>
  <el-dialog
    title="通知对象"
    :visible="visible"
    class="notify-object"
    width="1000px"
    :close-on-click-modal="false"
    append-to-body
    @close="close"
  >
    <div class="notify-object-tabs">
      <!-- 标签页 -->
      <ul class="notify-tabs">
        <li
          v-for="(item, index) in tabDatas"
          :key="index"
          :class="[tabStyle, item.selected ? tabSelctedStyle : '']"
          @click="changeSelectItem(item)"
        >
          <div
            class="tab-icon"
            :style="item.tabIconStyle"
          >
            <en-icon
              :name="item.icon"
              style="color:#ffffff"
            >
            </en-icon>
          </div>
          <div
            class="notify-tab__name"
            :style="item.selected ? 'color:#333333' : 'color:#636C78'"
          >
            {{ item.name }}
          </div>
        </li>
      </ul>
      <!-- 标签内容 -->
      <div class="notify-tabs__content">
        <div
          v-for="(item, index) in tabDatas"
          v-show="item.selected"
          :key="index"
        >
          <div class="notify-add">
            <!-- 固定类型选择 -->
            <en-select
              v-model="item.typeData.value"
              :value-key="item.type"
              :options="item.typeData.options"
            >
            </en-select>
            <!-- 动态级联数据 -->
            <template v-for="(cascadeConfig, cascadeIndex) in [item.cascadeConfig1, item.cascadeConfig2]">
              <en-select
                v-if="cascadeConfig.show"
                :key="'cascadeConfig' + cascadeIndex"
                v-model="cascadeConfig.value"
                :options="cascadeConfig.options"
                :placeholder="cascadeConfig.placeholder"
                :loading="cascadeConfig.options === null"
                :tree-data="cascadeConfig.options"
                :tree-select="cascadeConfig.tree"
                :default-props="defaultProps"
                lazy-load
                :page-load="cascadeConfig.loadNode"
                @visible-change="cascadeConfig.visibleChange"
              >
              </en-select>
            </template>
            <!-- 动态选项 -->
            <en-button @click="addData(item)">
              添加
            </en-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 数据 -->
    <el-table
      :data="tableData"
      size="mini"
      max-height="279px"
      :header-cell-style="cellStyle"
      :cell-style="cellStyle"
      :row-class-name="rowClass"
      border
    >
      <el-table-column
        prop="typeatext"
        label="类型"
      >
      </el-table-column>
      <el-table-column label="内容">
        <template slot-scope="scope">
          <span>{{ getContent(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="operator"
        label="并/或"
      >
        <template slot-scope="scope">
          <div class="operator">
            <en-select
              v-model="scope.row.operator"
              :options="operatorOptions"
              @visible-change="visbleChange($event, scope.row)"
            >
            </en-select>
            <span class="viewText">{{ scope.row.operator === "000" ? "并且" : "或者" }}</span>
            <div
              class="delete"
              @click="deleteData(scope)"
            >
              <en-icon name="shanchu-liebiao"></en-icon>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 保存 -->
    <div slot="footer">
      <en-button @click="save">
        保存
      </en-button>
    </div>
  </el-dialog>
</template>

<script>
// import { type } from "os";
// import { types } from "util";
// import { fips } from "crypto";
// import timewait from "timewait";
// import { toUnicode } from "punycode";
import Vue from "vue"
import { v4 as uuidv4 } from "uuid";
export default {
  name: "NotifyObject",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "flow",
      validator: function(value) {
        // 这个值必须匹配下列字符串中的一个
        return [
          "business", // 基础数据或者业务建模模板配置页面
          "notice", // 通知建模配置页面
          "flow", // 流程配置页面
          "flowExecutor"// 流程配置页面执行人
        ].indexOf(value) !== -1
      }
    }
  },
  data() {
    return {
      tabStyle: "notify-tab-pane",
      tabSelctedStyle: "notify-tab-selcted-pane",
      operatorOptions: [{
        "label": "并且",
        "value": "000"
      }, {
        "label": "或者",
        "value": "001"
      }],
      defaultProps: {
        children: "children",
        label: "label",
        pageSize: 15
      },
      staffTabData: {
        isBusinessValue: false,
        tabType: "staff",
        selected: true,
        name: "指定具体人员",
        icon: "tongzhijianmo-HRguanlileixing",
        tabIconStyle: { "background-color": "#4694DF" },
        typeData: {
          value: "",
          name: "",
          options: []
        }, // 类型值
        cascadeConfig1: {
          name: "",
          value: "",
          placeholder: "请选择",
          multiple: false,
          show: false,
          isStringValue: false,
          options: [],
          tree: false,
          valueChange: null,
          loadNode: "",
          visibleChange: ""
        },
        cascadeConfig2: {
          name: "",
          value: "",
          placeholder: "请选择",
          multiple: false,
          show: false,
          isStringValue: false,
          options: [],
          tree: false,
          valueChange: null,
          loadNode: "",
          visibleChange: ""
        },
        types: {
          staff: "1", // 指定具体人员
          directSupervisor: "2", // 直属领导
          initiatorHimself: "4", // 发起人自己
          departmentHead: "6", // 部门负责人
          userDepartmentHead: "7", // 用户所在部门直属领导
          agencyStaff: "8"// 指定机构部门人员
        },
        typeNames: null
      },
      postTabData: {
        tabType: "position",
        isBusinessValue: false,
        selected: false,
        name: "根据岗位筛选",
        icon: "genjugangweishaixuan-liucheng",
        tabIconStyle: { "background-color": "#26C393" },
        typeData: {
          value: "",
          name: "",
          options: []
        },
        cascadeConfig1: {
          name: "",
          value: "",
          placeholder: "请选择",
          multiple: false,
          show: false,
          isStringValue: false,
          options: [],
          tree: false,
          valueChange: null,
          loadNode: "",
          visibleChange: ""
        },
        cascadeConfig2: {
          name: "",
          value: "",
          placeholder: "请选择",
          multiple: false,
          show: false,
          isStringValue: false,
          options: [],
          tree: false,
          valueChange: null,
          loadNode: "",
          visibleChange: ""
        },
        types: {
          initiatorDepartmentPost: "3", // 发起人所在部门的岗位
          initiatorCompanyPost: "2", // 发起人所在公司的岗位
          departmentPost: "5"// 指定部门的岗位
        },
        typeNames: null
      },
      businessTabData: {
        tabType: "role",
        selected: false,
        isBusinessValue: false,
        name: "根据业务筛选",
        icon: "genjuyewushaixuan-liucheng",
        tabIconStyle: { "background-color": "#F76B6B" },
        typeData: {
          value: "",
          name: "",
          options: []
        },
        cascadeConfig1: {
          name: "",
          value: "",
          placeholder: "请选择",
          multiple: false,
          show: false,
          isStringValue: false,
          options: [],
          tree: false,
          valueChange: null,
          loadNode: "",
          visibleChange: ""
        },
        cascadeConfig2: {
          name: "",
          value: "",
          placeholder: "请选择",
          multiple: false,
          show: false,
          isStringValue: false,
          options: [],
          tree: false,
          valueChange: null,
          loadNode: "",
          visibleChange: ""
        },
        types: {
          nodeApprover: "1", // 指定环节审批人
          staff: "2", // 指定人员字段
          directSupervisor: "3", // 指定人员字段的直属领导
          approvedAllStaff: "4", // 指定已审批环节所有审批人
          businessStaff: "5", // 指定业务字段对应的人员
          staffDepartmentPost: "99", // 指定人员字段所在部门的岗位
          businessDepartmentHead: "98"// 指定业务字段的部门负责人
        },
        typeNames: null
      },
      roleTabData: {
        tabType: "role",
        isBusinessValue: false,
        selected: false,
        name: "根据角色筛选",
        icon: "genjujiaoseshaixuan-liucheng",
        tabIconStyle: { "background-color": "#FFAD2C" },
        typeData: {
          value: "1",
          name: "指定流程角色",
          options: [{
            "label": "指定流程角色",
            "value": "1"
          }]
        },
        cascadeConfig1: {
          name: "",
          value: "",
          placeholder: "请选择",
          multiple: false,
          show: true,
          isStringValue: false,
          options: [{
            "label": "1212",
            "value": "112312"
          }],
          tree: false,
          valueChange: null,
          loadNode: "",
          visibleChange: ""
        },
        cascadeConfig2: {
          name: "",
          value: "",
          placeholder: "请选择",
          multiple: false,
          show: false,
          isStringValue: false,
          options: [],
          tree: false,
          valueChange: null,
          loadNode: "",
          visibleChange: ""
        },
        types: {
          initiatorDepartmentPost: "3", // 发起人所在部门的岗位
          initiatorCompanyPost: "2", // 发起人所在公司的岗位
          departmentPost: "5"// 指定部门的岗位
        },
        typeNames: null
      },
      tableData: [
        {
          nav: "position",
          operator: "000",
          typea: "5",
          typeatext: "指定部门的岗位",
          typeb: {
            id: "225164323751723008",
            name: "财务部"
          },
          typec: "278157723241021440",
          typectext: "加扣扣",
          uuid: "2fa14c91-6a51-49c6-87ea-a57ab0c7cbd0",
          visible: false
        }
      ]
    }
  },
  computed: {
    tabDatas() {
      if (this.type === "flowExecutor") {
        return [
          this.staffTabData,
          this.postTabData,
          this.roleTabData,
          this.businessTabData
        ]
      } else {
        return [
          this.staffTabData,
          this.postTabData,
          this.businessTabData
        ]
      }
    },
    staffTypeValue() {
      return this.staffTabData.typeData.value
    },
    postTypeValue() {
      return this.postTabData.typeData.value
    },
    businessTypeValue() {
      return this.businessTabData.typeData.value
    },
    staffTabCascadeConfig1Value() {
      return this.staffTabData.cascadeConfig1.value
    },
    businessTabCascadeConfig1Value() {
      return this.businessTabData.cascadeConfig1.value
    }
  },
  watch: {
    // 监听类型选项的变化，改变动态选项
    staffTypeValue: function(val) {
      this.updateStaffConfig(val)
      this.staffTabData.typeData.name = this.staffTabData.typeNames[val]
    },
    postTypeValue: function(val) {
      this.updatePostConfig(val)
      this.postTabData.typeData.name = this.staffTabData.typeNames[val]
    },
    businessTypeValue: function(val) {
      this.updateBusinessConfig(val)
      this.businessTabData.typeData.name = this.staffTabData.typeNames[val]
    },
    staffTabCascadeConfig1Value: function(val) {
      if (this.staffTabData.cascadeConfig1.valueChange !== null) {
        this.staffTabData.cascadeConfig1.valueChange(val)
      }
    },
    businessTabCascadeConfig1Value: function(val) {
      if (this.businessTabData.cascadeConfig1.valueChange !== null) {
        this.businessTabData.cascadeConfig1.valueChange(val)
      }
    }
  },
  created() {
    // 初始化
    this.updateStaffTabData()
    this.updatePostTabData()
    this.updateBusinessTabData()
  },
  methods: {
    close() {
      // 弹框关闭
      this.$emit("update:visible")
    },
    rowClass({ row, rowIndex }) {
      const rowData = this.tableData[rowIndex]
      if (rowData.visible) {
        return ["rowStyle", "operatorEdit"]
      } else {
        return ["rowStyle", "operatorView"]
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) { // 指定列号
        return "padding-left: 10px"
      } else {
        return "padding-left:2px"
      }
    },
    // 标签页变化
    changeSelectItem(tab) {
      if (tab.selected) {
        return
      }
      for (const item of this.tabDatas) {
        item.selected = false
      }
      tab.selected = true
    },
    // 更新类型选项
    typeOptions(types, typeNames) {
      const options = []
      for (const item of types) {
        const data = {}
        data.value = item
        data.label = typeNames[item]
        options.push(data)
      }
      return options
    },
    updateStaffTabData() {
      const typeNames = {}

      const allTypes = this.staffTabData.types
      typeNames[allTypes.staff] = "指定具体人员"
      typeNames[allTypes.directSupervisor] = "直属领导"
      typeNames[allTypes.initiatorHimself] = "发起人自己"
      typeNames[allTypes.departmentHead] = "部门负责人"
      typeNames[allTypes.userDepartmentHead] = "用户所在部门直属领导"
      typeNames[allTypes.agencyStaff] = "指定机构部门人员"

      let useTypes = null
      if (this.type === "notice") {
        useTypes = [allTypes.staff, allTypes.agencyStaff]
      } else {
        useTypes = Object.values(this.staffTabData.types)
      }
      this.staffTabData.typeNames = typeNames
      this.staffTabData.typeData.options = this.typeOptions(useTypes, typeNames)
      // 默认第一个
      this.staffTabData.typeData.value = useTypes[0]
    },
    updateStaffConfig(val) {
      const types = this.staffTabData.types
      const itemData = this.staffTabData
      this.clearData(itemData)
      if (val === types.staff) {
        itemData.cascadeConfig1.placeholder = "请选择指定人员"
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请选择指定人员！"
        }
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.multiple = true

        const self = this
        itemData.cascadeConfig1.remote = function() {
          self.queryDeptData(this)
        }
        itemData.cascadeConfig1.loadNode = function(node, resolve) {
          self.loadNode(node, resolve)
        }
        itemData.cascadeConfig1.tree = true

        itemData.cascadeConfig1.remote()
      } else if (val === types.agencyStaff) {
        itemData.cascadeConfig1.placeholder = "请选择机构部门人员"
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请选择指定人员！"
        }
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.multiple = true
      } else if (val === types.directSupervisor) {
        itemData.cascadeConfig1.options = [{
          value: "001",
          label: "指定环节执行人的直属领导"
        }]
        itemData.cascadeConfig1.value = "001"
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.isStringValue = true

        itemData.cascadeConfig2.rule = {
          required: true,
          message: "请选择节点环节！"
        }
        itemData.cascadeConfig2.show = true
        itemData.cascadeConfig2.isStringValue = true
      } else if (val === types.departmentHead) {
        itemData.cascadeConfig1.options = [{
          "value": "1",
          "label": "发起人所在部门的负责人"
        }, {
          "value": "2",
          "label": "指定部门的负责人"
        }]
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.isStringValue = true

        itemData.cascadeConfig2.placeholder = "请选择部门"
        itemData.cascadeConfig2.rule = {
          required: true,
          message: "请选择部门负责人！"
        }
        itemData.cascadeConfig2.show = false

        itemData.cascadeConfig1.value = "1"
        itemData.cascadeConfig1.valueChange = function(val) {
          if (val === "2") {
            itemData.cascadeConfig2.show = true
          } else {
            itemData.cascadeConfig2.show = false
          }
        }
      }
    },
    updatePostTabData() {
      const allTypes = this.postTabData.types
      const typeNames = {}
      typeNames[allTypes.initiatorDepartmentPost] = "发起人所在部门的岗位"
      typeNames[allTypes.initiatorCompanyPost] = "发起人所在公司的岗位"
      typeNames[allTypes.departmentPost] = "指定部门的岗位"

      const useTypes = Object.values(allTypes)
      this.postTabData.typeData.options = this.typeOptions(useTypes, typeNames)
      this.postTabData.typeNames = typeNames
      // 默认第一个
      this.postTabData.typeData.value = useTypes[0]
    },
    updatePostConfig(val) {
      const types = this.postTabData.types
      const itemData = this.postTabData
      this.clearData(itemData)

      if (val === types.departmentPost) {
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请请选择部门！"
        }
        itemData.cascadeConfig1.show = true

        itemData.cascadeConfig2.rule = {
          required: true,
          message: "请选择岗位！"
        }
        itemData.cascadeConfig2.show = true
        itemData.cascadeConfig2.isStringValue = true
      } else if (val === types.initiatorDepartmentPost) {
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请选择岗位！"
        }
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.isStringValue = true
      } else if (val === types.initiatorCompanyPost) {
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请选择岗位！"
        }
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.isStringValue = true
      }
    },
    updateBusinessTabData(types) {
      const allTypes = this.businessTabData.types
      const typeNames = {}
      typeNames[allTypes.nodeApprover] = "指定环节审批人"
      typeNames[allTypes.staff] = "指定人员字段"
      typeNames[allTypes.directSupervisor] = "指定人员字段的直属领导"
      typeNames[allTypes.approvedAllStaff] = "指定已审批环节所有审批人"
      typeNames[allTypes.businessStaff] = "指定业务字段对应的人员"
      typeNames[allTypes.staffDepartmentPost] = "指定人员字段所在部门的岗位"
      typeNames[allTypes.businessDepartmentHead] = "指定业务字段的部门负责人"

      let useTypes = null
      if (this.type === "notice") {
        useTypes = [allTypes.approvedAllStaff]
      } else if (this.type === "business") {
        useTypes = [
          allTypes.staff,
          allTypes.directSupervisor,
          allTypes.businessStaff,
          allTypes.staffDepartmentPost,
          allTypes.businessDepartmentHead
        ]
      } else {
        useTypes = Object.values(allTypes)
      }

      this.businessTabData.typeNames = typeNames
      this.businessTabData.typeData.options = this.typeOptions(useTypes, typeNames)
      // 默认第一个
      this.businessTabData.typeData.value = useTypes[0]
    },
    updateBusinessConfig(val) {
      const types = this.businessTabData.types
      const itemData = this.businessTabData
      this.clearData(itemData)

      if (val === types.staff) {
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请选择指定人员字段！"
        }
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.multiple = true
      } else if (val === types.directSupervisor) {
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请选择指定人员字段的直属领导！"
        }
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.multiple = true
      } else if (val === types.businessStaff) {
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请选择业务字段！"
        }
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.isStringValue = true

        itemData.cascadeConfig2.rule = {
          required: true,
          message: "请选择业务字段！"
        }
        itemData.cascadeConfig2.show = true
        itemData.cascadeConfig2.isStringValue = true

        itemData.isBusinessValue = true

        itemData.cascadeConfig1.valueChange = function(val) {
          itemData.cascadeConfig2.value = ""
        }
      } else if (val === types.staffDepartmentPost) {
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请选择人员字段！"
        }
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.multiple = true
        itemData.cascadeConfig1.isStringValue = true

        itemData.cascadeConfig2.rule = {
          required: true,
          message: "请选择岗位！"
        }
        itemData.cascadeConfig2.show = true
        itemData.cascadeConfig2.isStringValue = true

        itemData.isBusinessValue = true
        // 值改变回调
        itemData.cascadeConfig1.valueChange = function(val) {
          itemData.cascadeConfig2.value = ""
        }
      } else if (val === types.businessDepartmentHead) {
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请选择人员字段！"
        }
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.isStringValue = true

        itemData.isBusinessValue = true
      } else if (val === types.nodeApprover) {
        itemData.cascadeConfig1.rule = {
          required: true,
          message: "请选择环节审批人！"
        }
        itemData.cascadeConfig1.show = true
        itemData.cascadeConfig1.isStringValue = true
      }
    },
    deleteData(scope) {
      this.tableData.splice(scope.$index, 1)
    },
    save() {
      this.close()
      this.$emit("valueChange", this.tableData)
    },
    visbleChange(visble, row) {
      Vue.set(row, "visible", visble)
    },
    checkData(tabData) {
      const items = [tabData.cascadeConfig1, tabData.cascadeConfig2]
      for (const item of items) {
        if (item.rule === undefined || !item.show) {
          continue
        }
        if (item.rule.required) {
          if (item.name.length === 0 && item.value.length === 0) {
            this.$message(item.rule.message)
            return false
          }
        }
      }
      return true
    },
    uuid() {
      return ("xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0; var v = c === "x" ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      }))
    },
    addData(tabData) {
      if (!this.checkData(tabData)) {
        return
      }
      const data = {}
      data.nav = tabData.tabType
      data.operator = "000"
      // data.uuid = this.uuid()
      data.uuid = uuidv4()
      data.typea = tabData.typeData.value
      data.typeatext = this.selectedDataValue(tabData.typeData).name
      if (tabData.cascadeConfig1.show) {
        const { name, value, area } = this.selectedDataValue(tabData.cascadeConfig1)
        if (tabData.cascadeConfig1.isStringValue === true) {
          data.typeb = value
          data.typebtext = name
        } else {
          data.typebtext = ""
          data.typeb = {
            id: value,
            name: name
          }
        }
        if (tabData.isBusinessValue) {
          data.typectext = ""
          data.typec = {
            id: value,
            name: name,
            area: area
          }
          data.area = area
        }
      }
      if (tabData.cascadeConfig2.show) {
        const { name, value } = this.selectedDataValue(tabData.cascadeConfig2)
        if (tabData.cascadeConfig2.isStringValue === true) {
          if (tabData.isBusinessValue) {
            data.typed = value
            data.typedtext = name
          } else {
            data.typec = value
            data.typectext = name
          }
        } else {
          data.typectext = ""
          data.typec = {
            id: value,
            name: name
          }
        }
      }
      let hasRepeat = false
      for (const item of this.tableData) {
        if (item.nav !== data.nav || item.typea !== data.typea) {
          continue
        }
        if (this.compareValue(data.typeb, item.typeb) &&
          this.compareValue(data.typec, item.typec) &&
          this.compareValue(data.typed, item.typed)) {
          hasRepeat = true
          break
        }
      }
      console.log(data)

      if (hasRepeat === true) {
        this.$message("不能重复添加！")
        return
      }
      this.tableData.push(data)
    },
    compareValue(value1, value2) {
      if ((value1 === undefined || value1 === "") &&
        (value2 === undefined || value2 === "")) {
        return true
      }
      if (value1 === value2) {
        return true
      }
      if (value1.id === value2.id) {
        return true
      }
      return false
    },
    clearData(itemData) {
      this.clearCascadeData(itemData.cascadeConfig1)
      this.clearCascadeData(itemData.cascadeConfig2)
      itemData.isBusinessValue = false
    },
    clearCascadeData(cascadeConfig) {
      cascadeConfig.show = false
      cascadeConfig.value = ""
      cascadeConfig.name = ""
      cascadeConfig.isStringValue = false
      cascadeConfig.valueChange = null
      cascadeConfig.options = [
        {
          "label": "大家克服了",
          "value": "111",
          "area": "MAIN"
        }
      ]
      cascadeConfig.loadNode = ""
      cascadeConfig.visbleChange = ""
      cascadeConfig.tree = false
    },
    getContent(par) {
      if (par.nav === "staff") {
        if (par.typea === "1" || par.typea === "8") {
          return par.typeb.name
        } else if (par.typea === "2") {
          if (par.typeb === "000") {
            return par.typebtext
          } else if (par.typeb === "001") {
            return par.typebtext + " " + par.typectext
          } else if (par.typeb === "002") {
            return par.typebtext + " " + par.typectext
          }
        } else if (par.typea === "3") {
          return par.typebtext
        } else if (par.typea === "4" || par.typea === "7") {
          return ""
        } else if (par.typea === "6") {
          if (par.typeb === "1") {
            return par.typebtext
          } else if (par.typeb === "2") {
            return par.typec.name + " 负责人"
          } else if (par.typeb === "3") {
            return par.typectext + " 字段"
          }
        }
      } else if (par.nav === "position") {
        if (par.typea === "3" || par.typea === "4") {
          return par.typebtext + " 岗位"
        } else if (par.typea === "5") {
          return par.typeb.name + "部门，" + par.typectext + " 岗位"
        } else if (par.typea === "7") {
          return par.typebtext + "字段， " + par.typectext + " 岗位"
        }
      } else if (par.nav === "role") {
        if (par.typea === "1") {
          return par.typebtext
        } else if (par.typea === "4") {
          return "-"
        } else if (par.typea === "5") {
          return par.typebtext + "，" + par.typec.name
        } else if (par.typea === "99") {
          return par.typec.name + "，" + par.typedtext
        } else if (par.typea === "97") {
          return "-"
        } else {
          return par.typec.name
        }
      }
    },
    selectedDataValue(cascadeData) {
      const value = this.stringWithValue(cascadeData.value)

      const options = this.allOptions(cascadeData.options)

      const datas = options.filter((item) => {
        return value.indexOf(item.value) !== -1// 筛选出匹配数据
      })
      let names = datas.map(function(item) {
        return item.label
      })
      names = this.stringWithValue(names)

      let areas = datas.map(function(item) {
        return item.area
      })
      areas = this.stringWithValue(areas)
      return {
        name: names,
        value: value,
        area: areas
      }
    },
    // 所有选项值，包含树结构子节点数据
    allOptions(options) {
      let all = []
      for (const item of options) {
        all.push(item)
        if (Object.prototype.toString.call(item.children) === "[object Array]") {
          all = all.concat(this.allOptions(item.children))
        }
      }
      return all
    },
    stringWithValue(values) {
      if (Object.prototype.toString.call(values) === "[object String]") {
        return values
      } else if (Object.prototype.toString.call(values) === "[object Array]") {
        return values.join(",")
      }
      return ""
    },
    async loadNode(node, resolve) {
      // await timewait(1);注释掉了 第三方库
      let data = {}
      if (node.data.id === "225073633627734016") {
        data = {
          "id": "225073633627734016",
          "msgType": "N",
          "rspCode": "000000",
          "rspData": [
            {
              "code": "0201",
              "createTime": "2017-08-24 17:53:11",
              "description": "",
              "enable": "000",
              "generation": "2",
              "hasChildren": "000",
              "id": "225164323751723008",
              "isParent": "000",
              "leader": "227053059725852672",
              "levelCode": "0,225073633627734016,225164323751723008",
              "modifyTime": "2017-12-26 20:45:23",
              "name": "财务部",
              "orgCode": "",
              "parentId": "225073633627734016",
              "parentNodeType": "000",
              "selectable": false,
              "sts": "000",
              "tenantId": "225062062644002816",
              "type": "001"
            },
            {
              "code": "001001",
              "createTime": "2017-08-23 17:53:02",
              "description": "",
              "enable": "000",
              "generation": "2",
              "hasChildren": "000",
              "id": "225073715422953472",
              "isParent": "000",
              "leader": "",
              "levelCode": "0,225073633627734016,225073715422953472",
              "modifyTime": "2017-11-20 09:37:40",
              "name": "测试部",
              "orgCode": "",
              "parentId": "225073633627734016",
              "parentNodeType": "000",
              "selectable": false,
              "sts": "000",
              "tenantId": "225062062644002816",
              "type": "001"
            },
            {
              "code": "11111",
              "createTime": "2017-10-18 17:11:03",
              "description": "",
              "enable": "000",
              "generation": "2",
              "hasChildren": "000",
              "id": "230144490197745664",
              "isParent": "000",
              "leader": "",
              "levelCode": "0,225073633627734016,230144490197745664",
              "modifyTime": "2017-11-20 09:37:18",
              "name": "移动研发部",
              "orgCode": "",
              "parentId": "225073633627734016",
              "parentNodeType": "000",
              "selectable": false,
              "sts": "000",
              "tenantId": "225062062644002816",
              "type": "001"
            },
            {
              "accountId": "287280568177000448",
              "deptName": "湖南英富莱网络技术有限公司",
              "enable": "000",
              "hasChildren": "001",
              "id": "287280567945265152",
              "isParent": "001",
              "levelCode": "0,225073633627734016",
              "mobileNo": "13657498277",
              "name": "23422525",
              "no": "123123123",
              "postName": "开发1",
              "selectable": true,
              "sort": 116,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "265482374510280704",
              "deptName": "湖南英富莱网络技术有限公司",
              "enable": "000",
              "hasChildren": "001",
              "id": "265482373977604096",
              "isParent": "001",
              "levelCode": "0,225073633627734016",
              "mobileNo": "13755037717",
              "name": "23422525",
              "no": "13755037717",
              "postName": "加扣扣",
              "selectable": true,
              "sort": 102,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "249723638366863360",
              "deptName": "湖南英富莱网络技术有限公司",
              "enable": "000",
              "hasChildren": "001",
              "id": "249723638136176640",
              "isParent": "001",
              "levelCode": "0,225073633627734016",
              "mobileNo": "13007513628",
              "name": "张家衡",
              "no": "008",
              "postName": "前端",
              "selectable": true,
              "sort": 95,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "266275127417634816",
              "deptName": "湖南英富莱网络技术有限公司",
              "enable": "000",
              "hasChildren": "001",
              "id": "266275127139762176",
              "isParent": "001",
              "levelCode": "0,225073633627734016",
              "mobileNo": "18012642244",
              "name": "1829382739",
              "no": "18012642244",
              "postName": "前端",
              "selectable": true,
              "sort": 72,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "264288772350279680",
              "deptName": "湖南英富莱网络技术有限公司",
              "enable": "000",
              "hasChildren": "001",
              "id": "264288771597402112",
              "isParent": "001",
              "levelCode": "0,225073633627734016",
              "mobileNo": "13288999999",
              "name": "cjm",
              "no": "232ssa",
              "postName": "iOS开发",
              "selectable": true,
              "sort": 16,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "259761404239675392",
              "deptName": "湖南英富莱网络技术有限公司",
              "enable": "000",
              "hasChildren": "001",
              "id": "259761403962851328",
              "isParent": "001",
              "levelCode": "0,225073633627734016",
              "mobileNo": "15122222222",
              "name": "男孩子",
              "no": "1997",
              "postName": "前端",
              "selectable": true,
              "sort": 8,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "254781606774964224",
              "deptName": "湖南英富莱网络技术有限公司",
              "enable": "000",
              "hasChildren": "001",
              "id": "254781392800448512",
              "isParent": "001",
              "levelCode": "0,225073633627734016",
              "mobileNo": "15367808399",
              "name": "iuiuyiyu",
              "no": "sffaf",
              "postName": "前端",
              "selectable": true,
              "sort": 4,
              "type": "003",
              "userLogo": ""
            }
          ],
          "rspMsg": "",
          "schemeVersionId": "",
          "submitToken": "a70f0af4-a6d0-483a-ab0c-6f587bf71ea1",
          "tenantId": "225062062644002816"
        }
      } else if (node.data.id === "225164323751723008") {
        data = {
          "id": "225164323751723008",
          "msgType": "N",
          "rspCode": "000000",
          "rspData": [
            {
              "accountId": "227053059753115648",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "227053059725852672",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15100000001",
              "name": "测试1",
              "no": "1231231",
              "postName": "开发2",
              "selectable": true,
              "sort": 124,
              "type": "003",
              "userLogo": "https://192.168.1.30:8243/Storage-api/img.api?token=85878D5F87E75BC420E761AE9BBEEC12&ts=3284071923874102938&fileCode=？？？？？？？？？？？？_201809031051032.jpg&appKey=enfrytest"
            },
            {
              "accountId": "275893972910473216",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "200595122077827072",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15367808360",
              "name": "余龙飞",
              "no": "1000009999",
              "postName": "加扣扣",
              "selectable": true,
              "sort": 119,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "264295279169110016",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "264295278741291008",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "13344444444",
              "name": "小麒麟",
              "no": "0333",
              "postName": "前端",
              "selectable": true,
              "sort": 70,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "260496038290259968",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "260496038010290176",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15367809993",
              "name": "kaikai",
              "no": "15367809993",
              "postName": "开发1",
              "selectable": true,
              "sort": 69,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "262558480485842944",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "262558480383082496",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "13007513678",
              "name": "123",
              "no": "12342123",
              "postName": "前端",
              "selectable": true,
              "sort": 67,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "260493825699479552",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "260493825314652160",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15367809991",
              "name": "凯凯",
              "no": "15367809991",
              "postName": "开发1",
              "selectable": true,
              "sort": 64,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "254761078807855104",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "254760996649828352",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15367809360",
              "name": "678900987",
              "no": "678900987",
              "postName": "开发2",
              "selectable": true,
              "sort": 27,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "260492866101444608",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "260492865892777984",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15367809999",
              "name": "余凯凯",
              "no": "15367809999",
              "postName": "开发1",
              "selectable": true,
              "sort": 26,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "252884341850374144",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "252884341642756096",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15100000005",
              "name": "测试5",
              "no": "32432",
              "postName": "开发2",
              "selectable": true,
              "sort": 17,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "227053690812366848",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "227053690011254784",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15100000002",
              "name": "测试2",
              "no": "1231235",
              "postName": "开发1",
              "selectable": true,
              "sort": 13,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "229575884649005056",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "229575884636422144",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15847482374",
              "name": "234234",
              "no": "15847482374",
              "postName": "开发2",
              "selectable": true,
              "sort": 10,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "227054229461663744",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "227054229433352192",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15100000003",
              "name": "测试3_批量测试",
              "no": "654321",
              "postName": "开发2",
              "selectable": true,
              "sort": 9,
              "type": "003",
              "userLogo": "https://192.168.1.30:8243/Storage-api/img.api?token=85878D5F87E75BC420E761AE9BBEEC12&ts=3284071923874102938&fileCode=group1/M00/02/BB/wKgBHlrsFBeAJgbjAAAgaCp0Qck0.1.jpg&appKey=enfrytest"
            },
            {
              "accountId": "236568183499128832",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "236568183421534208",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "18600000003",
              "name": "18600000003",
              "no": "18600000003",
              "postName": "开发2",
              "selectable": true,
              "sort": 2,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "225319206072090624",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "225319206064750592",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "17834562345",
              "name": "1234",
              "no": "4567uj",
              "postName": "前端",
              "selectable": true,
              "sort": 1,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "225969314648293376",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "225969314647244800",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "13207318888",
              "name": "13207318888",
              "no": "13207318888",
              "postName": "开发2",
              "selectable": true,
              "sort": 1,
              "type": "003",
              "userLogo": "https://192.168.1.30:8243/Storage-api/img.api?token=85878D5F87E75BC420E761AE9BBEEC12&ts=3284071923874102938&fileCode=group1/M00/01/4E/wKgBHlmyDGaAANk2AAEQeDdKZ9A1.1.jpg&appKey=enfrytest"
            },
            {
              "accountId": "225970746270154752",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "225970746253377536",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "18736452525",
              "name": "18736452525",
              "no": "234234324SDFSDF",
              "postName": "开发2",
              "selectable": true,
              "sort": 1,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "225973236114915328",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "225973236098138112",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "18938476455",
              "name": "18938476455",
              "no": "18938476455",
              "postName": "开发2",
              "selectable": true,
              "sort": 1,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "247988209085054976",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "247988208839688192",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "18073110038",
              "name": "彭虎",
              "no": "020",
              "postName": "开发2",
              "selectable": true,
              "sort": 1,
              "type": "003",
              "userLogo": ""
            },
            {
              "accountId": "226219453908516864",
              "deptName": "财务部",
              "enable": "000",
              "hasChildren": "001",
              "id": "226219453887545344",
              "isParent": "001",
              "levelCode": "0,225073633627734016,225164323751723008",
              "mobileNo": "15856888888",
              "name": "王辉",
              "no": "0908",
              "postName": "开发2",
              "selectable": true,
              "sort": 1,
              "type": "003",
              "userLogo": "https://192.168.1.30:8243/Storage-api/img.api?token=85878D5F87E75BC420E761AE9BBEEC12&ts=3284071923874102938&fileCode=group1/M00/01/53/wKgBHlork2qAYY1LAAEO8Jhl8dQ6.1.jpg&appKey=enfrytest"
            }
          ],
          "rspMsg": "",
          "schemeVersionId": "",
          "submitToken": "a70f0af4-a6d0-483a-ab0c-6f587bf71ea1",
          "tenantId": "225062062644002816"
        }
      }
      node.data.children = this.selectOptionValues(data.rspData)
      resolve(node.data.children)
    },
    selectOptionValues(options) {
      if (options === undefined) {
        return []
      }
      for (const item of options) {
        item.label = item.name
        item.value = item.id
        if (item.hasChildren === "000") {
          item.children = []
        }
      }
      return options
    },
    queryDeptData(item) {
      const data = {
        "id": "0",
        "msgType": "N",
        "rspCode": "000000",
        "rspData": [
          {
            "code": "11",
            "createTime": "2017-08-23 17:51:44",
            "description": "",
            "enable": "000",
            "generation": "1",
            "hasChildren": "000",
            "id": "225073633627734016",
            "isParent": "000",
            "leader": "",
            "levelCode": "0,225073633627734016",
            "modifyTime": "2018-10-25 19:09:53",
            "name": "湖南英富莱网络技术有限公司",
            "orgCode": "91430100MA4L395307",
            "parentId": "0",
            "parentNodeType": "000",
            "selectable": false,
            "sts": "000",
            "tenantId": "225062062644002816",
            "type": "002"
          }, {
            "code": "002",
            "createTime": "2019-03-22 09:43:36",
            "description": "",
            "enable": "000",
            "generation": "1",
            "hasChildren": "000",
            "id": "277226720811024384",
            "isParent": "000",
            "leader": "",
            "levelCode": "0,277226720811024384",
            "modifyTime": "2019-03-22 09:43:36",
            "name": "测试基础数据类型单选字段用户自定义",
            "orgCode": "",
            "parentId": "0",
            "parentNodeType": "000",
            "selectable": false,
            "sts": "000",
            "tenantId": "225062062644002816",
            "type": "002"
          }
        ],
        "rspMsg": "",
        "schemeVersionId": "",
        "submitToken": "553ee34d-b0b5-4515-bbf9-692453fdb83c",
        "tenantId": "225062062644002816"
      }
      item.options = this.selectOptionValues(data.rspData)
    }
  }
}
</script>

<style scoped lang="scss">
  .notify-object {
    .en-select /deep/ .el-select {
      width: 100%;
    }

    .en-select /deep/ .el-input__inner {
      line-height: 1;
    }

    ul,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    ul li {
      float: left;
    }

    ul {
      margin: 0 auto;
      display: inline-block;
    }

    .notify-object-tabs {
      margin: 0 20px;
    }

    .notify-tabs {
      width: 100%;
      display: flex;

      .notify-tab-pane {
        flex: 1;
        text-align: center;
        height: 140px;
        border: 1px solid #ffffff;

        .tab-icon {
          text-align: center;
          height: 50px;
          width: 50px;
          margin-left: calc((100% - 50px) / 2);
          margin-top: 32px;
          border-radius: 50%;

          .en-icon {
            height: 18px;
            width: 18px;
            margin-top: calc((100% - 18px) / 2);
          }
        }

        .notify-tab__name {
          margin-top: 16px;
          height: 13px;
          font-size: 12px;
          line-height: 13px;
        }
      }

      .notify-tab-selcted-pane {
        background: rgba(246, 250, 253, 1);
        border: 1px solid rgba(232, 236, 242, 1);
        border-radius: 6px;
        box-sizing: border-box;
      }
    }

    .notify-add {
      margin-top: 28px;
      height: 32px;
      white-space: nowrap;

      .en-select {
        display: inline-block;
        width: 284px;
        margin-left: 15px;
        height: 32px;

        &:first-child {
          margin-left: 0;
        }

        overflow: hidden;
      }

      .en-select /deep/ .tree-select,
      .en-select /deep/ .select-text {
        width: 284px;
        height: 32px;
        overflow: hidden;
      }

      .el-button {
        display: inline-block;
        margin-left: 12px;
        vertical-align: top;
        height: 32px;
      }
    }

    .el-table {
      margin: 41px 0 0;
      height: 279px;
    }

    & /deep/ .el-dialog__body {
      padding: 0;
    }

    .operator {
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .delete {
        line-height: 18px;

        .en-icon {
          width: 18px;
          height: 18px;
          color: #f76b6b;
        }
      }

      .en-select {
        width: 130px;
        box-sizing: border-box;
        border: 1px solid rgba(232, 236, 242, 1);
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        background: rgba(255, 255, 255, 1);
      }
    }

    .rowStyle:hover {
      .operator {
        .delete {
          display: inline;
        }

        .en-select {
          display: block;
        }

        .viewText {
          display: none;
        }
      }
    }

    .operatorEdit {
      .en-select {
        display: block;
      }

      .viewText {
        display: none;
      }

      .delete {
        display: block;
      }
    }

    .operatorView {
      .en-select {
        display: none;
      }

      .viewText {
        display: block;
      }

      .delete {
        display: none;
      }
    }
  }
</style>
