/* eslint-disable */

import XEUtils from "xe-utils/methods/xe-utils";
import GlobalConfig from "../../conf";
import Cell from "../../cell";
import VXETable, { Interceptor, Renderer } from "../../v-x-e-table";
import { Utils, DomTools, GlobalEvent, XObject, XArray } from "../../tools";
import CellUtils from '../../cell/src/cellUtils'
import ColumnUtils from "../../column/src/columnUtils";
import { TableMenu } from "./tableMenu";
import { TableUtils } from "./tableUtils";
import FilterUtils from "../../filter/src/utils";
//混入
import TableDrag from "./drag";
import Render from './render'
import Row from './row'
import Selection from './selection'
import Scroll from './scroll'
import Tree from './tree'
import body from "../../body/src/body";

var rowUniqueId = 0;
const browse = DomTools.browse;


class Rule {
  constructor(rule) {
    Object.assign(this, {
      $options: rule,
      required: rule.required,
      min: rule.min,
      max: rule.min,
      type: rule.type,
      pattern: rule.pattern,
      validator: rule.validator,
      trigger: rule.trigger,
      maxWidth: rule.maxWidth
    });
  }
  get message() {
    return CellUtils.getFuncText(this.$options.message);
  }
}

function getRowUniqueId() {
  return `row_${++rowUniqueId}`;
}

// 分组表头的属性
const headerProps = { children: "children" };

export default {
  name: "EnTable",
  props: {
    /** 基本属性 */
    // 数据
    data: Array,
    // 初始化绑定动态列
    customs: Array,
    // 表格行高
    tableLineHeight: {
      type: String,
      default: "default"
    },
    // 表格的高度
    height: [Number, String],
    // 表格的最大高度
    maxHeight: [Number, String],
    // 所有列是否允许拖动列宽调整大小
    resizable: { type: Boolean, default: () => GlobalConfig.resizable },
    // 是否带有斑马纹
    stripe: { type: Boolean, default: () => GlobalConfig.stripe },
    // 是否带有纵向边框
    border: { type: Boolean, default: () => GlobalConfig.border },
    // 表格的尺寸
    size: { type: String, default: () => GlobalConfig.size },
    // 列的宽度是否自撑开
    fit: { type: Boolean, default: () => GlobalConfig.fit },
    // 表格是否加载中
    loading: Boolean,
    // 所有的列对齐方式
    align: { type: String, default: () => GlobalConfig.align },
    // 所有的表头列的对齐方式
    headerAlign: { type: String, default: () => GlobalConfig.headerAlign },
    // 是否显示表头
    showHeader: { type: Boolean, default: () => GlobalConfig.showHeader },
    // 只对 type=index 时有效，自定义序号的起始值
    startIndex: { type: Number, default: 0 },
    // 是否要高亮当前选中行
    highlightCurrentRow: {
      type: Boolean,
      default: () => GlobalConfig.highlightCurrentRow
    },
    // 是否要高亮当前选中列
    highlightCurrentColumn: {
      type: Boolean,
      default: () => GlobalConfig.highlightCurrentColumn
    },
    // 鼠标移到行是否要高亮显示
    highlightHoverRow: {
      type: Boolean,
      default: () => GlobalConfig.highlightHoverRow
    },
    // 鼠标移到列是否要高亮显示
    highlightHoverColumn: {
      type: Boolean,
      default: () => GlobalConfig.highlightHoverColumn
    },
    // 是否要高亮选中行
    highlightSelectRow: {
      type: Boolean,
      default: () => GlobalConfig.highlightSelectRow
    },
    // 是否要高亮选中列
    highlightSelectColumn: {
      type: Boolean,
      default: () => GlobalConfig.highlightSelectColumn
    },
    // 激活单元格编辑时是否高亮显示
    highlightCell: Boolean,
    // 是否显示表尾合计
    showFooter: Boolean,
    // 表尾合计的计算方法
    footerMethod: Function,
    // 给行附加 className
    rowClassName: [String, Function],
    // 给单元格附加 className
    cellClassName: [String, Function],
    // 给单元格附加 style
    cellCustomStyle: [String, Function],
    // 给表头的行附加 className
    headerRowClassName: [String, Function],
    // 给表头的单元格附加 className
    headerCellClassName: [String, Function],
    // 给表头的单元格附加 style
    headerCellStyle: [String, Function],
    // 给表尾的行附加 className
    footerRowClassName: [String, Function],
    // 给表尾的单元格附加 className
    footerCellClassName: [String, Function],
    // 合并行或列
    spanMethod: Function,
    // 设置所有内容过长时显示为省略号
    showOverflow: {
      type: [Boolean, String],
      default: () => GlobalConfig.showOverflow
    },
    // 设置表头所有内容过长时显示为省略号
    showHeaderOverflow: {
      type: [Boolean, String],
      default: () => GlobalConfig.showHeaderOverflow
    },
    // 所有列宽度
    columnWidth: [Number, String],
    // 所有列最小宽度，把剩余宽度按比例分配
    columnMinWidth: [Number, String],

    /** 高级属性 */
    // 主键配置
    enableRowKey: Boolean, //rowkey 就不用了, 和ele不一样, 无需自己设置 add By xu
    enableColumnKey: Boolean,
    rowId: { type: String, default: () => GlobalConfig.rowId },
    // 是否自动监听父容器变化去更新响应式表格宽高
    autoResize: Boolean,
    // 是否自动根据状态属性去更新响应式表格宽高
    syncResize: Boolean,
    // 单选多选配置项
    selectConfig: Object,
    // tooltip 配置项
    tooltipConfig: Object,
    // 展开行配置项
    expandConfig: Object,
    // 树形结构配置项
    treeConfig: Object,
    treeProps: Object, //兼容el(会报错, 请使用treeConfig)
    // 快捷菜单配置项 "{stype: 1, moveField:"id", header: {options: headerMenus}, body: {options: bodyMenus,}, footer: {options: footerMenus}, visibleMethod}"
    menuConfig: Object, //菜单样式style (1:剪切/粘贴前面/粘贴后面 2:剪切/粘贴前面/粘贴后面/粘贴子级)   moveKey:需要移动(剪贴粘贴)字段名(default也是id)
    // 鼠标配置项
    mouseConfig: Object,
    // 按键配置项
    keyboardConfig: Object,
    // 表头末尾按钮配置
    headEndConfig: Object,
    //分页组件配置项
    pageConfig: Object,
    //悬浮行配置 { data数据/dataMethod 数据函数, changeMethod操作函数 }
    hoverRowConfig: Object,
    //高度config(表格高度, 单元格高度等等)
    heightConfig: Object,
    //边框配置 { showBorder, showBorderRadius }
    borderConfig: Object,
    // 校验配置项
    validConfig: Object,
    // 校验规则配置项
    editRules: Object,
    // 优化配置项
    optimization: Object,
    // 额外的参数 { supportSearchFieldList可筛选列表, defaultSearchParams默认筛选条件 }
    params: Object,
    // 隐藏列 czm add
    hideColumns: Array,
    noDataIconSize: {
      type: String,
      default: "normal"
    },
    // light or dark
    theme: {
      type: String,
      default: ""
    },
    // 筛选排序栏是否需要展示
    filterToolbarVisible: {
      default: true
    },
    // 表头排序tooltip是否展示
    headerSortTooltipVisible: {
      default: true
    }
  },
  mixins: [TableDrag, Render, Row, Selection, Scroll, Tree],
  provide() {
    return { $table: this };
  },
  data() {
    return {
      id: XEUtils.uniqueId(),
      // 列分组配置
      collectColumn: [],
      // 完整所有列
      tableFullColumn: [],
      // 渲染的列
      tableColumn: [],
      // 渲染中的数据
      tableData: [],
      // 是否启用了横向 X 可视渲染方式加载
      scrollXLoad: false,
      // 是否启用了纵向 Y 可视渲染方式加载
      scrollYLoad: false,
      // 是否存在纵向滚动条
      overflowY: true,
      // 是否存在横向滚动条
      overflowX: false,
      // 纵向滚动条的宽度
      scrollbarWidth: 0,
      // 横向滚动条的高度
      scrollbarHeight: 0,
      // 当前行
      currentRow: null,
      // 单选属性，选中行
      selectRow: null,
      // 表尾合计数据
      footerData: [],
      // 已展开的行
      expandeds: [],
      // 已展开树节点
      treeExpandeds: [],

      // 当前选中的筛选列
      filterStore: {
        isAllSelected: false,
        isIndeterminate: false,
        style: undefined,
        dataList: [], //所有筛选结果
        column: undefined,
        visible: false
      },
      // 存放列相关的信息
      columnStore: {
        leftList: [],
        centerList: [],
        rightList: [],
        resizeList: [],
        pxList: [],
        pxMinList: [],
        scaleList: [],
        scaleMinList: [],
        autoList: []
      },
      // 存放快捷菜单的信息
      ctxMenuStore: {
        selected: null,
        visible: false,
        showChild: false,
        selectChild: null,
        list: [],
        style: null
      },
      // 存放可编辑相关信息
      editStore: {
        indexs: { columns: [] },
        titles: { columns: [] },
        // 所有选中
        checked: {
          rows: [],
          columns: [],
          tRows: [],
          tColumns: []
        },
        // 选中源
        selected: {
          row: null,
          column: null
        },
        // 已复制源
        copyed: {
          cut: false,
          rows: [],
          columns: []
        },
        // 激活
        actived: {
          row: null,
          column: null
        },
        insertList: [],
        removeList: []
      },
      // 存放数据校验相关信息
      validStore: {
        visible: false,
        row: null,
        column: null,
        content: "",
        rule: null,
        isArrow: false
      },
      cache: { //缓存
        showBorderRadius: false
      },
      // 表格大小
      settingSize: this.tableLineHeight || ""
    };
  },
  computed: {
    vSize() {
      return this.size || this.$parent.size || this.$parent.vSize || this.settingSize;
    },
    validOpts() {
      return Object.assign({ message: "default" }, GlobalConfig.validConfig, this.validConfig);
    },
    optimizeOpts() {
      return Object.assign({}, GlobalConfig.optimization, this.optimization);
    },
    vaildTipOpts() {
      return Object.assign({ isArrow: false }, this.tooltipConfig);
    },
    // 是否使用了分组表头
    isGroup() {
      return this.collectColumn.some(column => Utils.hasChildrenList(column));
    },
    hasTip() {
      return VXETable._tooltip;
    },
    showBorder() {//是否显示边框
      return this.border || (this.borderConfig && this.borderConfig.showBorder);
    },
    showBorderRadius() {
      return this.cache.showBorderRadius || (this.borderConfig && this.borderConfig.showBorderRadius);
    },
    visibleColumn() {
      return this.tableFullColumn ? this.tableFullColumn.filter(column => column.visible) : [];
    },
    isResizable() {
      return this.resizable || this.tableFullColumn.some(column => column.resizable);
    },
    hasFilter() {
      return this.tableColumn.some(column => column.filterSortStore && column.filterSortStore.filterable);
    },
    headerCtxMenu() {
      return this.ctxMenuOpts.header && this.ctxMenuOpts.header.options ? this.ctxMenuOpts.header.options : [];
    },
    bodyCtxMenu() {
      return this.ctxMenuOpts.body && this.ctxMenuOpts.body.options ? this.ctxMenuOpts.body.options : [];
    },
    isCtxMenu() {
      return this.headerCtxMenu.length || this.bodyCtxMenu.length;
    },
    ctxMenuOpts() {
      let menu = Object.assign({}, GlobalConfig.menu, this.menuConfig);
      if (this.ctxRowMoveMenuConfig) {
        menu.body = TableMenu.getRowCutPasteMenu();
      }
      return menu;
    },
    ctxRowMoveMenuConfig() {
      if (!this.menuConfig) {
        return undefined;
      }

      let { style, moveField } = this.menuConfig;

      if (XObject.isEmpty(this.menuConfig)) {
        style = 1;
      }

      if (!style) {
        return undefined;
      }

      if (moveField === undefined) {
        moveField = "id";
      }

      let config = {};
      config.style = style;
      config.moveField = moveField;
      return config;
    },
    ctxMenuList() {
      let rest = [];
      this.ctxMenuStore.list.forEach(list => {
        list.forEach(item => {
          rest.push(item);
        });
      });
      return rest;
    }
  },
  watch: {
    // czm add 需要隐藏的列
    hideColumns: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          if (val && val.length) {
            this.tableColumn.forEach((item) => {
              if (val.find(column => column.nameVariable ? column.nameVariable === item.field : column.field === item.field)) {
                this.hideColumn(item)
              }
            })
            this.refreshColumn()
          }
        })
      }
    },
    data(value) {
      if (!this._isUpdateData) {
        this.loadTableData(value, true).then(this.handleDefault);
      }
      this._isUpdateData = false;
    },
    customs(value) {
      if (!this.isUpdateCustoms) {
        this.mergeCustomColumn(value);
      }
      this.isUpdateCustoms = false;
    },
    collectColumn(value) {
      // debugger
      let tableFullColumn = ColumnUtils.getColumnList(value);
      this.tableFullColumn = tableFullColumn;
      this.cacheColumnMap();
      if (this.customs) {
        this.mergeCustomColumn(this.customs);
      }
      this.refreshColumn();
      this.handleTableData(true);
      if (this.$toolbar) {
        this.$toolbar.updateColumn(tableFullColumn);
      }
      /*
      // 在 v3.0 中废弃 prop、label (但是我们又不用3.0, 看了下代码 是兼容两种的 add by xu)
      if (tableFullColumn.length) {
        let cIndex = Math.floor((tableFullColumn.length - 1) / 2);
        if (tableFullColumn[cIndex].prop) {
          Utils.warn("vxe.error.delProp");
        }
        if (tableFullColumn[cIndex].label) {
          Utils.warn("vxe.error.delLabel");
        }
      }
      */

      if (this.treeConfig && tableFullColumn.some(column => column.fixed) && tableFullColumn.some(column => column.type === "expand")) {
        Utils.warn("vxe.error.treeFixedExpand");
      }
    },
    tableColumn() {
      this.analyColumnWidth();
    },
    height() {
      this.$nextTick(this.recalculate);
    },
    loading() {
      if (!this._isLoading) {
        this._isLoading = true;
      }
    },
    syncResize(value) {
      if (value) {
        this.$nextTick(this.recalculate);
      }
    },
    selection(value) {
      if (!this.highlightSelectRow) {
        return;
      }
      //add by xu 清除选中
      XEUtils.arrayEach(this.$el.querySelectorAll("tr.vxe-body--row.row--select"), elem => DomTools.removeClass(elem, "row--select"));

      if (value && value.length > 0) {
        for (let row of value) {
          let rowid = CellUtils.getRowid(this, row);
          this.clearHoverRow();
          XEUtils.arrayEach(this.$el.querySelectorAll(`[data-rowid="${rowid}"]`), elem => DomTools.addClass(elem, "row--select"));
        }
      }
    },
    params: {
      immediate: true,
      handler(value) {
        if (!value) {
          return;
        }
        const { defaultSearchParams } = value;
        //默认筛选条件
        if (defaultSearchParams) {
          const dataList = [];
          defaultSearchParams.forEach(item => {
            const column = ColumnUtils.createColumnWithHeadData(item);
            column.filterSortStore = FilterUtils.filterSortStoreWithHeadData(item);
            column.own.$table = this;
  
            const data = FilterUtils.getColumnFilterSortInfo(column, 'filter');
            dataList.push(data);
          });
          this.filterStore.dataList = dataList;
        }
      }
    }
  },
  created() {
    let { scrollYStore, optimizeOpts, data, loading } = Object.assign(this, {
      elemStore: {},
      // 存放横向 X 虚拟滚动相关的信息
      scrollXStore: {},
      // 存放纵向 Y 虚拟滚动相关信息
      scrollYStore: {},
      // 存放 tooltip 相关信息
      tooltipStore: {},
      // 表格父容器的高度
      parentHeight: 0,
      // 表格宽度
      tableWidth: 0,
      // 表格高度
      tableHeight: 0,
      // 表头高度
      headerHeight: 0,
      // 表尾高度
      footerHeight: 0,
      // 单选属性，选中列
      // currentColumn: null,
      // 当前 hover 行
      // hoverRow: null,
      // 最后滚动位置
      lastScrollLeft: 0,
      lastScrollTop: 0,
      // 完整数据、条件处理后
      tableFullData: [],
      afterFullData: [],
      // 缓存数据集
      fullAllDataRowMap: new Map(),
      fullAllDataRowIdData: {},
      fullDataRowMap: new Map(),
      fullDataRowIdData: {},
      fullColumnMap: new Map(),
      fullColumnIdData: {}
    });
    let { scrollY } = optimizeOpts;
    // 是否加载过 Loading 模块
    this._isLoading = loading;
    if (!CellUtils.getRowkey(this)) {
      Utils.error("vxe.error.rowIdEmpty");
    }
    if (!VXETable._keyboard && (this.keyboardConfig || this.mouseConfig)) {
      throw new Error(Utils.error("vxe.error.reqKeyboard"));
    }
    if (!VXETable._resize && this.autoResize) {
      throw new Error(Utils.error("vxe.error.reqResize"));
    }
    if (scrollY) {
      Object.assign(scrollYStore, {
        startIndex: 0,
        visibleIndex: 0,
        adaptive: XEUtils.isBoolean(scrollY.adaptive) ? scrollY.adaptive : true,
        renderSize: XEUtils.toNumber(scrollY.rSize),
        offsetSize: XEUtils.toNumber(scrollY.oSize)
      });
    }

    this.loadTableData(data, true).then(() => {
      this.handleDefault();
      // this.updateStyle();
    });
    GlobalEvent.on(this, "mousedown", this.handleGlobalMousedownEvent);
    GlobalEvent.on(this, "blur", this.handleGlobalBlurEvent);
    GlobalEvent.on(this, "mousewheel", this.handleGlobalMousewheelEvent);
    GlobalEvent.on(this, "keydown", this.handleGlobalKeydownEvent);
    GlobalEvent.on(this, "resize", this.handleGlobalResizeEvent);
    GlobalEvent.on(this, "contextmenu", this.handleGlobalContextmenuEvent);
  },
  mounted() {
    if (this.autoResize && VXETable._resize) {
      this.bindResize();
    }

    //监听宽度为0 的数据变化
    // this.$nextTick(function() {
    //   // debugger
    //   TableUtils.addTableWidthMoniterIfNeed(this);
    // });
    //菜单 弹框之类的
    document.body.appendChild(this.$refs.tableWrapper);

    //弹框表格要有圆角
    let parent = this.$parent;
    while (parent && parent.$el) {
      const classList = parent.$el.classList;
      let isExist = classList ? classList.contains('el-dialog__wrapper') : false;
      if (isExist) {
        this.cache.showBorderRadius = isExist;
        break;
      }
      parent = parent.$parent;
    }

  },
  activated() {
    let { lastScrollLeft, lastScrollTop } = this;
    if (lastScrollLeft || lastScrollTop) {
      this.clearScroll()
        .then(this.recalculate)
        .then(() => this.scrollTo(lastScrollLeft, lastScrollTop));
    }
  },
  beforeDestroy() {
    let tableWrapper = this.$refs.tableWrapper;
    if (tableWrapper && tableWrapper.parentNode) {
      tableWrapper.parentNode.removeChild(tableWrapper);
    }

    // TableUtils.removeTableWidthMoniterIfNeed(this);

    if (VXETable._resize) {
      this.unbindResize();
    }
    this.closeFilter();
    this.closeMenu();
  },
  destroyed() {
    GlobalEvent.off(this, "mousedown");
    GlobalEvent.off(this, "blur");
    GlobalEvent.off(this, "mousewheel");
    GlobalEvent.off(this, "keydown");
    GlobalEvent.off(this, "resize");
    GlobalEvent.off(this, "contextmenu");
  },

  methods: {
    /**
     * 对外接口 获取tr元素
     * @param rowId /row 对象
     * @returns {Element|undefined}
     */
    getTrElemenet(rowId) {
      if (!rowId) {
        return undefined;
      }
      if (typeof(rowId) !== 'string') {//对象
        rowId = CellUtils.getRowid(this, rowId);
      }
      const bodyElem = this.$refs.tableBody.$el;
      return  bodyElem.querySelector(`[data-rowid="${rowId}"]`);
    },
    clearAll() {
      this.clearScroll();
      this.clearSort();
      this.clearCurrentRow();
      this.clearCurrentColumn();
      this.clearSelection();
      this.clearRowExpand();
      this.clearTreeExpand();
      if (VXETable._filter) {
        this.clearFilter();
      }
      if (this.keyboardConfig || this.mouseConfig) {
        this.clearIndexChecked();
        this.clearHeaderChecked();
        this.clearChecked();
        this.clearSelected();
        this.clearCopyed();
      }
      return this.clearActived();
    },
    refreshData() {
      return this.$nextTick().then(() => {
        this.tableData = [];
        return this.$nextTick().then(() => this.loadTableData(this.tableFullData));
      });
    },
    updateData() {
      return this.handleTableData(true)
        .then(this.updateFooter)
        .then(this.recalculate);
    },
    handleTableData(force) {
      let { scrollYLoad, scrollYStore } = this;
      let fullData = force ? this.updateAfterFullData() : this.afterFullData;
      this.tableData = scrollYLoad ? fullData.slice(scrollYStore.startIndex, scrollYStore.startIndex + scrollYStore.renderSize) : fullData.slice(0);
      return this.$nextTick();
    },
    loadTableData(datas, notRefresh) {
      let { height, maxHeight, editStore, optimizeOpts, lastScrollLeft, lastScrollTop } = this;
      let { scrollY } = optimizeOpts;
      let tableFullData = datas ? datas.slice(0) : [];
      let scrollYLoad = scrollY && scrollY.gt && scrollY.gt < tableFullData.length;
      editStore.insertList = [];
      editStore.removeList = [];
      // 全量数据
      this.tableFullData = tableFullData;
      // 缓存数据
      this.updateCache(true);
      // 原始数据
      this.tableSynchData = datas;
      this.tableSourceData = XEUtils.clone(tableFullData, true);
      this.scrollYLoad = scrollYLoad;
      if (scrollYLoad && !(height || maxHeight)) {
        Utils.error("vxe.error.scrollYHeight");
      }
      this.clearScroll();
      this.handleTableData(true);

      let rest = this.$nextTick();
      if (!notRefresh) {
        rest = rest.then(this.recalculate);
      }
      return rest.then(() => {
        // console.log('end------' + new Date());

        this.reserveCheckSelection();
        this.checkSelectionStatus();

        if (this.scrollXLoad || this.scrollYLoad) {
          if (this.mouseConfig) {
            Utils.error("vxe.error.notMouse");
          }
        }
        if (lastScrollLeft || lastScrollTop) {
          return this.scrollTo(lastScrollLeft, lastScrollTop);
        }
      });
    },
    loadData(datas) {
      return this.loadTableData(datas).then(this.recalculate);
    },
    reloadData(datas) {
      console.log(new Date());
      this.clearAll();
      return this.loadTableData(datas).then(this.handleDefault);
    },
    loadColumn(columns) {
      this.collectColumn = XEUtils.mapTree(columns, column => ColumnUtils.createColumn(column.own, this), headerProps);
      return this.$nextTick();
    },
    reloadColumn(columns) {
      this.clearAll();
      return this.loadColumn(columns);
    },
    // 更新数据的 Map
    updateCache(source) {
      let { treeConfig, tableFullData, fullDataRowIdData, fullDataRowMap, fullAllDataRowMap, fullAllDataRowIdData } = this;
      let rowkey = CellUtils.getRowkey(this);
      let handleCache = (row, index) => {
        let rowid = CellUtils.getRowid(this, row);
        if (!rowid) {
          rowid = getRowUniqueId();
          XEUtils.set(row, rowkey, rowid);
        }
        let rest = {
          row,
          rowid,
          index
        };
        if (source) {
          fullDataRowIdData[rowid] = rest;
          fullDataRowMap.set(row, rest);
        }
        fullAllDataRowIdData[rowid] = rest;
        fullAllDataRowMap.set(row, rest);
      };
      if (source) {
        fullDataRowIdData = this.fullDataRowIdData = {};
        fullDataRowMap.clear();
      }
      fullAllDataRowIdData = this.fullAllDataRowIdData = {};
      fullAllDataRowMap.clear();
      if (treeConfig) {
        XEUtils.eachTree(tableFullData, handleCache, treeConfig);
      } else {
        tableFullData.forEach(handleCache);
      }
    },
    // 更新列的 Map
    cacheColumnMap() {
      let { tableFullColumn, fullColumnMap } = this;
      let fullColumnIdData = (this.fullColumnIdData = {});
      fullColumnMap.clear();
      tableFullColumn.forEach((column, index) => {
        let rest = {
          column,
          colid: column.id,
          index
        };
        fullColumnIdData[column.id] = rest;
        fullColumnMap.set(column, rest);
      });
    },
    getRowNode(tr) {
      if (tr) {
        let { treeConfig, tableFullData, fullAllDataRowIdData } = this;
        let rowid = tr.getAttribute("data-rowid");
        if (treeConfig) {
          let matchObj = XEUtils.findTree(tableFullData, row => CellUtils.getRowid(this, row) === rowid, treeConfig);
          if (matchObj) {
            return matchObj;
          }
        } else {
          if (fullAllDataRowIdData[rowid]) {
            let rest = fullAllDataRowIdData[rowid];
            return {
              item: rest.row,
              index: rest.index,
              items: tableFullData
            };
          }
        }
      }
      return null;
    },
    getColumnNode(cell) {
      if (cell) {
        let { isGroup, fullColumnIdData, tableFullColumn } = this;
        let colid = cell.getAttribute("data-colid");
        if (isGroup) {
          let matchObj = XEUtils.findTree(tableFullColumn, column => column.id === colid, headerProps);
          if (matchObj) {
            return matchObj;
          }
        } else {
          let { column, index } = fullColumnIdData[colid];
          return {
            item: column,
            index,
            items: tableFullColumn
          };
        }
      }
      return null;
    },
    getRowIndex(row) {
      return this.fullDataRowMap.has(row) ? this.fullDataRowMap.get(row).index : -1;
    },
    getColumnIndex(column) {
      return this.fullColumnMap.has(column) ? this.fullColumnMap.get(column).index : -1;
    },
    hasIndexColumn(column) {
      return column && column.type === "index";
    },
    insert(records) {
      return this.insertAt(records);
    },
    /**
     * 从指定行插入数据
     */
    insertAt(records, row) {
      let { afterFullData, editStore, scrollYLoad, tableFullData, treeConfig } = this;
      if (treeConfig) {
        throw new Error(Utils.error("vxe.error.treeInsert"));
      }
      if (!XEUtils.isArray(records)) {
        records = [records];
      }
      let nowData = afterFullData;
      let newRecords = records.map(record => this.defineField(Object.assign({}, record)));
      if (!row) {
        nowData.unshift.apply(nowData, newRecords);
        tableFullData.unshift.apply(tableFullData, newRecords);
      } else {
        if (row === -1) {
          nowData.push.apply(nowData, newRecords);
          tableFullData.push.apply(tableFullData, newRecords);
        } else {
          let targetIndex = nowData.indexOf(row);
          if (targetIndex === -1) {
            throw new Error(Utils.error("vxe.error.unableInsert"));
          }
          nowData.splice.apply(nowData, [targetIndex, 0].concat(newRecords));
          tableFullData.splice.apply(tableFullData, [tableFullData.indexOf(row), 0].concat(newRecords));
        }
      }
      [].unshift.apply(editStore.insertList, newRecords);
      this.handleTableData();
      this.updateCache();
      this.checkSelectionStatus();
      if (scrollYLoad) {
        this.updateScrollYSpace();
      }
      return this.$nextTick().then(() => {
        this.recalculate();
        return {
          row: newRecords.length ? newRecords[newRecords.length - 1] : null,
          rows: newRecords
        };
      });
    },
    defineField(row) {
      let rowkey = CellUtils.getRowkey(this);
      this.visibleColumn.forEach(({ property, editRender }) => {
        if (property && !XEUtils.has(row, property)) {
          XEUtils.set(row, property, editRender && !XEUtils.isUndefined(editRender.defaultValue) ? editRender.defaultValue : null);
        }
      });
      // 必须有行数据的唯一主键，可以自行设置；也可以默认生成一个随机数
      if (!XEUtils.get(row, rowkey)) {
        XEUtils.set(row, rowkey, getRowUniqueId());
      }
      return row;
    },
    createData(records) {
      return this.$nextTick().then(() => records.map(this.defineField));
    },
    createRow(records) {
      let isArr = XEUtils.isArray(records);
      if (!isArr) {
        records = [records];
      }
      return this.$nextTick().then(() => {
        let rows = records.map(record => this.defineField(Object.assign({}, record)));
        return isArr ? rows : rows[0];
      });
    },
    /**
     * 删除指定行数据
     * 如果传 row 则删除一行
     * 如果传 rows 则删除多行
     */
    remove(rows) {
      let { afterFullData, tableFullData, editStore, treeConfig, selectConfig = {}, selection, hasRowInsert, scrollYLoad } = this;
      let { removeList, insertList } = editStore;
      let { checkField: property } = selectConfig;
      let rest = [];
      let nowData = afterFullData;
      if (!rows) {
        rows = tableFullData;
      } else if (!XEUtils.isArray(rows)) {
        rows = [rows];
      }
      if (treeConfig) {
        rows.forEach(row => {
          let matchObj = XEUtils.findTree(tableFullData, item => item === row, treeConfig);
          if (matchObj) {
            let { item, items, index } = matchObj;
            // 如果是新增，则保存记录
            if (!hasRowInsert(item)) {
              removeList.push(item);
            }
            // 从树节点中移除
            let restRow = items.splice(index, 1)[0];
            // 如果绑定了多选属性，则更新状态
            if (!property) {
              XEUtils.remove(selection, row => rows.indexOf(row) > -1);
            }
            rest.push(restRow);
          }
        });
      } else {
        // 如果是新增，则保存记录
        rows.forEach(row => {
          if (!hasRowInsert(row)) {
            removeList.push(row);
          }
        });
        // 如果绑定了多选属性，则更新状态
        if (!property) {
          XEUtils.remove(selection, row => rows.indexOf(row) > -1);
        }
        // 从数据源中移除
        if (tableFullData === rows) {
          rows = tableFullData.slice(0);
          tableFullData.length = 0;
          nowData.length = 0;
        } else {
          rest = XEUtils.remove(tableFullData, row => rows.indexOf(row) > -1);
          XEUtils.remove(nowData, row => rows.indexOf(row) > -1);
        }
      }
      // 从新增中移除已删除的数据
      XEUtils.remove(insertList, row => rows.indexOf(row) > -1);
      this.handleTableData();
      this.updateCache();
      this.checkSelectionStatus();
      if (scrollYLoad) {
        this.updateScrollYSpace();
      }
      return this.$nextTick().then(() => {
        this.recalculate();
        return {
          row: rows && rows.length ? rows[rows.length - 1] : null,
          rows: rest
        };
      });
    },
    /**
     * 删除选中数据
     */
    removeSelecteds() {
      return this.remove(this.getSelectRecords()).then(params => {
        this.clearSelection();
        return params;
      });
    },
    revert() {
      Utils.warn("vxe.error.delRevert");
      return this.revertData.apply(this, arguments);
    },
    /**
     * 还原数据
     * 如果不传任何参数，则还原整个表格
     * 如果传 row 则还原一行
     * 如果传 rows 则还原多行
     * 如果还额外传了 field 则还原指定单元格
     */
    revertData(rows, field) {
      let { tableSourceData, getRowIndex } = this;
      if (arguments.length) {
        if (rows && !XEUtils.isArray(rows)) {
          rows = [rows];
        }
        rows.forEach(row => {
          let rowIndex = getRowIndex(row);
          let oRow = tableSourceData[rowIndex];
          if (oRow && row) {
            if (field) {
              XEUtils.set(row, field, XEUtils.get(oRow, field));
            } else {
              XEUtils.destructuring(row, oRow);
            }
          }
        });
        return this.$nextTick();
      }
      return this.reloadData(tableSourceData);
    },
    /**
     * 清空单元格内容
     * 如果不创参数，则清空整个表格内容
     * 如果传 row 则清空一行内容
     * 如果传 rows 则清空多行内容
     * 如果还额外传了 field 则清空指定单元格内容
     */
    clearData(rows, field) {
      let { tableFullData, visibleColumn } = this;
      if (!arguments.length) {
        rows = tableFullData;
      } else if (rows && !XEUtils.isArray(rows)) {
        rows = [rows];
      }
      if (field) {
        rows.forEach(row => XEUtils.set(row, field, null));
      } else {
        rows.forEach(row => {
          visibleColumn.forEach(column => {
            if (column.property) {
              CellUtils.setCellValue(row, column, null);
            }
          });
        });
      }
      return this.$nextTick();
    },
    hasRowInsert(row) {
      return this.editStore.insertList.indexOf(row) > -1;
    },
    hasRowChange(row, field) {
      let oRow, property;
      let { visibleColumn, treeConfig, tableSourceData, fullDataRowIdData } = this;
      let rowid = CellUtils.getRowid(this, row);
      // 新增的数据不需要检测
      if (!fullDataRowIdData[rowid]) {
        return false;
      }
      if (treeConfig) {
        let children = treeConfig.children;
        let matchObj = XEUtils.findTree(tableSourceData, item => rowid === CellUtils.getRowid(this, item), treeConfig);
        row = Object.assign({}, row, { [children]: null });
        if (matchObj) {
          oRow = Object.assign({}, matchObj.item, { [children]: null });
        }
      } else {
        let oRowIndex = fullDataRowIdData[rowid].index;
        oRow = tableSourceData[oRowIndex];
      }
      if (oRow) {
        if (arguments.length > 1) {
          return !XEUtils.isEqual(XEUtils.get(oRow, field), XEUtils.get(row, field));
        }
        for (let index = 0, len = visibleColumn.length; index < len; index++) {
          property = visibleColumn[index].property;
          if (property && !XEUtils.isEqual(XEUtils.get(oRow, property), XEUtils.get(row, property))) {
            return true;
          }
        }
      }
      return false;
    },
    /**
     * 获取表格所有列
     */
    getColumns(columnIndex) {
      let columns = this.visibleColumn;
      return arguments.length ? columns[columnIndex] : columns.slice(0);
    },
    getColumnById(colid) {
      let fullColumnIdData = this.fullColumnIdData;
      return fullColumnIdData[colid] ? fullColumnIdData[colid].column : null;
    },
    getColumnByField(field) {
      return this.visibleColumn.find(column => column.property === field);
    },
    /**
     * 获取表格可视列
     */
    getTableColumn() {
      return {
        fullColumn: this.tableFullColumn.slice(0),
        visibleColumn: this.visibleColumn.slice(0),
        tableColumn: this.tableColumn.slice(0)
      };
    },
    // 在 v3.0 中废弃 getRecords
    getRecords() {
      Utils.warn("vxe.error.delGetRecords");
      return this.getData.apply(this, arguments);
    },
    /**
     * 获取表格所有数据
     */
    getData(rowIndex) {
      let tableSynchData = this.data || this.tableSynchData;
      return arguments.length ? tableSynchData[rowIndex] : tableSynchData.slice(0);
    },
    // 在 v3.0 中废弃 getAllRecords
    getAllRecords() {
      Utils.warn("vxe.error.delGetAllRecords");
      return this.getRecordset();
    },
    /**
     * 获取表格数据集
     */
    getRecordset() {
      return {
        insertRecords: this.getInsertRecords(),
        removeRecords: this.getRemoveRecords(),
        updateRecords: this.getUpdateRecords()
      };
    },
    /**
     * 获取新增数据
     */
    getInsertRecords() {
      return this.editStore.insertList;
    },
    /**
     * 获取删除数据
     */
    getRemoveRecords() {
      return this.editStore.removeList;
    },
    /**
     * 获取选中数据
     */
    getSelectRecords(column) {
      if (!column) {
        let list = [];
        this.tableColumn.forEach(column => {
          if (column.isSelectionType()) {
            const tmpList = this.getSelectRecords(column) || [];
            tmpList.forEach(item => {
              if (!list.some(item1 => item1 === item) ) {
                list.push(item);
              }
            });
          }
        });
        return list;
      }

      if (!column.selectConfig) {
        return [];
      }

      let { tableFullData, editStore, treeConfig } = this;
      let { checkField: property, checkMethod, selection } = column.selectConfig;
      let rowList = [];
      let insList = [];
      let func = undefined;
      if (checkMethod) {
        func = (row, rowIndex) => checkMethod({ row, column, rowIndex });
      } else if (property) {
        func = row => XEUtils.get(row, property);
      } else {
        func = row => selection.indexOf(row) > -1;
      }
      //考虑下 isCheckedRow  我后面加上了

      if (treeConfig) {
        rowList = XEUtils.filterTree(tableFullData, func, treeConfig);
      } else {
        rowList = tableFullData.filter(func);
      }
      insList = editStore.insertList.filter(func);

      return rowList.concat(insList);
    },
    /**
     * 获取更新数据
     * 只精准匹配 row 的更改
     * 如果是树表格，子节点更改状态不会影响父节点的更新状态
     */
    getUpdateRecords() {
      let { tableFullData, hasRowChange, treeConfig } = this;
      if (treeConfig) {
        return XEUtils.filterTree(tableFullData, row => hasRowChange(row), treeConfig);
      }
      return tableFullData.filter(row => hasRowChange(row));
    },
    /**
     * 获取处理后全量的表格数据
     * 如果存在筛选条件，继续处理
     */
    updateAfterFullData() {
      let { tableFullData /*visibleColumn, , remoteSort, remoteFilter*/ } = this;
      let tableData = tableFullData;
      // let column = visibleColumn.find(column => column.filterSortStore.sort);
      // let filterColumn = visibleColumn.filter(({ filterSortStore }) => filterSortStore && filterSortStore.filterable);
      // tableData = tableData.filter(row => {
      //   return filterColumn.every(column => {
      //     //本地筛选去掉
      //     return true;
      //   });
      // });
      // if (column && column.filterSortStore.sort) {
      //   let isRemote = XEUtils.isBoolean(column.remoteSort) ? column.remoteSort : remoteSort;
      //   if (!isRemote) {
      //     if (this.sortMethod) {
      //       tableData = this.sortMethod({
      //         data: tableData, column, property: column.property, order: column.filterSortStore.sort, $table: this
      //       }) || tableData;
      //     } else {
      //       let rest = column.filterSortMethod ? tableData.sort(column.filterSortMethod) : XEUtils.sortBy(tableData, column.property);
      //       tableData = column.filterSortStore.sort === "desc" ? rest.reverse() : rest;
      //     }
      //   }
      // }
      this.afterFullData = tableData;
      return tableData;
    },
    getRowById(rowid) {
      let fullDataRowIdData = this.fullDataRowIdData;
      return fullDataRowIdData[rowid] ? fullDataRowIdData[rowid].row : null;
    },
    /**
     * 获取处理后的表格数据
     * 如果存在筛选条件，继续处理
     * 如果存在排序，继续处理
     */
    getTableData() {
      let { tableFullData, afterFullData, tableData, footerData } = this;
      return {
        fullData: tableFullData.slice(0),
        visibleData: afterFullData.slice(0),
        tableData: tableData.slice(0),
        footerData: footerData.slice(0)
      };
    },
    setupBeforceRender() {
      //render初始化
      if (this.treeConfig) {
        if (!this.treeConfig.children) {
          this.treeConfig.children = "children";
        }
        if (!this.treeConfig.hasChildren) {
          this.treeConfig.hasChildren = "hasChildren";
        }
      }
    },
    handleDefault() {
      if (this.selectConfig) {
        this.handleSelectionDefChecked();
      }

      if (this.expandConfig) {
        this.handleDefaultRowExpand();
      }

      if (this.treeProps) {
        Utils.error("vxe.error.delTreeProps"); //单向绑定是不允许修改值的, 请不要使用这个props
        this.treeConfig = this.treeProps; //兼容el, 由于组件是后期写的, 前期没有 哎
      }

      if (this.treeConfig) {
        this.handleDefaultTreeExpand();
      }

      ///首次初始化 赋值无法修改 (add by xu)
      // if (this.tableColumn) {
      //   this.tableColumn.forEach(column => {
      //     if (column.editRender && column.own && column.editRender !== column.own.editRender) {
      //       column.own.editRender = column.editRender;
      //     }
      //   });
      // }
      this.updateFooter();
      this.$nextTick(() => setTimeout(this.recalculate));
    },
    /**
     * 动态列处理
     */
    mergeCustomColumn(customColumns) {
      let { tableFullColumn } = this;
      this.isUpdateCustoms = true;
      if (customColumns.length) {
        tableFullColumn.forEach(column => {
          // 在 v3.0 中废弃 prop
          let item = customColumns.find(item => column.property && (item.field || item.prop) === column.property);
          if (item) {
            if (XEUtils.isNumber(item.resizeWidth)) {
              column.resizeWidth = item.resizeWidth;
            }
            if (XEUtils.isBoolean(item.visible)) {
              column.visible = item.visible;
            }
          }
        });
      }
      this.$emit("update:customs", tableFullColumn);
    },
    resetAll() {
      this.resetCustoms();
      this.resetResizable();
    },
    hideColumn(column) {
      return this.handleVisibleColumn(column, false);
    },
    showColumn(column) {
      return this.handleVisibleColumn(column, true);
    },
    resetCustoms() {
      return this.handleVisibleColumn();
    },
    handleVisibleColumn(column, visible) {
      if (arguments.length) {
        column.visible = visible;
      } else {
        this.tableFullColumn.forEach(column => {
          column.visible = true;
        });
      }
      if (this.$toolbar) {
        this.$toolbar.updateSetting();
      }
      return this.$nextTick();
    },
    /**
     * 初始化加载动态列
     */
    reloadCustoms(customColumns) {
      return this.$nextTick().then(() => {
        this.mergeCustomColumn(customColumns);
        return this.refreshColumn().then(() => this.tableFullColumn);
      });
    },
    /**
     * 刷新列信息
     * 将固定的列左边、右边分别靠边
     * 如果使用了分组表头，固定列必须在左侧或者右侧
     */
    refreshColumn() {
      let isColspan;
      let letIndex = 0;
      let leftList = [];
      let leftStartIndex = null;
      let rightEndIndex = null;
      let centerList = [];
      let rightList = [];
      let { tableFullColumn, isGroup, columnStore, scrollXStore, optimizeOpts } = this;
      let { scrollX } = optimizeOpts;
      // 如果是分组表头，如果子列全部被隐藏，则根列也隐藏
      if (isGroup) {
        XEUtils.eachTree(
          this.collectColumn,
          column => {
            if (column.children && column.children.length) {
              column.visible = !!XEUtils.findTree(column.children, subColumn => (subColumn.children && subColumn.children.length ? 0 : subColumn.visible), headerProps);
            }
          },
          headerProps
        );
      }
      // 重新分配列
      tableFullColumn
        .filter(column => column.visible)
        .forEach((column, columnIndex) => {
          if (column.fixed === "left") {
            if (leftStartIndex === null) {
              leftStartIndex = letIndex;
            }
            if (!isColspan) {
              if (columnIndex - letIndex !== 0) {
                isColspan = true;
              } else {
                letIndex++;
              }
            }
            leftList.push(column);
          } else if (column.fixed === "right") {
            if (!isColspan) {
              if (rightEndIndex === null) {
                rightEndIndex = columnIndex;
              }
              if (columnIndex - rightEndIndex !== 0) {
                isColspan = true;
              } else {
                rightEndIndex++;
              }
            }
            rightList.push(column);
          } else {
            centerList.push(column);
          }
        });
      let visibleColumn = leftList.concat(centerList).concat(rightList);

      //treeNode 相关设置
      let isSetTreeNode = visibleColumn.some(column => column.own && column.own.treeNode); //未设置过显示节点信息, 采用默认
      // debugger
      if (!isSetTreeNode && visibleColumn.length > 0) {
        const firstCol = visibleColumn[0];
        const lastCol = visibleColumn[visibleColumn.length - 1];
        visibleColumn.forEach(column => {
          let treeNode = false;
          // 判断是否有树形配置
          if (!isSetTreeNode && !column.isSystemType() && !column.editRender && this.treeConfig) {
            isSetTreeNode = treeNode = true;
          }
          column.treeNode = treeNode;
          column.isFirstCol = column === firstCol;
          column.isLastCol = column === lastCol;
        });
      }

      let scrollXLoad = scrollX && scrollX.gt && scrollX.gt < tableFullColumn.length;
      Object.assign(columnStore, {
        leftList,
        centerList,
        rightList
      });
      if (isGroup && (isColspan || leftStartIndex || (rightEndIndex !== null && rightEndIndex !== visibleColumn.length))) {
        Utils.error("vxe.error.groupFixed");
      }
      if (scrollXLoad) {
        if (this.resizable || visibleColumn.some(column => column.resizable)) {
          Utils.warn("vxe.error.notResizable");
        }
        Object.assign(scrollXStore, {
          startIndex: 0,
          visibleIndex: 0,
          renderSize: XEUtils.toNumber(scrollX.rSize),
          offsetSize: XEUtils.toNumber(scrollX.oSize)
        });
        visibleColumn = visibleColumn.slice(scrollXStore.startIndex, scrollXStore.startIndex + scrollXStore.renderSize);
      }
      this.scrollXLoad = scrollXLoad;
      this.tableColumn = visibleColumn;
      return this.$nextTick().then(() => {
        this.updateFooter();
        this.recalculate(true);
      });
    },
    /**
     * 指定列宽的列进行拆分
     */
    analyColumnWidth() {
      let { columnWidth, columnMinWidth } = this;
      let resizeList = [];
      let pxList = [];
      let pxMinList = [];
      let scaleList = [];
      let scaleMinList = [];
      let autoList = [];
      this.tableFullColumn.forEach(column => {
        if (columnWidth && !column.width) {
          column.width = columnWidth;
        }
        if (columnMinWidth && !column.minWidth) {
          column.minWidth = columnMinWidth;
        }
        if (column.visible) {
          if (column.resizeWidth) {
            resizeList.push(column);
          } else if (DomTools.isPx(column.width)) {
            pxList.push(column);
          } else if (DomTools.isScale(column.width)) {
            scaleList.push(column);
          } else if (DomTools.isPx(column.minWidth)) {
            pxMinList.push(column);
          } else if (DomTools.isScale(column.minWidth)) {
            scaleMinList.push(column);
          } else {
            autoList.push(column);
          }
        }
      });
      Object.assign(this.columnStore, {
        resizeList,
        pxList,
        pxMinList,
        scaleList,
        scaleMinList,
        autoList
      });
    },
    /**
     * 计算单元格列宽，动态分配可用剩余空间
     * 支持 width=? width=?px width=?% min-width=? min-width=?px min-width=?%
     */
    recalculate(refull) {
      if (TableUtils.isDisplayNone(this.$el)) {
        return;
      }
      // 自适应固定列高度
      const heightMap = {};
      this.$el.querySelectorAll(".vxe-body--row").forEach((row) => {
        const rowId = row.getAttribute("data-rowid");
        if (!heightMap[rowId]) {
          heightMap[rowId] = row.offsetHeight;
        } else {
          row.style.height = heightMap[rowId] + "px";
        }
      })
      // DomTools.addClass($el, 'is--recalculate')
      // debugger
      let ret = this.reCalculateCellWidth();
      if (ret && refull) {
        // 初始化时需要在列计算之后再执行优化运算，达到最优显示效果
        return this.computeScrollLoad().then(() => {
          this.reCalculateCellWidth();
          this.computeScrollLoad();
          // DomTools.removeClass($el, 'is--recalculate')
        });
      }

      // DomTools.removeClass($el, 'is--recalculate')
      return this.computeScrollLoad();
    },
    /**
     * 重新布局
     */
    reLayout() {
      if (TableUtils.isDisplayNone(this.$el)) {
        return;
      }
      this.reCalculateCellWidth();
      this.updateStyle();
    },
    /**
     * 列宽计算
     * @returns {boolean} 是否成功
     */
    reCalculateCellWidth() {
      let { $refs } = this;
      let { tableBody } = $refs;
      let bodyElem = tableBody ? tableBody.$el : null;
      // 当中间table被内容撑开时两侧对不齐
      // this.$el.querySelectorAll(".vxe-table--body").forEach((body) => {
      //   body.style.height = tableBody.$el.querySelector(".vxe-table--body").offsetHeight + "px"
      // })
      if (!bodyElem) {
        return false;
      }

      let { tableHeader, tableFooter } = $refs;
      let headerElem = tableHeader ? tableHeader.$el : null;
      let footerElem = tableFooter ? tableFooter.$el : null;

      let tableWidth = 0;
      let bodyWidth = bodyElem.clientWidth; //没显示出来 宽度为0
      let remainWidth = bodyWidth;
      let meanWidth = remainWidth / 100; //百分比
      let { $el, fit, columnStore } = this;
      let minCellWidth = 50; // 列宽最少限制 50px
      let { resizeList, pxMinList, pxList, scaleList, scaleMinList, autoList } = columnStore;

      // 最小宽
      pxMinList.forEach(column => {
        let minWidth = parseInt(column.minWidth);
        tableWidth += minWidth;
        column.renderWidth = minWidth;
      });
      // 最小百分比
      scaleMinList.forEach(column => {
        let scaleWidth = Math.floor(parseInt(column.minWidth) * meanWidth);
        tableWidth += scaleWidth;
        column.renderWidth = scaleWidth;
      });
      // 固定百分比
      scaleList.forEach(column => {
        let scaleWidth = Math.floor(parseInt(column.width) * meanWidth);
        tableWidth += scaleWidth;
        column.renderWidth = scaleWidth;
      });
      // 固定宽
      pxList.forEach(column => {
        let width = parseInt(column.width);
        tableWidth += width;
        column.renderWidth = width;
      });
      // 调整了列宽
      resizeList.forEach(column => {
        let width = parseInt(column.resizeWidth);
        tableWidth += width;
        column.renderWidth = width;
      });
      // debugger
      let remainCount = scaleMinList.length + pxMinList.length + autoList.length;
      remainWidth -= tableWidth;
      // meanWidth = remainWidth > 0 ? Math.floor(remainWidth / remainCount*100)/100  : 0;//最后一列比较宽, 进度条去不掉, 系统显示会默认取整
      meanWidth = remainCount > 0 ? Math.floor(remainWidth / remainCount) : 0;

      if (fit) {
        if (remainWidth > 0) {
          scaleMinList.concat(pxMinList).forEach(column => {
            tableWidth += meanWidth;
            column.renderWidth += meanWidth;
          });
        }
      } else {
        meanWidth = minCellWidth;
      }

      // 自适应
      autoList.forEach((column, index) => {
        let width = Math.max(meanWidth, minCellWidth);
        tableWidth += width;
        column.renderWidth = width;
        if (fit && index === autoList.length - 1) {
          // 如果所有列足够放的情况下，修补列之间的误差
          let otherWidth = 0; //(meanWidth - Math.floor(meanWidth))*remainCount;
          let odiffer = bodyWidth - tableWidth - otherWidth;
          // debugger
          if (odiffer > 0) {
            column.renderWidth += odiffer;
            tableWidth = bodyWidth;
          }
          column.renderWidth = Math.floor(column.renderWidth);
        }
      });
      let tableHeight = bodyElem.offsetHeight;
      let overflowY = bodyElem.scrollHeight > bodyElem.clientHeight;
      this.scrollbarWidth = overflowY ? bodyElem.offsetWidth - bodyWidth : 0;
      this.overflowY = overflowY;
      this.tableWidth = tableWidth;
      this.tableHeight = tableHeight;
      //计算父节点的 parentHeight

      if (!this.parentHeight) {
        this.parentHeight = $el.parentNode.clientHeight;
      }
      if (headerElem) {
        // 有固定列时对不齐，所以当tableData只有一行数据时取scrollHeight和offsetHeight平均值
        if (this.tableData.length === 1) {
          this.headerHeight =  (headerElem.scrollHeight + headerElem.offsetHeight) / 2;
        } else {
          this.headerHeight = headerElem.offsetHeight;
        }
      }
      if (footerElem) {
        let footerHeight = footerElem.offsetHeight;
        this.scrollbarHeight = Math.max(footerHeight - footerElem.clientHeight, 0);
        this.overflowX = tableWidth > footerElem.clientWidth;
        this.footerHeight = footerHeight;
      } else {
        this.scrollbarHeight = Math.max(tableHeight - bodyElem.clientHeight, 0);
        this.overflowX = tableWidth > bodyWidth;
      }
      if (this.overflowX) {
        this.checkScrolling();
      }

      return true;
    },
    resetResizable() {
      this.visibleColumn.forEach(column => {
        column.resizeWidth = 0;
      });
      if (this.$toolbar) {
        this.$toolbar.resetResizable();
      }
      this.analyColumnWidth();
      return this.recalculate(true);
    },
    updateStyle() {
      let {
        //$el,
        $refs,
        fullColumnIdData,
        maxHeight,
        height,
        parentHeight,
        showBorder,
        tableColumn,
        headerHeight,
        showOverflow: allColumnOverflow,
        showHeaderOverflow: allColumnHeaderOverflow,
        showFooterOverflow: allColumnFooterOverflow,
        showFooter,
        footerHeight,
        tableHeight,
        tableWidth,
        overflowY,
        scrollbarHeight,
        scrollbarWidth,
        scrollXLoad,
        columnStore,
        elemStore,
        currentRow,
        dragConfig,
        heightConfig
      } = this;

      // let { tableBody } = $refs;
      // let bodyElem = tableBody ? tableBody.$el : null;
      // if (!bodyElem) {
      //   debugger;
      //   console.log("xxxxx");
      //   return this.$nextTick();
      // }
      if (TableUtils.isDisplayNone(this.$el)) {
        return ;
      }

      let containerList = ["main", "left", "right"];
      // debugger
      let freeHeight, customHeight, fixedHeight, fixedLeftWrapperHeight;
      
      if ($refs.tableHeader && $refs.tableHeader.$el && $refs.tableBody && $refs.tableBody.$el) {
        fixedLeftWrapperHeight = $refs.tableBody.$el.offsetHeight;
        fixedHeight = $refs.tableBody.$el.querySelector(".vxe-table--body").offsetHeight + $refs.tableHeader.$el.offsetHeight;
      }
      if (heightConfig && heightConfig.bottomHeight !== undefined && $refs.tableBody.$el) {
        //add by xu 固定 头尾组件
        const paginationHeight = $refs.pagination && $refs.pagination.$el && $refs.pagination.$el.clientHeight ? $refs.pagination.$el.clientHeight : 0;
        freeHeight = DomTools.getAbsoluteTransitionPos(this,$refs.tableBody.$el).top + paginationHeight + parseInt(heightConfig.bottomHeight) + footerHeight + 1; //1为外边框
      } else {
        customHeight = height === "auto" ? parentHeight : DomTools.isScale(height) ? Math.floor((parseInt(height) / 100) * parentHeight) : XEUtils.toNumber(height);
      }
      
      containerList.forEach((name, index) => {
        let fixedType = index > 0 ? name : "";
        let layoutList = ["header", "body", "footer"];
        let fixedColumn = columnStore[`${fixedType}List`];
        let fixedWrapperElem = $refs[`${fixedType}Container`];
        layoutList.forEach(layout => {
          let wrapperElem = elemStore[`${name}-${layout}-wrapper`];
          let tableElem = elemStore[`${name}-${layout}-table`];
          if (layout === "header") {
            // 表头体样式处理
            // 横向滚动渲染
            // debugger
            let tWidth = tableWidth;
            if (scrollXLoad) {
              if (fixedType) {
                tableColumn = fixedColumn;
              }
              tWidth = tableColumn.reduce((previous, column) => previous + column.renderWidth, 0);
            }
            if (tableElem) {
              // tableElem.style.width = tWidth === null ? tWidth : `${tWidth}px`;
              tableElem.style.width = tWidth === null ? tWidth : `${tWidth + scrollbarWidth}px`;
            }

            let repairElem = elemStore[`${name}-${layout}-repair`];
            if (repairElem) {
              repairElem.style.width = `${tableWidth}px`;
            }

            let listElem = elemStore[`${name}-${layout}-list`];
            if (listElem) {
              XEUtils.arrayEach(listElem.querySelectorAll(".col--gutter"), thElem => {
                thElem.style.width = `${scrollbarWidth}px`;
              });
            }
          } else if (layout === "body") {
            let emptyBlockElem = elemStore[`${name}-${layout}-emptyBlock`];
            if (wrapperElem) {
              if (freeHeight) {
                //add by xu 固定 头尾组件
                const tmpHeight = freeHeight + (fixedType ? (showFooter ? 0 : scrollbarHeight) : 0); //固定列 滚动条会压住
                wrapperElem.style.height = "calc(100vh - " + tmpHeight + "px)";
              } else if (customHeight > 0) {
                wrapperElem.style.height = `${
                  fixedType ?
                    (customHeight > 0 ? customHeight - headerHeight - footerHeight : tableHeight) - (showFooter ? 0 : scrollbarHeight) :
                    customHeight - headerHeight - footerHeight// - (showFooter ? 0 : scrollbarHeight)
                }px`;
              } else if (maxHeight) {
                maxHeight = DomTools.isScale(maxHeight) ? Math.floor((parseInt(maxHeight) / 100) * parentHeight) : XEUtils.toNumber(maxHeight);
                wrapperElem.style.maxHeight = `${fixedType ? maxHeight - headerHeight - (showFooter ? 0 : scrollbarHeight) : maxHeight - headerHeight}px`;
              }
            }

            // 如果是固定列
            if (fixedWrapperElem) {
              let isRightFixed = fixedType === "right";
              let isLeftFixed = fixedType === "left";
              let fixedColumn = columnStore[`${fixedType}List`];
              let fixedWrapperElemWidth = fixedColumn.reduce((previous, column) => previous + column.renderWidth, isRightFixed ? scrollbarWidth : 0);
              if (isRightFixed && scrollbarWidth) {
                fixedWrapperElemWidth -= 1;
              } else if (showBorder && isLeftFixed) {
                fixedWrapperElemWidth += 1;
              } else if (showBorder) {
                fixedWrapperElemWidth += 1;
              }
              wrapperElem.style.top = `${headerHeight}px`;
              fixedWrapperElem.style.width = `${fixedWrapperElemWidth}px`;
              const otherHeight = headerHeight + footerHeight - scrollbarHeight * (showFooter ? 2 : 1);
              if (fixedHeight && fixedHeight < fixedLeftWrapperHeight) {
                fixedWrapperElem.style.height = `${fixedHeight}px`;
              } else if (freeHeight) {
                fixedWrapperElem.style.height = "calc(100vh - " + `${freeHeight - otherHeight}px)`;
              } else {
                fixedWrapperElem.style.height = `${(customHeight > 0 ? customHeight - headerHeight - footerHeight : tableHeight) + otherHeight}px`;
              }
            }

            let tWidth = tableWidth;
            // 如果是固定列与设置了超出隐藏
            if (fixedType && allColumnOverflow) {
              tableColumn = fixedColumn;
              tWidth = tableColumn.reduce((previous, column) => previous + column.renderWidth, 0);
            } else if (scrollXLoad) {
              if (fixedType) {
                tableColumn = fixedColumn;
              }
              tWidth = tableColumn.reduce((previous, column) => previous + column.renderWidth, 0);
            }

            if (tableElem) {
              if (tWidth) {
                tableElem.style.width = `${tWidth}px`;
              }
              // 兼容性处理
              if (overflowY && fixedType && (browse["-moz"] || browse["safari"])) {
                tableElem.style.paddingRight = `${scrollbarWidth}px`;
              }
            }
            if (emptyBlockElem && tWidth) {
              emptyBlockElem.style.width = `${tWidth}px`;
            }
          } else if (layout === "footer") {
            // 如果是使用优化模式
            let tWidth = tableWidth;
            if (fixedType && allColumnOverflow) {
              tableColumn = fixedColumn;
              tWidth = tableColumn.reduce((previous, column) => previous + column.renderWidth, 0);
            } else if (scrollXLoad) {
              if (fixedType) {
                tableColumn = fixedColumn;
              }
              tWidth = tableColumn.reduce((previous, column) => previous + column.renderWidth, 0);
            }
            if (wrapperElem) {
              // 如果是固定列
              if (fixedWrapperElem) {
                wrapperElem.style.top = `${customHeight ? customHeight - footerHeight : tableHeight + headerHeight}px`;
              }
              wrapperElem.style.marginTop = `${-scrollbarHeight - 1}px`;
            }
            if (tableElem) {
              tableElem.style.width = tWidth === null ? tWidth : `${tWidth + scrollbarWidth}px`;
            }
          }
          let colgroupElem = elemStore[`${name}-${layout}-colgroup`];
          if (colgroupElem) {
            XEUtils.arrayEach(colgroupElem.children, colElem => {
              let colid = colElem.getAttribute("name");
              if (colid === "col-gutter") {
                colElem.width = `${scrollbarWidth || ""}`;
              }
              if (fullColumnIdData[colid]) {
                let column = fullColumnIdData[colid].column;
                // debugger
                // column.renderWidth = 154;

                let { showHeaderOverflow, showFooterOverflow, showOverflow, renderWidth } = column;
                let cellOverflow;
                colElem.width = `${renderWidth || ""}`;
                if (layout === "header") {
                  //add by xu 拖拽不能启动宽度,不然存在宽度问题
                  // debugger
                  cellOverflow = !dragConfig && (XEUtils.isUndefined(showHeaderOverflow) || XEUtils.isNull(showHeaderOverflow) ? allColumnHeaderOverflow : showHeaderOverflow);
                } else if (layout === "footer") {
                  cellOverflow = !dragConfig && (XEUtils.isUndefined(showFooterOverflow) || XEUtils.isNull(showFooterOverflow) ? allColumnFooterOverflow : showFooterOverflow);
                } else {
                  cellOverflow = XEUtils.isUndefined(showOverflow) || XEUtils.isNull(showOverflow) ? allColumnOverflow : showOverflow;
                }
                let showEllipsis = cellOverflow === "ellipsis";
                let showTitle = cellOverflow === "title";
                let showTooltip = cellOverflow === true || cellOverflow === "tooltip";
                let hasEllipsis = showTitle || showTooltip || showEllipsis;
                let listElem = elemStore[`${name}-${layout}-list`];
                if (listElem && hasEllipsis) {
                  XEUtils.arrayEach(listElem.querySelectorAll(`.${column.id}`), thElem => {
                    let cellElem = thElem.querySelector(".vxe-cell");
                    if (cellElem) {
                      //TODO: 此处存在bug, 合并单元格后 又有overflow, 税务报表手动处理了
                      cellElem.style.width = `${showBorder ? renderWidth - 1 : renderWidth}px`; //单元格宽度
                    }
                  });
                }
              }
            });

            //add by xu (多行列头的时候  只有最后一个列头才会被计算宽度,  列数较多,窄, 并且表头文本很长会重现)
            if (layout === "header") {
              let listElem = elemStore[`${name}-${layout}-list`];
              if (listElem && listElem.children && listElem.children.length > 1) {
                // debugger
                let listElemLength = listElem.children.length;
                let trEndElem = listElem.children[listElemLength - 1]; //最后一行表头, 此表头已经是正确的宽度了, 上方代码已经写了

                for (let trIndex = 0; trIndex < listElemLength - 1; ++trIndex) {
                  let trElem = listElem.children[trIndex];

                  for (let thElem of trElem.children) {
                    let colid = thElem.getAttribute("data-colid");
                    let colspan = thElem.getAttribute("colspan");
                    if (!colid || !colspan) {
                      break; //那就证明都没必要循环了
                    }
                    colid = ColumnUtils.getColumnIndex(colid); //变为序号
                    colspan = Number(colspan);

                    let cellWidth = 0;
                    for (let index = colid; index < colid + colspan; ++index) {
                      XEUtils.arrayEach(trEndElem.querySelectorAll(`.${ColumnUtils.getColumnId(colid)}`), thElem => {
                        let cellElem = thElem.querySelector(".vxe-cell");
                        if (cellElem && cellElem.style && cellElem.style.width) {
                          let width = cellElem.style.width.replace("px", "");
                          cellWidth += Number(width) + !!showBorder;
                        }
                      });
                    }

                    if (cellWidth) {
                      let cellElem = thElem.querySelector(".vxe-cell");
                      if (cellElem) {
                        cellElem.style.width = `${showBorder ? cellWidth - 1 : cellWidth}px`; //这就是真实单元格宽度
                      }
                    }
                  }
                }
              }
            }
          }
        });
      });
      if (currentRow) {
        this.setCurrentRow(currentRow);
      }
      return this.$nextTick();
    },
    preventEvent(evnt, type, args, next, end) {
      let evntList = Interceptor.get(type);
      if (!evntList.some(func => func(args, evnt, this) === false)) {
        if (next) {
          next();
        }
      }
      if (end) {
        end();
      }
    },
    /**
     * 全局按下事件处理
     */
    handleGlobalMousedownEvent(evnt) {
      let {
        $el,
        filterStore,
        $refs,
        // editStore,
        ctxMenuStore
      } = this;
      // let { actived } = editStore;
      let { filterWrapper /*, validTip*/ } = $refs;
      if (filterWrapper) {
        const {datePicker} = filterWrapper.$refs;
        if (this.getEventTargetNode(evnt, $el, "vxe-filter-wrapper").flag) {
          // 如果点击了筛选按钮
        } else if (this.getEventTargetNode(evnt, filterWrapper.$el).flag) {
          // 如果点击筛选容器
        } else if (datePicker && datePicker.picker && this.getEventTargetNode(evnt, datePicker.picker.$el).flag) {
          // 如果点击筛选容器弹出框
        } else {
          this.preventEvent(evnt, "event.clear_filter", filterStore.args, this.closeFilter);
        }
      }
      // 如果配置了快捷菜单且，点击了其他地方则关闭
      if (ctxMenuStore.visible && this.$refs.ctxWrapper && !this.getEventTargetNode(evnt, this.$refs.ctxWrapper.$el).flag) {
        this.closeMenu();
      }
    },
    /**
     * 窗口失焦事件处理
     */
    handleGlobalBlurEvent(evnt) {
      // this.closeFilter();//不能关闭, 筛选可能存在二级弹框
      this.closeMenu();
    },
    /**
     * 全局滚动事件
     */
    handleGlobalMousewheelEvent(evnt) {
      this.clostTooltip();
      this.closeMenu();
    },
    /**
     * 全局键盘事件
     */
    handleGlobalKeydownEvent(evnt) {
      let { isCtxMenu, ctxMenuStore, editStore, mouseConfig = {}, keyboardConfig = {}, treeConfig, highlightCurrentRow, currentRow } = this;
      let { selected, actived } = editStore;
      let keyCode = evnt.keyCode;
      let isBack = keyCode === 8;
      let isTab = keyCode === 9;
      let isEnter = keyCode === 13;
      let isEsc = keyCode === 27;
      let isSpacebar = keyCode === 32;
      let isLeftArrow = keyCode === 37;
      let isUpArrow = keyCode === 38;
      let isRightArrow = keyCode === 39;
      let isDwArrow = keyCode === 40;
      let isDel = keyCode === 46;
      let isA = keyCode === 65;
      let isC = keyCode === 67;
      let isV = keyCode === 86;
      let isX = keyCode === 88;
      let isF2 = keyCode === 113;
      let isCtrlKey = evnt.ctrlKey;
      let operArrow = isLeftArrow || isUpArrow || isRightArrow || isDwArrow;
      let operCtxMenu = isCtxMenu && ctxMenuStore.visible && (isEnter || isSpacebar || operArrow);
      let params;
      if (isEsc) {
        // 如果按下了 Esc 键，关闭快捷菜单、筛选
        this.closeMenu();
        this.closeFilter();
        // 如果是激活编辑状态，则取消编辑
        if (actived.row) {
          params = actived.args;
          this.clearActived(evnt);
          // 如果配置了选中功能，则为选中状态
          if (mouseConfig.selected) {
            this.$nextTick(() => this.handleSelected(params, evnt));
          }
        }
      } else if (isSpacebar && (keyboardConfig.isArrow || keyboardConfig.isTab) && selected.row && selected.column && selected.column.isSelectionRadioType()) {
        // 空格键支持选中复选列
        evnt.preventDefault();
        if (selected.column.isRadioType()) {
          this.triggerRadioRowEvent(evnt, selected.args);
        } else {
          this.handleToggleCheckRowEvent(selected.args, evnt);
        }
      } else if (isEnter && (keyboardConfig.isArrow || keyboardConfig.isTab) && (selected.row || actived.row || (treeConfig && highlightCurrentRow && currentRow))) {
        // 如果是激活状态，退则出到下一行
        if (selected.row || actived.row) {
          this.moveSelected(selected.row ? selected.args : actived.args, isLeftArrow, isUpArrow, isRightArrow, true, evnt);
        } else if (treeConfig && highlightCurrentRow && currentRow) {
          // 如果是树形表格当前行回车移动到子节点
          let childrens = currentRow[treeConfig.children];
          if (childrens && childrens.length) {
            evnt.preventDefault();
            let targetRow = childrens[0];
            params = { $table: this, row: targetRow };
            this.setTreeExpansion(currentRow, true)
              .then(() => this.scrollToRow(targetRow))
              .then(() => this.triggerCurrentRowEvent(evnt, params));
          }
        }
      } else if (operCtxMenu) {
        // 如果配置了右键菜单; 支持方向键操作、回车
        evnt.preventDefault();
        if (ctxMenuStore.showChild && Utils.hasChildrenList(ctxMenuStore.selected)) {
          this.moveCtxMenu(evnt, keyCode, ctxMenuStore, "selectChild", 37, false, ctxMenuStore.selected.children);
        } else {
          this.moveCtxMenu(evnt, keyCode, ctxMenuStore, "selected", 39, true, this.ctxMenuList);
        }
      } else if (isF2) {
        // 如果按下了 F2 键
        if (selected.row && selected.column) {
          evnt.preventDefault();
          this.handleActived(selected.args, evnt);
        }
      } else if (operArrow && keyboardConfig.isArrow) {
        // 如果按下了方向键
        if (selected.row && selected.column) {
          this.moveSelected(selected.args, isLeftArrow, isUpArrow, isRightArrow, isDwArrow, evnt);
        } else if ((isUpArrow || isDwArrow) && highlightCurrentRow && currentRow) {
          // 当前行按键上下移动
          this.moveCurrentRow(isUpArrow, isDwArrow, evnt);
        }
      } else if (isTab && keyboardConfig.isTab) {
        // 如果按下了 Tab 键切换
        if (selected.row || selected.column) {
          this.moveTabSelected(selected.args, evnt);
        } else if (actived.row || actived.column) {
          this.moveTabSelected(actived.args, evnt);
        }
      } else if (isDel || (treeConfig && highlightCurrentRow && currentRow ? isBack && keyboardConfig.isArrow : isBack)) {
        // 如果是删除键
        if (keyboardConfig.isDel && (selected.row || selected.column)) {
          CellUtils.setCellValue(selected.row, selected.column, null);
          if (isBack) {
            this.handleActived(selected.args, evnt);
          }
        } else if (isBack && keyboardConfig.isArrow && treeConfig && highlightCurrentRow && currentRow) {
          // 如果树形表格回退键关闭当前行返回父节点
          let { parent: parentRow } = XEUtils.findTree(this.afterFullData, item => item === currentRow, treeConfig);
          if (parentRow) {
            evnt.preventDefault();
            params = { $table: this, row: parentRow };
            this.setTreeExpansion(parentRow, false)
              .then(() => this.scrollToRow(parentRow))
              .then(() => this.triggerCurrentRowEvent(evnt, params));
          }
        }
      } else if (keyboardConfig.isCut && isCtrlKey && (isA || isX || isC || isV)) {
        // 如果开启复制功能
        if (isA) {
          this.handleAllChecked(evnt);
        } else if (isX || isC) {
          this.handleCopyed(isX, evnt);
        } else {
          this.handlePaste(evnt);
        }
      } else if (
        keyboardConfig.isEdit &&
        !isCtrlKey &&
        ((keyCode >= 48 && keyCode <= 57) ||
          (keyCode >= 65 && keyCode <= 90) ||
          (keyCode >= 96 && keyCode <= 111) ||
          (keyCode >= 186 && keyCode <= 192) ||
          (keyCode >= 219 && keyCode <= 222) ||
          keyCode === 32)
      ) {
        // 如果是按下非功能键之外允许直接编辑
        if (selected.column && selected.row && selected.column.editRender) {
          if (!keyboardConfig.editMethod || !(keyboardConfig.editMethod(selected.args, evnt) === false)) {
            CellUtils.setCellValue(selected.row, selected.column, null);
            this.handleActived(selected.args, evnt);
          }
        }
      }
      this.preventEvent(evnt, "event.keydown", { $table: this });
    },
    handleGlobalResizeEvent() {
      // if (this.$el && this.$el.clientWidth === 0) {
      //   TableUtils.addTableWidthMoniterIfNeed(this);
      // } else {
      //   this.recalculate();
      // }
      this.recalculate();

    },
    /**
     * 关闭快捷菜单
     */
    closeMenu() {
      Object.assign(this.ctxMenuStore, {
        visible: false,
        selected: null,
        selectChild: null,
        showChild: false
      });
      return this.$nextTick();
    },
    /**
     * 触发表头 tooltip 事件
     */
    triggerHeaderTooltipEvent(evnt, params) {
      let { tooltipStore } = this;
      let { column } = params;
      if (tooltipStore.column !== column || !tooltipStore.visible) {
        // 在 v3.0 中废弃 label
        this.handleTooltip(evnt, column);
      }
    },
    /**
     * 触发表尾 tooltip 事件
     */
    triggerFooterTooltipEvent(evnt, params) {
      let { column } = params;
      let { tooltipStore } = this;
      if (tooltipStore.column !== column || !tooltipStore.visible) {
        this.handleTooltip(evnt, column);
      }
    },
    /**
     * 触发 tooltip 事件
     */
    triggerTooltipEvent(evnt, params) {
      let { tooltipStore } = this;
      let { row, column } = params;
      if (tooltipStore.column !== column || tooltipStore.row !== row || !tooltipStore.visible) {
        this.handleTooltip(evnt, column, row);
      }
    },
    // 显示 tooltip
    handleTooltip(evnt, column, row) {
      let cell = evnt.currentTarget;
      let tooltip = this.$refs.tooltip;
      let wrapperElem = cell.children[0];
      let content = cell.innerText;
      // debugger;
      // if (content && wrapperElem.scrollWidth > wrapperElem.clientWidth) {//原始代码(只有一行文本 省略号 这么判断没问题)
      if (content && row && wrapperElem.nodeType === 1 && wrapperElem.scrollHeight > wrapperElem.clientHeight && ![...(wrapperElem.firstChild.classList || [])].includes("not-tooltip")) {
        //add by xu 多行文本省略号(表头不需要)
        Object.assign(this.tooltipStore, {
          row,
          column,
          visible: true
        });
        if (tooltip) {
          tooltip.toVisible(cell, CellUtils.formatText(content));
        }
      }
      return this.$nextTick();
    },
    // 关闭 tooltip
    clostTooltip() {
      let tooltip = this.$refs.tooltip;
      Object.assign(this.tooltipStore, {
        row: null,
        column: null,
        content: null,
        visible: false
      });
      if (tooltip) {
        tooltip.close();
      }
      return this.$nextTick();
    },

    triggerCurrentRowEvent(evnt, params) {
      let isChange = this.currentRow !== params.row;
      this.setCurrentRow(params.row);
      if (isChange) {
        Utils.emitEvent(this, "current-change", [params, evnt]);
      }
    },
    /**
     * 高亮行，设置某一行为高亮状态，如果调不加参数，则会取消目前高亮行的选中状态
     */
    setCurrentRow(row) {
      this.clearCurrentRow();
      this.clearCurrentColumn();
      this.currentRow = row;
      if (this.highlightCurrentRow) {
        XEUtils.arrayEach(this.$el.querySelectorAll(`[data-rowid="${CellUtils.getRowid(this, row)}"]`), elem => DomTools.addClass(elem, "row--current"));
      }
      return this.$nextTick();
    },
    clearCurrentRow() {
      this.currentRow = null;
      this.hoverRow = null;
      XEUtils.arrayEach(this.$el.querySelectorAll(".row--current"), elem => DomTools.removeClass(elem, "row--current"));
      return this.$nextTick();
    },
    getCurrentRow() {
      return this.currentRow;
    },
    /**
     * 行 hover 事件
     */
    triggerHoverEvent(evnt, { row }) {
      this.setHoverRow(row);
    },
    //悬浮行位置
    changeHoverRowElemPos(rowElem, hoverElem) {
      if (!rowElem || !hoverElem) {
        return;
      }
      
      // debugger
      DomTools.addClass(hoverElem, "row--hover");
      //top
      const top = DomTools.getOffsetPos(rowElem, hoverElem.parentElement).top;//hoverElem.offsetTop + _this.headerHeight;//
      hoverElem.style.top = top + "px";
      //right
      let right = this.scrollbarWidth || 0; //_this.$el.offsetWidth - rowWidth;
      hoverElem.style.right = right + "px";
      if (rowElem.clientHeight) {
        hoverElem.style.lineHeight = rowElem.clientHeight + 'px';
      }
    },
    setHoverRow(row) {

      let rowElem;
      let rowid = CellUtils.getRowid(this, row);
      const { hoverRowConfig, $refs = {} } = this;
      this.clearHoverRow();
      XEUtils.arrayEach(this.$el.querySelectorAll(`[data-rowid="${rowid}"]`), elem => {
        const className = hoverRowConfig && hoverRowConfig.hoverClassName !== undefined ? hoverRowConfig.hoverClassName : "row--hover"
        DomTools.addClass(elem, className);
        if (!rowElem)//只取第一个
          rowElem = elem;
      });

      if (rowElem && ($refs.rowHover || $refs.rowHoverSlot)) {
        this.changeHoverRowElemPos(rowElem, this.$refs.rowHover);
        this.changeHoverRowElemPos(rowElem, this.$refs.rowHoverSlot);
        this.hoverRowData = row;
        if (hoverRowConfig && (hoverRowConfig.data || hoverRowConfig.dataMethod)) {
          this.hoverRowButtonDatas = hoverRowConfig.data || (hoverRowConfig.dataMethod ? hoverRowConfig.dataMethod({ row }) : undefined) || [];
        }
      }

      this.hoverRow = row;
    },
    clearHoverRow() {
      const { hoverRowConfig } = this;
      const className = hoverRowConfig && hoverRowConfig.hoverClassName !== undefined ? hoverRowConfig.hoverClassName : "row--hover"
      XEUtils.arrayEach(this.$el.querySelectorAll(`tr.vxe-body--row.${className}`), elem => DomTools.removeClass(elem, className));

      if (this.hoverRowButtonDatas.length) {//配置数据
        DomTools.removeClass(this.$refs.rowHover, className);
        // this.hoverRowButtonDatas = [];//不能置空(脱离焦点  闪烁)
      }
      if (this.$refs.rowHoverSlot) {
        DomTools.removeClass(this.$refs.rowHoverSlot, className);
      }

      // if (this.$scopedSlots && this.$scopedSlots.hoverRow) {//插槽(兼容)
      //   XEUtils.arrayEach(this.$el.querySelectorAll("span.vxe-hover-row-right-wrapper.row--hover"), elem => DomTools.removeClass(elem, "row--hover")); //悬浮行
      // }

      this.hoverRow = null;
    },
    triggerHeaderCellClickEvent(evnt, params) {
      let { _lastResizeTime } = this;
      let { column, cell } = params;
      let triggerResizable = _lastResizeTime && _lastResizeTime > Date.now() - 300;
      let triggerSort = this.getEventTargetNode(evnt, cell, "vxe-sort-wrapper").flag;
      let triggerFilter = this.getEventTargetNode(evnt, cell, "vxe-filter-wrapper").flag;
      if (!(triggerResizable || triggerSort || triggerFilter)) {
        // this.triggerSortEvent(column, params);
      }
      Utils.emitEvent(this, "header-cell-click", [
        Object.assign(
          {
            triggerResizable,
            triggerSort,
            triggerFilter
          },
          params
        ),
        evnt
      ]);
      if (this.highlightCurrentColumn) {
        return this.setCurrentColumn(column, true);
      }
      return this.$nextTick();
    },
    setCurrentColumn(column) {
      this.clearCurrentRow();
      this.clearCurrentColumn();
      this.currentColumn = column;
      XEUtils.arrayEach(this.$el.querySelectorAll(`.${column.id}`), elem => DomTools.addClass(elem, "col--current"));
      return this.$nextTick();
    },
    clearCurrentColumn() {
      this.currentColumn = null;
      XEUtils.arrayEach(this.$el.querySelectorAll(".col--current"), elem => DomTools.removeClass(elem, "col--current"));
      return this.$nextTick();
    },
    handleChangeCell(evnt, params) {
      this.triggerValidate("blur")
        .catch(e => e)
        .then(() => {
          this.handleActived(params, evnt)
            .then(() => this.triggerValidate("change"))
            .catch(e => e);
        });
    },
    /**
     * 列点击事件
     * 如果是单击模式，则激活为编辑状态
     * 如果是双击模式，则单击后选中状态
     */
    triggerCellClickEvent(evnt, params) {
      const { $el, selectConfig = {}, expandConfig = {}, treeConfig = {}, } = this;//, editStore ,mouseConfig = {}
      // let { actived } = editStore;
      let { column, cell } = params;//row,

      //编辑框 (无需处理)
      // if (column.isEditColumn()) {
      //   return;
      // }

      if ((!column.treeNode || !this.getEventTargetNode(evnt, $el, "vxe-tree-wrapper").flag) && (column.type !== "expand" || !this.getEventTargetNode(evnt, $el, "vxe-table--expanded").flag)) {
        // 如果是高亮行
        // if (highlightCurrentRow) {
        //   if (selectConfig.trigger === "row" || (!this.getEventTargetNode(evnt, $el, "vxe-checkbox").flag && !this.getEventTargetNode(evnt, $el, "vxe-radio").flag)) {
        //     this.triggerCurrentRowEvent(evnt, params);
        //   }
        // }

        // 如果是单/多选(默认值cell单元格点击)
        let isSelectionType = column.isSelectionRadioType();
        if (isSelectionType || selectConfig.trigger === "row") {
          if (selectConfig.trigger !== "default") {
            if (column.isRadioType() && !this.getEventTargetNode(evnt, $el, "vxe-radio").flag) {
              this.triggerRadioRowEvent(evnt, params);
            }
            if (column.isSelectionType() && !this.getEventTargetNode(evnt, params.cell, "vxe-checkbox").flag) {
              this.handleToggleCheckRowEvent(params, evnt);
            }
          }

          //选择列不转发消息
          if (isSelectionType) {
            return;
          }
        }
      }

      // 如果是展开行
      if ((expandConfig.trigger === "row" || (column.type === "expand" && expandConfig.trigger === "cell")) && !this.getEventTargetNode(evnt, $el, "vxe-table--expanded").flag) {
        this.triggerRowExpandEvent(evnt, params);
      }
      // 如果是树形表格
      if (treeConfig && treeConfig.trigger === "row" || (column.treeNode && treeConfig && treeConfig.trigger === "cell")) {
        this.triggerTreeExpandEvent(evnt, params);
      }

      if (cell) {
        Utils.emitEvent(this, "cell-click", [params, evnt]);
      }
      Utils.emitEvent(this, "row-click", [params, evnt]);
    },
    /**
     * 列双击点击事件
     * 如果是双击模式，则激活为编辑状态
     */
    triggerCellDBLClickEvent(evnt, params) {
      Utils.emitEvent(this, "cell-dblclick", [params, evnt]);
    },
    /**
     * 处理激活编辑
     */
    handleActived(params, evnt) {
      let { editStore } = this;
      let { actived } = editStore;
      let { row, column, cell } = params;
      let { editRender } = column;
      if (editRender && cell) {
        if (actived.row !== row) {
          // 判断是否禁用编辑
          let type = "edit-disabled";
          Utils.emitEvent(this, type, [params, evnt]);
        } else {
          let { column: oldColumn } = actived;
          if (oldColumn !== column) {
            let { model: oldModel } = oldColumn;
            if (oldModel.update) {
              CellUtils.setCellValue(row, oldColumn, oldModel.value);
            }
            this.clearValidate();
          }
          column.renderHeight = cell.offsetHeight;
          actived.args = params;
          actived.column = column;
          setTimeout(() => {
            this.handleFocus(params, evnt);
          });
        }
      }
      return this.$nextTick();
    },
    /**
     * 清除激活的编辑
     */
    clearActived(evnt) {
      let { editStore } = this;
      let { actived } = editStore;
      let { args, row, column } = actived;
      if (row || column) {
        let { model } = column;
        if (model.update) {
          CellUtils.setCellValue(row, column, model.value);
          model.update = false;
          model.value = null;
          this.updateFooter();
        }
        Utils.emitEvent(this, "edit-closed", [args, evnt]);
      }
      actived.args = null;
      actived.row = null;
      actived.column = null;
      return this.clearValidate().then(this.recalculate);
    },
    getActiveRow() {
      let { $el, editStore, tableData } = this;
      let { args, row } = editStore.actived;
      if (args && tableData.indexOf(row) > -1 && $el.querySelectorAll(".vxe-body--column.col--actived").length) {
        return Object.assign({}, args);
      }
      return null;
    },
    hasActiveRow(row) {
      return this.editStore.actived.row === row;
    },
    /**
     * 处理聚焦
     */
    handleFocus(params, evnt) {
      let { column, cell } = params;
      let { editRender } = column;
      if (editRender) {
        let compRender = Renderer.get(editRender.name);
        let { autofocus, autoselect } = editRender;
        let inputElem;
        // 如果指定了聚焦 class
        if (autofocus) {
          inputElem = cell.querySelector(autofocus);
        }
        // 渲染器的聚焦处理
        if (!inputElem && compRender && compRender.autofocus) {
          inputElem = cell.querySelector(compRender.autofocus);
        }
        if (inputElem) {
          inputElem[autoselect ? "select" : "focus"]();
          if (browse.msie) {
            let textRange = inputElem.createTextRange();
            textRange.collapse(false);
            textRange.select();
          }
        }
      }
    },
    /**
     * 激活行编辑
     */
    setActiveRow(row) {
      let column = this.visibleColumn.find(column => column.editRender);
      if (!column || !column.property) {
        return this.$nextTick();
      }
      return this.setActiveCell(row, column.property);
    },
    /**
     * 激活单元格编辑
     */
    setActiveCell(row, field) {
      return this.scrollToRow(row, true).then(() => {
        if (row && field) {
          let column = this.visibleColumn.find(column => column.property === field);
          if (column && column.editRender) {
            let cell = DomTools.getCell(this, { row, column });
            if (cell) {
              this.handleActived({
                row,
                rowIndex: this.getRowIndex(row),
                column,
                columnIndex: this.getColumnIndex(column),
                cell,
                $table: this
              });
              this.lastCallTime = Date.now();
            }
          }
        }
        return this.$nextTick();
      });
    },
    /**
     * 只对 trigger=dblclick 有效，选中单元格
     */
    setSelectCell(row, field) {
      let { tableData, visibleColumn } = this;
      if (row && field) {
        let column = visibleColumn.find(column => column.property === field);
        let rowIndex = tableData.indexOf(row);
        if (rowIndex > -1 && column) {
          let cell = DomTools.getCell(this, {
            row,
            rowIndex,
            column
          });
          let params = {
            row,
            rowIndex,
            column,
            columnIndex: visibleColumn.indexOf(column),
            cell
          };
          this.handleSelected(params, {});
        }
      }
      return this.$nextTick();
    },

    /**
     * 点击排序事件
     */

    triggerSortEvent(column, params, sortType = "") {
      let { filterSortStore, filterSortConfig } = column;

      if (filterSortStore && filterSortStore.sortable) {
        let sort = null;
        let type = "sort";
        let { filterStore } = this;
        // sortMode => loop 降序后还原
        if (!filterSortStore.sort) {
          filterSortStore.sort = undefined
        }
        if (!filterSortStore.sort || (filterSortStore.sort === "desc" && filterSortConfig.sortMode !== "loop")) {
          sort = "asc";
        } else if (filterSortStore.sort === "asc") {
          sort = "desc";
        }
        if (sortType === filterSortStore.sort) {
          sortType = "";
          sort = "";
        }
        if (sortType) {
          sort = sortType;
        }
        filterStore.sort = filterSortStore.sort = sort;
        filterStore.column = column;
        filterStore.args = params;

        //记录 所有筛选条件
        filterStore.dataList = FilterUtils.getFilterSortList(this, column, type);
        filterStore.visible = false;

        //响应事件
        let filterParams = FilterUtils.getColumnFilterSortInfo(column, type, true);
        filterParams.$table = this;

        if (column.filterSortMethod) {
          column.filterSortMethod(filterParams); //列绑定 方法
        } else {
          Utils.emitEvent(this, "filter-sort-change", [filterParams]); //表格事件 @filter-sort-change
        }

        // return this.$nextTick().then(this.updateStyle);
      }
      // return this.$nextTick();
    },
    clearSort() {
      this.tableFullColumn.forEach(column => {
        column.filterSortStore.sort = null;
      });
      return this.handleTableData(true);
    },
    // 关闭筛选
    closeFilter(evnt) {
      Object.assign(this.filterStore, {
        isAllSelected: false,
        isIndeterminate: false,
        options: [],
        visible: false
      });
      return this.$nextTick();
    },
    /**
     * 展开行事件
     */
    triggerRowExpandEvent(evnt, { row }) {
      let rest = this.toggleRowExpansion(row);
      Utils.emitEvent(this, "toggle-expand-change", [
        {
          row,
          rowIndex: this.getRowIndex(row),
          $table: this
        },
        evnt
      ]);
      return rest;
    },
    /**
     * 切换展开行
     */
    toggleRowExpansion(row) {
      return this.setRowExpansion(row);
    },
    /**
     * 处理默认展开行
     */
    handleDefaultRowExpand() {
      let { expandConfig = {}, tableFullData, fullDataRowIdData } = this;
      let { expandAll, expandRowIds } = expandConfig;
      if (expandAll) {
        this.expandeds = tableFullData.slice(0);
      } else if (expandRowIds) {
        let defExpandeds = [];
        expandRowIds.forEach(rowid => {
          if (fullDataRowIdData[rowid]) {
            defExpandeds.push(fullDataRowIdData[rowid].row);
          }
        });
        this.expandeds = defExpandeds;
      }
    },
    setAllRowExpansion(expanded) {
      this.expandeds = expanded ? this.tableFullData.slice(0) : [];
      return this.$nextTick().then(this.recalculate);
    },
    /**
     * 设置展开行，二个参数设置这一行展开与否
     * 支持单行
     * 支持多行
     */
    setRowExpansion(rows, expanded) {
      let { expandeds, expandConfig = {} } = this;
      let isToggle = arguments.length === 1;
      if (rows) {
        if (!XEUtils.isArray(rows)) {
          rows = [rows];
        }
        if (expandConfig.accordion) {
          // 只能同时展开一个
          expandeds.length = 0;
          rows = rows.slice(rows.length - 1, rows.length);
        }
        rows.forEach(row => {
          let index = expandeds.indexOf(row);
          if (index > -1) {
            if (isToggle || !expanded) {
              expandeds.splice(index, 1);
            }
          } else {
            if (isToggle || expanded) {
              expandeds.push(row);
            }
          }
        });
      }
      return this.$nextTick().then(this.recalculate);
    },
    hasRowExpand(row) {
      return this.expandeds.indexOf(row) > -1;
    },
    clearRowExpand() {
      const isExists = this.expandeds.length;
      this.expandeds = [];
      return this.$nextTick().then(() => (isExists ? this.recalculate() : 0));
    },
    /**
     * 展开树节点事件
     */
    triggerTreeExpandEvent(evnt, { row }) {
      if (evnt) {
        evnt.stopPropagation();//无需传递事件向上传递了
      }

      const { lazyLoadRows, treeConfig, selectConfig = {} } = this;
      const { lazy, loadMethod } = treeConfig;
      const {checkStrictly} = selectConfig;
      if (!row.$treeLoaded && lazy && loadMethod) {
        lazyLoadRows.push(row);
        loadMethod({ $table: this, row }).catch(() => []).then(children => {
          XArray.removeObject(lazyLoadRows,row);
          row.$treeLoaded = true;//这里需要写个row的映射, 不能直接修改对象
          row.children = children;

          // 如果当前节点已选中，则展开后子节点也被选中
          if (!checkStrictly && this.isCheckedRow({ row })) {
            this.handleSelectRow({ row }, true)
          }
          this.updateCache(true);
          this.toggleTreeExpansion(row);
        });

        return ;
      }

      let rest = this.toggleTreeExpansion(row);
      Utils.emitEvent(this, "toggle-tree-change", [
        {
          row,
          rowIndex: this.getRowIndex(row),
          $table: this
        },
        evnt
      ]);
      this.$nextTick(() => {
        let { currentRow, currentColumn } = this;
        if (currentRow) {
          this.setCurrentRow(currentRow);
        } else if (currentColumn) {
          this.setCurrentColumn(currentColumn);
        }
      });
      return rest;
    },
    /**
     * 切换/展开树节点
     */
    toggleTreeExpansion(row) {
      return this.setTreeExpansion(row);
    },
    /**
     * 处理默认展开树节点
     */
    handleDefaultTreeExpand() {
      let { treeConfig, tableFullData } = this;
      if (treeConfig) {
        let { expandAll, expandRowIds } = treeConfig;
        let { children } = treeConfig;
        let treeExpandeds = [];
        if (expandAll) {
          XEUtils.filterTree(
            tableFullData,
            row => {
              let rowChildren = row[children];
              if (rowChildren && rowChildren.length) {
                treeExpandeds.push(row);
              }
            },
            treeConfig
          );
          this.treeExpandeds = treeExpandeds;
        } else if (expandRowIds) {
          let rowkey = CellUtils.getRowkey(this);
          expandRowIds.forEach(rowid => {
            let matchObj = XEUtils.findTree(tableFullData, item => rowid === XEUtils.get(item, rowkey), treeConfig);
            let rowChildren = matchObj ? matchObj.item[children] : 0;
            if (rowChildren && rowChildren.length) {
              treeExpandeds.push(matchObj.item);
            }
          });
          this.treeExpandeds = treeExpandeds;
        }
      }
    },
    /**
     * 点击全部展开/收起
     */
    triggerExpandAllEvent() {
      let isAllExpand = this.isTreeAllExpand();
      this.setAllTreeExpansion(!isAllExpand);
    },
    setAllTreeExpansion(expanded) {
      let { tableFullData, treeConfig } = this;
      let { children } = treeConfig;
      let treeExpandeds = [];
      if (expanded) {
        XEUtils.eachTree(
          tableFullData,
          row => {
            let rowChildren = row[children];
            if (rowChildren && rowChildren.length) {
              treeExpandeds.push(row);
            } else if (rowChildren && treeConfig.lazy) {
              // 懒加载的子集
              this.triggerTreeExpandEvent(null, { row });
            }
          },
          treeConfig
        );
      }
      this.treeExpandeds = treeExpandeds;
      return this.$nextTick().then(this.recalculate);
    },
    /**
     * 设置展开树形节点，二个参数设置这一行展开与否
     * 支持单行
     * 支持多行
     */
    setTreeExpansion(rows, expanded) {
      let { tableFullData, treeExpandeds, treeConfig } = this;
      let { children } = treeConfig;
      let isToggle = arguments.length === 1;
      if (rows) {
        if (!XEUtils.isArray(rows)) {
          rows = [rows];
        }
        if (treeConfig.accordion) {
          rows = rows.slice(rows.length - 1, rows.length);
        }
        rows.forEach(row => {
          let rowChildren = row[children];
          if (rowChildren && rowChildren.length) {
            let index = treeExpandeds.indexOf(row);
            if (treeConfig.accordion) {
              // 同一级只能展开一个
              let matchObj = XEUtils.findTree(tableFullData, item => item === row, treeConfig);
              XEUtils.remove(treeExpandeds, item => matchObj.items.indexOf(item) > -1);
            }
            if (index > -1) {
              if (isToggle || !expanded) {
                treeExpandeds.splice(index, 1);
              }
            } else {
              if (isToggle || expanded) {
                treeExpandeds.push(row);
              }
            }
          }
        });
      }
      return this.$nextTick().then(this.recalculate);
    },
    ///当前行是否展开
    hasTreeExpand(row) {
      return this.treeExpandeds.indexOf(row) > -1;
    },
    ///是否树 展开了(展开一个也算展开)
    isTreeExpand() {
      return !!this.treeExpandeds.length;
    },
    //是否树 全部展开了 (可能有更好的方法吧)
    isTreeAllExpand() {
      let { tableFullData, treeConfig } = this;
      let { children } = treeConfig;

      let treeExpandeds = [];
      XEUtils.eachTree(
        tableFullData,
        row => {
          let rowChildren = row[children];
          if (rowChildren && rowChildren.length || rowChildren && !row.$treeLoaded) {
            treeExpandeds.push(row);
          }
        },
        treeConfig
      );
      return treeExpandeds.length === this.treeExpandeds.length;
    },
    clearTreeExpand() {
      const isExists = this.isTreeExpand();
      this.treeExpandeds = [];
      return this.$nextTick().then(() => (isExists ? this.recalculate() : 0));
    },

    /**
     * 更新表尾合计
     */
    updateFooter() {
      let { showFooter, tableColumn, footerMethod } = this;
      if (showFooter && footerMethod) {
        this.footerData = tableColumn.length ? footerMethod({ columns: tableColumn, data: this.afterFullData }) : [];
      }
      return this.$nextTick();
    },
    /**
     * 更新列状态
     * 如果组件值 v-model 发生 change 时，调用改函数用于更新某一列编辑状态
     * 如果单元格配置了校验规则，则会进行校验
     */
    updateStatus(scope, cellValue) {
      let customVal = !XEUtils.isUndefined(cellValue);
      return this.$nextTick().then(() => {
        let { $refs, tableData, editRules, validStore } = this;
        if (scope && $refs.tableBody && editRules) {
          let { row, column } = scope;
          let type = "change";
          if (this.hasCellRules(type, row, column)) {
            let rowIndex = tableData.indexOf(row);
            let cell = DomTools.getCell(this, {
              row,
              rowIndex,
              column
            });
            if (cell) {
              return this.validCellRules(type, row, column, cellValue)
                .then(() => {
                  if (customVal && validStore.visible) {
                    CellUtils.setCellValue(row, column, cellValue);
                  }
                  this.clearValidate();
                })
                .catch(({ rule }) => {
                  if (customVal) {
                    CellUtils.setCellValue(row, column, cellValue);
                  }
                  this.showValidTooltip({
                    rule,
                    row,
                    column,
                    cell
                  });
                });
            }
          }
        }
      });
    },
    triggerValidate(type) {
      let { editStore, editRules, validStore } = this;
      let { actived } = editStore;
      if (actived.row && editRules) {
        let { row, column, cell } = actived.args;
        if (this.hasCellRules(type, row, column)) {
          return this.validCellRules(type, row, column).catch(({ rule }) => {
            // 如果校验不通过与触发方式一致，则聚焦提示错误，否则跳过并不作任何处理
            if (!rule.trigger || type === rule.trigger) {
              let rest = {
                rule,
                row,
                column,
                cell
              };
              this.showValidTooltip(rest);
              return Promise.reject(rest);
            }
            return Promise.resolve();
          });
        }
      }
      return Promise.resolve();
    },

    /**
     * 与 validate 一致行为，区别就是会校验所有并返回所有不通过的所有列
     */
    fullValidate(rows, cb) {
      return this.beginValidate(rows, cb, true);
    },
    /**
     * 对表格数据进行校验
     */
    validate(rows, cb) {
      return this.beginValidate(rows, cb);
    },
    /**
     * 对表格数据进行校验
     * 如果传 row 指定行记录，则只验证传入的行
     * 如果传 rows 为多行记录，则只验证传入的行
     * 如果只传 callback 否则默认验证整个表格数据
     * 返回 Promise 对象，或者使用回调方式
     */
    beginValidate(rows, cb, isAll) {
      let validRest = {};
      let status = true;
      let { editRules, tableData, tableFullData, scrollYLoad } = this;
      let vaildDatas = scrollYLoad ? tableFullData : tableData;
      if (rows) {
        if (XEUtils.isFunction(rows)) {
          cb = rows;
        } else {
          vaildDatas = XEUtils.isArray(rows) ? rows : [rows];
        }
      }
      let rowValids = [];
      this.lastCallTime = Date.now();
      this.clearValidate();
      if (editRules) {
        let columns = this.getColumns();
        vaildDatas.forEach(row => {
          let colVailds = [];
          columns.forEach((column, columnIndex) => {
            if (XEUtils.has(editRules, column.property)) {
              colVailds.push(
                new Promise((resolve, reject) => {
                  this.validCellRules("all", row, column)
                    .then(resolve)
                    .catch(({ rule, rules }) => {
                      let rest = {
                        rule,
                        rules,
                        rowIndex: this.getRowIndex(row),
                        row,
                        columnIndex,
                        column,
                        $table: this
                      };
                      if (isAll) {
                        if (!validRest[column.property]) {
                          validRest[column.property] = [];
                        }
                        validRest[column.property].push(rest);
                        return resolve();
                      }
                      return reject(rest);
                    });
                })
              );
            }
          });
          rowValids.push(Promise.all(colVailds));
        });
        return Promise.all(rowValids)
          .then(() => {
            let ruleProps = Object.keys(validRest);
            if (ruleProps.length) {
              return Promise.reject(validRest[ruleProps[0]][0]);
            }
            if (cb) {
              cb(status);
            }
          })
          .catch(params => {
            let args = isAll ? validRest : { [params.column.property]: params };
            return new Promise((resolve, reject) => {
              let finish = () => {
                params.cell = DomTools.getCell(this, params);
                this.handleValidError(params);
                if (cb) {
                  status = false;
                  resolve(cb(status, args));
                } else {
                  reject(args);
                }
              };
              if (scrollYLoad) {
                this.scrollToRow(params.row, true).then(finish);
              } else {
                finish();
              }
            });
          });
      } else {
        if (cb) {
          cb(status);
        }
      }
      return Promise.resolve(true);
    },
    hasCellRules(type, row, column) {
      let { editRules } = this;
      let { property } = column;
      if (property && editRules) {
        let rules = XEUtils.get(editRules, property);
        return rules && rules.find(rule => type === "all" || !rule.trigger || type === rule.trigger);
      }
      return false;
    },
    /**
     * 校验数据
     * 按表格行、列顺序依次校验（同步或异步）
     * 校验规则根据索引顺序依次校验，如果是异步则会等待校验完成才会继续校验下一列
     * 如果校验失败则，触发回调或者Promise，结果返回一个 Boolean 值
     * 如果是传回调方式这返回一个 Boolean 值和校验不通过列的错误消息
     *
     * rule 配置：
     *  required=Boolean 是否必填
     *  min=Number 最小长度
     *  max=Number 最大长度
     *  validator=Function(rule, value, callback, {rules, row, column, rowIndex, columnIndex}) 自定义校验
     *  trigger=blur|change 触发方式（除非特殊场景，否则默认为空就行）
     */
    validCellRules(type, row, column, val) {
      let { editRules } = this;
      let { property } = column;
      let errorRules = [];
      let cellVailds = [];
      if (property && editRules) {
        let rules = XEUtils.get(editRules, property);
        let cellValue = XEUtils.isUndefined(val) ? XEUtils.get(row, property) : val;
        if (rules) {
          rules.forEach(rule => {
            cellVailds.push(
              new Promise(resolve => {
                let isRequired = rule.required === true;
                if (type === "all" || !rule.trigger || type === rule.trigger) {
                  if (XEUtils.isFunction(rule.validator)) {
                    rule.validator(
                      rule,
                      cellValue,
                      e => {
                        if (XEUtils.isError(e)) {
                          let cusRule = {
                            type: "custom",
                            trigger: rule.trigger,
                            message: e.message,
                            rule: new Rule(rule)
                          };
                          errorRules.push(new Rule(cusRule));
                        }
                        return resolve();
                      },
                      {
                        rules,
                        row,
                        column,
                        rowIndex: this.getRowIndex(row),
                        columnIndex: this.getColumnIndex(column)
                      }
                    );
                  } else {
                    let len;
                    let restVal = cellValue;
                    let isNumber = rule.type === "number";
                    let isEmpty = cellValue === null || cellValue === undefined || cellValue === "";
                    if (isNumber) {
                      restVal = XEUtils.toNumber(cellValue);
                    } else {
                      len = XEUtils.getSize(restVal);
                    }
                    if (isRequired && isEmpty) {
                      errorRules.push(new Rule(rule));
                    } else if (
                      (isNumber && isNaN(cellValue)) ||
                      (XEUtils.isRegExp(rule.pattern) && !rule.pattern.test(cellValue)) ||
                      (XEUtils.isNumber(rule.min) && (isNumber ? restVal < rule.min : len < rule.min)) ||
                      (XEUtils.isNumber(rule.max) && (isNumber ? restVal > rule.max : len > rule.max))
                    ) {
                      errorRules.push(new Rule(rule));
                    }
                    resolve();
                  }
                } else {
                  resolve();
                }
              })
            );
          });
        }
      }
      return Promise.all(cellVailds).then(() => {
        if (errorRules.length) {
          let rest = { rules: errorRules, rule: errorRules[0] };
          return Promise.reject(rest);
        }
      });
    },
    clearValidate() {
      let validTip = this.$refs.validTip;
      Object.assign(this.validStore, {
        visible: false,
        row: null,
        column: null,
        content: "",
        rule: null
      });
      if (validTip && validTip.visible) {
        validTip.close();
      }
      return this.$nextTick();
    },
    /**
     * 聚焦到校验通过的单元格并弹出校验错误提示
     */
    handleValidError(params) {
      this.handleActived(params, { type: "valid-error", trigger: "call" }).then(() => this.showValidTooltip(params));
    },
    /**
     * 弹出校验错误提示
     */
    showValidTooltip(params) {
      let { $refs, height, tableData, validOpts } = this;
      let { rule, row, column, cell } = params;
      let validTip = $refs.validTip;
      let content = rule.message;
      this.$nextTick(() => {
        Object.assign(this.validStore, {
          row,
          column,
          rule,
          content,
          visible: true
        });
        if (validTip && (validOpts.message === "tooltip" || (validOpts.message === "default" && !height && tableData.length < 2))) {
          validTip.toVisible(cell, content);
        }
        Utils.emitEvent(this, "valid-error", [params]);
      });
    },

    /*************************
     * Publish methods
     *************************/
    // 与工具栏对接
    connect({ toolbar }) {
      this.$toolbar = toolbar;
    },
    // 检查触发源是否属于目标节点
    getEventTargetNode: DomTools.getEventTargetNode

    /*************************
     * xujun methods (还没添加)
     *************************/
  }
};
