<template>
  <div
    class="time-resource"
    :style="bindStyle"
  >
    <div class="left">
      {{ name }}
    </div>
    <div class="right">
      {{ time }}
    </div>
  </div>
</template>
<script>
export default {
  name: "TimeResource",
  props: {
    name: String,
    time: String,
    height: {
      type: [String, Number],
      default: 46
    },
    top: {
      type: [String, Number],
      default: 0
    },
    type: {
      type: String,
      default: "blue",
      invalidator: function(val) {
        return [
          "blue",
          "red",
          "orange",
          "gray"
        ].indexOf(val) !== -1;
      }
    }
  },
  computed: {
    bindStyle() {
      const height = parseFloat(this.height) + "px";
      const top = parseFloat(this.top) + "px";
      let borderLeftColor = "";
      let backgroundColor = "";
      let borderColor = "";
      switch (this.type) {
        case "blue":
          borderColor = "rgba(70, 148, 223, 0.2)";
          borderLeftColor = "#4694DF";
          backgroundColor = "rgba(236, 244, 252, 1)";
          break;
        case "red":
          borderColor = "rgba(247, 107, 107, 0.2)";
          borderLeftColor = "#F76B6B";
          backgroundColor = "rgba(254, 240, 240, 1)";
          break;
        case "orange":
          borderColor = "rgba(255, 173, 44, 0.2)";
          borderLeftColor = "#FFAD2C";
          backgroundColor = "rgba(255, 247, 233, 1)";

          break;
        case "gray":
          borderColor = "rgba(99, 108, 120, 0.2)";
          borderLeftColor = "#636C78";
          backgroundColor = "rgba(239, 240, 241, 1)";
          break;

        default:
          break;
      }
      return {
        height,
        "line-height": height,
        top,
        border: "1px solid " + borderColor,
        "border-left": "2px solid " + borderLeftColor,
        background: backgroundColor,
        color: borderLeftColor
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.time-resource {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  .left {
    margin-left: 14px;
  }
  .right {
    margin-right: 14px;
  }
}
</style>
