/**
 * @Author 李煜
 * @Date 2020/7/1
 * @Description select公共判断逻辑
 */
import { getValueByPath } from "element-ui/src/utils/util";

// const isString = (val) => Object.prototype.toString.call(val) === "[object String]";

const isObject = (val) => Object.prototype.toString.call(val) === "[object Object]";

/**
 * 对比
 * @param val1 值
 * @param val2 值
 * @param valueKey key
 * @returns {boolean}
 */
export const selectValueEqual = (val1, val2, valueKey) => {
  val1 = isObject(val1) ? getValueByPath(val1, valueKey) : val1;
  val2 = isObject(val2) ? getValueByPath(val2, valueKey) : val2;
  return val1 === val2;
}

/**
 * 对比
 * @param a 值
 * @param b 值
 * @param valueKey key
 * @returns {boolean}
 */
const arrIsEqual = (a, b, valueKey) => {
  return isObject(a)
    ? getValueByPath(a, valueKey) === b
    : a === b;
}
/**
 */
/**
 * 根据value在数组中查询位置
 * @param arr 数组
 * @param value 带查找的值
 * @param valueKey key
 * @returns {number}
 */
export const getValueIndex = (arr = [], value, valueKey) => {
  const target = isObject(value) ? getValueByPath(value, valueKey) : value
  return arr.findIndex((v) => arrIsEqual(v, target, valueKey))
}
