<!--
 * @Author: zql
 * @Date: 2020-03-13 15:29:14
 * @LastEditTime: 2020-06-02 14:33:55
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \enfry-components\packages\components\en-result\src\noImg\no-img.vue
-->
<template>
  <div
    ref="NoImg"
    class="noimg"
  >
    <img
      slot="icon"
      class="icon-img"
      src="http://cdn.enfry.com/no-img11350.png"
      :alt="this.title"
    >
    <div class="title" v-html="title"></div>
    <div class="subtitle" v-html="subTitle"></div>
  </div>
</template>

<script>
export default {
  name: "NoImg",
  props: {
    type: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: [String, Number],
      default: "normal"
    },
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: "暂无图片"
    }
  }
}
</script>
<style lang="scss" scoped>
  .noimg {
    text-align: center;

    .icon-img {
      height: 74px;
      width: 120px;
    }
  }
</style>

