/**
 * project: enfry-components
 * fileName: drag
 * Created by 徐俊 on 2020/04/02
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import Sortable from "sortablejs";

export default {
  mounted() {
    this.createdSortableIfNeeded();
  },
  destroyed() {
    this.destroySortableIfNeeded();
  },
  props: {
    dragConfig: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  methods: {
    /**
     * 创建拖拽
     */
    createdSortableIfNeeded() {
      if (this.dragConfig.dragRow) {
        this.createdSortableRowIfNeeded();
      }
      if (this.dragConfig.dragColumn) {
        this.createdSortableColumnIfNeeded();
      }
    },
    /**
     * 销毁拖拽
     */
    destroySortableIfNeeded() {
      this.destroySortableRowIfNeeded();
      this.destroySortableColumnIfNeeded();
    },

    /**
     * 创建行拖拽
     */
    createdSortableRowIfNeeded() {
      if (this.sortableRow) {
        return;
      }
      const _this = this;
      const selector = this.$el.querySelector(".body--wrapper>.vxe-table--body tbody");
      this.sortableRow = Sortable.create(selector, {
      // handle: ".drag-btn",
        animation: 150,
        ghostClass: "drag-background-class",
        dragClass: "drag-class",
        onStart: function(/** Event*/evt) {
          _this._isDrag = true;
        },
        onEnd: ({ newIndex, oldIndex }) => {
          setTimeout(() => {
            _this._isDrag = false;
          }, 500);

          if (newIndex === oldIndex) {
            return;
          }

          if (_this.dragConfig.changeRowMethod) {
            _this.dragConfig.changeRowMethod({ newIndex, oldIndex });
          }

          // 提供方法后, 改变原始数据
          const currRow = _this.data.splice(oldIndex, 1)[0];
          _this.data.splice(newIndex, 0, currRow);
        }
      });
    },
    /**
     * 销毁行拖拽
     */
    destroySortableRowIfNeeded() {
      if (this.sortableRow) {
        this.sortableRow.destroy();
        this.sortableRow = undefined;
      }
    },

    /**
     * 创建列拖拽
     */
    createdSortableColumnIfNeeded() {
      if (this.sortableColumn) {
        return;
      }

      const _this = this;
      // let preIndex = undefined;//记录上一次移动的index
      const selector = this.$el.querySelector(".body--wrapper>.vxe-table--header .vxe-header--row");
      if (!selector) {
        return;// 可能没有显示头部
      }
      this.sortableColumn = Sortable.create(selector, {
        draggable: ".vxe-header--column:not(.col--fixed)",
        filter: ".col--fixed",
        // animation: 150,
        ghostClass: "drag-background-class",
        dragClass: "drag-class",
        onStart: function(/** Event*/evt) {
          _this._isDrag = true;
        },
        // onChange: function({ item ,clone, newIndex, oldIndex }) { //只有简单表格才支持, 对于有合并单元格的表头和数据, 将不支持(先屏蔽吧 快速拖动有问题)
        //   // debugger;
        //   oldIndex = _this.validIndex(oldIndex);
        //   newIndex = _this.validIndex(newIndex);
        //
        //   // return;
        //
        //   if (preIndex !== undefined) {
        //     oldIndex = preIndex;
        //   }
        //   preIndex = newIndex;
        //
        //   console.log("from:" + oldIndex  + "--- to:" + newIndex );
        //
        //   if (newIndex === oldIndex) {
        //     return;
        //   }
        //
        //   // let isValid = true;//是否所有dataid都合法, 主要检测合并单元格问题
        //   // let fromDataid = clone.attributes["data-colid"].value;
        //   // let toDataid = item.attributes["data-colid"].value;
        //   let tbody = _this.$el.querySelector(".body--wrapper>.vxe-table--body tbody");
        //
        //   let div = document.createElement("div");//用个临时变量存储吧, 好像还原,不然显示有问题, 不明白
        //   for (let tr of tbody.children) {
        //     let children = tr.children;
        //     let oldData = children[oldIndex];
        //     let newData = children[newIndex];
        //     // let cloneData = oldData.cloneNode(false);
        //     tr.replaceChild(div,newData);//交换oldIndex 和 newIndex
        //     tr.replaceChild(newData,oldData);
        //     tr.replaceChild(oldData,div);
        //   }
        //
        // },
        onEnd: ({ item, newIndex, oldIndex }) => {
          _this._isDrag = false;

          oldIndex = _this.validIndex(oldIndex);
          newIndex = _this.validIndex(newIndex);

          if (newIndex === oldIndex) {
            return;
          }

          const { tableFullColumn, tableColumn } = _this;
          // 转换真实索引
          oldIndex = tableFullColumn.findIndex(column => column.id === tableColumn[oldIndex].id);
          newIndex = tableFullColumn.findIndex(column => column.id === tableColumn[newIndex].id);
          // 移动到目标列
          const currColumn = tableFullColumn.splice(oldIndex, 1)[0];
          tableFullColumn.splice(newIndex, 0, currColumn);
          this.refreshColumn()
          this.$forceUpdate()
          const fieldSort = []
          for (const column of tableFullColumn) {
            const obj = Object.assign({}, column)
            obj.own = undefined
            fieldSort.push(obj)
          }
          if (_this.dragConfig.changeColumnMethod) {
            _this.dragConfig.changeColumnMethod({ oldIndex, newIndex, fieldSort: JSON.stringify(fieldSort) });
          }
          // _this.loadColumn(fullColumn);
        }
      });
    },
    /**
     * 销毁列拖拽
     */
    destroySortableColumnIfNeeded() {
      if (this.sortableColumn) {
        this.sortableColumn.destroy();
        this.sortableColumn = undefined;
      }
    },
    /**
     * 返回 真实序号必须 > 0 且 < 表格最大列 (由于拖拽过程中会出现 index超出这个范围(sortable bug), 所以加上此限制)
     * @param index
     * @returns {number} 合理的序号
     */
    validIndex(index) {
      return Math.max(Math.min(index, this.tableColumn.length - 1), 0);
    }

  }
};
