/**
  * project: enfry-components
  * fileName: index * Created by 徐俊 on 2019/11/30
  * Copyright © 2019 Enfry Ltd. All rights reserved.
*/

<template>
  <div v-if="filterStore && filterStore.dataList && filterStore.dataList.length > 0 || filterStore && filterStore.conditions && filterStore.conditions.length > 0" class="vxe-filter-toolbar-wrapper">
    <slot name="left"></slot>
    <span
      v-for="(item, index) in filterStore.dataList"
      :key="index"
      class="filter-wrapper"
    >
      <!--排序-->
      <span v-if="item.type === 'sort'" class="filter-text">
        <span>{{ showSortText(item) }}</span>
        <en-icon  v-if="item.sort && item.type === 'sort'" :name="item.sort === 'asc' ? 'sheng' : 'jiang'" size="11"></en-icon>
      </span>
      <!--筛选-->
      <div v-else class="filter-text">
        <span>{{showTitle(item) + '：'}}</span>
        <span class="op-type">{{showOpTypeText(item)}}</span>
        <span v-html="item.valueText"></span>
      </div>
      <en-button
        icon="iconguanbi-danchuang"
        class="clear-button"
        type="text"
        @click="handleClearFilter(item)"
      ></en-button>
    </span>
    <!--默认插槽-->
    <slot></slot>
    <en-button
      type="text"
      class="clear-all-button"
      @click="handleClearAllFilter"
    >
      清空
    </en-button>
    <slot name="right"></slot>
  </div>
</template>

<script>

export default {
  name: "EnTableFilterToolbar",
  props: { filterStore: Object },
  data() {
    return {
    };
  },
  methods: {
    // 初始条件文本
    showOpTypeText({ column }) {
      if (!column || !column.opType) {
        return "";
      }
      return "(" + column.opTypeText + ")";
    },
    // 排序文本
    showSortText(item) {
      return "排序：" + this.showTitle(item);
    },
    // 字段名文本
    showTitle({ column }) {
      return column.title || "";
    },
    // 删除操作
    handleClearFilter(item) {
      this.$parent.clearFilterOfColumn(item.column, item.type);
    },
    // 清空操作
    handleClearAllFilter() {
      this.$parent.clearFilter();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../styles/variable";

.vxe-filter-toolbar-wrapper {
  background: $vxe-table-row-striped-background-color;
  display: flex;
  align-items: center;
  padding: 7px 20px;
  font-size: 12px;
  vertical-align: center;
  overflow-x: auto;
  border-left: $vxe-table-border-color 1px solid;
  border-right: $vxe-table-border-color 1px solid;
  border-top: $vxe-table-border-color 1px solid;

  .filter-wrapper {
    display: flex;
    align-items: center;
    max-width: 500px;
    color: $vxe-font-color;
    margin: 0 0 0 5px;
    /*max-width: 200px;*/
    padding: 0 10px;
    border-radius: 3px;
    border: 1px solid $vxe-input-border-color;
    background: $vxe-table-filter-tag-background-color;
    height: 25px;

    .filter-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
      .op-type {
        color: $vxe-optype-color;
      }
    }

    .clear-button {
      padding-left: 10px;
    }
  }

  .clear-all-button {
    padding: 0 10px;
    color: $vxe-input-selected-color;
  }
}
</style>
