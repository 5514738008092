<!--
 * @Author: Fenngding
 * @Date: 2019-11-29 15:09:35
 * @LastEditors: Fengding
 * @LastEditTime: 2019-12-17 17:20:44
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="attention-container">
    <el-tooltip
      placement="bottom-start"
      :offset="-10"
      popper-class="attention-container"
    >
      <div
        slot="content"
        class="content"
      >
        <div
          v-for="(item,index) in content"
          :key="item.label"
          class="item"
        >
          <div
            v-if="showDot"
            class="point"
            :style="{background:item.color || getColor(index,item)}"
          ></div>
          <span>{{ item.label }}</span>
        </div>
      </div>
      <en-icon
        :name="iconName"
        size="16"
        :style="{color:iconColor}"
      ></en-icon>
    </el-tooltip>
  </div>
</template>
<script>
export default {
  name: "EnAttention",
  components: {},
  props: {
    iconName: {
      type: String,
      default: "tishi"
    },
    iconColor: {
      type: String,
      default: "#FBA800"
    },
    content: {
      type: Array,
      default() {
        return [
          { label: "这是一个事例" },
          { label: "这还是一个事例" },
          { label: "这又是一个事例" },
          { label: "这又又是一个事例" },
          { label: "这叒是一个事例" }
        ]
      }
    },
    showDot: {
      type: Boolean,
      default: true
    },
    colorList: {
      type: Array,
      default() {
        return [
          "#B0F566",
          "#5CC9F5",
          "#F3F3F3",
          "#4AF2A1",
          "#6638F0",
          "#F78AE0",
          "#FBA800",
          "#D2F0FA",
          "#4495E0"
        ]
      }
    },
    colorListFunc: { type: Function }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    getColor(index, item) {
      if (this.colorListFunc) {
        return this.colorListFunc(index, item)
      } else {
        const colorIndex = index % (this.colorList.length - 1)
        return this.colorList[colorIndex]
      }
    }
  }
}
</script>
<style scoped lang="scss">
  .attention-container {
    display: inline-block;
    vertical-align: middle;
  }

  .item {
    height: 28px;
    line-height: 28px;

    .point {
      display: inline-block;
      vertical-align: middle;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
</style>
<style lang="css">
  .attention-container.el-tooltip__popper.is-dark {
    background-color: #666975;
    border-radius: 5px;
  }
</style>
