<!--
 * @Author: Fenngding
 * @Date: 2019-12-17 14:20:08
 * @LastEditors: Fengding
 * @LastEditTime: 2019-12-17 17:55:07
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="upload-container">
    <div class="title-area">
      <div class="icon">
        <en-icon
          :name="iconName"
          size="24px"
          :style="{ color: iconColor }"
        ></en-icon>
      </div>
      <div class="file-name">
        {{ fileName }}
      </div>
    </div>
    <div class="progress">
      <el-progress
        :percentage="percent"
        :color="customColor"
        :stroke-width="10"
        :show-text="false"
      ></el-progress>
    </div>
    <div
      v-if="progressStatus !== 'error'"
      class="percent"
      :style="{ color: customColor }"
    >
      {{ percent }}%
    </div>
    <div v-else class="error-show">
      <en-icon name="shanchu-liebiao" size="small" class="err-icon"></en-icon>
      <span class="desc">
        {{ errMsgTip }}
      </span>
      <u class="remark" @click="$emit('viewErrorLog')">
        查看错误日志
      </u>
      <el-dialog
        title="错误信息提示"
        :visible.sync="dialogVisible"
        width="30%"
        style="text-align:left"
      >
        <span style="font-size:12px">{{ errMsgDetail }}</span>
        <span slot="footer" class="dialog-footer">
          <en-button type="white" @click="dialogVisible = false"
            >取 消</en-button
          >
          <en-button type="primary" @click="dialogVisible = false"
            >确 定</en-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "EnUploadProgress",
  components: {},
  props: {
    iconName: {
      type: String,
      default: "wai-huijikemu"
    },
    iconColor: {
      type: String,
      default: "#58CB7E"
    },
    fileName: {
      type: String,
      default: "文件名.xx"
    },
    percent: {
      type: Number || String,
      default: 100
    },
    progressStatus: {
      type: String,
      default: "loading"
      // default:"error",
      // default:"finish"
    },
    errMsgTip: {
      type: String,
      default: "数据校验失败,请修正后重新校验"
    },
    errMsgDetail: {
      type: String,
      default: "错了吗"
    }
  },
  data() {
    return { dialogVisible: false };
  },
  computed: {
    customColor() {
      let color = "#4694DF";
      switch (this.progressStatus) {
        case "finish":
          color = "#26C393";
          break;
        case "error":
          color = "#F76B6B";
          break;
        default:
          color = "#4694DF";
      }
      return color;
    }
  },
  watch: {
    progressStatus: {
      immediate: true,
      handler(val) {
        if (val === "finish") {
          this.$notify({
            title: "提示",
            message: "数据校验成功",
            type: "success"
          });
        }
      }
    }
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.upload-container {
  width: 600px;
  // height: 100px;
  background: rgba(251, 251, 251, 1);
  border: 1px solid rgba(232, 236, 242, 1);
  border-radius: 4px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  padding: 20px;
  .title-area {
    display: flex;
    align-items: center;
    .icon {
      width: 20px;
      height: 20px;
    }
    .file-name {
      text-align: left;
      margin-left: 20px;
      margin-top: 2px;
    }
  }
  .progress {
    margin-left: 40px;
    margin-top: 10px;
  }
  .percent {
    text-align: right;
    margin-top: 8px;
  }
  .error-show {
    margin-top: 16px;
    text-align: right;
    .err-icon {
      vertical-align: middle;
      color: #f76b6b;
    }
    .desc {
      color: #f76b6b;
      vertical-align: middle;
    }
    .remark {
      color: #4694df;
      margin-left: 10px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
}
</style>
