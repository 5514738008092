/*
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-09 16:55:50
 * @LastEditTime: 2019-10-12 17:31:39
 */
export const BussinessFieldType = {
  default: 0, // 区域
  inputCommon: 1, // 输入框不限制
  mutiText: 2, // 多行文本,根据编辑权限看是否可以输入
  inputNumber: 3, // 输入框数字
  inputAmount: 4, // 输入框金额
  select: 5, // 左右展示 + ">" 单选
  associatedData: 6, // 关联数据
  date: 7, // 日期 字符串
  checkBox: 8, // 复选 唯一，标记完成
  attachment: 9, // 附件
  newProgress: 10, // 进度 唯一
  detailParent: 11, // 明细或者子表
  multiSelect: 15, // 多选
  pureText: 16, // 纯文本
  jianGang: 18, // 兼岗
  shouFu: 19, // 收付信息
  onlyViewAssociatedData: 20, // 关联数据，直接能查看 弃用
  basicDataControl: 28, // 基础数据控件
  address: 29, // 地址
  child: 30, // 添加子项控件 唯一
  line: 31, // 分割线 App展示g高度为8的空白行
  superior: 33, // 所属上级
  tabControl: 34, // 开票选项卡
  subSet: 35, // 子集（特殊的二级明细区）
  imgaeAttachment: 36, // 图片附件
  cashObject: 37, // 收付对象 唯一
  associatedObject: 38, // 关联对象控件
  timeline: 39, // 时间轴控件
  associatedInterface: 40 // 关联接口
};

// 拖动的类型...
export const DragType = {
  inline: 1 << 0, // 行内
  singleLine: 1 << 1, // 单独一行
  region: 1 << 2 // 区域
};
// 能够拖往的区域类型 容器类型
export const ContainerType = {
  region: 1 << 0, // 区域
  main: 1 << 1, // 主表区
  detail: 1 << 2, // 明细区
  subset: 1 << 3 // 子集区
};

// 类型
export const BusinessType = {
  basic: 1, // 基础数据
  business: 2 // 业务建模
};

// 字段类型
export const ColumnType = {
  main: 1, // 主表区字段
  detail: 2, // 明细区字段
  shouFu: 3 // 收付信息字段
};
