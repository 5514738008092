import XEUtils from "xe-utils/methods/xe-utils";
import CellUtils from "../../cell/src/cellUtils";

const browse = XEUtils.browse();
const htmlElem = document.querySelector("html");
const bodyElem = document.body;

/**
 * cls
 * @param cls
 * @returns {*}
 */
function getClsRE(cls) {
  if (!reClsMap[cls]) {
    reClsMap[cls] = new RegExp(`(?:^|\\s)${cls}(?!\\S)`, "g");
  }
  return reClsMap[cls];
}

const reClsMap = {};

export const DomTools = {
  browse,
  isPx(val) {
    return val && /^\d+(px)?$/.test(val);
  },
  isScale(val) {
    return val && /^\d+%$/.test(val);
  },
  hasClass(elem, cls) {
    return elem && elem.className && elem.className.match && elem.className.match(getClsRE(cls));
  },
  removeClass(elem, cls) {
    if (elem && DomTools.hasClass(elem, cls)) {
      elem.className = elem.className.replace(getClsRE(cls), "");
    }
  },
  addClass(elem, cls) {
    if (elem && !DomTools.hasClass(elem, cls)) {
      DomTools.removeClass(elem, cls);
      elem.className = `${elem.className} ${cls}`;
    }
  },
  updateCellTitle(evnt) {
    const cellElem = evnt.currentTarget.querySelector(".vxe-cell");
    const content = cellElem.innerText;
    if (cellElem.getAttribute("title") !== content) {
      cellElem.setAttribute("title", content);
    }
  },
  rowToVisible($table, row) {
    const bodyElem = $table.$refs.tableBody.$el;
    const trElem = bodyElem.querySelector(`[data-rowid="${CellUtils.getRowid($table, row)}"]`);
    if (trElem) {
      const bodyHeight = bodyElem.clientHeight;
      const bodySrcollTop = bodyElem.scrollTop;
      const trOffsetTop = trElem.offsetTop + (trElem.offsetParent ? trElem.offsetParent.offsetTop : 0);
      const trHeight = trElem.clientHeight;
      if (trOffsetTop < bodySrcollTop || trOffsetTop > bodySrcollTop + bodyHeight) {
        // 如果跨行滚动
        bodyElem.scrollTop = trOffsetTop;
      } else if (trOffsetTop + trHeight >= bodyHeight + bodySrcollTop) {
        bodyElem.scrollTop = bodySrcollTop + trHeight;
      }
    } else {
      // 如果是虚拟渲染跨行滚动
      if ($table.scrollYLoad) {
        bodyElem.scrollTop = ($table.afterFullData.indexOf(row) - 1) * $table.scrollYStore.rowHeight;
      }
    }
  },
  // ele的宽度,  尽最大可能
  getElemClientWidth(elem) {
    let bodyWidth = elem.clientWidth;// 没显示出来 display:none 宽度为0
    if (bodyWidth === 0) {
      let parentElem = elem.parentElement;
      while (parentElem !== undefined && parentElem.clientWidth === 0) {
        parentElem = parentElem.parentElement;
      }
      bodyWidth = parentElem.clientWidth;
    }
    return bodyWidth;
  },
  colToVisible($table, column) {
    const bodyElem = $table.$refs.tableBody.$el;
    const tdElem = bodyElem.querySelector(`.${column.id}`);
    if (tdElem) {
      const bodyWidth = bodyElem.clientWidth;
      const bodySrcollLeft = bodyElem.scrollLeft;
      const tdOffsetLeft = tdElem.offsetLeft + (tdElem.offsetParent ? tdElem.offsetParent.offsetLeft : 0);
      const tdWidth = tdElem.clientWidth;
      if (tdOffsetLeft < bodySrcollLeft || tdOffsetLeft > bodySrcollLeft + bodyWidth) {
        // 如果跨列滚动
        bodyElem.scrollLeft = tdOffsetLeft;
      } else if (tdOffsetLeft + tdWidth >= bodyWidth + bodySrcollLeft) {
        bodyElem.scrollLeft = bodySrcollLeft + tdWidth;
      }
    } else {
      // 如果是虚拟渲染跨行滚动
      if ($table.scrollXLoad) {
        const visibleColumn = $table.visibleColumn;
        let scrollLeft = 0;
        for (let index = 0; index < visibleColumn.length; index++) {
          if (visibleColumn[index] === column) {
            break;
          }
          scrollLeft += visibleColumn[index].renderWidth;
        }
        bodyElem.scrollLeft = scrollLeft;
      }
    }
  },
  getDomNode() {
    const documentElement = document.documentElement;
    const bodyElem = document.body;
    return {
      scrollTop: documentElement.scrollTop || bodyElem.scrollTop,
      scrollLeft: documentElement.scrollLeft || bodyElem.scrollLeft,
      visibleHeight: documentElement.clientHeight || bodyElem.clientHeight,
      visibleWidth: documentElement.clientWidth || bodyElem.clientWidth
    };
  },
  /**
   * 检查触发源是否属于目标节点
   */
  getEventTargetNode(evnt, container, queryCls) {
    let targetElem;
    let target = evnt.target;
    while (target && target.nodeType && target !== document) {
      if (queryCls && DomTools.hasClass(target, queryCls)) {
        targetElem = target;
      } else if (target === container) {
        return {
          flag: queryCls ? !!targetElem : true, container, targetElem: targetElem
        };
      }
      target = target.parentNode;
    }
    return { flag: false };
  },
  /**
   * 获取元素相对于 document 的位置
   */
  getOffsetPos(elem, container) {
    return getNodeOffset(elem, container, { left: 0, top: 0 });
  },
  /**
   * 获得绝对定位
   * @param elem
   * @returns {{top: number, left: number}}
   */
  getAbsolutePos(elem) {
    const bounding = elem.getBoundingClientRect();
    const { scrollTop, scrollLeft } = DomTools.getDomNode();
    return { top: scrollTop + bounding.top, left: scrollLeft + bounding.left };
  },
  /**
   * 获得绝对定位(对于transition也有效)
   */
  getAbsoluteTransitionPos(root, elem) {
    let top = 0;
    let tmp = root;

    while (tmp) {
      if (tmp.$el && tmp.$el.className.indexOf("v-enter") !== -1) {
        // debugger
        top = DomTools.getAbsolutePos(tmp.$el).top;
        break;
      }
      tmp = tmp.$parent;
    }

    // if (transition && tmp.$el && tmp.$el.parentElement.children) {
    //   for (const tmpElem of tmp.$el.parentElement.children) {
    //     if (tmpElem.className.indexOf("v-leave") !== -1) {
    //       debugger
    //       top = DomTools.getAbsolutePos(tmpElem).top;
    //       break;
    //     }
    //   }
    // }
    const pos = DomTools.getAbsolutePos(elem);
    pos.top -= top;
    return pos;
  },
  /**
   * 获取单元格节点索引
   */
  getCellNodeIndex(cell) {
    const trElem = cell.parentNode;
    const columnIndex = XEUtils.arrayIndexOf(trElem.children, cell);
    const rowIndex = XEUtils.arrayIndexOf(trElem.parentNode.children, trElem);
    return { columnIndex, rowIndex };
  },
  /**
   * 获取选中单元格矩阵范围
   */
  getRowNodes(trList, cellNode, targetCellNode) {
    const startColIndex = cellNode.columnIndex;
    const startRowIndex = cellNode.rowIndex;
    const targetColIndex = targetCellNode.columnIndex;
    const targetRowIndex = targetCellNode.rowIndex;
    const rows = [];
    for (let rowIndex = Math.min(startRowIndex, targetRowIndex), rowLen = Math.max(startRowIndex, targetRowIndex); rowIndex <= rowLen; rowIndex++) {
      const cells = [];
      const trElem = trList[rowIndex];
      for (let colIndex = Math.min(startColIndex, targetColIndex), colLen = Math.max(startColIndex, targetColIndex); colIndex <= colLen; colIndex++) {
        const tdElem = trElem.children[colIndex];
        cells.push(tdElem);
      }
      rows.push(cells);
    }
    return rows;
  },
  getCellIndexs(cell) {
    const trElem = cell.parentNode;
    const rowid = trElem.getAttribute("data-rowid");
    const columnIndex = [].indexOf.call(trElem.children, cell);
    const rowIndex = [].indexOf.call(trElem.parentNode.children, trElem);
    return {
      rowid, rowIndex, columnIndex
    };
  },
  getCell($table, { row, column }) {
    const rowid = CellUtils.getRowid($table, row);
    return $table.$refs.tableBody.$el.querySelector(`.vxe-body--row[data-rowid="${rowid}"] .${column.id}`);
  }
};

/**
 * offset
 * @param elem
 * @param container
 * @param rest
 * @returns {*}
 */
function getNodeOffset(elem, container, rest) {
  if (elem) {
    const parentElem = elem.parentNode;
    rest.top += elem.offsetTop;
    rest.left += elem.offsetLeft;
    if (parentElem && parentElem !== htmlElem && parentElem !== bodyElem) {
      rest.top -= parentElem.scrollTop;
      rest.left -= parentElem.scrollLeft;
    }
    if (container && (elem === container || elem.offsetParent === container) ? 0 : elem.offsetParent) {
      return getNodeOffset(elem.offsetParent, container, rest);
    }
  }
  return rest;
}

export default DomTools;
