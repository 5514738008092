/*
 * @Author: Fenngding
 * @Date: 2019-09-02 12:40:11
 * @LastEditors: liyu
 * @LastEditTime: 2020-06-4
 * @Email: fenngding@live.com
 * @Description:
 */
export function microSync(type) {
  const listener = type === "master" ? "slaveEvent" : "masterEvent";
  const trigger = type === "master" ? "masterEvent" : "slaveEvent";
  return store => {
    window.addEventListener(listener, function(e) {
      store.replaceState(e.state);
    });
    store.subscribe((mutation, state) => {
      var event = new Event(trigger);
      event.state = state;
      window.dispatchEvent(event);
    });
  };
}
