var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
      'en-button',
      'el-button',
      'en-button--' + _vm.buttonType,
      {
        'en-btn-selected':_vm.selected,
       'en-btn-disabled':_vm.buttonDisabled,
       'en-btn-selected-disabled':_vm.selected && _vm.buttonDisabled,
       'en-btn-normal':!_vm.selected && !_vm.buttonDisabled,
       'is--btn':!_vm.round && !_vm.circle && !_vm.isTextButton && _vm.$slots.default,
       'is--round':_vm.round,
       'is--circle':_vm.circle,
       'is--text':_vm.isTextButton
      }
    ],style:(_vm.isMouseEnter ? _vm.buttonCustomHoverStyle : _vm.buttonStyle),attrs:{"type":"button"},on:{"click":_vm.handleClick,"mouseenter":function($event){_vm.isCustomStyle && !_vm.selected && !_vm.buttonDisabled ? _vm.handleMouseEnter() : undefined},"mouseleave":function($event){_vm.isCustomStyle && !_vm.selected && !_vm.buttonDisabled ? _vm.handleMouseLeave() : undefined}}},[_vm._t("left"),(_vm.loading)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),(_vm.elIcon && !_vm.loading)?_c('i',{class:_vm.elIcon}):_vm._e(),(_vm.svgIcon)?_c('svg',{class:{'en-icon-svg':true, 'en-icon-gap':_vm.$slots.default || _vm.$slots.right},style:(_vm.iconStyle),attrs:{"fill":_vm.iconColor}},[_c('use',{attrs:{"xlink:href":'#'+ _vm.svgIcon}})]):_vm._e(),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e(),_vm._t("right")],2)}
var staticRenderFns = []

export { render, staticRenderFns }