/* eslint-disable */
/**
 * @description 求真函数
 * @param a
 * @param b
 * @param c
 * @return {*}
 * @constructor
 */
function IF(a, b, c) {
  return a ? b : c
}

/**
 * @description 求和公式
 * @return {*}
 * @constructor
 */
function SUM(...number) {
  let sum = 0
  try {
    const len = number.length
    if (len > 0) {
      for (let i = 0; i < len; i++) {
        sum += parseFloat(number[i])
      }
    }
  } catch (e) {
    console.error("数据异常，无法继续进行计算")
    sum = 0
  }
  return sum
}

/**
 * @description 将时间日期转换为时间戳
 * @constructor
 * @return {string}
 */
function DATE() {
  const len = arguments.length
  let result = ""
  if (len === 3 || len === 6) {
    if (len === 3) {
      try {
        result = new Date(arguments[0] + "/" + arguments[1] + "/" + arguments[2]).getTime()
      } catch (e) {
        console.error("传递的时间格式不对")
        result = "0"
      }
    } else {
      try {
        result = new Date(arguments[0] + "/" + arguments[1] + "/" + arguments[2] + " " + arguments[3] + ":" + arguments[4] + ":" + arguments[5]).getTime()
      } catch (e) {
        console.error("传递的时间格式不对")
        result = "0"
      }
    }
  } else {
    console.error("时间格式不对，无法继续进行数据处理")
    result = "0"
  }
  return result
}

/**
 * @description 求真函数，必须所有条件都成立才成立
 * @return {boolean}
 * @constructor
 */
function AND() {
  let flag = true
  try {
    for (let i = 0, len = arguments.length; i < len; i++) {
      if (!arguments[i]) {
        flag = false
      }
    }
  } catch (e) {
    console.error("数值有误，无法继续")
    flag = 0
  }
  return flag
}

/**
 * @description 求伪函数，只要有一个条件成立就成立
 * @return {boolean}
 * @constructor
 */
function OR() {
  let flag = false
  try {
    for (let i = 0, len = arguments.length; i < len; i++) {
      if (arguments[i]) {
        flag = true
      }
    }
  } catch (e) {
    console.error("数值有误，无法继续")
    flag = 0
  }
  return flag
}

/**
 * @description
 * @constructor
 */
function SUMIF() {

}

/**
 * @description
 * @constructor
 */
function SUMIFS() {
}

function SUMPRODUCT() {
}

/**
 * @description 求平均值
 * @return {string}
 * @constructor
 */
function AVERAGE() {
  const len = arguments.length
  let result = ""
  try {
    for (let i = 0; i < len; i++) {
      result += arguments[i]
    }
    result = result / len
  } catch (e) {
    console.error("数值有误，无法继续进行计算")
  }
  return result
}

/**
 * @description 求参数个数
 * @return {number}
 * @constructor
 */
function COUNT() {
  return arguments.length
}

/**
 * @description
 * @constructor
 */
function COUNTIF() {

}

/**
 * @description 求最大数
 * @return {number}
 * @constructor
 */
function MAX() {
  try {
    return Math.max(...arguments)
  } catch (e) {
    console.error("无法计算最大数，传递的参数有误")
    return 0
  }
}

/**
 * @description 求最小数
 * @return {number}
 * @constructor
 */
function MIN() {
  try {
    return Math.min(...arguments)
  } catch (e) {
    console.error("无法计算最大数，传递的参数有误")
    return 0
  }
}

/**
 * @description 对指定数值进行小数保留计算
 * @return {*}
 * @constructor
 */
function ROUND() {
  try {
    arguments[0].toFixed(arguments[1])
  } catch (e) {
    console.error("无法进行保留小数计算，传递的参数有误")
    return 0
  }
}

/** *
 * @description 求余计算
 * @return {number}
 * @constructor
 */
function MOD() {
  try {
    return arguments[0] % arguments[1]
  } catch (e) {
    console.error("无法进行取余计算，传递参数有误")
    return 0
  }
}

/**
 * @description 文本合并函数
 * @return {string}
 * @constructor
 */
function CONCATENATE() {
  let result = ""
  try {
    for (let i = 0, len = arguments.length; i < len; i++) {
      result += arguments[i]
    }
  } catch (e) {
    console.error("传递参数有误，无法完成文本拼接")
  }
  return result
}

/**
 * @description 数字转换为字符串
 * @return {string}
 * @constructor
 */
function TEXT() {
  let result = ""
  try {
    result = arguments.toString()
  } catch (e) {
    console.error("传递参数有误，无法完成数字转换为字符串输出")
  }
  return result
}

/**
 * @description 计算字符串的长度
 * @return {number}
 * @constructor
 */
function LEN() {
  let result = ""
  try {
    for (let i = 0, len = arguments.length; i < len; i++) {
      result += arguments[i].length
    }
  } catch (e) {
    console.error("传递参数有误，无法计算字符串长度")
  }
  return result
}

/**
 * @description 从左至右，完成字符串截取
 * @return {string}
 * @constructor
 */
function LEFT() {
  let result = ""
  try {
    result = arguments[0].substring(0, arguments[1])
  } catch (e) {
    console.error("参数有误，无法完成字符串截取")
  }
  return result
}

/**
 * @description 从右到左，截取字符串
 * @return {string}
 * @constructor
 */
function RIGHT() {
  let result = ""
  try {
    result = arguments[0].substring(arguments[0].length - arguments[1], arguments[0].length)
  } catch (e) {
    console.error("参数有误，无法完成字符串截取")
  }
  return result
}

/**
 * @description 从左开始，到指定位置，截取指定长度的字符串
 * @return {string}
 * @constructor
 */
function MID() {
  let result = ""
  try {
    result = arguments[0].substring(arguments[1], arguments[1] + arguments[2])
  } catch (e) {
    console.error("参数有误，无法完成字符串截取")
  }
  return result
}

/**
 * @description 将指定数值，重复指定次数并返回
 * @return {*}
 * @constructor
 */
function REPT() {
  let result = ""
  try {
    for (let i = 0; i < arguments[1]; i++) {
      result += arguments[0]
    }
  } catch (e) {
    console.error("参数有误，无法完成数据复制")
  }
  return result
}

/**
 * @description 将字符串转化为数值
 * @return {number}
 * @constructor
 */
function VALUE() {
  let result = ""
  try {
    result = Number(arguments[0])
  } catch (e) {
    console.error("参数有误，无法完成字符串转成数值")
  }
  return result
}

/**
 * @description 返回某日期的天数。 天数是介于 1 到 31 之间的整数。
 * @return {number}
 * @constructor
 */
function DAY() {
  let result = ""
  try {
    if (typeof arguments[0] === "number") {
      result = new Date(arguments[0]).getDate()
    } else {
      result = new Date(arguments[0]).getDate()
    }
  } catch (e) {
    console.error("时间格式异常，无法读取该时间日期的天数")
  }
  return result
}

/**
 * @description 返回某日期的年份。
 * @return {number}
 * @constructor
 */
function YEAR() {
  let result = ""
  try {
    if (typeof arguments[0] === "number") {
      result = new Date(arguments[0]).getFullYear()
    } else {
      result = new Date(arguments[0]).getFullYear()
    }
  } catch (e) {
    console.error("时间格式异常，无法读取该时间日期的年份")
  }
  return result
}

/**
 * @description 返回某日期的月份。
 * @return {number}
 * @constructor
 */
function MONTH() {
  let result = ""
  try {
    if (typeof arguments[0] === "number") {
      result = new Date(arguments[0]).getMonth() + 1
    } else {
      result = new Date(arguments[0]).getMonth() + 1
    }
  } catch (e) {
    console.error("时间格式异常，无法读取该时间日期的月份")
  }
  return result
}

/**
 * @description 获取指定时间日期的小时数
 * @return {number}
 * @constructor
 */
function HOUR() {
  let result = ""
  try {
    if (typeof arguments[0] === "number") {
      result = new Date(arguments[0]).getHours()
    } else {
      result = new Date(arguments[0]).getHours()
    }
  } catch (e) {
    console.error("时间格式异常，无法读取该时间日期的小时数")
  }
  return result
}

/**
 * @description 获取指定时间日期的分钟数
 * @return {string}
 * @constructor
 */
function MINUTE() {
  let result = ""
  try {
    if (typeof arguments[0] === "number") {
      result = new Date(arguments[0]).getMinutes()
    } else {
      result = new Date(arguments[0]).getMinutes()
    }
  } catch (e) {
    console.error("时间格式异常，无法读取该时间日期的小时数")
  }
  return result
}

/**
 * @description 获取指定时间日期的秒数
 * @return {string}
 * @constructor
 */
function SECOND() {
  let result = ""
  try {
    if (typeof arguments[0] === "number") {
      result = new Date(arguments[0]).getSeconds()
    } else {
      result = new Date(arguments[0]).getSeconds()
    }
  } catch (e) {
    console.error("时间格式异常，无法读取该时间日期的小时数")
  }
  return result
}

/**
 * @description 计算两个时间日期的月份差
 * @return {string}
 * @constructor
 */
function MONDIF() {
  let result = ""
  try {
    const sD = new Date(arguments[0])
    const eD = new Date(arguments[1])
    const sYear = sD.getFullYear()
    const eYear = eD.getFullYear()
    const sMonth = sD.getMonth() + 1
    const eMonth = eD.getMonth() + 1
    result = Math.abs(Math.abs(sYear - eYear) * 12) + (sMonth - eMonth)
  } catch (e) {
    console.error("传递参数有误，无法计算月份差")
  }
  return result
}

/**
 * @description 计算两个时间日期的天数差
 * @return {string}
 * @constructor
 */
function DAYDIF() {
  let result = ""
  try {
    const sTimeStamp = new Date(arguments[0]).getTime()
    const eTimeStamp = new Date(arguments[1]).getTime()
    result = Math.abs(sTimeStamp - eTimeStamp) / 24 / 60 / 60 / 1000
  } catch (e) {
    console.error("传递参数有误，无法计算天数差")
  }
  return result
}

/**
 * @description 计算两个时间日期的小时差
 * @return {string}
 * @constructor
 */
function HOUDIF() {
  let result = ""
  try {
    const sTimeStamp = new Date(arguments[0]).getTime()
    const eTimeStamp = new Date(arguments[1]).getTime()
    result = Math.abs(sTimeStamp - eTimeStamp) / 60 / 60 / 1000
  } catch (e) {
    console.error("传递参数有误，无法计算小时差")
  }
  return result
}

/**
 * @description 计算两个时间日期的分钟差
 * @return {string}
 * @constructor
 */
function MINDIF() {
  let result = ""
  try {
    const sTimeStamp = new Date(arguments[0]).getTime()
    const eTimeStamp = new Date(arguments[1]).getTime()
    result = Math.abs(sTimeStamp - eTimeStamp) / 60 / 1000
  } catch (e) {
    console.error("传递参数有误，无法计算分钟差")
  }
  return result
}

function WDAYDIF() {

}

function WHOUDIF() {

}

function WMINDIF() {

}

const formula = {
  "IF": "判断真伪",
  "SUM": "求和",
  "AND": "求真函数",
  "OR": "求伪函数",
  "SUMIF": "真伪求和",
  "SUMIFS": "",
  "SUMPRODUCT": "求乘积",
  "AVERAGE": "求平均值",
  "COUNT": "求数量值，即求参数个数",
  "COUNTIF": "根据条件求数值个数",
  "MAX": "求最大值",
  "MIN": "求最小值",
  "ROUND": "保留几位小数",
  "MOD": "求余数",
  "CONCATENATE": "文本合并",
  "TEXT": "数字转化为字符串",
  "LEN": "计算字符个数",
  "LEFT": "从左到右，截取字符串",
  "RIGHT": "从右到左，截取字符串",
  "MID": "从左开始，到指定位置，截取指定长度的字符串",
  "REPT": "将指定数值，重复指定次数并返回",
  "VALUE": "将字符串转换为数字",
  "DATE": "将时间日期转换为时间戳",
  "DAY": "获取某时间日期是第几天",
  "YEAR": "获取某时间日期的年份",
  "MONTH": "获取某时间日期的月份",
  "HOUR": "获取某时间日期的小时",
  "MINUTE": "获取某时间日期的分钟",
  "SECOND": "获取某时间日期的秒数",
  "MONDIF": "获取两个时间的月份差",
  "DAYDIF": "获取两个时间的天数差",
  "HOUDIF": "获取两个时间的小时差",
  "MINDIF": "获取两个时间的分钟差",
  "WDAYDIF": "获取两个时间的工作天数差",
  "WHOUDIF": "获取两个时间的工作小时差",
  "WMINDIF": "获取两个时间的工作分钟差"
}

const formulaUsage = [
  {
    name: "常用函数",
    key: "frequently",
    contains: [
      {
        key: "fun",
        name: "IF",
        explain: "IF函数判断一个条件是否满足；如果满足返回一个值，如果不满足则返回另外一个值，IF(逻辑表达式，'true值','false值')",
        usage: "用法：IF(逻辑表达式，为true时返回的值，为false时，返回的值)",
        eg: "示例：IF(语文成绩>60,'及格','不及格')，当语文成绩>60时，返回及格，否则返回不及格"
      },
      {
        key: "fun",
        name: "SUM",
        explain: "SUM函数可以获取一组数值的总和。数组可以是一个参数，也可以是多个参数的求和。参数类型：数值、金额类型",
        usage: "用法：SUM(数字1,数字2,...)",
        eg: "示例：SUM(语文成绩,数学成绩, 英语成绩)返回三门课程的总分"
      },
      {
        key: "fun",
        name: "DATE",
        explain: "DATE函数可以将时间戳转换为日期对象时间戳参数可以是以下类型：DATE(year,month,day)DATE(year,month,day,hour,minute,second)，全部可以转换成日期类型。",
        usage: "用法：DATE(时间戳)",
        eg: "示例：DATE(2019,05,03),返回1559491200000"
      }
    ]
  },
  {
    name: "逻辑函数",
    key: "logic",
    contains: [
      {
        key: "fun",
        name: "AND",
        explain: "如果所有参数都为真，AND函数返回布尔值true，否则返回布尔值 false。里面可以有1到多个参数；",
        usage: "用法：AND(逻辑表达式1,逻辑表达式2,...)",
        eg: "示例：AND(语文成绩>90,数学成绩>90,英语成绩>90)，如果三门课成绩都> 90，返回true，否则返回false"
      },
      {
        key: "fun",
        name: "OR",
        explain: "如果任意参数为真，OR 函数返回布尔值true；如果所有参数为假，返回布尔值false。逻辑表达式可以单个，多个，无限制",
        usage: "用法：OR(逻辑表达式1,逻辑表达式2,...)",
        eg: "示例：OR(语文成绩>90,数学成绩>90,英语成绩>90)，任何一门课成绩> 90，返回true，否则返回false"
      },
      {
        key: "fun",
        name: "IF",
        explain: "IF函数判断一个条件能否满足；如果满足返回一个值，如果不满足则返回另外一个值。If(逻辑表达式，“ture值”，“false值”)",
        usage: "用法：IF(逻辑表达式,为true时返回的值,为false时返回的值)",
        eg: "示例：IF(语文成绩>60,\"及格\",\"不及格\")，当语文成绩>60时返回及格，否则返回不及格。"
      }
    ]
  },
  {
    name: "数学函数",
    key: "math",
    contains: [
      {
        key: "fun",
        name: "SUM",
        explain: "SUM函数可以获取一组数值的总和。数组可以是一个参数，也可以是多个参数的求和。参数类型：数值、金额类型",
        usage: "用法：SUM(数字1,数字2,...)",
        eg: "示例：SUM(语文成绩,数学成绩, 英语成绩)返回三门课程的总分"
      },
      {
        key: "fun",
        name: "SUMIF",
        explain: "可以对数据中符合指定条件的一组数值进行求和。sumif函数语法是：=SUMIF(求和参数，条件)sumif函数的参数如下：第一个参数：求和参数。第二个参数：求和条件，由数字、逻辑表达式等组成的判定条件。",
        usage: "用法：SUMIF（求和参数，逻辑判断条件表达式）",
        eg: ""
      },
      {
        key: "fun",
        name: "SUMIFS",
        explain: "可以对数据中符合多个指定条件的一组数值进行求和。sumifs函数语法是：=SUMIFS(求和参数,条件1,条件2)sumif函数的参数如下：第一个参数：求和参数第二个参数：是求和条件，由数字、逻辑表达式等组成的判定条件。可以是1个或多个",
        usage: "用法：SUMIFS（求和参数，逻辑判断条件表达式1，逻辑判断条件表达式2，...）",
        eg: ""
      },
      {
        key: "fun",
        name: "SUMPRODUCT",
        explain: "求乘积，函数可以将数组间对应的元素相乘，并返回乘积之和，适用于加权求和数组可以是一个参数，也可以是多个参数加权求和。SUMPRODUCT(array1, array2)array参数类型：数值、金额类型",
        usage: "用法：SUMPRODUCT(数组,数组...)",
        eg: "示例：SUMPRODUCT([1,2,3],[0.1,0.2,0.3])返回1.4，也就是 1×0.1 + 2×0.2 + 3×0.3的值SUMPRODUCT(明细.价格,明细.折扣)"
      },
      {
        key: "fun",
        name: "AVERAGE",
        explain: "平均值，可以获取一组数值的算术平均值数组可以是一个参数，也可以是多个参数求平均值。AVERAGE(number1, number2, …)number参数类型：数值、金额类型",
        usage: "用法：AVERAGE(数字1,数字2,...)",
        eg: "示例：AVERAGE(语文成绩,数学成绩, 英语成绩)返回三门课程的平均分"

      },
      {
        key: "fun",
        name: "COUNT",
        explain: "计数，函数可以获取参数的数量COUNT(value1, value2, …)value参数类型：数值、金额类型",
        usage: "用法：COUNT(值,值,...)",
        eg: "示例：COUNT(小明,小王,小张,小李)返回4，也就是人员的数量"
      },
      {
        key: "fun",
        name: "COUNTIF",
        explain: "COUNTIF函数可以获取数组中满足条件的参数个数COUNTIF(数组,“条件”)数组参数类型：数值、金额类型",
        usage: "用法：COUNTIF(数组,\"条件\")",
        eg: "示例：COUNTIF(子表单.性别, \"女\")，可得到子表单中性别填的是\"女\"的数据条数；COUNTIF([1,2,3,4],\">2\")，可得到1,2,3,4中大于2的数字数量，结果为2。"
      },
      {
        key: "fun",
        name: "MAX",
        explain: "MAX函数可以获取一组数值的最大值MAX(number1, [number2], …)参数类型：数值、金额类型",
        usage: "用法：MAX(数字1,数字2,...)",
        eg: "示例：MAX(语文成绩,数学成绩,英语成绩)返回三门课程中的最高分"
      },
      {
        key: "fun",
        name: "MIN",
        explain: "MIN函数可以获取一组数值的最小值MIN(number1, [number2], …)参数类型：数值、金额类型",
        usage: "用法：MIN(数字1,数字2,...)",
        eg: "示例：MIN(语文成绩,数学成绩,英语成绩)返回三门课程中的最低分"

      },
      {
        key: "fun",
        name: "ROUND",
        explain: "保留几位小数，ROUND函数可以将数字四舍五入到指定的位数ROUND(number, num_digits)将数字四舍五入到指定的位数。number: 必需。 要四舍五入的数字。num_digits: 必需。 要进行四舍五入运算的位数。",
        usage: "用法：ROUND(数字,数字位数)",
        eg: "示例：ROUND(3.1485,2)返回3.15"
      },
      {
        key: "fun",
        name: "MOD",
        explain: "求余，MOD函数可以获取两数相除的余数MOD(number, divisor)返回两数相除的余数。 结果的符号与除数相同。number: 必需。 要计算余数的被除数。divisor: 必需。 除数。",
        usage: "用法：MOD(被除数,除数)",
        eg: "示例：MOD(4,3)返回1，也就是4/3的余数"
      }
    ]
  },
  {
    name: "文本函数",
    key: "text",
    contains: [
      {
        key: "fun",
        name: "CONCATENATE",
        explain: "合并单元格，CONCATENATE函数可以将多个文本合并成一个文本CONCATENATE(text1,[text2], …)将多个文本字符串合并成一个文本字符串。示例：CONCATENATE(A,B,C)，即返回值为ABC字段或者函数之间，用逗号隔开。如果是字符串，需要用引号包裹起来。",
        usage: "用法：CONCATENATE(文本1,文本2,...)",
        eg: "示例：CONCATENATE(\"三年二班\",\"周杰伦\")会返回\"三年二班周杰伦\""
      },
      {
        key: "fun",
        name: "TEXT",
        explain: "格式，TEXT函数可以将数字转化成文本",
        usage: "用法：TEXT(数字)",
        eg: "示例：TEXT(3.1415)返回\"3.1415\""
      },
      {
        key: "fun",
        name: "LEN",
        explain: "计算字符，LEN函数可以获取文本中的字符个数LEN(text)",
        usage: "用法：LEN(文本)",
        eg: "示例：LEN(\"朝辞白帝彩云间\")返回7，因为这句诗中有7个字符"
      },
      {
        key: "fun",
        name: "LEFT",
        explain: "取左边的几位，LEFT函数可以从一个文本的第一个字符开始返回指定个数的字符LEFT(text,[num_chars])从一个文本字符串的第一个字符开始返回指定个数的字符。",
        usage: "用法：LEFT(文本,文本长度)",
        eg: "示例：LEFT(\"三年二班周杰伦\",2)返回\"三年\"，也就是\"三年二班周杰伦\"的从左往右的前2个字符"
      },
      {
        key: "fun",
        name: "RIGHT",
        explain: "RIGHT函数可以获取由给定文本右端指定数量的字符构成的文本值RIGHT(text,[num_chars])返回文本值中最右边的字符。text: 必需。包含要提取字符的文本字符串。num_chars: 可选。指定希望提取的字符数。",
        usage: "用法：RIGHT(文本,文本长度)",
        eg: "示例：RIGHT(\"三年二班周杰伦\",3)返回\"周杰伦\"，也就是\"三年二班周杰伦\"从右往左的前3个字符"
      },
      {
        key: "fun",
        name: "MID",
        explain: "MID返回文本中从指定位置开始的指定数目的字符MID(text, startnum*, numchars*)返回文本字符串中从指定位置开始的特定数目的字符，该数目由用户指定。text: 必需。 包含要提取字符的文本字符串。start_num: 必需。 文本中要提取的第一个字符的位置。 文本中第一个字符的start_num为 1，以此类推。num_chars: 必需。 指定希望从文本中返回字符的个数。",
        usage: "用法：MID(文本,开始位置_数字,指定数目)",
        eg: "示例：MID(\"英富莱应用定制工具\",4,6)返回\"应用定制工具\""
      },
      {
        key: "fun",
        name: "REPT",
        explain: "可以将文本重复一定次数",
        usage: "用法：REPT(文本,重复次数)",
        eg: "示例：REPT(\"英富莱\",3)返回\"英富莱英富莱英富莱\""
      },
      {
        key: "fun",
        name: "VALUE",
        explain: "转成数值，VALUE函数可以将文本转化为数字",
        usage: "用法：VALUE(文本)",
        eg: "示例：VALUE(\"3.1415\")返回3.1415"
      }
    ]
  },
  {
    name: "日期函数",
    key: "date",
    contains: [
      {
        key: "fun",
        name: "DATE",
        explain: "DATE函数可以将时间戳转换为日期对象时间戳参数可以是以下类型：DATE(year,month,day)DATE(year,month,day,hour,minute,second)，全部可以转换成日期类型。",
        usage: "用法：DATE(时间戳)",
        eg: ""
      },
      {
        key: "fun",
        name: "DAY",
        explain: "DAY函数可以获取某日期是当月的第几日用法：DAY(时间戳)，参数是日期类型，返回的结果是数值类型。返回某日期的天数。 天数是介于 1 到 31 之间的整数。",
        usage: "用法：DAY(时间戳)",
        eg: ""
      },
      {
        key: "fun",
        name: "YEAR",
        explain: "YEAR函数可以返回某日期的年份YEAR(timestamp)返回某日期的年份。参数是日期类型，返回的结果是数值类型。",
        usage: "用法：YEAR(时间戳)",
        eg: ""
      },
      {
        key: "fun",
        name: "MONTH",
        explain: "MONTH返回某日期的月份参数是日期类型，返回的结果是数值类型。",
        usage: "用法：MONTH(时间戳)",
        eg: ""
      },
      {
        key: "fun",
        name: "HOUR",
        explain: "HOUR函数可以返回某日期的小时数参数是日期类型，返回的结果是数值类型。",
        usage: "用法：HOUR(时间戳)",
        eg: ""
      },
      {
        key: "fun",
        name: "MINUTE",
        explain: "MINUTE函数可以返回某日期的分钟数参数是日期类型，返回的结果是数值类型。",
        usage: "用法：MINUTE(时间戳)",
        eg: ""
      },
      {
        key: "fun",
        name: "SECOND",
        explain: "SECOND函数可以返回某日期的秒数参数是日期类型，返回的结果是数值类型。",
        usage: "用法：SECOND(时间戳)",
        eg: ""
      },
      {
        key: "fun",
        name: "MONDIF",
        explain: "MONDIF函数可以返回某两个时间之间相隔的月份数",
        usage: "用法：MONDIF(时间2,时间1），表示：时间2 - 时间1",
        eg: ""
      },
      {
        key: "fun",
        name: "DAYDIF",
        explain: "DAYDIF函数可以返回某两个时间之间相隔的天数",
        usage: "用法：DAYDIF(时间2,时间1），表示：时间2 - 时间1",
        eg: ""
      },
      {
        key: "fun",
        name: "HOUDIF",
        explain: "HOUDIF函数可以返回某两个时间之间相隔的小时数",
        usage: "用法：HOUDIF(时间2,时间1），表示：时间2 - 时间1",
        eg: ""
      },
      {
        key: "fun",
        name: "MINDIF",
        explain: "MINDIF函数可以返回某两个时间之间相隔的分钟数",
        usage: "用法：MINDIF(时间2,时间1），表示：时间2 - 时间1",
        eg: ""
      },
      {
        key: "fun",
        name: "WDAYDIF",
        explain: "WDAYDIF函数可以返回某两个时间之间相隔的工作日天数",
        usage: "用法：WDAYDIF(时间2,时间1），表示：时间2 - 时间1",
        eg: ""
      },
      {
        key: "fun",
        name: "WHOUDIF",
        explain: "WHOUDIF函数可以返回某两个时间之间相隔的工作日小时数",
        usage: "用法：WHOUDIF(时间2,时间1），表示：时间2 - 时间1",
        eg: ""
      },
      {
        key: "fun",
        name: "WMINDIF",
        explain: "WMINDIF函数可以返回某两个时间之间相隔的工作日分钟数",
        usage: "用法：WMINDIF(时间2,时间1），表示：时间2 - 时间1",
        eg: ""
      }
    ]
  }
]

/**
 * @description 解析
 * @param params
 */
function changeResult(params) {
  const fields = params.fields
  let result = ""
  for (const i in fields) {
    fields[i].value = (Math.random() * 111000).toFixed(0)
    params.code = params.code.replace(i, fields[i].value)
  }
  try {
    result = eval(params.code)
  } catch (e) {
    console.error("计算公式配置有误，无法继续执行")
  }
  return result
}

export {
  changeResult,
  formula,
  formulaUsage
}
