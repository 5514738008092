<!--
 * @Description: 范围过滤的通用组件
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-08-21 13:38:33
 * @LastEditTime: 2019-10-28 15:14:21
 -->

<template>
  <el-dialog
    title="数据范围"
    :visible="dialogVisible"
    class="associated-data-range"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    width="1100px"
    @close="close"
  >
    <en-tabs
      v-if="tabList.length >= 2"
      v-model="activeName"
      :list="tabList"
      type="white"
    >
    </en-tabs>
    <div v-if="activeName === 'associatedCondition'" class="tab-item">
      <div class="header">
        <en-button @click="associatedConditionAdd">
          添加
        </en-button>
      </div>
      <div class="content">
        <en-and-or
          v-for="(data, index) in associatedConditionData"
          :key="index"
          :length-of-and="data.length"
          :index="index"
          :line-height="43"
          :padding="17"
          @add="associatedConditionAddRow(data)"
          @deleteItem="associatedConditionDeleteSection"
        >
          <en-and-or-column
            v-for="(_, columnIndex) in data"
            :key="columnIndex"
            :name="columnIndex"
            @deleteRow="associatedConditionDeleteRow(data, columnIndex, index)"
          >
            <span style="line-height:32px;margin-right:12px;">{{
              conditionTexts[0]
            }}</span>
            <en-select style="margin-right:19px;width:325px"></en-select>
            <span style="line-height:32px;margin-right:12px;"> = </span>
            <span style="line-height:32px;margin-right:11px;">{{
              conditionTexts[1]
            }}</span>
            <en-select style="margin-right:12px;width:325px"> </en-select>
          </en-and-or-column>
        </en-and-or>
      </div>
    </div>
    <div v-if="activeName === 'rangeFilter'" class="tab-item">
      <div class="header">
        <en-button @click="associatedConditionAdd">
          添加
        </en-button>
      </div>
      <div class="content">
        <en-and-or
          v-for="(data, index) in associatedConditionData"
          :key="index"
          :length-of-and="data.length"
          :index="index"
          :line-height="43"
          :padding="17"
          @add="associatedConditionAddRow(data)"
          @deleteItem="associatedConditionDeleteSection"
        >
          <en-and-or-column
            v-for="(_, columnIndex) in data"
            :key="columnIndex"
            :name="columnIndex"
            @deleteRow="associatedConditionDeleteRow(data, columnIndex, index)"
          >
            <en-cascade-compare :column-length="150"></en-cascade-compare>
          </en-and-or-column>
        </en-and-or>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </el-dialog>
</template>
<script>
const FilterType = {
  associatedCondition: 1, // 关联条件
  rangeFilter: 1 << 1 // 范围过滤
};

export default {
  name: "EnRangeFilter",
  props: {
    // 等式左边和右边的文字,必须为字符串数组,length为2
    conditionTexts: {
      type: Array,
      default: function() {
        return ["关联数据源", "本数据源"];
      }
    },
    // 触发条件类型
    filterType: {
      type: Number,
      default: 1
    },
    column: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 弹窗可见
    dialogVisible: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      activeName: "associatedCondition",
      associatedConditionData: [[{}]],
      // 字段变化 要取模板上的字段
      changeOptions: [
        {
          label: "文本",
          value: 1,
          fieldType: 1
        },
        {
          label: "数值",
          value: 3,
          fieldType: 3
        },
        {
          label: "金额",
          value: "4",
          fieldType: 4
        },
        {
          label: "单选",
          value: 5,
          fieldType: 5
        },
        {
          label: "时间",
          value: 7,
          fieldType: 7
        }
      ],
      changefields: [],
      value: ""
    };
  },
  computed: {
    tabList() {
      const tabs = [];
      if (this.filterType & FilterType.associatedCondition) {
        tabs.push({
          id: "associatedCondition",
          name: "关联条件"
        });
      }
      if (this.filterType & FilterType.rangeFilter) {
        tabs.push({
          id: "rangeFilter",
          name: "范围过滤"
        });
      }
      return tabs;
    }
  },
  mounted() {
    if (this.tabList.length === 1) {
      this.activeName = this.tabList[0].id;
    }
  },

  methods: {
    // 关闭弹窗
    close() {
      this.$emit("update:dialog-visible");
    },
    associatedConditionAdd() {
      this.associatedConditionData.push([{}]);
    },
    associatedConditionAddRow(data) {
      // 增加一行数据
      data.push({});
    },
    associatedConditionDeleteSection(index) {
      this.associatedConditionData.splice(index, 1);
    },
    associatedConditionDeleteRow(data, index, dataIndex) {
      if (data.length === 1) {
        this.associatedConditionData.splice(dataIndex, 1);
      } else {
        data.splice(index, 1);
      }
    },
    // 保存
    save() {
      // this.up
      this.$emit("update:datas", this.conditionConfig);
      this.close();
    }
  }
};
</script>
<style lang="scss" scoped>
.associated-data-range {
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  .en-tabs {
    // @include seperator-line();
    // margin-left: -20px;
    // margin-right: -20px;
    // width: auto;
    margin-bottom: 20px;
  }
  .tab-item {
    // margin-top: 20px;
    color: #636c78;
  }
  .content {
    padding-top: 20px;
    height: 500px;
    overflow-y: auto;
  }
  .column {
    width: 190px;
    display: inline-block;
    margin-right: 12px;
  }
}
</style>
