<template>
  <div class="en-drag-item">
    <div class="header">
      <span
        class="clear"
        @click="clearItem"
      >
        清空
      </span>
      <span class="header-text">可拖动排序;选中内容按Delete键可删除</span>
    </div>
    <draggable
      group="item"
      animation="150"
      :list="datas"
      class="en-drag-item-content"
      scroll="true"
      @sort="sort"
    >
      <input
        v-for="(item,idx) in datas"
        :key="idx"
        type="button"
        class="item"
        :value="item.name"
        @keyup.delete="deleteItemPress(item)"
      />
    </draggable>
  </div>
</template>
<script>

import draggable from "vuedraggable";
export default {
  name: "EnDragItem",
  components: { draggable },
  props: {
    datas: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  computed: {
    indexDatas() {
      return this.datas.map((data, index) => {
        data.index = index;
        return data;
      });
    }
  },
  methods: {
    sort(aa, vv) {
      return true;
    },
    // 删除
    deleteItemPress(item) {
      var index = this.datas.indexOf(item);
      if (index > -1) {
        this.datas.splice(index, 1);
        this.$emit("deleteItem", index);
      }
    },
    // 清空
    clearItem() {
      this.datas.splice(0, this.datas.length);
      this.$emit("clearItem");
    }
  }
};
</script>
<style lang="scss" scoped>
.en-drag-item {
  padding: 12px;

  .header {
    font-size: 12px;
    font-weight: 400;
    color: rgba(185, 196, 210, 1);
    height: 12px;
    line-height: 12px;
    .header-text {
      vertical-align: middle;
    }
    .clear {
      float: right;
      color: #4694df;
      cursor: pointer;
    }
  }
  .en-drag-item-content {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-left: -12px;
    .item {
      margin-left: 12px;
      margin-top: 12px;
      padding-left: 10px;
      height: 32px;
      line-height: 32px;
      font-weight: 400;
      color: rgba(70, 148, 223, 1);
      background: rgba(225, 242, 250, 1);
      border-radius: 4px;
      border: none;
      outline: none;
      &:focus {
        background: rgba(183, 226, 245, 1);
      }
    }
  }
}
</style>

