<template>
  <div class="first-step-contain">
    <!-- 上传提示区域 start -->
    <div class="tips-contain">
      <div class="tips-title1">
        1、请先下载 <a @click="downLoadTemplate">数据模板</a>
      </div>
      <div class="tips-title2">2、按照格式填充数据模板内容，并核对无误</div>
      <div class="tips-title3">
        3、上传填充好的数据模板文件<a>（仅支持扩展名xls与xlsx）</a>
      </div>
    </div>
    <!-- 上传提示区域 end -->

    <!-- 下载数据模板 start -->
    <enDataExport
      v-if="dataExportShow"
      :dialog-visible.sync="dataExportShow"
      @getChildDialogVisible="getChildDialogVisible"
    />
    <!-- 下载数据模板 end -->

    <!-- 上传选择,按钮显示 start -->
    <template v-if="!fileChoosed">
      <div class="un-upload-contain">
        <img src="../import_img.png" class="tips-image" />
        <el-upload
          ref="upload"
          class="upload-demo"
          action="https://192.168.1.23/importExcelModelValueDataMap.ajax?modelId=287854953842606080"
          accept=".xlsx,.xls"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :auto-upload="true"
          name="importFile"
        >
          <!-- @click="uploadToChooseFile" -->
          <el-button slot="trigger" class="updata-button" size="small">
            立即上传模板
          </el-button>
        </el-upload>
      </div>
    </template>
    <!-- 上传选择,按钮显示 end -->

    <!-- 显示上传选择的文件 start -->
    <template v-else>
      <div class="upload-contain">
        <div class="file-contain">
          <div @click="deleteChooseFile">
            <en-icon
              name="shanchu-liebiao"
              class="delete-icon"
              size="small"
              :style="{ fill: '#F76B6B' }"
            ></en-icon>
          </div>

          <en-icon
            name="excel-wendangyangshi"
            class="file-icon"
            size="24px"
            :style="{ fill: '#58CB7E' }"
          ></en-icon>
          <span class="file-name">销售机会信息表.xlsx</span>
          <el-progress
            class="file-progress"
            color="#4694DF"
            :show-text="false"
            :stroke-width="10"
            :percentage="upProgress"
          />
          <span class="file-progress-text" @click="progressClick"
            >{{ upProgress }}%</span
          >
        </div>
      </div>
    </template>
    <!-- 显示上传选择的文件 end -->
  </div>
</template>

<script>
// import axios from "axios";
// /**
//  * 该接口为添加通知建模详情
//  *
//  * @export
//  */
// export let importExcelModelValueDataMap = async function (data){
//   let result = await axios.post("/api/importExcelModelValueDataMap",data);
//   return result;
// };
export default {
  name: "FirstStep",
  data() {
    return {
      fileChoosed: false,
      dataExportShow: false,
      contactShow: true,
      upProgress: 40,
      myInterval: ""
    }
  },
  mounted() {
    this.startUpLoad()
  },
  methods: {
    startUpLoad() {
      window.clearInterval(this.myInterval)
      this.myInterval = ""
      const self = this
      const myInterval = window.setInterval(function() {
        self.upProgress += 1
        if (self.upProgress > 100) {
          self.upProgress = 0
        }
      }, 300)
      this.myInterval = myInterval
    },
    // 下载数据模板
    downLoadTemplate() {
      // this.contactShow = true;
      this.dataExportShow = true
      // window.open("https://192.168.1.23/exportExcelModelValueDataMap.do?modelId=281489740904005632");
    },
    getChildDialogVisible(value) {
      this.dataExportShow = value
    },
    // 上传选择文件
    uploadToChooseFile() {
      this.fileChoosed = true
    },
    submitUpload() {
      this.$refs.upload.submit()
    },
    handleRemove(file, fileList) {
      debugger

      console.log(file, fileList)
      if (!fileList.length) {
        this.$parent.hasFile = false
      }
    },
    /**
     * @description: 点击已上传文件
     * @param {type}
     */
    handlePreview(file) {
      debugger
      console.log(file)
    },
    /**
     * @description: 上传成功
     * @param {type}
     */
    handleSuccess(rs, file, fileList) {
      this.$parent.hasFile = true
      // 上传成功后  拿到返回的 数据展示  传给 $parent
    },
    // 改变上传进度
    progressClick() {
      this.upProgress += 10
      if (this.upProgress > 100) {
        this.upProgress = 0
      }

      this.startUpLoad()
    },
    // 删除选择文件
    deleteChooseFile() {
      this.fileChoosed = false
      window.clearInterval(this.myInterval)
      this.myInterval = ""
    }
  }
};
</script>

<style lang="scss" scoped>
.first-step-contain {
  .tips-contain {
    position: absolute;
    left: 50%;
    transform: translateX(-35%);
    margin-top: 140px;
    width: 360px;
    text-align: left;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: rgba(99, 108, 120, 1);

    .tips-title1 {
      height: 13px;
      a {
        color: #4694df;
        text-decoration: underline;
      }
    }
    .tips-title2 {
      margin-top: 28px;
      height: 13px;
    }
    .tips-title3 {
      margin-top: 28px;
      height: 13px;
      a {
        color: #b9c4d2;
      }
    }
  }

  .un-upload-contain {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 287px;
    width: 222px;
    height: 260px;

    .tips-image {
      width: 222px;
      height: 192px;
    }
    .updata-button {
      margin-top: 38px;
      width: 120px;
      height: 32px;
      background-color: #26c393;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #fff;
      border-width: 0px;
    }
  }

  .upload-contain {
    position: absolute;
    margin: 275px 22px 8px 22px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(232, 236, 242, 1);
    border-radius: 4px;
    height: 280px;
    width: calc(100% - 44px);
    .file-contain {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      margin-top: 92px;
      width: 600px;
      height: 100px;
      background: rgba(251, 251, 251, 1);
      border: 1px solid rgba(232, 236, 242, 1);
      border-radius: 4px;
      .delete-icon {
        margin-right: -6px;
        margin-top: -6px;
        border-radius: 9px;
        float: right;
      }
      .file-icon {
        position: absolute;
        top: 22px;
        left: 21px;
      }

      .file-name {
        position: absolute;
        top: 28px;
        left: 60px;
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        display: inline-block;
      }

      .file-progress {
        position: absolute;
        top: 50px;
        left: 60px;
        right: 21px;
        height: 10px;
        background: rgba(224, 228, 239, 1);
        border-radius: 5px;
      }

      .file-progress-text {
        position: absolute;
        top: 68px;
        right: 21px;
        display: inline-block;
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(70, 148, 223, 1);
      }
    }
  }
}
</style>
