import XEUtils from "xe-utils/methods/xe-utils";
import { Utils, DomTools } from "../../tools";
import { Menus } from "../../v-x-e-table";
import { TableMenu } from "../../table/src/tableMenu";

export default {
  // 处理菜单的移动
  moveCtxMenu(evnt, keyCode, ctxMenuStore, property, operKey, operRest, menuList) {
    let selectItem;
    const selectIndex = XEUtils.findIndexOf(menuList, item => ctxMenuStore[property] === item);
    if (keyCode === operKey) {
      if (operRest && Utils.hasChildrenList(ctxMenuStore.selected)) {
        ctxMenuStore.showChild = true;
      } else {
        ctxMenuStore.showChild = false;
        ctxMenuStore.selectChild = null;
      }
    } else if (keyCode === 38) {
      for (let len = selectIndex - 1; len >= 0; len--) {
        if (menuList[len].visible !== false) {
          selectItem = menuList[len];
          break;
        }
      }
      ctxMenuStore[property] = selectItem || menuList[menuList.length - 1];
    } else if (keyCode === 40) {
      for (let index = selectIndex + 1; index < menuList.length; index++) {
        if (menuList[index].visible !== false) {
          selectItem = menuList[index];
          break;
        }
      }
      ctxMenuStore[property] = selectItem || menuList[0];
    } else if (ctxMenuStore[property] && (keyCode === 13 || keyCode === 32)) {
      this.ctxMenuLinkEvent(evnt, ctxMenuStore[property]);
    }
  },
  /**
   * 快捷菜单事件处理
   */
  handleGlobalContextmenuEvent(evnt) {
    const { isCtxMenu, ctxMenuStore, ctxMenuOpts } = this;
    const layoutList = [
      "header",
      "body",
      "footer"
    ];

    if (isCtxMenu) {
      if (ctxMenuStore.visible) {
        if (ctxMenuStore.visible && this.$refs.ctxWrapper && this.getEventTargetNode(evnt, this.$refs.ctxWrapper.$el).flag) {
          evnt.preventDefault();
          return;
        }
      }
      for (let index = 0; index < layoutList.length; index++) {
        const layout = layoutList[index];
        const columnTargetNode = this.getEventTargetNode(evnt, this.$el, `vxe-${layout}--column`);
        const params = {
          type: layout, $table: this, columns: this.visibleColumn.slice(0)
        };
        if (columnTargetNode.flag) {
          const cell = columnTargetNode.targetElem;
          const column = this.getColumnNode(cell).item;
          let typePrefix = `${layout}-`;
          Object.assign(params, {
            column, columnIndex: this.getColumnIndex(column), cell
          });
          if (layout === "body") {
            const row = this.getRowNode(cell.parentNode).item;
            typePrefix = "";
            params.row = row;
            params.rowIndex = this.getRowIndex(row);
            // add by xu
            this.setCurrentRow(params.rowIndex);
          }
          this.openContextMenu(evnt, layout, params);

          Utils.emitEvent(this, `${typePrefix}cell-menu-open`, [params, evnt]);
          return;
        } else if (this.getEventTargetNode(evnt, this.$el, `vxe-table--${layout}-wrapper`).flag) {
          if (ctxMenuOpts.trigger === "cell") {
            evnt.preventDefault();
          } else {
            this.openContextMenu(evnt, layout, params);
          }
          return;
        }
      }
    }
    this.closeMenu();
    this.closeFilter();
  },
  /**
   * 显示快捷菜单
   */
  openContextMenu(evnt, type, params) {
    const { ctxMenuStore, ctxMenuOpts } = this;
    const config = ctxMenuOpts[type];
    const visibleMethod = ctxMenuOpts.visibleMethod;
    if (config) {
      const { options, disabled } = config;
      if (disabled) {
        evnt.preventDefault();
      } else if (options && options.length) {
        params.options = options;
        // /处理剪切复制菜单的显示隐藏
        TableMenu.processRowCutPasteMenu(params);
        this.preventEvent(evnt, "event.show_menu", params, null, () => {
          if (!visibleMethod || visibleMethod(params, evnt)) {
            evnt.preventDefault();
            const { scrollTop, scrollLeft, visibleHeight, visibleWidth } = DomTools.getDomNode();
            const top = evnt.clientY + scrollTop;
            const left = evnt.clientX + scrollLeft;
            Object.assign(ctxMenuStore, {
              args: params,
              visible: true,
              list: options,
              selected: null,
              selectChild: null,
              showChild: false,
              style: {
                top: `${top}px`,
                left: `${left}px`
              }
            });
            this.$nextTick(() => {
              const ctxElem = this.$refs.ctxWrapper.$el;
              const clientHeight = ctxElem.clientHeight;
              const clientWidth = ctxElem.clientWidth;
              const offsetTop = evnt.clientY + clientHeight - visibleHeight;
              const offsetLeft = evnt.clientX + clientWidth - visibleWidth;
              if (offsetTop > -10) {
                ctxMenuStore.style.top = `${top - clientHeight}px`;
              }
              if (offsetLeft > -10) {
                ctxMenuStore.style.left = `${left - clientWidth}px`;
              }
            });
          } else {
            this.closeMenu();
          }
        });
      }
    }
    this.closeFilter();
  },
  ctxMenuMouseoverEvent(evnt, item, child) {
    const ctxMenuStore = this.ctxMenuStore;
    evnt.preventDefault();
    evnt.stopPropagation();
    ctxMenuStore.selected = item;
    ctxMenuStore.selectChild = child;
    if (!child) {
      ctxMenuStore.showChild = Utils.hasChildrenList(item);
    }
  },
  ctxMenuMouseoutEvent(evnt, item, child) {
    const ctxMenuStore = this.ctxMenuStore;
    if (!item.children) {
      ctxMenuStore.selected = null;
    }
    ctxMenuStore.selectChild = null;
  },
  /**
   * 快捷菜单点击事件
   */
  ctxMenuLinkEvent(evnt, menu) {
    if (!menu.disabled && (!menu.children || !menu.children.length)) {
      const ctxMenuMethod = Menus.get(menu.code);
      const params = Object.assign({ menu, $table: this }, this.ctxMenuStore.args);
      if (ctxMenuMethod) {
        ctxMenuMethod.call(this, params, evnt);
      }
      // /处理剪切复制菜单的点击事件
      TableMenu.processRowCutPasteMenuClick(params);

      if (this.menuConfig && this.menuConfig.changeMethod) {
        this.menuConfig.changeMethod(params, evnt);
      } else {
        Utils.emitEvent(this, "menu-click", [params, evnt]);
      }

      this.closeMenu();
    }
  }
};
