<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-10-22 11:55:23
 * @LastEditTime: 2019-11-01 11:25:55
 -->
<template>
  <div class="en-notification-config">
    <el-form
      ref="form"
      :model="tempNotificationData"
      :rules="rules"
      label-position="left"
      label-width="75px"
      class="form"
    >
      <el-form-item label="通知设置">
        <el-switch
          v-model="modelData.notificationEnable"
          class="switch"
          :active-value="0"
          :inactive-value="1"
        >
        </el-switch>
      </el-form-item>
      <el-form-item v-if="modelData.notificationEnable === 0" label="通知模板">
        <el-select
          v-model="currentSelectConnectOptions"
          placeholder="请选择关联模型"
          class="content-input"
          @change="handleSelectChange"
        >
          <el-option
            v-for="item in connectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <en-button
          v-if="needShowAddButton"
          icon="tianjia-anniutoubu"
          icon-color="fff"
          class="notification-add"
          @click="handleAddNotiModel"
        >
          添加
        </en-button>
      </el-form-item>
      <div v-if="modelData.notificationEnable === 0" class="notification-area">
        <en-notification-model
          v-for="(noti, index) in modelData.noti"
          :key="index"
          ref="notificationModel"
          class="notification-model"
          v-bind="$attrs"
          :notification-data="noti"
          @deleteModel="handleDeleteModel(index)"
        ></en-notification-model>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "EnNotificationConfig",
  data() {
    return {
      // 当前选中的关联模型
      currentSelectConnectOptions: "",
      // 当前写死的通知数据
      tempNotificationData: {
        // id:"",
        // 通知模板名称
        name: "",
        // 关联类型
        refType: 1,
        // 所属分类
        classifyId: "",
        // 通知类型
        type: 1,
        // 需要回执 0启用,1停用
        receiptEnable: 1,
        // 发送时间
        sendTimeEnable: 1,
        // 发送对象
        sendToEnable: 1,
        // 触发条件（0启用、1停用）
        conditionEnable: 1,
        // 通知内容（0启用、1停用）
        contentEnable: 1,
        // 通知方式(1:en+,2:短线,3:邮箱)
        method: 1,
        // 通知标题（0启用、1停用） ? 干啥的
        titleEnable: 1,
        // 通知元素（0启用、1停用）模型元素
        modelEnable: 0,
        // 模型对应的值? 默认值?
        defaultValue: {
          conditionConfig: [],
          conditionConfigStr: "",
          // 通知内容
          contentConfig: {},
          method: [], // 提交数据或者获取数据需要处理?
          notifyObj: {},
          notifyObjStr: "",
          operate: {
            buttonKey: "",
            dataMapping: [],
            name: "",
            operateType: "",
            refId: "",
            refName: ""
          },
          sendType: 1, // 1 触发条件后  2 流程结束后
          title: ""
        }
      },
      connectOptions: [
        {
          label: 1,
          value: 1
        }
      ],
      modelData: {
        notificationEnable: 1,
        noti: []
      }
    };
  },
  computed: {
    // 是否需要显示添加按钮,除了HR只支持单个(选中即变化)之外,基础\业务\流程都支持多个,需要点击添加.
    needShowAddButton() {
      return this.$attrs.type !== "HR";
    }
  },
  methods: {
    // 添加按钮添加模型
    handleAddNotiModel() {
      this.modelData.noti.push({ ...this.tempNotificationData });
    },
    // 删除模型
    handleDeleteModel(index) {
      this.modelData.noti.splice(index, 1);
    },
    // 选择的模型发生便便,只有当是HR时才需要此功能,因为是单个的 选完就联动
    handleSelectChange() {
      if (this.$attrs.type === "HR") {
        this.modelData.noti = [{ ...this.tempNotificationData }];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.en-notification-config {
  font-size: 12px;
  .switch {
    height: 32px;
  }
  .notification-model:nth-child(n + 2) {
    margin-top: 20px;
  }
  .el-form {
    .el-form-item {
      margin-bottom: 16px;
      max-width: 600px;

      & /deep/ .el-form-item__content {
        position: relative;
        display: flex;
        .notification-add {
          position: absolute;
          right: 0px;
          top: 0px;
        }

        .content-input {
          flex: 1;
          margin-right: 90px;
        }
      }
    }
  }
  text-align: left;
}
</style>
