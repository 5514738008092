<template>
  <td
    v-if="dataInfo.getCellRowSpan > 0 && dataInfo.getCellColSpan > 0"
    class="components-excel-cell"
    :rowSpan="dataInfo.getCellRowSpan"
    :colSpan="dataInfo.getCellColSpan"
    @click="cellClick"
    :style="{
      'text-align': dataInfo.getTextAlign,
      'font-weight': dataInfo.getFontWeight ? 'bold' : 'normal',
      'background-color': dataInfo.getBackGround,
      'color': dataInfo.getFontColor,
      'border-left-width': dataInfo.getLeftBorderWidth,
      'border-top-width': dataInfo.getTopBorderWidth,
      'border-right-width': dataInfo.getRightBorderWidth,
      'border-bottom-width': dataInfo.getBottomBorderWidth,
      'border-left-style': dataInfo.getLeftBorderStyle ? 'dashed': 'solid',
      'border-top-style': dataInfo.getTopBorderStyle ? 'dashed': 'solid',
      'border-right-style': dataInfo.getRightBorderStyle ? 'dashed': 'solid',
      'border-bottom-style': dataInfo.getBottomBorderStyle ? 'dashed': 'solid',
      'border-left-color': dataInfo.getLeftBorderColor,
      'border-top-color': dataInfo.getTopBorderColor,
      'border-right-color': dataInfo.getRightBorderColor,
      'border-bottom-color': dataInfo.getBottomBorderColor,
      'width':dataInfo.getCellWidth && dataInfo.getCellWidth > 0 ? dataInfo.getCellWidth + 'px' : null,
      'height':dataInfo.getCellHeight && dataInfo.getCellHeight > 0 ? dataInfo.getCellHeight + 'px' : null,
    }"
  >
    <el-input
      v-if="store.config.editAble"
      v-model="dataInfo.data.name"
    >
    </el-input>
    <span v-if="!store.config.editAble">
      {{ dataInfo.data.name }}
    </span>
    <en-icon
      v-if="store.config.showDelete && dataInfo.data.name"
      name="shanchu-liebiao"
      size="small"
      class="components-excel-cell-icon"
      @click.native.stop="removeCellDataInfo"
    >
    </en-icon>
  </td>
</template>

<script>
export default {
  name: "CellModel",
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    store: {}
  },
  methods: {
    /**
     * @description 清除单元格的数据
     */
    removeCellDataInfo() {
      this.dataInfo.removeDataInfo();
      this.store.clearSelectedCells();
    },
    /**
     * @description 单元格点击事件，执行回传，回传单元格对象
     */
    cellClick() {
      if (this.dataInfo.getDataInfo.id) {
        this.$emit("cellClick", this.dataInfo);
      }
    }
  }
};
</script>

<style lang="scss">
  .components-excel-cell {
    user-select: none;
    user-modify: none;
    height: 40px;
    line-height: 40px;
    position: relative;
    font-size: 12px;

    .el-input {
      width: 100%;
      height: 100%;

      .el-input__inner {
        width: 100%;
        height: calc(100% - 2px);
        border: none;
      }
    }

    .components-excel-cell-icon {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 16px;
      height: 16px;
      z-index: 3;
      cursor: pointer;
      display: none;
    }

    &.during {
      background-color: #4694df3b !important;
    }

    &:hover {
      .components-excel-cell-icon {
        display: block;
      }
    }
  }
</style>
