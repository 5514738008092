export default (
  '<div class="viewer-container" touch-action="none">' +
    '<div class="viewer-canvas"></div>' +
    '<div class="viewer-footer">' +
      '<div class="viewer-title"></div>' +
      '<div class="viewer-toolbar"></div>' +
      '<div class="viewer-navbar-wrapper">' +
        '<div class="viewer-list-icon viewer-list-left">' +
          '<svg aria-hidden="true" class="en-icon en-icon-prev-next"  style="width: 18px; height: 18px; color: #A9B5C6" data-viewer-action="left">' +
            '<use data-viewer-action="left" xlink:href="#icontupian-zuojiantou"></use>' +
          "</svg>" +
        "</div>" +
        '<div class="viewer-navbar">' +
          '<ul class="viewer-list"></ul>' +
        "</div>" +
        '<div class="viewer-list-icon viewer-list-right">' +
          '<svg aria-hidden="true" class="en-icon en-icon-prev-next"  style="width: 18px; height: 18px; color: #A9B5C6" data-viewer-action="right">' +
          '<use data-viewer-action="right" xlink:href="#icontupian-youjiantou"></use>' +
          "</svg>" +
        "</div>" +
      "</div>" +
    "</div>" +
    '<div class="viewer-tooltip"></div>' +
    '<div role="button" class="viewer-button" data-viewer-action="mix"></div>' +
    '<div class="viewer-player"></div>' +
  "</div>"
)
