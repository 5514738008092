// import XEUtils from "xe-utils/methods/xe-utils";
// import GlobalConfig from "../../conf"
import { Utils, DomTools } from "../../tools"
import FilterUtils from "./utils";
import PopupManager from "element-ui/lib/utils/popup/popup-manager";
// 主要提供事件方法
export default {
  filter(field, callback) {
    const column = this.getColumnByField(field)
    const data = column.filterSortStore ? column.filterSortStore.value : ""
    return Promise.resolve(data)
  },
  /**
   * 点击筛选事件
   */
  triggerFilterEvent(evnt, column, params) {
    const { $refs, filterStore } = this
    if (filterStore.column === column && filterStore.visible) {
      filterStore.visible = false
      return;
    }

    const filterWrapper = $refs.filterWrapper
    const { target: targetElem, pageX } = evnt
    const { visibleWidth } = DomTools.getDomNode()
    const { top, left } = DomTools.getAbsolutePos(targetElem)
    PopupManager.nextZIndex()
    PopupManager.nextZIndex()
    PopupManager.nextZIndex()
    console.log(PopupManager.zIndex)
    Object.assign(filterStore, {
      args: params,
      multiple: column.filterMultiple,
      options: column.filters,
      column: column,
      style: {
        // 当table放在dialog中时filterStore.zIndex为undefined导致过滤框展示不出来
        zIndex: PopupManager.zIndex,
        top: `${top + targetElem.clientHeight + 6}px`,
        left: `${left}px`
      },
      visible: true
    })

    this.$nextTick(() => {
      const filterWrapperElem = filterWrapper.$el
      const prevClientWidth = filterWrapperElem.clientWidth
      const handleUpdateStyle = () => {
        const clientWidth = filterWrapperElem.clientWidth
        let wrapperLeft = left - clientWidth / 2 + 10
        if (pageX + clientWidth > visibleWidth) {
          wrapperLeft = left - clientWidth
        }
        filterStore.style.left = `${Math.max(20, wrapperLeft + 20)}px`
        filterStore.style.top = `${top + targetElem.clientHeight + 6}px`
      }
      handleUpdateStyle();
      setTimeout(() => {
        if (column.slots && column.slots.filter && filterWrapperElem.clientWidth > prevClientWidth + 30) {
          handleUpdateStyle();
        }
      }, 300)
    })
  },
  // 确认筛选
  confirmFilterEvent() { // evnt
    const { filterStore } = this// visibleColumn,, scrollXLoad, scrollYLoad
    const { column } = filterStore
    column.filterSortStore.isConfirmed = true;
    // const filterList = []
    const type = "filter"

    // visibleColumn.filter(column => {
    //   const { filterSortStore } = column
    //   if (filterSortStore && filterSortStore.filterable && !XObject.isEmpty(filterSortStore.value)) {
    //     filterList.push(FilterUtils.getColumnFilterSortInfo(column, type))
    //   }
    // })
    // 记录 所有筛选条件
    filterStore.dataList = FilterUtils.getFilterSortList(this, column, type)
    filterStore.visible = false

    // 响应事件
    const filterParams = FilterUtils.getColumnFilterSortInfo(column, type, true, this)
    filterParams.$table = this

    if (column.filterSortMethod) {
      column.filterSortMethod(filterParams)// 列绑定 方法
    } else {
      Utils.emitEvent(this, "filter-sort-change", [filterParams])// 表格事件 @filter-sort-change
    }

    // this.updateFooter()
    // if (scrollXLoad || scrollYLoad) {
    //   this.clearScroll()
    //   if (scrollYLoad) {
    //     this.updateScrollYSpace()
    //   }
    // }
    this.closeFilter()
    // this.$nextTick(this.recalculate)
  },
  // 重置筛选(当前列)
  resetFilterEvent(evnt) {
    this.clearFilterOfColumn(this.filterStore.column)
  },
  clearFilter(field) {
    const column = arguments.length ? this.getColumnByField(field) : null
    this.clearFilterOfColumn(column)
  },
  clearFilterOfColumn(column, type) {
    const filterStore = this.filterStore
    const handleClear = column => {
      if (column.filterSortConfig) {
        if (!type) {
          column.filterSortStore.sort = column.filterSortConfig.sort;
          column.filterSortStore.value = column.filterSortConfig.value;
          column.filterSortStore.valueText = column.filterSortConfig.valueText;
        }
        if (type === "filter") {
          column.filterSortStore.value = column.filterSortConfig.value;
          column.filterSortStore.valueText = column.filterSortConfig.valueText;
        }
        if (type === "sort") {
          column.filterSortStore.sort = column.filterSortConfig.sort;
        }
        column.filterSortStore.isConfirmed = false;
      }
    }
    if (column) {
      handleClear(column)
    } else {
      this.visibleColumn.forEach(handleClear)
    }

    // 生成数据
    filterStore.dataList = FilterUtils.getFilterSortList(this, column, type, true)

    if (!column || column !== filterStore.column) {
      Object.assign(filterStore, {
        isAllSelected: false,
        isIndeterminate: false,
        style: undefined,
        column: undefined,
        visible: false
      })
    }

    // 响应事件
    const filterParams = column ? FilterUtils.getColumnFilterSortInfo(column, type, true, this, true) : {};
    if (column && column.filterSortMethod) { // 先判断情况操作
      column.filterSortMethod(filterParams)// 列绑定 方法
    } else {
      filterParams.$table = this
      Utils.emitEvent(this, "filter-sort-change", [filterParams])// 表格事件 @filter-sort-change
    }
    // return this.updateData()
  }

}
