
/**
 * project: enfry-components
 * fileName: cellUtils
 * Created by 徐俊 on 2019/10/12
 * Copyright © 2019 Enfry Ltd. All rights reserved.
 */

import GlobalConfig from "../../conf";
import XEUtils from "xe-utils/methods/xe-utils";

const CellUtils = {
  // 行主键 key
  getRowkey($table) {
    return $table.rowId
  },
  // 行主键 value
  getRowid($table, row) {
    const rowId = XEUtils.get(row, CellUtils.getRowkey($table))
    return rowId ? encodeURIComponent(rowId) : ""
  },
  getFuncText(content) {
    return XEUtils.isFunction(content) ? content() : (GlobalConfig.translate ? GlobalConfig.translate(content) : content)
  },
  formatText(value, placeholder) {
    return "" + (value === null || value === void 0 ? (placeholder ? GlobalConfig.emptyCell : "") : value)
  },
  getCellValue(row, column) {
    return XEUtils.get(row, column.property)
  },
  getCellLabel(row, column, params) {
    const { formatter } = column
    const cellValue = CellUtils.getCellValue(row, column)
    let cellLabel = cellValue
    if (params && formatter) {
      let rest, formatData
      const { $table } = params
      const colid = column.id
      const cacheFormat = $table && $table.fullAllDataRowMap.has(row)
      if (cacheFormat) {
        rest = $table.fullAllDataRowMap.get(row)
        formatData = rest.formatData
        if (!formatData) {
          formatData = $table.fullAllDataRowMap.get(row).formatData = {}
        }
      }
      if (rest && formatData[colid]) {
        if (formatData[colid].value === cellValue) {
          return formatData[colid].label
        }
      }
      if (XEUtils.isString(formatter)) {
        cellLabel = XEUtils[formatter](cellValue)
      } else if (XEUtils.isArray(formatter)) {
        cellLabel = XEUtils[formatter[0]].apply(XEUtils, [cellValue].concat(formatter.slice(1)))
      } else {
        cellLabel = formatter(Object.assign({ cellValue }, params))
      }
      if (formatData) {
        formatData[colid] = {
          value: cellValue,
          label: cellLabel
        }
      }
    }
    return cellLabel
  },
  setCellValue(row, column, value) {
    return XEUtils.set(row, column.property, value)
  }
}

export default CellUtils
