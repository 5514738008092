<!--
 * @Description: file content
 * @Date: 2020-04-28 17:28:10
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-04-28 17:37:44
 * @FilePath: /en-components/packages/advanceComponents/en-data-export/src/drop-select/index.vue
 -->
<template>
  <div class="drop-select-contain">
    <span class="title">{{ title }}</span>
    <el-select
      v-model="selectValue"
      placeholder="请选择"
      size="small"
      @change="selectValueChange"
    >
      <el-option
        v-for="item in optionList"
        :key="item.id"
        :value="item.id"
        :label="item.name"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: "DropSelect",
  props: {
    title: {
      type: String,
      default: "默认名称"
    },
    optionList: {
      type: Array,
      default: function() {
        return [{}];
      }
    }
  },
  data() {
    return { selectValue: "" };
  },
  watch: {
    selectValue(val) {
      console.log(val);
    }
  },
  methods: {
    selectValueChange(value) {
      this.$emit("getDropSelectValue", value);
    }
  }
};
</script>

<style lang="scss" scoped>
.drop-select-contain {
  padding: 0px;
  .title {
    display: inline-block;
    line-height: 30px;
    font-size: 12px;
    height: 30px;
    width: 60px;
    color: #333;
    font-weight: 400;
    font-family: MicrosoftYaHei;
  }
}
.drop-select-contain /deep/ .el-input {
  width: 600px;
}
.drop-select-contain /deep/ .el-input__inner {
  padding: 0px 8px;
  line-height: 30px;
  font-size: 12px;
  height: 32px !important;
  font-weight: normal;
  color: #333;
}
</style>

<style lang="css" scoped>
.el-select-dropdown__item {
  line-height: 30px;
  font-size: 12px;
  height: 30px;
  font-weight: normal;
  color: #333;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
</style>

