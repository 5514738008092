<template>
  <div :id="domId" ref="barYPercent"></div>
</template>

<script>
const elementResizeDetectorMaker = require("element-resize-detector");
export default {
  name: "BarYPercent",
  props: {
    store: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      chartsInfo: {},
      seriesData: [],
      isDone: false
    };
  },
  mounted() {
    if (this.erd) {
      return;
    }
    const erd = elementResizeDetectorMaker();
    const that = this;
    this.erd = erd.listenTo(document.getElementById(this.domId), function(
      element
    ) {
      that.resizeCharts();
    });
  },
  unmounted() {
    if (this.erd) {
      this.erd.uninstall(document.getElementById(this.domId));
    }
  },
  computed: {
    domId() {
      return "bar_y_percent_" + (Math.random() * 90000000000).toFixed(0);
    }
  },
  created() {
    this.$nextTick(() => {
      this.checkApply();
      window.addEventListener("scroll", this.checkApply, true);
      window.addEventListener("resize", this.resizeCharts, true);
    });
  },
  methods: {
    /**
     * @description 检测图表是否需要生成
     */
    checkApply() {
      if (!this.isDone && this.store.showCharts(this.$refs.barYPecent)) {
        this.isDone = true;
        this.initChartsInfo();
      }
    },
    /**
     * @description 初始化图表DOM信息
     */
    initChartsInfo() {
      this.chartsInfo = window.$charts.init(
        document.getElementById(this.domId)
      );
      this.dataFormat();
    },
    /**
     * @description 图表数据整理
     */
    dataFormat() {
      const _this = this;
      const sumList = [];
      this.store.getYData.map((item, index) => {
        let sum = 0;
        this.store.getXData.map(cItem => {
          sum += parseFloat(cItem[_this.store.getDataKey][index]);
        });
        sumList.push(sum);
      });
      this.store.getXData.map(cItem => {
        const arr = [];
        sumList.map((item, index) => {
          arr.push(
            ((cItem[_this.store.getDataKey][index] / item) * 100).toFixed(2)
          );
        });
        this.$set(cItem, "result", arr);
      });
      this.store.getXData.map(item => {
        const obj = {
          name: _this.store.getDataLabel
            ? item[_this.store.getDataLabel]
            : item,
          type: "bar",
          data: item.result,
          barMaxWidth: "20px",
          itemStyle: _this.store.getYBarStyle
        };
        this.seriesData.push(obj);
      });
      this.initChartsDataInfo();
    },
    /**
     * @description 图表绘制及数据渲染
     */
    initChartsDataInfo() {
      const _this = this;
      const tooltip = _this.store.getToolTip;
      if (tooltip.trigger === "axis") {
        if (_this.store.originalShow === true) {
          tooltip.formatter = val => {
            let tips = '<div class="title">' + val[0].axisValueLabel + "</div>";
            val.map(item => {
              const originaValue =
                _this.seriesData[item.seriesIndex].originalData[item.dataIndex];
              tips +=
                "<div class='tips-item'>" +
                item.marker +
                item.seriesName +
                ":" +
                item.data +
                "%" +
                "(" +
                originaValue +
                ")" +
                "</div>";
            });
            return tips;
          };
        } else {
          tooltip.formatter = val => {
            let tips = '<div class="title">' + val[0].axisValueLabel + "</div>";
            val.map(item => {
              tips +=
                "<div class='tips-item'>" +
                item.marker +
                item.seriesName +
                ":" +
                item.data +
                "%" +
                "</div>";
            });
            return tips;
          };
        }
      } else {
        if (_this.store.originalShow === true) {
          tooltip.formatter = val => {
            const originaValue =
              _this.seriesData[val.seriesIndex].originalData[val.dataIndex];
            const tips =
              "<div class='title'>" +
              val.name +
              "</div>" +
              "<div class='tips-item'>" +
              val.marker +
              val.seriesName +
              ":" +
              val.data +
              "%" +
              "(" +
              originaValue +
              ")" +
              "</div>";
            return tips;
          };
        } else {
          tooltip.formatter = val => {
            const tips =
              "<div class='title'>" +
              val.name +
              "</div>" +
              "<div class='tips-item'>" +
              val.marker +
              val.seriesName +
              ":" +
              val.data +
              "%" +
              "</div>";
            return tips;
          };
        }
      }
      // if (tooltip.trigger === "axis") {
      //   tooltip.formatter = val => {
      //     let tips = '<div class="title">' + val[0].axisValueLabel + "</div>";
      //     val.map(item => {
      //       tips +=
      //       "<div class='tips-item'>" +
      //       item.marker +
      //       item.seriesName +
      //       ":" +
      //       item.data +
      //       "%" +
      //       "</div>";
      //     });
      //     return tips;
      //   };
      // } else {
      //   tooltip.formatter = val => {
      //     const tips = "<div class='title'>" + val.name + "</div>" +
      //     "<div class='tips-item'>" + val.marker + val.seriesName + ":" + val.data + "%" + "</div>";
      //     return tips;
      //   };
      // }
      _this.chartsInfo.setOption(
        {
          tooltip: tooltip,
          title: _this.store.getTitleInfo,
          yAxis: _this.store.getAxisConfig,
          legend: _this.store.getLegendConfig,
          xAxis: _this.store.getPercentAxis,
          color: _this.store.getColorList,
          grid: _this.store.getGridConfig,
          series: _this.seriesData,
          animationEasing: "elasticOut",
          animationDelayUpdate: function(idx) {
            return idx * 5;
          }
        },
        true
      );
      // 点击事件处理
      _this.chartsInfo.on("click", params => {
        _this.$emit("change", {
          xIndex: [params.componentIndex],
          yIndex: [params.dataIndex],
          params: params,
          totalData: _this.seriesData
        });
      });
    },
    /**
     * @description 窗口尺寸变化，重绘图表
     */
    resizeCharts() {
      if (!this.isDone) {
        return;
      }
      this.chartsInfo.resize();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkApply, false);
    window.removeEventListener("resize", this.resizeCharts, false);
  }
};
</script>

<style lang="scss"></style>
