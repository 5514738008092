<template>
  <div :id="domId" class="bar-percent" ref="barXPercent"></div>
</template>

<script>
const elementResizeDetectorMaker = require("element-resize-detector");
export default {
  name: "BarXPercent",
  props: {
    store: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      chartsInfo: {},
      seriesData: [],
      isDone: false
    };
  },
  mounted() {
    if (this.erd) {
      return;
    }
    const erd = elementResizeDetectorMaker();
    const that = this;
    this.erd = erd.listenTo(document.getElementById(this.domId), function(
      element
    ) {
      that.resizeCharts();
    });
  },
  unmounted() {
    if (this.erd) {
      this.erd.uninstall(document.getElementById(this.domId));
    }
  },
  computed: {
    domId() {
      return "bar_x_percent_" + (Math.random() * 90000000000).toFixed(0);
    }
  },
  created() {
    this.$nextTick(() => {
      this.checkApply();
      window.addEventListener("scroll", this.checkApply, true);
      window.addEventListener("resize", this.resizeCharts, true);
    });
  },
  methods: {
    /**
     * @description 检测图表是否需要生成
     */
    checkApply() {
      if (!this.isDone && this.store.showCharts(this.$refs.barXPercent)) {
        this.isDone = true;
        this.initChartsInfo();
      }
    },
    /**
     * @description 初始化图表信息
     */
    initChartsInfo() {
      this.chartsInfo = window.$charts.init(
        document.getElementById(this.domId)
      );
      this.dataFormat();
      this.initChartsDataInfo();
    },
    /**
     * @description 百分比图需要对数据进行处理
     */
    dataFormat() {
      const flag = this.store.getYDataType === "object";
      const _this = this;
      if (flag) {
        const sumList = [];
        this.store.getXData.map((item, index) => {
          let sum = 0;
          this.store.getYData.map(cItem => {
            sum += parseFloat(cItem[_this.store.getDataKey][index]);
          });
          sumList.push(sum);
        });
        this.store.getYData.map(cItem => {
          const arr = [];
          const originalArr = [];
          sumList.map((item, index) => {
            arr.push(
              ((cItem[_this.store.getDataKey][index] / item) * 100).toFixed(2)
            );
            originalArr.push(cItem[_this.store.getDataKey][index]);
          });
          this.$set(cItem, "result", arr);
          this.$set(cItem, "originalData", originalArr);
        });
        this.store.getYData.map(item => {
          const obj = {
            name: _this.store.getDataLabel
              ? item[_this.store.getDataLabel]
              : item,
            type: "bar",
            data: item.result,
            originalData: item.originalData,
            barMaxWidth: "20px",
            itemStyle: _this.store.getXBarStyle
          };
          this.seriesData.push(obj);
        });
      }
    },
    /**
     * @description 初始化图表及图表数据信息
     */
    initChartsDataInfo() {
      const _this = this;
      const tooltip = _this.store.getToolTip;
      if (tooltip.trigger === "axis") {
        if (_this.store.originalShow === true) {
          tooltip.formatter = val => {
            let tips = '<div class="title">' + val[0].axisValueLabel + "</div>";
            val.map(item => {
              const originaValue =
                _this.seriesData[item.seriesIndex].originalData[item.dataIndex];
              tips +=
                "<div class='tips-item'>" +
                item.marker +
                item.seriesName +
                ":" +
                item.data +
                "%" +
                "(" +
                originaValue +
                ")" +
                "</div>";
            });
            return tips;
          };
        } else {
          tooltip.formatter = val => {
            let tips = '<div class="title">' + val[0].axisValueLabel + "</div>";
            val.map(item => {
              tips +=
                "<div class='tips-item'>" +
                item.marker +
                item.seriesName +
                ":" +
                item.data +
                "%" +
                "</div>";
            });
            return tips;
          };
        }
      } else {
        if (_this.store.originalShow === true) {
          tooltip.formatter = val => {
            const originaValue =
              _this.seriesData[val.seriesIndex].originalData[val.dataIndex];
            const tips =
              "<div class='title'>" +
              val.name +
              "</div>" +
              "<div class='tips-item'>" +
              val.marker +
              val.seriesName +
              ":" +
              val.data +
              "%" +
              "(" +
              originaValue +
              ")" +
              "</div>";
            return tips;
          };
        } else {
          tooltip.formatter = val => {
            const tips =
              "<div class='title'>" +
              val.name +
              "</div>" +
              "<div class='tips-item'>" +
              val.marker +
              val.seriesName +
              ":" +
              val.data +
              "%" +
              "</div>";
            return tips;
          };
        }
      }

      _this.chartsInfo.setOption(
        {
          tooltip: tooltip,
          title: _this.store.getTitleInfo,
          xAxis: _this.store.getAxisConfig,
          legend: _this.store.getLegendConfig,
          yAxis: _this.store.getPercentAxis,
          color: _this.store.getColorList,
          grid: _this.store.getGridConfig,
          series: _this.seriesData,
          animationEasing: "elasticOut",
          animationDelayUpdate: function(idx) {
            return idx * 5;
          }
        },
        true
      );
      // 点击事件处理
      _this.chartsInfo.on("click", params => {
        _this.$emit("change", {
          xIndex: [params.componentIndex],
          yIndex: [params.dataIndex],
          params: params,
          totalData: _this.seriesData
        });
      });
    },
    /**
     * @description 窗口尺寸变化，重绘图表
     */
    resizeCharts() {
      if (!this.isDone) {
        return;
      }
      this.chartsInfo.resize();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkApply, false);
    window.removeEventListener("resize", this.resizeCharts, false);
  }
};
</script>

<style lang="scss"></style>
