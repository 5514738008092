/* eslint-disable */

const GlobalConfig = {
  // showOverflow: null,
  // showHeaderOverflow: null,
  // resizeInterval: 250,
  // size: null,
  // validConfig: {
  //   message: 'default'
  // },
  // resizable: false,
  // stripe: false,
  // border: false,
  fit: true,
  emptyCell: "　",
  showHeader: true,
  rowId: "id", // 行数据的唯一主键字段名$rowId
  version: 0, // 版本号，对于某些带数据缓存的功能有用到，上升版本号可以用于重置数据
  optimization: {
    animat: true,
    scrollX: {
      gt: 35//最大的列数
      // oSize: 0,
      // rSize: 0
      // vSize: 0
    },
    scrollY: {
      gt: 100,//最大的行数
      // oSize: 0,
      // rSize: 200
      // vSize: 0,
      // rHeight: 0
    }
  },
  icon: {
    sortAsc: "vxe-icon--caret-top",
    sortDesc: "vxe-icon--caret-bottom",
    filter: "vxe-icon--funnel",
    edit: "vxe-icon--edit-outline",
    tree: "vxe-icon--caret-right",
    treeExpand: "vxe-icon--caret-right",
    refresh: "vxe-icon--refresh",
    custom: "vxe-icon--menu",
    jumpPrev: "vxe-icon--d-arrow-left",
    jumpNext: "vxe-icon--d-arrow-right",
    prevPage: "vxe-icon--arrow-left",
    nextPage: "vxe-icon--arrow-right",
    zoomIn: "vxe-icon--zoomin",
    zoomOut: "vxe-icon--zoomout",
    modalClose: "vxe-icon--close",
    modalInfo: "vxe-icon--info",
    modalSuccess: "vxe-icon--success",
    modalWarning: "vxe-icon--warning",
    modalError: "vxe-icon--error",
    modalQuestion: "vxe-icon--question",
    modalLoading: "vxe-icon--refresh roll",
    caretBottom: "vxe-icon--caret-bottom",
    dropdownBottom: "vxe-icon--arrow-bottom"
  },
  menu: {},
  tooltip: {
    trigger: "hover",
    theme: "dark"
  },
  i18n: key => key
}

export default GlobalConfig
