/**
 * project: enfry-components
 * fileName: utils
 * Created by 徐俊 on 2020/08/05
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import XEUtils from "xe-utils/methods/xe-utils";
import ColumnConfig from "./columnConfig"

export const ColumnUtils = {
  createColumn(_vm, $table) {
    // _vm为column
    const renMaps = _vm.createRenderMap($table);
    const columnConfig = ColumnUtils.getColumnConfig(_vm, renMaps);
    // 默认值处理 (add by xu)
    columnConfig.refreshColumnDefaultValueIfNeed();
    return columnConfig;
  },
  createColumnWithHeadData(headData) {
    const column = new ColumnConfig();
    column.data = headData;
    column.refreshProps();
    column.own = {};
    return column;
  },
  getColumnConfig(_vm, options) {
    return _vm instanceof ColumnConfig ? _vm : new ColumnConfig(_vm, options)
  },
  getColumnId(colIndex) {
    return `col_${++colIndex}`
  },
  getColumnIndex(colid) {
    return colid ? Number(colid.replace("col_", "")) : undefined
  },
  // 获取所有的列，排除分组
  getColumnList(columns) {
    const result = []
    columns.forEach(column => {
      result.push.apply(result, column.children && column.children.length ? ColumnUtils.getColumnList(column.children) : [column])
    })
    return result
  },
  // 组装列配置
  assemColumn(_vm) {
    const { $table, $parent = {}, columnConfig } = _vm
    // 递归写的时候出现问题,故这么写 或者while找咯
    const parentColumnConfig = $parent.columnConfig ? $parent.columnConfig : ($parent.$parent || {}).columnConfig
    columnConfig.slots = _vm.$scopedSlots

    if (columnConfig.slots && columnConfig.slots.edit) {
      columnConfig.refreshRenderMap()
    }

    if (parentColumnConfig && $parent.$children.length > 0) {
      if (!parentColumnConfig.children) {
        parentColumnConfig.children = []
      }
      // 这样写会导致动态列有children是渲染错列
      // parentColumnConfig.children.splice([].indexOf.call($parent.$el.children, _vm.$el), 0, columnConfig)
      parentColumnConfig.children.push(columnConfig)
    } else {
      $table.collectColumn.splice([].indexOf.call($table.$refs.hideColumn.children, _vm.$el), 0, columnConfig)
    }
  },
  // 销毁列
  destroyColumn(_vm) {
    const { $table, columnConfig } = _vm
    const matchObj = XEUtils.findTree($table.collectColumn, column => column === columnConfig)
    if (matchObj) {
      matchObj.items.splice(matchObj.index, 1)
    }
  },
  registerNormalWatchers(_vm) {
    // "filterSortConfig",
    const props = ["data", "label", "property", "type", "filterSortMethod", "formatter", "showOverflowTooltip"]
    // 一些属性具有别名
    const aliases = {
      prop: "property",
      field: "property",
      label: "title"
    }
    const allAliases = props.reduce((prev, cur) => {
      prev[cur] = cur
      return prev
    }, aliases)

    Object.keys(allAliases).forEach(key => {
      const columnKey = aliases[key]

      _vm.$watch(key, (newVal, oldVal) => {
        _vm.columnConfig[columnKey] = newVal

        if (columnKey === "type") {
          console.warn(`可能您使用了v-if, 复用table, 可能导致显示异常, 不确保所有情况兼容, 建议在en-table 上绑定key, 例如"<en-table key="table1">...</en-table>" `)
          _vm.updateColumnConfig()
          _vm.$table.$nextTick(_vm.$table.resetAll)
        } else if (columnKey === "data") {
          _vm.columnConfig.refreshProps();
        }
        // else if (columnKey === "filterSortConfig") {
        // _vm.columnConfig.refreshColumnDefaultValueIfNeed()
        // }
      })
    })
  },

  registerComplexWatchers(_vm) {
    const props = ["fixed", "width", "minWidth"]
    const aliases = {
      // realWidth: 'width',
      // realMinWidth: 'minWidth'
    }
    const allAliases = props.reduce((prev, cur) => {
      prev[cur] = cur
      return prev
    }, aliases)

    Object.keys(allAliases).forEach(key => {
      const columnKey = aliases[key]

      _vm.$watch(key, (newVal) => {
        console.warn(`可能您使用了v-if, 复用table, 可能导致显示异常, 不确保所有情况兼容, 建议在en-table 上绑定key, 例如"<en-table key="table1">...</en-table>" `)
        _vm.columnConfig[columnKey] = newVal
        // const updateColumns = columnKey === 'fixed';
        _vm.$table.$nextTick(_vm.$table.resetAll)
      })
    })
  }

}

export default ColumnUtils
