<!--
 * @Description: 步骤设置
 * @Date: 2020-04-28 17:28:10
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-04-29 17:57:39
 * @FilePath: /en-components/packages/components/en-step-config/src/index.vue
 -->
<template>
  <div class="en-step-config">
    <div class="step-content">
      <div
        v-for="(item, index) in steps"
        :key="index"
        class="step"
        @click="handleStepClick(index)"
      >
        <span
          class="number"
          :class="{ 'number-select': index === selectIndex }"
        >
          {{ index + 1 }}
        </span>
        <span
          class="step-name"
          :class="{ 'step-name-select': index === selectIndex }"
        >
          {{ item.name }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "EnStepConfig",
  props: {
    selectIndex: {
      type: [Number, String],
      default: 0
    },
    // 数据...[{},{}}]
    steps: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  methods: {
    handleStepClick(index) {
      this.$emit("update:selectIndex", index);
      this.$emit("stepDidChange", index, this.steps[index]);
    }
  }
};
</script>
<style lang="scss" scoped>
.en-step-config {
  display: inline-block;
  padding-right: 30px;
  .step-content {
    height: 100%;
    display: flex;
    align-items: center;
    .step {
      margin-left: 30px;
      text-align: center;
      user-select: none;
      .number {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        line-height: 20px;
        background-color: #a9b5c6;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
      }
      .number.number-select {
        background-color: #4694df;
      }
      .step-name {
        margin-left: 6px;
        color: #fff;
        font-size: 13px;
      }
      .step-name.step-name-select {
        color: #4694df;
      }
    }
  }
}
</style>
