/**
 * @Author 李煜
 * @Date 2020/7/1
 * @Description select option node
 */
export const isObject = (val) => Object.prototype.toString.call(val) === "[object Object]";

/**
 * 获取组件传递的props属性
 * @param node
 * @param prop
 * @returns {*}
 */
const getPropertyFromData = function(node, prop) {
  const props = node.store.props;
  const data = node.data || {};
  const config = props[prop];

  if (typeof config === "function") {
    return config(data, node);
  } else if (typeof config === "string") {
    return data[config];
  } else if (typeof config === "undefined") {
    const dataProp = data[prop];
    return dataProp === undefined ? "" : dataProp;
  }
};

export default class Node {
  constructor(options) {
    this.text = null;
    this.checked = false;
    this.data = null;
    this.visible = true;
    this.isCurrent = false;

    for (const name in options) {
      if (options.hasOwnProperty(name)) {
        this[name] = options[name];
      }
    }

    this.loaded = false;
    this.showAssist = false;

    const store = this.store;
    if (!store) {
      throw new Error("[Node]store is required!");
    }
  }

  get label() {
    return getPropertyFromData(this, "label");
  }

  get value() {
    return getPropertyFromData(this, "value");
  }

  get assist() {
    return getPropertyFromData(this, "assist");
  }

  get title() {
    return getPropertyFromData(this, "title");
  }

  get thum() {
    return getPropertyFromData(this, "thum");
  }

  get thumText() {
    const text = getPropertyFromData(this, "thumText");
    if (text && Object.prototype.toString.call(text) === "[object String]") {
      return text.substr(0, 1);
    }
    return null;
  }

  get thumBackgroundColor() {
    return getPropertyFromData(this, "thumBackgroundColor");
  }

  get color() {
    return getPropertyFromData(this, "color");
  }

  get hide() {
    return getPropertyFromData(this, "hide");
  }

  get key() {
    const nodeKey = this.store.key;
    if (this.data) return this.data[nodeKey];
    return null;
  }

  get disabled() {
    return getPropertyFromData(this, "disabled") || false;
  }

  setChecked(value) {

  }
}
