<template>
  <div :id="domId" ref="pieRing"></div>
</template>

<script>
const elementResizeDetectorMaker = require("element-resize-detector");
export default {
  name: "PieRing",
  props: {
    store: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      chartsInfo: {},
      seriesData: [],
      isDone: false
    };
  },
  mounted() {
    if (this.erd) {
      return;
    }
    const erd = elementResizeDetectorMaker();
    const that = this;
    this.erd = erd.listenTo(document.getElementById(this.domId), function(
      element
    ) {
      that.resizeCharts();
    });
  },
  unmounted() {
    if (this.erd) {
      this.erd.uninstall(document.getElementById(this.domId));
    }
  },
  computed: {
    domId() {
      return "pie_ring_" + (Math.random() * 90000000000).toFixed(0);
    }
  },
  created() {
    this.$nextTick(() => {
      this.checkApply();
      window.addEventListener("scroll", this.checkApply, true);
      window.addEventListener("resize", this.resizeCharts, true);
    });
  },
  methods: {
    /**
     * @description 检测图表是否需要生成
     */
    checkApply() {
      if (!this.isDone && this.store.showCharts(this.$refs.pieRing)) {
        this.isDone = true;
        this.initChartsInfo();
      }
    },
    /**
     * @description 图表初始化
     */
    initChartsInfo() {
      this.chartsInfo = window.$charts.init(
        document.getElementById(this.domId)
      );
      this.dataFormat();
    },
    /**
     * @description 图表数据处理
     */
    dataFormat() {
      const _this = this;
      const obj = {
        name: this.store.getStackLabel,
        type: "pie",
        radius: ["30%", "75%"],
        center: ["50%", "45%"],
        avoidLabelOverlap: false,
        labelLine: { show: true },
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        },
        label: {
          normal: {
            show: true,
            position: "outOfBrush",
            // textStyle: {
            //   fontSize: "14",
            //   fontWeight: "normal"
            // },
            formatter: params => {
              if (_this.store.showPieLineValue) {
                return params.data.name + "\n" + params.data.value;
              } else {
                return params.data.name;
              }
            }
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "16",
              fontWeight: "bold"
            }
          }
        }
      };
      this.store.getXData.map(item => {
        this.$set(item, "name", item[this.store.getDataLabel]);
        this.$set(item, "value", item[this.store.getDataKey]);
        obj.data.push(item);
      });
      this.seriesData.push(obj);
      this.initChartsDataInfo();
    },
    /**
     * @description 执行图表生成
     */
    initChartsDataInfo() {
      const _this = this;
      _this.chartsInfo.setOption(
        {
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)"
          },
          title: _this.store.getTitleInfo,
          legend: _this.store.getPieLegendDataInfo,
          color: _this.store.getColorList,
          series: _this.seriesData
        },
        true
      );
      // 点击事件处理
      _this.chartsInfo.on("click", params => {
        _this.$emit("change", {
          xIndex: [params.componentIndex],
          yIndex: [params.dataIndex],
          params: params,
          totalData: _this.seriesData
        });
      });
    },
    /**
     * @description 窗口尺寸变化，重绘图表
     */
    resizeCharts() {
      if (!this.isDone) {
        return;
      }
      this.chartsInfo.resize();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkApply, false);
    window.removeEventListener("resize", this.resizeCharts, false);
  }
};
</script>

<style lang="scss"></style>
