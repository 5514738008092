/**
 * project: enfry-components
 * fileName: object
 * Created by 徐俊 on 2019/12/09
 * Copyright © 2019 Enfry Ltd. All rights reserved.
 */

// 数组部分
export const XObject = {

  /**
   * 判断是否为空对象  null undefined 空字符 空数组 等等
   * @param obj
   * @returns {boolean}
   */
  isEmpty(obj) {
    if (!obj) {
      return true;
    }

    if (obj instanceof String || obj instanceof Array) {
      return obj.length === 0;
    } else if (obj instanceof Date) {
      return false;
    }

    for (const key in obj) {
      return false;
    }
    return true;
  },
  valueForKey(obj, key) {
    if (!obj || !key || typeof key !== "string") { return undefined; }

    if (obj instanceof Array) {
      const array = [];
      for (const value of obj) {
        const tmp = this.valueForKey(value, key);
        if (tmp !== undefined) {
          array.push(tmp);
        }
      }
      return array.length > 0 ? array : undefined;
    } else if (obj instanceof Object) {
      return obj[key];
    }
    return undefined;
  },

  /**
   * 获得对象里面值(源于iOS, 目前仅实现最简单的基本功能, 非常强大与实用的功能)
   * @param obj 对象
   * @param keyPath 路径,如"data.text"
   * @returns {*}返回值
   */
  valueForKeyPath(obj, keyPath) {
    if (!obj || !keyPath || typeof keyPath !== "string") { return undefined; }
    const array = keyPath.split(".");
    let value;
    for (const key of array) {
      value = obj = this.valueForKey(obj, key);
      if (!obj) { return undefined; }
    }
    return value;
  }

};

export default XObject;
