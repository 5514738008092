<template>
  <div class="import-contain">
    <!-- 导航栏 start -->
    <div class="head-contain">
      <div class="back-contain">
        <en-icon
          v-if="!!needBack"
          class="icon"
          name="fanhui"
          size="18px"
          :style="{ fill: '#A9B5C6' }"
          @click.native="goBack"
        ></en-icon>
        <div class="title">
          导入数据
        </div>
      </div>

      <div class="step-contain">
        <en-step-list :step-options="stepOptions" :current-step="stepIndex" />
      </div>

      <div class="right-button-contain">
        <el-button
          class="next-button"
          size="small"
          type="primary"
          @click="nextClick"
        >
          {{ nextButtonText }}
        </el-button>

        <el-button
          v-show="previousShow"
          class="previous-button"
          size="small"
          plain
          @click="previousClick"
        >
          上一步
        </el-button>
      </div>
    </div>
    <!-- 导航栏 end -->

    <!-- 主页面 start -->
    <div class="body-contain">
      <template v-if="stepIndex === 1">
        <firstStep />
      </template>

      <template v-else-if="stepIndex === 2">
        <secondStep />
      </template>

      <template v-else>
        <thirdStep />
      </template>
    </div>
    <!-- 主页面 end -->
  </div>
</template>

<script>
import firstStep from "./first-step";
import secondStep from "./second-step";
import thirdStep from "./third-step";

export default {
  name: "EnDataImport",
  components: {
    firstStep,
    secondStep,
    thirdStep
  },
  props: {
    needBack: { // 返回按钮
      type: Boolean,
      default: true
    },
    templateId: {// 模板id
      type: String,
      default: ""
    },
    templateName: { // 模板名称
      type: String,
      default: ""
    }
  },
  data() {
    return {
      stepIndex: 1,
      previousShow: false,
      nextButtonText: "下一步",
      stepOptions: [
        "上传文件",
        "处理及预览",
        "处理及预览"
      ],
      dialogVisible: false,
      dialogTipsVisible: false,
      dialogLogVisible: false,
      hasFile: false // 是否已经上传文件
    };
  },
  methods: {
    nextClick() {
      // 判断是否已经上传文件
      if (!this.hasFile) {
        this.$message("请先上传文件");
        return;
      }
      if (this.stepIndex === 3) {
        this.stepIndex = 0;
      }
      this.stepIndex += 1;
      this.previousShow = this.stepIndex === 2;
      this.nextButtonText = this.stepIndex === 3 ? "继续导入" : "下一步";
    },
    previousClick() {
      this.previousShow = false;
      this.stepIndex = 1;
      this.dialogLogVisible = true;
      this.openOperateLog();
    },
    stepListClick() {
      this.dialogTipsVisible = false;
      this.dialogTipsVisible = true;
    },
    getEnDialogVisible(val) {
      this.dialogVisible = val;
    },
    getEnDialogConfirm() {
      console.log("getEnDialogConfirm");
    },
    getEnTipsDialogConfirm() {
      console.log("getEnTipsDialogConfirm");
    },
    openOperateLog() {
      this.$refs.myDialogLog.open();
    },
    /**
     * @description:
     * @param {type}
     * @return:
     */
    goBack() {
      history.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.import-contain {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;

  .head-contain {
    background-color: #fff;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    .back-contain {
      height: 100%;
      display: flex;
      align-items: center;
      .icon {
        margin-left: 12px;
      }
      .title {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #333;
      }
    }

    .right-button-contain {
      padding-right: 20px;
      height: 100%;
      width: 180px;
      .previous-button {
        float: right;
        margin-top: 14px;
        margin-right: 6px;
        width: 72px;
        height: 32px;

        border-radius: 4px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #636c78 !important;
        background: #fff !important;
        border: 1px solid #e8ecf2 !important;
      }
      .next-button {
        float: right;
        margin-top: 14px;
        //margin-right: 20px;
        width: 72px;
        height: 32px;

        border-radius: 4px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #fff !important;
        background: #4694df !important;
        border: #4694df 0px solid !important;
      }
    }
  }
  .body-contain {
    top: 8px;
    left: 8px;
    background-color: #fff;
    width: calc(100% - 16px);
    height: calc(100% - 76px);
    position: relative;
    border-radius: 4px;
  }
}

.head-contain /deep/ .en-top-menu-left {
  flex-basis: 20% !important;
}
.head-contain /deep/ .en-top-menu-center {
  flex-basis: 60% !important;
}
.head-contain /deep/ .en-top-menu-right {
  flex-basis: 20% !important;
}
.head-contain /deep/ .top-menu-name {
  margin-left: 5px;
  font-size: 14px !important;
  font-family: MicrosoftYaHei !important;
  font-weight: bold !important;
  color: #333;
  line-height: 60px;
}
.head-contain /deep/ .en-top-menu {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.head-contain /deep/ .en-top-menu-left {
  padding-left: 20px !important;
}
.head-contain /deep/ .back-icon {
  fill: #a9b5c6 !important;
  width: 18px !important;
}
// class="en-top-menu-left"
</style>
