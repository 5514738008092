<template>
  <div class="second-step-contain">
    <!-- 导入方式 start -->
    <div class="choose-contain">
      <span class="title1">导入方式</span>
      <el-select
        v-model="selectValue"
        placeholder="请选择"
        size="small"
        class="import-select"
      >
        <el-option
          v-for="item in optionList"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        />
      </el-select>
    </div>
    <!-- 导入方式 end -->

    <!-- 标题名称 start -->
    <div class="title-contain" @click="changeStatus">
      <span class="title2">预览数据：</span>
      <span class="title3">{{ this.$parent.templateName }}</span>
    </div>
    <!-- 标题名称 end -->

    <!-- 预览数据 start -->
    <template v-if="showData">
      <!-- 表格数据 -->
      <!-- <div class="data-contain">

      </div> -->
      <en-table
        border
        :stripe="true"
        class="data-contain"
        :data="tableData"
        :row-style="rowStyle"
        :cell-style="cellStyle"
      >
        <en-table-column
          v-for="key in tableHeadList"
          :key="key"
          :prop="key"
          width="385"
        >
          <template slot="header" slot-scope="scope">
            <div
              class="table-header-contain"
              @mouseover="headMouseEnter(scope)"
              @mouseleave="headMouseLeave(scope)"
            >
              <div class="header-title">
                {{ tableHeadName[scope.column.property] }}
              </div>

              <el-dropdown
                v-show="tableHeadEdit[scope.column.property]"
                size="mini"
                class="header-dropdown"
              >
                <en-icon
                  name="bianji"
                  class="bianji-icon"
                  size="small"
                  :style="{ fill: '#2D9DD9' }"
                ></en-icon>
                <el-dropdown-menu
                  slot="dropdown"
                  style="margin:0px;width:148px;"
                >
                  <el-dropdown-item style="width:125px;">
                    导入
                  </el-dropdown-item>
                  <el-dropdown-item style="width:125px;">
                    不导入
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </en-table-column>
      </en-table>
      <!-- 翻页页码 -->
      <en-pagination class="pagination" />

      <!--<tablePage-->
      <!--:total-count="801"-->
      <!--:selet-size="50"-->
      <!--:page-sizes="[10,20,50,100,200]"-->
      <!--class="table-page-contain"-->
      <!--@getTablePageValueChange="getTablePageValueChange"-->
      <!--&gt;</tablePage>-->
    </template>
    <!-- 预览数据 end -->

    <!-- 数据超过100不支持预览 start-->
    <template v-else>
      <div class="large-data-contain">
        <img src="../wushuj.png" class="wushuju-icon" />
        <span class="large-text">数据内容超过100行，不支持预览</span>
      </div>
    </template>
    <!-- 数据超过100不支持预览 end-->
  </div>
</template>

<script>
export default {
  name: "SecondStep",
  data() {
    return {
      title: "第二",
      showData: true,
      selectValue: 1,
      optionList: [
        {
          id: 1,
          name: "仅新增数据"
        },
        {
          id: 2,
          name: "仅更新数据"
        }
      ],
      tableHeadList: ["date", "name", "age", "height", "weight", "address"],
      tableHeadName: {
        date: "日期",
        name: "姓名",
        age: "年龄",
        height: "身高",
        weight: "体重",
        address: "地址"
      },
      tableHeadEdit: {
        date: true,
        name: false,
        age: false,
        height: false,
        weight: false,
        address: false
      },
      tableData: [],

      screenWidth: "",
      screenHeight: "",
      tableWidth: " ",
      tableHeight: " "
    };
  },
  watch: {
    screenWidth(val) {
      console.log("screenWidth:" + val);
      const w = parseInt(this.screenWidth, 10) - 56;
      this.tableWidth = "width: " + w + "px";
    },
    screenHeight(val) {
      console.log("screenHeight:" + val);
      this.tableHeight = parseInt(this.screenHeight, 10) - 293;
      // let sss = this.$refs.table.$el.offsetTop;
    }
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;
    const self = this;
    window.onresize = () => {
      return (() => {
        self.screenWidth = document.body.clientWidth;
        self.screenHeight = document.body.clientHeight;
      })();
    };

    for (let i = 1; i < 25; i++) {
      this.tableData.push({
        date: "2016-05-0" + (1 + i < 10 ? 1 + i : 1),
        name: "王晓晓" + i,
        age: 18 + i,
        height: 165 + i,
        weight: 50 + i,
        address: "上海市普陀区金沙江路" + i + "号"
      });
    }
    this.tableData = [...this.tableData];
  },
  methods: {
    headMouseEnter(scope) {
      this.tableHeadEdit[scope.column.property] = true;
    },
    headMouseLeave(scope) {
      this.tableHeadEdit[scope.column.property] = false;
    },
    changeStatus() {
      this.showData = !this.showData;
    },
    getTablePageValueChange(val1, val2, val3, val4) {
      this.$message(
        "当前第" +
          val1 +
          "页,每页" +
          val2 +
          "条,共" +
          val3 +
          "条,分为" +
          val4 +
          "页"
      );
    },
    rowStyle({ row, rowIndex }) {
      return "height:20px";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "padding:0";
    }
  }
};
</script>

<style lang="scss" scoped>
.second-step-contain {
  .choose-contain {
    .title1 {
      position: absolute;
      top: 31px;
      left: 20px;
      width: 50px;
      height: 12px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 12px;
      display: inline-block;
    }
    .import-select {
      position: absolute;
      top: 20px;
      left: 88px;
      width: 40%;
    }
  }

  .title-contain {
    position: absolute;
    top: 83px;
    left: 20px;
    .title2 {
      height: 12px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      line-height: 12px;
    }
    .title3 {
      height: 12px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: normal;
      color: #636c78;
      line-height: 12px;
    }
  }

  .data-contain {
    position: absolute;
    top: 109px;
    left: 20px;
    right: 20px;
    // bottom: 94px;
    height: calc(100% - 109px - 94px);
    overflow: auto;
    // border: 1px solid #dce5ec;

    .table-header-contain {
      padding: 0px;
      margin: 0px;
      height: 50px;
      width: 100%;
      display: flex;
      .header-title {
        font-family: Microsoft YaHei;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #333;
        width: calc(100% - 56px);
        height: 50px;
        line-height: 50px;
      }
      .header-dropdown {
        float: right;
        width: 50px;
        height: 50px;
        padding: 0px;
        margin: 0px;
        .bianji-icon {
          width: 16px;
          height: 16px;
          margin: 15px 10px 12px 24px;
        }
      }
    }
  }

  .pagination {
    position: absolute;
    bottom: 30px;
    right: 28px;
  }

  .large-data-contain {
    position: absolute;
    top: 109px;
    left: 20px;
    right: 20px;
    height: 514px;
    border: 1px solid rgba(220, 229, 236, 1);
    .wushuju-icon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 193px;
      width: 76px;
      height: 100px;
    }
    .large-text {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 309px;
      height: 13px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: rgba(185, 196, 210, 1);
    }
  }
}

.second-step-contain /deep/ .el-input {
  height: 32px;
  min-width: 100%;
}

.second-step-contain /deep/ .el-input__inner {
  padding: 0px 8px;
  line-height: 32px;
  font-size: 12px;
  height: 32px;
  font-weight: normal;
  color: #333;
}

.second-step-contain {
  .el-select-dropdown__item {
    line-height: 32px;
    font-size: 12px;
    height: 32px;
    font-weight: normal;
    color: #333;
  }
}

.second-step-contain /deep/ .el-table__header-wrapper /deep/ .cell {
  background-color: #f6fafd;
  padding: 0px;
}
</style>
<style lang="css" scoped>
.el-select-dropdown__item {
  line-height: 30px;
  font-size: 12px;
  height: 30px;
  font-weight: normal;
  color: #333;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
</style>
