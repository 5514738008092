<template>
  <div
    class="menu-wrapper right-menu"
    id="js_right_menu"
    v-if="isShow"
    :style="styObj"
    ref="menuwrapper"
    @click.stop
  >
    <ul class="menu-items">
      <li
        class="menu-item"
        @mousedown.stop.prevent
        :class="{'no-do': item.disable,'divider': item.divider}"
        v-for="(item, index) in menuList"
        v-show="!item.hidden"
        :key="index"
        :data-type="item.val"
        @click.stop="doClick($event, item)"
      >
        <span v-if="!item.children">{{item.val}}</span>
        <el-popover
          v-else
          :visible-arrow="false"
          popper-class="custom-popover"
          placement="right-start"
          width="180"
          trigger="hover">
          <li
            class="menu-item"
            @mousedown.stop.prevent
            v-for="(itm, index) in item.children"
            :key="index"
            :data-type="itm.val"
            @click.stop="doClick($event, itm)"
          >
           <span>{{itm.val}}</span>
          </li>
          <div slot="reference" style="display: flex;justify-content: space-between;align-items: center;">
            <span>{{item.val}}</span>
            <span class="el-icon-arrow-right"></span>
          </div>
        </el-popover>
      </li>
    </ul>
  </div>
</template>

<script>
// const Clipboard = require('clipboard')

export default {
  data() {
    return {
      isShow: false,
      clipboard: undefined,
      menuHeight: 0,
      menuWidth: 0
    };
  },
  mounted() {
    this.isShow = true;
    document.addEventListener("click", this.close);
    document.addEventListener("keydown", this.closeByEsc);
    this.$nextTick(() => {
      this.menuHeight = document.querySelector("#js_right_menu").clientHeight;
      this.menuWidth = document.querySelector("#js_right_menu").clientWidth;
    });
  },
  methods: {
    doClick(event, item) {
      event.preventDefault();
      event.stopPropagation();
      if (item.disable || item.divider || item.children) return;
      this.callback(item, this.activeItem);
    },
    close() {
      if (this.isShow) {
        this.isShow = false;
        this.$emit("closed");
      }
    },
    closeByEsc(e) {
      if (e.keyCode === 27) {
        this.close();
      }
    }
  },
  destroyed() {
    document.removeEventListener("click", this.close);
    document.removeEventListener("keydown", this.closeByEsc);
  },
  computed: {
    styObj() {
      if (!this.posobj || !this.posobj.left || !this.posobj.top) {
        return {};
      }
      // eslint-disable-next-line prefer-const
      let { left, top } = this.posobj;
      const { clientHeight, clientWidth } = document.body;
      if (left > clientWidth - this.menuWidth) {
        left -= this.menuWidth;
      }
      if (top > clientHeight - this.menuHeight) {
        return {
          left: `${left}px`,
          bottom: `${clientHeight - top}px`
        };
      }
      return {
        left: `${left}px`,
        top: `${top}px`
      };
    }
  }
};
</script>
<style lang="scss">
.custom-popover {
  padding: 0;
  .menu-item {
    list-style: none;
    line-height: 36px;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 12px;
    color: #333333;
    cursor: pointer;
    &:hover {
      background-color: #f5f8fc;
    }
    &.no-do {
      color:#dddddd;
      cursor: default;
      &:hover {
        background-color: #ffffff;
      }
    }
  }
}

</style>
<style lang="scss" scoped>
.menu-wrapper {
  position: fixed;
  z-index: 999;
  padding: 6px 0;
  background-color: #ffffff;
  box-shadow: #cccccc 1px 1px 10px;
  min-width: 180px;
  border-radius: 4px;
  overflow: hidden;
  .menu-items {
    margin: 0;
    padding: 0;
  }
  .menu-item {
    line-height: 36px;
    box-sizing: border-box;
    padding: 0 12px;
    font-size: 12px;
    color: #333333;
    cursor: pointer;
    &:hover {
      background-color: #f5f8fc;
    }
    &.no-do {
      color:#dddddd;
      cursor: default;
      &:hover {
        background-color: #ffffff;
      }
    }
  }
  .divider {
    height: 1px;
    margin: 5px 0;
    background: #dce5ec;
    cursor: none;
    transform: scale(1, .5);
    &:hover {
      background: #dce5ec;
    }
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.2s ease;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
