import component from "./src/fun-menu.js";

import {
  openRightMenu,
  closeRightMenu
} from "./src/function.js";
component.install = function(Vue, name = "$rightmenu") {
  Vue.component(component.name, component);
  Vue.prototype[name] = {
    open: openRightMenu,
    close: closeRightMenu
  };
  // Vue.prototype[name] = openRightMenu
}
export default component;
