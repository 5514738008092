/* eslint-disable */

import XEUtils from "xe-utils/methods/xe-utils";
import CellUtils from "../../packages/cell/src/cellUtils";
//import VXETable from "../../packages/vxe-table";


function parseDate(value, props) {
  return value && props.valueFormat ? XEUtils.toStringDate(value, props.valueFormat) : value;
}

function getFormatDate(value, props, defaultFormat) {
  return XEUtils.toDateString(parseDate(value, props), props.format || defaultFormat);
}

function getFormatDates(values, props, separator, defaultFormat) {
  return XEUtils.map(values, (date) => getFormatDate(date, props, defaultFormat)).join(separator);
}

function equalDaterange(cellValue, data, props, defaultFormat) {
  cellValue = getFormatDate(cellValue, props, defaultFormat);
  return cellValue >= getFormatDate(data[0], props, defaultFormat) && cellValue <= getFormatDate(data[1], props, defaultFormat);
}

function matchCascaderData(index, list, values, labels) {
  let val = values[index];
  if (list && values.length > index) {
    XEUtils.each(list, (item) => {
      if (item.value === val) {
        labels.push(item.label);
        matchCascaderData(++index, item.children, values, labels);
      }
    });
  }
}

function getProps({ $table }, { props }) {
  return XEUtils.assign($table.vSize ? { size: $table.vSize } : {}, props);
}

function getCellEvents(renderOpts, params) {
  let { name, events } = renderOpts;
  let { $table } = params;
  let type = "change";
  switch (name) {
  case "ElAutocomplete":
    type = "select";
    break;
  case "ElInput":
  case "ElInputNumber":
    type = "input";
    break;
  }
  let on = {
    [type]: (evnt) => {
      $table.updateStatus(params);
      if (events && events[type]) {
        events[type](params, evnt);
      }
    }
  };
  if (events) {
    return XEUtils.assign({}, XEUtils.objectMap(events, (cb) => function (...args) {
      cb.apply(null, [ params ].concat.apply(params, args));
    }), on);
  }
  return on;
}

function doRenderInit(renderOpts) {

  let placeholder;
  switch (renderOpts.name) {
    case "ElAutocomplete":
    case "ElInputNumber":
      break;
    case "ElDatePicker":
      placeholder = "请选择...";
      break;
    case "ElInput":
     placeholder = "请输入...";
      break;
  }

  if (placeholder) {
    let { attrs } = renderOpts;
    if (!attrs) {
      renderOpts.attrs = attrs = {};
    }
    if (!attrs.placeholder) {
      attrs.placeholder = placeholder;
    }
  }

  if (!renderOpts.style) {
    renderOpts.style = {};
  }

}

function defaultEditRender(h, renderOpts, params) {
  let { row, column, $table } = params;
  let props = getProps(params, renderOpts);

  doRenderInit(renderOpts);

  //add by 徐,自定义颜色(后期可以增加方法 返回颜色) -- TODO: 颜色处理 #非必须
  let ref = undefined;
  if (renderOpts.name === 'ElInput' && renderOpts.colorField) {//renderOpts.attrs && renderOpts.attrs.color && renderOpts.name === 'ElInput'
    ref = 'elInput_' + CellUtils.getRowid($table, row) + '_' + column.id ;
    let color = row[renderOpts.colorField];
    renderOpts.style.color = color;
    this.$nextTick(()=>{
      this.$refs.tableBody.$refs[ref].$refs.input.style.color = color;
    });
  }

  return [
    h(renderOpts.name, {
      props,
      attrs:renderOpts.attrs,
      model: {
        value: XEUtils.get(row, column.property),
        callback(value) {
          XEUtils.set(row, column.property, value);
        }
      },
      on: getCellEvents(renderOpts, params),
      ref,
      style:renderOpts.style,
    })
  ];
}

function getFilterEvents(on, renderOpts, params, context) {
  let { events } = renderOpts;
  if (events) {
    return XEUtils.assign({}, XEUtils.objectMap(events, (cb) => function (...args) {
      params = Object.assign({ context }, params);
      cb.apply(null, [ params ].concat.apply(params, args));
    }), on);
  }
  return on;
}

function defaultFilterRender(h, renderOpts, params, context) {
  let { column } = params;
  if (!column) return h();
  let { filterSortStore } = column;
  let { name, events } = renderOpts;
  let props = getProps(params, renderOpts);
  let type = "change";
  switch (name) {
    case "ElAutocomplete":
      // type = "select";
      type = "";
      break;
    case "ElInput":
    case "ElInputNumber":
      // type = "input";
      type = "";
      break;
    case"EnInputRange":
    case"EnDatePicker":
      type = "change"
      break;
  }
  doRenderInit(renderOpts);
  const on = getFilterEvents({
    [type](evnt) {
      handleConfirmFilter(context, column, !!filterSortStore.value);
      if (events && events[type]) {
        events[type](Object.assign({ context }, params), evnt);
      }
    }
  }, renderOpts, params, context);
  const nativeOn = {};
  if (name === "ElInput" || name === "ElInputNumber") {
    nativeOn["keyup"] = function(e) {
      if (e.keyCode === 13) {
        context.confirmFilter();
      }
    }
  }
  return [h(name, {
    props,
    on,
    nativeOn,
    attrs: renderOpts.attrs,
    ref: name === "EnDatePicker" ? "datePicker" : "",
    model: {
      value: filterSortStore.value,
      callback(optionValue) {
        column.filterSortStore.value = optionValue;
      }
    }
  })];
}

function handleConfirmFilter(context, column, checked) {
  context.confirmFilter();
  // context[column.filterMultiple ? "changeMultipleOption" : "changeRadioOption"]({}, checked);
}

function defaultFilterMethod({ option, row, column }) {
  let { data } = option;
  let cellValue = XEUtils.get(row, column.property);
  /* eslint-disable eqeqeq */
  return cellValue == data;
}

function renderOptions(h, options, optionProps) {
  let labelProp = optionProps.label || "label";
  let valueProp = optionProps.value || "value";
  let disabledProp = optionProps.disabled || "disabled";
  return XEUtils.map(options, (item, index) => {
    return h("el-option", {
      props: {
        value: item[valueProp],
        label: item[labelProp],
        disabled: item[disabledProp]
      },
      key: index
    });
  });
}

function cellText(h, cellValue) {
  return [ "" + (cellValue === null || cellValue === void 0 ? "" : cellValue) ];
}

/**
 * 渲染默认函数
 */
function renderDefaultMap(padding, visible) {
 return {
   padding: padding,
   visible: visible,
   autofocus: "input.el-input__inner",
   renderDefault: defaultEditRender,
   renderEdit: defaultEditRender,
   renderFilter: defaultFilterRender,
   filterMethod: defaultFilterMethod
 }
}

/**
 * 渲染下拉框函数
 */
const renderSelectMap = {
  padding: false,
  renderEdit(h, renderOpts, params) {
    let { options, optionGroups, optionProps = {}, optionGroupProps = {} } = renderOpts;
    let { row, column } = params;
    let { attrs } = renderOpts;
    let props = getProps(params, renderOpts);
    if (optionGroups) {
      let groupOptions = optionGroupProps.options || "options";
      let groupLabel = optionGroupProps.label || "label";
      return [
        h("el-select", {
          props,
          attrs,
          model: {
            value: XEUtils.get(row, column.property),
            callback(cellValue) {
              XEUtils.set(row, column.property, cellValue);
            }
          },
          on: getCellEvents(renderOpts, params)
        }, XEUtils.map(optionGroups, (group, gIndex) => {
          return h("el-option-group", {
            props: { label: group[groupLabel] },
            key: gIndex
          }, renderOptions(h, group[groupOptions], optionProps));
        }))
      ];
    }
    return [
      h("el-select", {
        props,
        attrs,
        model: {
          value: XEUtils.get(row, column.property),
          callback(cellValue) {
            XEUtils.set(row, column.property, cellValue);
          }
        },
        on: getCellEvents(renderOpts, params)
      }, renderOptions(h, options, optionProps))
    ];
  },
  renderCell(h, renderOpts, params) {
    let { options, optionGroups, props = {}, optionProps = {}, optionGroupProps = {} } = renderOpts;
    let { row, column } = params;
    let labelProp = optionProps.label || "label";
    let valueProp = optionProps.value || "value";
    let groupOptions = optionGroupProps.options || "options";
    let cellValue = XEUtils.get(row, column.property);
    if (!cellValue) {
      return cellText(h, XEUtils.map(props.multiple ? cellValue : [ cellValue ], optionGroups ? (value) => {
        let selectItem;
        for (let index = 0; index < optionGroups.length; index++) {
          selectItem = XEUtils.find(optionGroups[index][groupOptions], (item) => item[valueProp] === value);
          if (selectItem) {
            break;
          }
        }
        return selectItem ? selectItem[labelProp] : null;
      } : (value) => {
        let selectItem = XEUtils.find(options, (item) => item[valueProp] === value);
        return selectItem ? selectItem[labelProp] : null;
      }).join(";"));
    }
    return cellText(h, "");
  },
  renderFilter(h, renderOpts, params, context) {
    //本地 筛选
  },
  filterMethod({ option, row, column }) {
    let { data } = option;
    let { property, filterRender: renderOpts } = column;
    let { props = {} } = renderOpts;
    let cellValue = XEUtils.get(row, property);
    if (props.multiple) {
      if (XEUtils.isArray(cellValue)) {
        return XEUtils.includeArrays(cellValue, data);
      }
      return data.indexOf(cellValue) > -1;
    }
    /* eslint-disable eqeqeq */
    return cellValue == data;
  }
}

/**
 * 渲染函数
 */
const renderMap = {
  //enfry(add by xu)
  undefined: renderDefaultMap(false, false),//默认值(插槽使用)
  EnButton: renderDefaultMap(true, false),
  EnSelect: renderSelectMap,
  EnInputRange: renderDefaultMap(false, false),
  EnDatePicker: renderDefaultMap(false, false),
  // element
  ElAutocomplete: renderDefaultMap(false, false),
  ElInput: renderDefaultMap(false, true),
  ElInputNumber: renderDefaultMap(false, false),
  ElSelect: renderSelectMap,
  ElTimeSelect: { renderEdit: defaultEditRender },
  ElRate: renderDefaultMap(true, false),
  ElSwitch: renderDefaultMap(true, false),
  ElSlider: renderDefaultMap(true, false),
  ElCascader: {
    padding: false,
    renderEdit: defaultEditRender,
    renderCell(h, { props = {} }, params) {
      let { row, column } = params;
      let cellValue = XEUtils.get(row, column.property);
      var values = cellValue || [];
      var labels = [];
      matchCascaderData(0, props.options, values, labels);
      return cellText(h, (props.showAllLevels === false ? labels.slice(labels.length - 1, labels.length) : labels).join(` ${props.separator || "/"} `));
    }
  },
  ElTimePicker: {
    padding: false,
    renderEdit: defaultEditRender,
    renderCell(h, { props = {} }, params) {
      let { row, column } = params;
      let { isRange, format = "hh:mm:ss", rangeSeparator = "-" } = props;
      let cellValue = XEUtils.get(row, column.property);
      if (cellValue && isRange) {
        cellValue = XEUtils.map(cellValue, (date) => XEUtils.toDateString(parseDate(date, props), format)).join(` ${rangeSeparator} `);
      }
      return XEUtils.toDateString(parseDate(cellValue, props), format);
    }
  },
  ElDatePicker: {
    padding: false,
    renderEdit: defaultEditRender,
    renderCell(h, { props = {} }, params) {
      let { row, column } = params;
      let { rangeSeparator = "-" } = props;
      let cellValue = XEUtils.get(row, column.property);
      switch (props.type) {
      case "week":
        cellValue = getFormatDate(cellValue, props, "yyyywWW");
        break;
      case "month":
        cellValue = getFormatDate(cellValue, props, "yyyy-MM");
        break;
      case "year":
        cellValue = getFormatDate(cellValue, props, "yyyy");
        break;
      case "dates":
        cellValue = getFormatDates(cellValue, props, ", ", "yyyy-MM-dd");
        break;
      case "daterange":
        cellValue = getFormatDates(cellValue, props, ` ${rangeSeparator} `, "yyyy-MM-dd");
        break;
      case "datetimerange":
        cellValue = getFormatDates(cellValue, props, ` ${rangeSeparator} `, "yyyy-MM-dd HH:mm:ss");
        break;
      case "monthrange":
        cellValue = getFormatDates(cellValue, props, ` ${rangeSeparator} `, "yyyy-MM");
        break;
      default:
        cellValue = getFormatDate(cellValue, props, "yyyy-MM-dd");
      }
      return cellText(h, cellValue);
    },
    renderFilter(h, renderOpts, params, context) {
      let { column } = params;
      if (!column) return h();
      let { filterSortStore } = column;
      let { events } = renderOpts;
      let props = getProps(params, renderOpts);
      let type = "change";

      doRenderInit(renderOpts);

      return [h(renderOpts.name, {
        props,
        ref: "datePicker",
        attrs : renderOpts.attrs,
        model: {
          value: filterSortStore.value,
          callback(optionValue) {
            filterSortStore.value = optionValue;
          }
        },
        on: getFilterEvents({
          [type](value) {
            handleConfirmFilter(context, column, !!value);
            if (events && events[type]) {
              events[type](Object.assign({ context }, params), value);
            }
          }
        }, renderOpts, params, context)
      })];
    },
    filterMethod({ option, row, column }) {
      let { data } = option;
      let { filterRender: renderOpts } = column;
      let { props = {} } = renderOpts;
      let cellValue = XEUtils.get(row, column.property);
      if (data) {
        switch (props.type) {
        case "daterange":
          return equalDaterange(cellValue, data, props, "yyyy-MM-dd");
        case "datetimerange":
          return equalDaterange(cellValue, data, props, "yyyy-MM-dd HH:ss:mm");
        case "monthrange":
          return equalDaterange(cellValue, data, props, "yyyy-MM");
        default:
          return cellValue == data;
        }
      }
      return false;
    }
  },

};

/**
 * 事件兼容性处理
 */
function handleClearEvent(params, evnt, context) {
  let { getEventTargetNode } = context;
  let bodyElem = document.body;
  if (
    // 远程搜索
    getEventTargetNode(evnt, bodyElem, "el-autocomplete-suggestion").flag ||
    // 下拉框
    getEventTargetNode(evnt, bodyElem, "el-select-dropdown").flag ||
    // 级联
    getEventTargetNode(evnt, bodyElem, "el-cascader__dropdown").flag ||
    getEventTargetNode(evnt, bodyElem, "el-cascader-menus").flag ||
    // 日期
    getEventTargetNode(evnt, bodyElem, "el-time-panel").flag ||
    getEventTargetNode(evnt, bodyElem, "el-picker-panel").flag
  ) {
    return false;
  }
}

/**
 * 基于 vxe-table 表格的适配插件，用于兼容 element-ui 组件库
 */
export const VXETablePluginElement = {
  install(xtable) {
    let { interceptor, renderer } = xtable;
    renderer.mixin(renderMap);
    interceptor.add("event.clearFilter", handleClearEvent);
    interceptor.add("event.clearActived", handleClearEvent);
  }
};

// if (typeof window !== 'undefined' && window.VXETable) {
//   window.VXETable.use(VXETablePluginElement)
// }

// VXETable.use(VXETablePluginElement);

export default VXETablePluginElement;
