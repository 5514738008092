<template>
  <div class="radio-choose-contain">
    <el-checkbox-group v-model="checkStatus" @change="checkboxValueChange">
      <el-checkbox v-for="item in checkList" :key="item.id" :label="item.id">
        {{ item.fieldName }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>
<script>
export default {
  name: "RadioChoose",
  props: {
    checkList: {
      type: Array,
      default: function() {
        return [{}];
      }
    }
  },
  data() {
    return {
      checkStatus: [],
      checkObjList: []
    };
  },
  watch: {},
  mounted() {
    // 默认选中全部
    for (let i = 0; i < this.checkList.length; i++) {
      const item = this.checkList[i];
      this.checkStatus.push(item.id);
    }
    this.checkStatus = [...this.checkStatus];

    // 默认告知1次父控件选中数据
    this.checkboxValueChange();
  },
  methods: {
    checkboxValueChange() {
      const checkObjList = [];
      this.checkList.forEach(obj => {
        this.checkStatus.forEach(id => {
          if (obj.id === id) {
            checkObjList.push(obj);
          }
        });
      });
      this.checkObjList = [...checkObjList];
      this.$emit("getCheckboxSelectValue", this.checkObjList);
    }
  }
};
</script>

<style lang="scss" scoped>
.radio-choose-contain {
  margin: 10px 0px 0px 60px;
  padding: 6px 13px 6px 13px;
  background: rgba(251, 251, 251, 1);
  border: 1px solid rgba(232, 236, 242, 1);
  border-radius: 4px;
  height: 80px;
  overflow: auto;
}
.radio-choose-contain /deep/ .el-checkbox__label {
  padding-left: 8px;
  line-height: 20px;
  font-size: 12px;
  height: 20px;
  font-weight: normal;
  color: #636c78;
}
.radio-choose-contain
  /deep/
  .el-checkbox__input.is-checked
  + .el-checkbox__label {
  color: #636c78;
}
.radio-choose-contain /deep/ .el-checkbox {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 16px;
}
</style>
