var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.type,{tag:"components",staticClass:"en-transfer",scopedSlots:_vm._u([{key:"left-title",fn:function(){return [_vm._t("left-title")]},proxy:true},{key:"right-title",fn:function(){return [_vm._t("right-title")]},proxy:true},{key:"left-content",fn:function(ref){
var item = ref.item;
var data = ref.data;
var node = ref.node;
return [_vm._t("left-content",null,{"item":item,"data":data,"node":node})]}},{key:"left-assist",fn:function(ref){
var data = ref.data;
var node = ref.node;
return [_vm._t("left-assist",null,{"data":data,"node":node})]}},{key:"right-content",fn:function(ref){
var item = ref.item;
return [_vm._t("right-content",null,{"item":item})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }