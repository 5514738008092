<template>
  <div class="en-switch" :class="{ 'en-switch-disabled': disabled }">
    <div
      v-for="(item, index) in switchDatas"
      :key="index"
      class="switch-item-content"
      :class="{
        'is-selected': value === item[propsData.model],
        'is-disabled': item[propsData.disabled] || disabled,
        'is-icon-switch': type === 'icon-switch'
      }"
      @click="switchChange(item, index)"
    >
      <en-icon
        v-if="type === 'icon-switch'"
        :name="item[propsData.icon]"
        size="small"
      >
      </en-icon>
      <span v-else> {{ item[propsData.text] }} </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "EnSwitch",
  model: {
    prop: "value",
    event: "update"
  },
  props: {
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // v-model/value
    value: {
      type: Number,
      default: 1
    },
    // switch类型
    type: {
      type: String,
      default: "icon-switch",
      validator: function(value) {
        return ["icon-switch", "text-switch"].indexOf(value) > -1;
      }
    },
    // 数据源..包含可能的icon,text或者model值
    switchDatas: {
      type: Array,
      default: () => [
        {
          icon: "zuoduiqi",
          text: "",
          model: 1
        },
        {
          icon: "juzhongduiqi",
          text: "",
          model: 2
        },
        {
          icon: "youduiqi",
          text: "",
          model: 3
        }
      ]
    },
    // 值
    propsData: {
      type: Object,
      default: () => {
        return {
          text: "text",
          icon: "icon",
          model: "model",
          disabled: "disabled"
        };
      }
    }
  },
  methods: {
    switchChange(item, index) {
      if (!item[this.propsData.disabled] && !this.disabled) {
        const toIndex = item[this.propsData.model] || index + 1;
        this.$emit("change", this.value, toIndex);
        this.$emit("update", toIndex);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin normalBorder {
  border-left: 1px solid rgba(236, 241, 247, 1);
  border-top: 1px solid rgba(236, 241, 247, 1);
  border-bottom: 1px solid rgba(236, 241, 247, 1);
}

@mixin highlightBorder {
  border-left: 1px solid rgba(70, 148, 223, 1);
  border-top: 1px solid rgba(70, 148, 223, 1);
  border-bottom: 1px solid rgba(70, 148, 223, 1);
}

.en-switch {
  display: flex;
  height: 28px;
  line-height: 28px;

  .switch-item-content {
    display: inline-block;
    flex: 1;
    text-align: center;
    cursor: pointer;
    color: #4694df;
    font-size: 12px;
    @include normalBorder;
    transition: all 0.3s;
    .en-icon {
      height: 100%;
    }
  }

  .switch-item-content.is-icon-switch {
    color: #a9b5c6;
  }

  .switch-item-content.is-icon-switch.is-disabled {
    color: #cdcdcd;
  }

  .switch-item-content.is-disabled {
    cursor: not-allowed;
    color: #a9b5c6;
  }

  .switch-item-content:last-child {
    border-right: 1px solid rgba(236, 241, 247, 1);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .switch-item-content:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .switch-item-content.is-selected {
    background: rgba(70, 148, 223, 1);
    @include highlightBorder;
    color: #fff;
    fill: #fff;
  }

  .switch-item-content:not(.is-disabled):not(.is-selected):hover {
    color: #4694df;
    fill: #4694df;
  }

  .switch-item-content.is-selected.is-disabled {
    background-color: rgba(245, 247, 250, 1);
    color: #cdcdcd;
    @include normalBorder;
  }
}
</style>
