<template>
  <div class="en-tree-transfer">
    <!-- 左侧面板 -->
    <div class="en-transfer-panel">
      <!--      <div-->
      <!--        class="en-transfer-panel-title"-->
      <!--        v-if="-->
      <!--          $parent.showTitle &&-->
      <!--            $parent.title.length === 2 &&-->
      <!--            $parent.showTitle !== 'false'-->
      <!--        "-->
      <!--      >-->
      <!--        {{ $parent.title[0] }}-->
      <!--      </div>-->
      <div
        class="en-transfer-panel-title"
        v-if="$parent.showTitle && $parent.title.length === 2 && $parent.showTitle !== 'false'"
      >
        <slot name="left-title">
          <template v-if="$parent.singleSelect || $parent.multiple === 1">
            {{ $parent.title[0]}}
          </template>
          <el-checkbox
            v-model="leftPanel.checkedAll"
            :indeterminate="leftPanel.isIndeterminate"
            @change="toggleLeftCheckAll"
            v-else
          >
            {{ $parent.title[0]}}
          </el-checkbox>
          <template v-if="!$parent.singleSelect && $parent.multiple !== 1">
            {{ leftPanel.checkedList.length + " /" + allowCheckList.length }}
          </template>
        </slot>
      </div>
      <el-input
        class="en-transfer-search-title"
        v-if="$parent.showSearch"
        :placeholder="$parent.placeholder"
        suffix-icon="el-icon-search"
        v-model="keywords"
      >
      </el-input>
      <div
        class="en-transfer-panel-body"
        :class="{'with-search': $parent.showSearch}"
      >
        <en-tree
          ref="tree"
          :default-expanded-keys="[]"
          :default-checked-keys="[]"
          :data="leftPanel.list"
          :props="$parent.props"
          show-checkbox
          :node-key="$parent.props.value"
          :filterable="$parent.showSearch"
          :show-assist="$parent.showAssist"
          :filter-node-method="$parent.filterNodeMethod"
          :expand-on-click-node="false"
          :check-limit="$parent.multiple"
          check-mode="normal"
          :lazy="$parent.lazy"
          :load="loadFn"
          :page-size="$parent.pageSize"
          @check="changeLeftPanel"
        >
          <template v-slot="{ node, data }">
            <slot name="left-content" :data="data" :node="node">
                    <span
                      class="el-tree-node__label"
                      :title="node.title"
                      :style="{ color: node.color }"
                    >{{ node.label }}</span
                    >
            </slot>
          </template>
          <template #assist="{ node, data }">
            <slot name="left-assist" :data="data" :node="node">
              <span class="en-node-assist">{{ node.label }}</span>
            </slot>
          </template>
        </en-tree>
      </div>
    </div>
    <!-- 中间面板-仨按钮 -->
    <div class="en-transfer-tool">
      <div class="en-transfer-tool-container">
        <en-button
          :disabled="leftPanel.checkedList.length === 0"
          @click="dataToRight"
          icon="el-icon-arrow-right"
        >
        </en-button>
        <en-button
          :type="rightPanel.checkedList.length > 0 ? '' : 'white'"
          icon="el-icon-arrow-left"
          :disabled="rightPanel.checkedList.length === 0"
          @click="dataToLeft"
        >
        </en-button>
        <!-- 排除按钮 -->
        <en-button
          v-if="$parent.exclude && $parent.exclude !== 'false'"
          icon="el-icon-close"
          :disabled="leftPanel.checkedList.length === 0"
          type="white"
          @click="excludeToRight"
        >
        </en-button>
      </div>
    </div>
    <!-- 右侧面板 -->
    <div class="en-transfer-panel">
      <div
        class="en-transfer-panel-title"
        v-if="$parent.showTitle && $parent.title.length === 2 && $parent.showTitle !== 'false'"
      >
        <slot name="right-title">
          <template v-if="$parent.singleSelect || $parent.multiple === 1">
            {{ $parent.title[0]}}
          </template>
          <el-checkbox
            v-model="rightPanel.checkedAll"
            :indeterminate="rightPanel.isIndeterminate"
            @change="toggleRightCheckAll"
            v-else
          >
            {{ $parent.title.length > 1 ? $parent.title[1] : "右侧" }}
          </el-checkbox>
          <template v-if="!$parent.singleSelect && $parent.multiple !== 1">
            {{ rightPanel.checkedList.length + " /" + rightPanel.list.length }}
          </template>
        </slot>
      </div>
      <div class="en-transfer-panel-body">
        <el-checkbox-group
          v-model="rightPanel.checkedList"
          @change="rightPanelCheckedChange"
        >
          <draggable
            group="item"
            animation="150"
            :list="rightPanel.list"
            class="en-drag-item-content"
            scroll="true"
            :disabled="!$parent.canSort"
            draggable=".can-drag-item"
          >
            <el-checkbox
              v-for="item in rightPanel.list"
              :key="item[$parent.idKey]"
              class="en-transfer-panel-item"
              :label="item"
              :disabled="item.disabled"
              :class="{'can-drag-item':!item.disabled}"
            >
              <!-- 除去复选框，右侧整体不涉及自定义 -->
              <div class="en-transfer-panel-item-container"
                   v-if="!$parent.selfDefine && $parent.selfDefine !== 'false'">
                <slot name="right-content" :item="item">
                  <!-- 若数据是排除的 -->
                  <i
                    class="el-icon el-icon-close"
                    v-if="item.isExclude && $parent.isExclude !== 'false'"
                    style="color:#FF0000;"
                  >
                  </i>
                  <!-- 若数据是带头像的 -->
                  <!--<img-->
                    <!--v-if="-->
                <!--$parent.showHead &&-->
                <!--$parent.showHead !== 'false' &&-->
                <!--item[$parent.imgPath]-->
              <!--"-->
                    <!--:src="item[$parent.imgPath] ? item[$parent.imgPath] : ''"-->
                    <!--alt=""-->
                  <!--/>-->
                  <en-user-logo v-if="$parent.showHead && $parent.showHead !== 'false'"
                                :user-name="item[$parent.props.label]"
                                :image-url="item[$parent.imgPath]"
                                size="30px"
                                class="en-transfer-panel-item-user-logo"
                  >

                  </en-user-logo>
                  <div class="en-transfer-panel-item-label">
                    {{ item[$parent.props.label] }}
                    <!--                  <slot-->
                    <!--                    :item="item"-->
                    <!--                    name="right"-->
                    <!--                    v-if="$parent.selfIcon && $parent.selfIcon !== 'false'"-->
                    <!--                  >-->
                    <!--                  </slot>-->
                  </div>
                </slot>
              </div>
            </el-checkbox>
          </draggable>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>
<script>

import draggable from "vuedraggable";
import debounce from "throttle-debounce/debounce";
export default {
  name: "en-transfer-tree",
  provide() {
    return {
      transfer: this
    };
  },
  components: { draggable },
  data() {
    return {
      keywords: "",
      rootNode: null,
      resolve: null,
      leftPanel: {
        list: [],
        checkedList: [],
        checkedAll: false,
        isIndeterminate: false
      },
      rightPanel: {
        list: [],
        checkedList: [],
        checkedAll: false,
        isIndeterminate: false
      },
      allowCheckList: [],
      options: []
    };
  },
  created() {
    this.setPanelList();
  },
  watch: {
    options() {
      this.$nextTick(() => {
        const { store } = this.$refs.tree;
        const allData = store._getAllNodes();
        const allowCheckList = allData.filter((v) => !v.disabled);
        this.allowCheckList = allowCheckList;
        this.resetLeftCheckStatus();
      });
    },
    keywords(val) {
      console.log("keywords", val)
      if (this.$parent.lazy && val) { // 如果是懒加载 则搜索后台数据
        this.leftPanel.list = []// 清空原来的$parent.list数据
        const debouncedQueryChange = debounce(200, () => {
          this.loadFn(this.rootNode, this.resolve, false, val);
        });
        setTimeout(() => {
          // 注意使用异步，否则 this.$parent.list = [],会将你留下一行代码懒加载的数据一起清空
          debouncedQueryChange()
        }, 50)
      } else if (this.$parent.lazy && !val) {
        this.leftPanel.list = [];// 清空原来的$parent.list数据
        setTimeout(() => {
          this.setPanelList();
        }, 50);
      } else { // 如果不是懒加载 则过滤本地数据
        this.$refs.tree.filter(val);
      }
    },
    "$parent.list": {
      deep: true,
      immediate: true,
      handler() {
        this.setPanelList();
      }
    },
    "$parent.value": {
      deep: true,
      immediate: true,
      handler() {
        this.reSetPanelList();
      }
    }
  },
  methods: {
    /**
     * @description 设置左侧面板数据
     */
    setPanelList() {
      this.leftPanel.list = [];
      this.leftPanel.list = JSON.parse(JSON.stringify(this.$parent.list));
      // add by lpx 20201202: 穿梭框v-model没有赋值
      this.rightPanel.list = this.$parent.value;
    },
    /**
     * @description 初始化设置，将左右两侧数据进行默认填充
     */
    reSetPanelList() {
      if (this.$parent.resetLeft) {
        this.leftPanel.list = [];
        this.leftPanel.list = JSON.parse(JSON.stringify(this.$parent.list));
      }
      this.rightPanel.list = this.$parent.value;
    },
    loadFn(node, resolve, ...args) {
      if (node.level === 0) {
        this.rootNode = node// 保存初始节点
        this.resolve = resolve// 保存resolve方法
      }
      this.$parent.load(node, resolve, ...args)
    },
    /**
     * @description 节点筛选
     */
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.label.indexOf(value) !== -1;
    },
    /**
     * @description 节点选中状态变化时，判断数据右移图标是否可用
     */
    changeLeftPanel(data, params) {
      this.leftPanel.checkedList = this.$refs.tree.getCheckedNodes();
      this.resetLeftCheckStatus();
    },
    /**
     * @description 如果只允许单选数据节点时，需清空之前选择的节点，然后设置当前节点的选中
     */
    resetSingleSelect(data, params) {
      if (data.id) {
        this.$refs.tree.setCheckedKeys([]);
        this.leftPanel.checkedList = [];
        // 先清空，再填充选中
        this.$refs.tree.setCheckedNodes([data]);
      }
    },
    /**
     * @description 右侧面板数据减少选中的数据
     */
    dataToLeft() {
      this.rightPanel.checkedList.map(item => {
        this.rightPanel.list.map((cItem, cIndex) => {
          if (item.id === cItem.id) {
            this.rightPanel.list.splice(cIndex, 1);
          }
        });
      });
      this.rightPanel.checkedList = [];
      this.rightPanel.checkedAll = false;
      this.rightPanel.isIndeterminate = false;
    },
    /**
     * @description 数据选择并往右侧面板填塞
     */
    dataToRight() {
      if (this.$parent.singleSelect || this.$parent.multiple === 1) {
        const obj = Object.assign({}, this.leftPanel.checkedList[0]);
        this.rightPanel.list = [obj];
      } else {
        if (this.rightPanel.list.length === 0) {
          this.leftPanel.checkedList.forEach(item => {
            this.rightPanel.list.push({ ...item, isExclude: false });
          });
        } else {
          this.leftPanel.checkedList.forEach(item => {
            const index = this.rightPanel.list.findIndex((cItem) => {
              return cItem[this.$parent.props.value] === item[this.$parent.props.value];
            });
            if (index === -1) {
              this.rightPanel.list.push({ ...item, isExclude: false });
            } else if (index > -1 && this.rightPanel.list[index].isExclude) {
              this.rightPanel.list[index].isExclude = false;
            }
          });
        }
      }
      this.emitDataInfo();
    },
    /**
     * @description 将左侧选中的数据，从数据源中排除掉
     */
    excludeToRight() {
      if (this.$parent.singleSelect || this.$parent.multiple === 1) {
        const obj = Object.assign({}, this.leftPanel.checkedList[0]);
        obj.isExclude = true;
        this.rightPanel.list = [obj];
      } else {
        if (this.rightPanel.list.length === 0) {
          this.leftPanel.checkedList.forEach(item => {
            const obj = Object.assign({}, item, { isExclude: true });
            this.rightPanel.list.push(obj);
          });
        } else {
          this.leftPanel.checkedList.forEach(item => {
            const index = this.rightPanel.list.findIndex(cItem => {
              return cItem[this.$parent.props.value] === item[this.$parent.props.value];
            });
            if (index === -1) {
              const obj = Object.assign({}, item);
              obj.isExclude = true;
              this.rightPanel.list.push(obj);
            } else if (index > -1 && !this.rightPanel.list[index].isExclude) {
              this.rightPanel.list[index].isExclude = true;
            }
          });
        }
      }
      this.emitDataInfo();
    },
    /**
     * @description 全选/取消左侧侧面板数据
     * @param val 是否已全选=>布尔值
     */
    toggleLeftCheckAll() {
      if (this.leftPanel.checkedList.length === this.allowCheckList.length) {
        this.allowCheckList.forEach((v) => {
          v.checked = false;
        });
      } else {
        this.allowCheckList.forEach((v) => {
          v.checked = true;
        });
      }
      this.$nextTick(() => {
        this.leftPanel.checkedList = this.$refs.tree.getCheckedNodes();
        this.resetLeftCheckStatus();
      });
    },
    /**
     * @description 全选/取消右侧面板数据
     * @param val 是否已全选=>布尔值
     */
    toggleRightCheckAll(val) {
      const list = [];
      this.rightPanel.list.map(item => {
        if (!item.disabled) {
          list.push(item);
        }
      });
      this.rightPanel.checkedList = val ? list : [];
      this.rightPanel.isIndeterminate = false;
    },
    /**
     * @description 右侧面板的数据选中/取消选中
     * @param value
     */
    rightPanelCheckedChange(value) {
      const checkedCount = value.length;
      let totalCount = 0;
      this.rightPanel.list.map(item => {
        if (!item.disabled) {
          totalCount += 1;
        }
      });
      // 如果已勾选数量等于总可选数量，则表示全选了
      this.rightPanel.checkedAll = checkedCount === totalCount;
      this.rightPanel.isIndeterminate =
        checkedCount > 0 && checkedCount < totalCount;
    },
    /**
     * @description 数据上层冒泡
     */
    emitDataInfo() {
      this.rightPanel.checkedList = [];
      this.rightPanel.checkedAll = false;
      this.rightPanel.isIndeterminate = false;
      this.$refs.tree.setCheckedKeys([]);
      this.leftPanel.checkedList = [];
      this.resetLeftCheckStatus();
      this.$parent.$emit("input", this.rightPanel.list);
      this.$parent.$emit("change", this.rightPanel.list);
    },
    /**
     * 重新设置左边整体状态
     */
    resetLeftCheckStatus() {
      this.leftPanel.checkedAll = this.leftPanel.checkedList.length === this.allowCheckList.length && this.allowCheckList.length > 0;
      this.leftPanel.isIndeterminate = this.leftPanel.checkedList.length > 0 && this.leftPanel.checkedList.length < this.allowCheckList.length;
    }
  }
};
</script>
