/* eslint-disable */
import XEUtils from "xe-utils/methods/xe-utils";
import GlobalConfig from "../../conf";
import { Renderer } from "../../v-x-e-table";
import CellUtils from "./cellUtils"

export const Cell = {
  /**
   * 创建渲染对象
   */
  createRenderMap($table) {
    let { type, editRender, $scopedSlots, columnConfig } = this;
    const { treeConfig } = $table;
    const isTreeNode = treeConfig;

    if (!editRender && $scopedSlots && $scopedSlots.edit) {
      // 只有mounted以后才有
      editRender = { type: "visible" };
      if (columnConfig) {
        columnConfig.editRender = editRender;
      }
    }

    const renMaps = {
      renderHeader: this.renderHeader,
      renderCell: isTreeNode ? this.renderTreeCell : this.renderCell
    };
    switch (type) {
      case "index":
        renMaps.renderHeader = this.renderIndexHeader;
        renMaps.renderCell = isTreeNode ? this.renderTreeIndexCell : this.renderIndexCell;
        break;
      case "radio":
        renMaps.renderHeader = this.renderRadioHeader;
        renMaps.renderCell = isTreeNode ? this.renderTreeRadioCell : this.renderRadioCell;
        break;
      case "selection":
      case "index-selection": // 选择有定制开发(序号+选择)
      case "column-selection": // 列选择(不会影响真实选择, 每个格子的选择)
        renMaps.renderHeader = this.renderSelectionHeader;
        renMaps.renderCell = isTreeNode ? this.renderTreeSelectionCell : this.renderSelectionCell;
        break;
      case "expand":
        renMaps.renderCell = this.renderExpandCell;
        renMaps.renderData = this.renderExpandData;
        break;
      default:
        renMaps.renderHeader = this.renderFilterAndSortHeader;
        if (editRender) {
          // renMaps.renderHeader = this.renderEditHeader;
          renMaps.renderCell = isTreeNode ? this.renderTreeCellEdit : this.renderCellEdit; //
        } else {
          // renMaps.renderHeader = this.renderFilterAndSortHeader;
        }
    }

    return renMaps;
  },
  /**
   * 更新 ConlumnConfig 复用的时候有用
   */
  updateColumnConfig() {
    const { columnConfig } = this;
    const renMaps = this.createRenderMap(this.$table);
    columnConfig.refreshRenderMap(this, renMaps);
    columnConfig.refreshColumnDefaultValueIfNeed();
  },
  /**
   * 单元格
   */
  renderHeader(h, params) {
    const { column } = params;
    const { slots, title, renderCustomHeader } = column;
    if (renderCustomHeader) {
      return [
        renderCustomHeader(h, column)
      ];
    }
    if (slots && slots.header) {
      return slots.header(params, h);
    }
    // 在 v3.0 中废弃 label
    let res = [
      CellUtils.formatText(CellUtils.getFuncText(title), 1)
    ];
    // 固定列加图标
    if (params.fixed === "left" || params.fixed === "right") {
      res = [
        CellUtils.formatText(CellUtils.getFuncText(title), 1)
        // h("en-icon", { class: "vxe-ctxmenu--link-prefix", attrs: { name: "iconsuodinglie", size: 12, color: "#26c293" }, style: {color: "#26c293 !important"} }) 
      ];
    }
    return res;
  },
  renderCell(h, params) {
    const { $table, row, column } = params;
    const { slots, editRender, cellRender } = column;
    const renderOpts = editRender || cellRender;
    if (slots && slots.default) {
      return slots.default(params, h);
    }
    if (renderOpts) {
      const funName = editRender ? "renderCell" : "renderDefault";
      const compConf = Renderer.get(renderOpts.name);
      if (compConf && compConf[funName]) {
        return compConf[funName].call($table, h, renderOpts, params, {
          $type: editRender ? "edit" : "cell",
          $excel: $table.$parent,
          $table,
          $column: column
        });
      }
    }
    const cellValue = CellUtils.getCellLabel(row, column, params);
    return [CellUtils.formatText(cellValue, 1)];
  },
  renderTreeCell(h, params) {
    return Cell.renderTreeIcon(h, params).concat(
      Cell.renderCell.call(this, h, params)
    );
  },

  /**
   * 树节点
   */
  renderTreeIcon(h, params) {
    const { column } = params;
    if (column && !column.treeNode) {
      // 需要显示需要图标 (编辑模式不能显示这些, 数据已处理  || column.editRender))
      return [];
    }

    const { icon } = GlobalConfig;
    const { $table } = params;
    const { treeConfig, treeExpandeds, lazyLoadRows } = $table;
    const { row, level } = params;
    const { children, indent, trigger } = treeConfig;
    const rowChildren = row[children];
    const isLoading = lazyLoadRows.indexOf(row) > -1;
    const isActive = !isLoading && treeExpandeds.indexOf(row) > -1;
    const on = {};
    if (!trigger || trigger === "default") {
      on.click = evnt => $table.triggerTreeExpandEvent(evnt, params);
    }

    // add by xu替换图标
    const renderChildren = [];
    if (isLoading) {
      renderChildren.push(h("i", {
        class: ` ${isLoading ? icon.treeLoading : ""}`
      }));
    } else if (rowChildren) {
      renderChildren.push(h("i", {
        class: `vxe-tree--node-btn ${isActive ? icon.treeExpand : icon.tree}`
      }));
    } else {
      return []
    }

    return [
      h("span", {
        class: "vxe-tree--indent",
        style: { width: `${level * (indent || 16)}px` }
      }),
      h(
        "span",
        {
          class: ["vxe-tree-wrapper", { "is--active": isActive }],
          on
        },
        renderChildren
      )
    ];
  },

  /**
   * 索引
   */
  renderIndexHeader(h, params) {
    const { column } = params;
    const { slots, title } = column;
    if (slots && slots.header) {
      return slots.header(params, h);
    }
    // 在 v3.0 中废弃 label
    return [
      CellUtils.formatText(
        CellUtils.getFuncText(title || "序号"),
        1
      )
    ];
  },
  renderIndexCell(h, params) {
    const { $table, column } = params;
    let { startIndex, pageConfig } = $table;
    const { slots, indexMethod } = column;
    if (slots && slots.default) {
      return slots.default(params, h);
    }
    const { $seq, seq, level } = params;
    if (pageConfig && pageConfig.pageModel && pageConfig.pageModel.pageNo > 1) {
      startIndex += (pageConfig.pageModel.pageNo - 1) * pageConfig.pageModel.pageSize;
    }
    return [
      CellUtils.formatText(
        indexMethod
          ? indexMethod(params)
          : level
            ? `${$seq}.${seq}`
            : startIndex + seq,
        1
      )
    ];
  },
  renderTreeIndexCell(h, params) {
    return Cell.renderTreeIcon(h, params).concat(
      Cell.renderIndexCell(h, params)
    );
  },

  /**
   * 单选
   */
  renderRadioHeader(h, params) {
    const { column } = params;
    const { slots, title } = column;
    if (slots && slots.header) {
      return slots.header(params, h);
    }
    // 在 v3.0 中废弃 label
    return [
      CellUtils.formatText(CellUtils.getFuncText(title), 1)
    ];
  },
  renderRadioCell(h, params) {
    const { $table, column, isHidden } = params;
    const { vSize } = $table;
    const { slots, property, selectConfig } = column;

    if (slots && slots.default) {
      return slots.default(params, h);
    }
    const { checkDisabledMethod, selectRow } = selectConfig;
    let isDisabled = !!checkDisabledMethod;
    const { row } = params;
    const options = {
      attrs: {
        type: "radio",
        name: `vxe-radio--${$table.id}`
      }
    };
    if (!isHidden) {
      if (checkDisabledMethod) {
        isDisabled = checkDisabledMethod(params);
        options.attrs.disabled = isDisabled;
      }
      options.domProps = { checked: row === selectRow };
      options.on = {
        change(evnt) {
          // evnt.stopPropagation();
          $table.triggerRadioRowEvent(evnt, params);
        }
      };
    }
    return [
      h(
        "label",
        {
          class: [
            "vxe-radio",
            {
              [`size--${vSize}`]: vSize,
              "is--disabled": isDisabled
            }
          ]
        },
        [
          h("input", options),
          h("span", { class: "vxe-radio--icon" }),
          property
            ? h(
              "span",
              { class: "vxe-radio--label" },
              XEUtils.get(row, property)
            )
            : null
        ]
      )
    ];
  },
  renderTreeRadioCell(h, params) {
    return Cell.renderTreeIcon(h, params).concat(
      Cell.renderRadioCell(h, params)
    );
  },

  /**
   * 多选(正常)
   */
  renderSelectionHeader(h, params) {
    // debugger;
    const { $table, column, isHidden } = params;
    const { slots, title, selectConfig }  = column;
    // 在 v3.0 中废弃 label

    if (slots && slots.header) {
      return slots.header(params, h);
    }

    const { vSize } = $table;
    const {
      checkStrictly,
      showHeader,
      isIndeterminate,
      isAllSelected
    } = selectConfig;
    if (checkStrictly ? !showHeader : showHeader === false) {
      return [];
    }

    const options = { attrs: { size: vSize, indeterminate: isIndeterminate } };

    if (!isHidden) {
      options.attrs.value = isAllSelected; // 是否选中
      options.on = {
        change(checked, evnt) {
          // evnt.stopPropagation();
          $table.triggerCheckAllEvent(evnt, params, checked);
        }
      };
    }
    const renderList = [];
    // 添加序号
    const indexHeaderRender = this.renderIndexHeader(h, params);
    const isSelectOne = isIndeterminate || isAllSelected; // 选中了一个
    const isIndexSelection = column.type === "index-selection"; // 是否是序号选择
    if (!isSelectOne && isIndexSelection) {
      renderList.push(
        h("span", { class: ["vxe-index-selection", "is--index"] }, indexHeaderRender)
      );
    }
    renderList.push(h(
      "el-checkbox",
      {
        class: ["vxe-checkbox", !isSelectOne ? "vxe-checkbox-header" : ""],
        attrs: options.attrs,
        domProps: options.domProps,
        on: options.on
      },
      title ? CellUtils.getFuncText(title) : null
      // headerTitle ? h("span", { class: "vxe-checkbox--label" }, CellUtils.getFuncText(headerTitle)) : null      )
    ))
    return renderList;
  },
  renderSelectionCell(h, params) {
    // debugger;
    const { $table, row, column, isHidden } = params;
    const { slots, property, selectConfig } = column;
    const isIndexSelection = column.type === "index-selection"; // 是否是序号选择

    if (!isIndexSelection && slots && slots.default) {
      return slots.default(params, h);
    }

    const { checkHiddenMethod } = selectConfig;
    if (checkHiddenMethod && checkHiddenMethod(params)) {
      // 隐藏(全部隐藏, 若以后有特殊需求只需要隐藏checkbox再说)
      return [];
    }

    // 处理逻辑
    const options = {};
    const {
      treeIndeterminates,
      isIndeterminate,
      isAllSelected,
      checkDisabledMethod,
      selection
    } = selectConfig;
    const { vSize, treeConfig } = $table;
    let isDisabled = !!checkDisabledMethod;
    let indeterminate = false;

    // 序号读取 renderIndexCell( add by xu )
    const isSelectOne = isIndeterminate || isAllSelected; // 选中了一个
    const isIndexSelected = isIndexSelection && !isSelectOne && !selection.length;

    if (!isHidden) {
      if (checkDisabledMethod) {
        isDisabled = checkDisabledMethod(params);
      }
      if (treeConfig) {
        indeterminate = treeIndeterminates.indexOf(row) > -1;
      }

      // if (checkField || checkMethod) { //存在checkField
      //   options.checked = checkMethod ? checkMethod(params) : XEUtils.get(row, checkField);
      // } else {
      options.checked = selectConfig.selection.indexOf(row) > -1;
      // }

      options.on = {
        change(checked, evnt) {
          $table.triggerCheckRowEvent(evnt, params, checked);
        }
      };
    }

    // 最后一步:渲染输出
    const renderList = [];
    if (isIndexSelected) {
      // 添加序号
      const indexRender = this.renderIndexCell(h, params);
      renderList.push(
        h("span", { class: ["vxe-index-selection", "is--index"] }, indexRender)
      );
    }
    // 添加selection

    renderList.push(
      h(
        "el-checkbox",
        {
          class: [
            "vxe-checkbox",
            {
              "vxe-index-selection": isIndexSelected,
              "is--selection": isIndexSelected
            }
          ],
          attrs: {
            size: vSize,
            indeterminate: indeterminate,
            value: options.checked,
            disabled: isDisabled
          },
          domProps: options.domProps,
          on: options.on
        },
        property ? XEUtils.get(row, property) : null
        // property ? h("span", { class: "vxe-checkbox--label" }, XEUtils.get(row, property)) : null
      )
    );

    return renderList;
  },
  renderTreeSelectionCell(h, params) {
    return Cell.renderTreeIcon(h, params).concat(
      Cell.renderSelectionCell(h, params)
    );
  },

  /**
   * 展开行
   */
  renderExpandCell(h, params) {
    const { $table, isHidden } = params;
    let expandActive = false;
    if (!isHidden) {
      expandActive = $table.expandeds.indexOf(params.row) > -1;
    }
    return [
      h("span", {
          class: ["vxe-table--expanded", { "expand--active": expandActive }],
          on: {
            click(evnt) {
              $table.triggerRowExpandEvent(evnt, params);
            }
          }
        },
        [h("i", { class: "vxe-table--expand-icon" })]
      )
    ];//.concat(this.renderCell(h,params))//这里也可以改改, 具体看实际业务场景, 但改的话 默认插槽default的名称改下
  },
  renderExpandData(h, params) {
    const { column } = params;
    const { slots } = column;
    if (slots && slots.default) {
      return slots.default(params, h);
    }
    return [];
  },

  /**
   * 筛选/排序
   */
  renderFilterAndSortHeader(h, params) {
    const { $table, column } = params;
    const { treeConfig, filterStore } = $table;
    const { filterSortConfig, treeNode, filterSortStore } = column;
    const canFilter = filterSortConfig ? filterSortConfig.filterable : false;
    const canSort = filterSortConfig ? filterSortConfig.sortable : false;
    const renderFilterSort = [];
    const isTreeNode = treeConfig && treeNode;
    const { sort, valueText, value } =  filterSortStore;
    const hasSortedOrFilter = sort || valueText || value
    if (canFilter) {
      renderFilterSort.push(Cell.renderFilterIcon(h, params, valueText || value));
    }

    if (canSort) {
      renderFilterSort.push(Cell.renderSortIcon(h, params));
    }

    const renderList = [];

    if (isTreeNode) {
      // let renderExpandAll =  (<el-tooltip  effect="dark" content="一键展开/收起" placement="top">
      //   <en-button class="vxe-tree-wrapper vxe-tree--head-expand-btn" type="text" icon="iconyijianzhankai" iconColor="aeaeae" on-click={ () => $table.triggerExpandAllEvent() }></en-button>
      // </el-tooltip>);
      // renderList.push(renderExpandAll);

      let className = "vxe-tree-wrapper vxe-tree--head-expand-btn";
      if ($table.isTreeAllExpand()) {
        // 展开状态
        className += " is--expand";
      }
      //
      const renderExpandAll = h("span", { class: "vxe-header-flex-center" }, [
        h("en-button", {
          class: className,
          props: {
            type: "text",
            icon: "iconyijianzhankai",
            iconColor: "aeaeae"
          },
          on: {
            click(data, event) {
              event.stopPropagation();
              $table.triggerExpandAllEvent();
            }
          }
        }),
        h("span", { class: "vxe-name-wrapper" }, Cell.renderHeader(h, params))
      ]);

      // let renderExpandAll = (<span class="vxe-header-flex-center">
      //     <en-button class={className} type="text" icon="iconyijianzhankai" iconColor="aeaeae" on-click={ () => $table.triggerExpandAllEvent() }></en-button>
      //     <span class="vxe-name-wrapper">{Cell.renderHeader(h, params)}</span>
      //   </span>);
      renderList.push(renderExpandAll);

      // renderList.push(h("en-button",{
      //   class :[ "vxe-tree-wrapper", "vxe-tree--head-expand-btn" ],
      //   attrs :{
      //     icon: "yijianzhankai", type: "text" ,iconColor: "aeaeae"
      //   },
      //   on: {
      //     click () {
      //       $table.triggerExpandAllEvent();
      //     }
      //   }
      // },null));
    } else {
      // 内容区域
      // renderList.push(Cell.renderHeader(h, params));
      renderList.push(
        h("span", { class: "vxe-name-wrapper",  on: { click() { 
            // $table.triggerSortEvent(column, params);
          } } }, Cell.renderHeader(h, params)
        )
      );
    }

    if (renderFilterSort.length > 0) {
      renderList.push(
        h(
          "span",
          {
            class: [
              "vxe-header-icon-wrapper",
              {
                "is--filter-active":
                  filterStore.visible && filterStore.column === column || hasSortedOrFilter,
                "vxe-flex-justify-space-between": canFilter && canSort,
                "vxe-flex-justify-flex-end": !canFilter && canSort
              }
            ]
          },
          renderFilterSort
        )
      );
    }

    return renderList;
  },
  // 排序
  renderSortIcon(h, params) {
    const { icon } = GlobalConfig;
    const { $table, column } = params;
    return [h("span", {
        attrs: {
          title: "排序"
        },
        class: "vxe-sort-wrapper",
        on: {
          mouseenter() {
            column.filterSortStore.tooltipVisible = false;
          },
          mouseleave() {
            column.filterSortStore.tooltipVisible = true;
          }
        }
        // on: { click() { $table.triggerSortEvent(column, params); } }
        }, [
        h("i", { class: ["vxe-sort--asc-btn", icon.sortAsc,
            { "sort--active": column.filterSortStore && column.filterSortStore.sort === "asc" }
          ], on: { click(e) {
            $table.triggerSortEvent(column, params, "asc");
            e.stopPropagation();
            e.preventDefault();
          } } }
          ),
          h("i", { class: ["vxe-sort--desc-btn", icon.sortDesc,
              { "sort--active": column.filterSortStore && column.filterSortStore.sort === "desc" }
            ], on: { click(e) {
              $table.triggerSortEvent(column, params, "desc");
              e.stopPropagation();
              e.preventDefault();
            } }
          })
        ]
      )
    ];
  },
  // 筛选
  renderFilterIcon(h, params, hasFilter) {
    const { icon } = GlobalConfig;
    const { $table, column } = params;
    const {  filterStore } = $table;//optimizeOpts,

    return [
      h('span', {
        attrs: {
          title: "筛选"
        },
        class: ['vxe-filter-wrapper', {
          'is--active': filterStore.visible && filterStore.column === column
        }]
      }, [
        h("en-button", {
          props: {
            type: "text",
            icon: "iconlieshaixuan-xiala",
            iconColor: filterStore.visible && filterStore.column === column || hasFilter ? "9c75ea" : "b1afee",
            iconSize: 13
          },
          on: {
            click(data, evnt) {
              evnt.stopPropagation();
              $table.triggerFilterEvent(evnt, params.column, params);
            }
          },
          nativeOn: {
            mouseenter() {
              column.filterSortStore.tooltipVisible = false;
            },
            mouseleave() {
              column.filterSortStore.tooltipVisible = true;
            }
          }
        })
        // h('i', {
        //   class: ['vxe-filter--btn', icon.filter],
        //   on: {
        //     click (evnt) {
        //       $table.triggerFilterEvent(evnt, params.column, params)
        //     }
        //   }
        // })
      ])
    ];

    // debugger;
    // let iconName = "vxe-filter--btn " + icon.filter;
    // let click = function(evnt) {
    //   debugger;
    //   $table.triggerFilterEvent(evnt, params.column, params);
    // };
    //
    // let popover = (<el-popover v-model={filterStore.visible} class="vxe-filter-wrapper" popperClass="vxe-filter-popper">
    //   <vxe-table-filter optimizeOpts={optimizeOpts} filterStore={filterStore}></vxe-table-filter >
    //   <i class={iconName} slot="reference" onClick={click}></i>
    // </el-popover>);
    //
    // return popover;
    // return [
    //   h("el-popover", {
    //       class: ["vxe-filter-wrapper", { "is--active": filterStore.visible && filterStore.column === column } ],
    //       props: { placement: "bottom", trigger: "click", popperClass: "vxe-filter-popper" },
    //       ref: "popover",
    //       refInFor: true,
    //       domProps: { value: filterStore.visible }, // 实现v-model 具体参考vue官网
    //       on: {
    //         // 实现v-model 具体参考vue官网
    //         input: function(value) {
    //           // debugger;
    //           // if (filterStore.visible === value) {
    //           //   return;
    //           // }
    //           if (value) {
    //             setTimeout(() => {
    //               filterStore.visible = value; // 解决连续点击 不同列 筛选 导致关闭打开 图标消失的bug
    //             });
    //           } else {
    //             filterStore.visible = value;
    //           }
    //         }
    //       }
    //     },
    //     [h("vxe-table-filter", { props: { optimizeOpts, filterStore } }),
    //       h("i", {
    //         class: `vxe-filter--btn ${icon.filter}`,
    //         slot: "reference",
    //         on: {
    //           click(evnt) {
    //             $table.triggerFilterEvent(evnt, params.column, params);
    //           }
    //         }
    //       })
    //     ]
    //   ) //
    // ];
  },

  /**
   * 可编辑
   */
  renderEditHeader(h, params) {
    // let { icon } = GlobalConfig;
    const { $table, column } = params;
    const { editRules } = $table;
    const { filterSortStore = {} } = column;
    const { sortable, filterable } = filterSortStore;
    let isRequired;
    if (editRules) {
      const columnRules = XEUtils.get(editRules, params.column.property);
      if (columnRules) {
        isRequired = columnRules.some(rule => rule.required);
      }
    }
    // add by xu 去掉图标
    return [isRequired ? h("i", { class: "vxe-required-icon" }) : null, null]
      .concat(Cell.renderHeader(h, params))
      .concat(sortable ? Cell.renderSortIcon(h, params) : [])
      .concat(filterable ? Cell.renderFilterIcon(h, params) : []);
  },
  // 行格编辑模式
  renderRowEdit(h, params) {
    const { $table } = params;
    const { actived } = $table.editStore;
    return Cell.runRenderer(
      h,
      params,
      this,
      actived && actived.row === params.row
    );
  },
  renderTreeRowEdit(h, params) {
    return Cell.renderTreeIcon(h, params).concat(Cell.renderRowEdit(h, params));
  },
  // 单元格编辑模式
  renderCellEdit(h, params) {
    const { $table } = params;
    const { actived } = $table.editStore;
    return Cell.runRenderer(
      h,
      params,
      this,
      actived && actived.row === params.row && actived.column === params.column
    );
  },
  renderTreeCellEdit(h, params) {
    return Cell.renderTreeIcon(h, params).concat(
      Cell.renderCellEdit(h, params)
    );
  },
  runRenderer(h, params, _vm, isEdit) {
    const { $table, row, column } = params;
    let { slots, formatter, editRender } = column;
    const compConf = Renderer.get(editRender.name);

    if (isEdit || editRender.type === "visible") {
      if (slots && slots.edit) {
        return slots.edit(Object.assign({}, params), h);
      }
      editRender = column.refreshEditRenderIfNeeded(column.editRender);//column.own.editRender
      return compConf && compConf.renderEdit
        ? compConf.renderEdit.call($table, h, editRender, params, {
          $type: "edit",
          $excel: $table.$parent,
          $table,
          $column: column
        })
        : [];
    }
    if (slots && slots.default) {
      return slots.default(row, h);
    }
    if (formatter) {
      return [
        CellUtils.formatText(CellUtils.getCellLabel(row, column, params), 1)
      ];
    }
    return Cell.renderCell.call(_vm, h, params);
  }
};

export default Cell;
