/**
 * project: enfry-components
 * fileName: render
 * Created by 徐俊 on 2020/07/29
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */
import CellUtils from "../../cell/src/cellUtils";

const Render = {

  data() {
    return {
      // 悬浮行操作按钮数据
      hoverRowData: undefined, // 不能共用hoverRow
      hoverRowButtonDatas: []
    }
  },
  render(h) {
    const children = [
      // 筛选工具栏
      h("div", { class: "vxe-main-box" }, [
        this.renderFilterSort(h), // 渲染表格
        this.renderTable(h)]), // 表格组件
      this.renderPagination(h)// 分页组件
    ];

    if (this.loading) {
      children.push(h("div", { class: "vxe-table--loading" }, [h("en-result", { props: { type: "loading" } })]));
    }

    return h("div", { class: "vxe-table-box" }, children);
  },
  methods: {

    renderFilterSort(h) {
      const { filterToolbarVisible, _e } = this;
      if (!filterToolbarVisible) {
        return _e();
      }
      const slots = [];
      if (this.$scopedSlots.filterToolbar) {
        slots.push(h("template", { slot: "default" }, this.$scopedSlots.filterToolbar()));
      }
      if (this.$scopedSlots.filterToolbarLeft) {
        slots.push(h("template", { slot: "left" }, this.$scopedSlots.filterToolbarLeft()));
      }
      if (this.$scopedSlots.filterToolbarRight) {
        slots.push(h("template", { slot: "right" }, this.$scopedSlots.filterToolbarRight()));
      }
      return h("en-table-filter-toolbar", { props: { filterToolbarVisible, filterStore: this.filterStore } }, slots);
    },
    renderPagination(h) {
      const { pageConfig } = this;

      if (!pageConfig || pageConfig.hidePage) {
        return undefined;
      }

      const slots = [];
      if (this.$scopedSlots.pagination) {
        slots.push(h("template", { slot: "default" }, this.$scopedSlots.pagination(pageConfig.pageModel)));
      }
      if (this.$scopedSlots.paginationLeft) {
        slots.push(h("template", { slot: "left" }, this.$scopedSlots.paginationLeft(pageConfig.pageModel)));
      }
      if (this.$scopedSlots.paginationRight) {
        slots.push(h("template", { slot: "right" }, this.$scopedSlots.paginationRight(pageConfig.pageModel)));
      }

      return h("en-pagination", {
        props: { pageModel: pageConfig.pageModel, type: pageConfig.type }, //, disabled: loading
        ref: "pagination",
        on: { change: pageConfig.changeMethod }
      }, slots);
    },
    renderTable(h) {
      const {
        _e,
        id,
        tableData,
        tableColumn,
        visibleColumn,
        collectColumn,
        isGroup,
        isResizable,
        isCtxMenu,
        showHeader,
        showBorder,
        showBorderRadius,
        stripe,
        height,
        highlightHoverRow,
        highlightHoverColumn,
        highlightCell,
        vSize,
        showOverflow,
        showHeaderOverflow,
        validOpts,
        mouseConfig = {},
        editRules,
        showFooter,
        footerMethod,
        overflowX,
        overflowY,
        scrollbarHeight,
        optimizeOpts,
        vaildTipOpts,
        tooltipConfig,
        columnStore,
        ctxMenuStore,
        footerData,
        hasFilter,
        filterStore,
        hasTip,
        noDataIconSize,
        theme
      } = this;
      const { leftList, rightList } = columnStore;
      this.setupBeforceRender();
      // debugger

      return h(
        "div",
        {
          class: {
            "vxe-table": 1,
            [`vxe-table-${theme}`]: theme,
            [`size--${vSize}`]: vSize,
            "show--head": showHeader,
            "show--foot": showFooter,
            "scroll--y": overflowY,
            "scroll--x": overflowX,
            "fixed--left": leftList.length,
            "fixed--right": rightList.length,
            "all-overflow": showOverflow,
            "all-head-overflow": showHeaderOverflow,
            "c--highlight": highlightCell,
            "t--animat": optimizeOpts.animat,
            "t--stripe": stripe,
            "t--border": showBorder,
            "t--border-radius": showBorderRadius,
            "t--checked": mouseConfig.checked,
            "row--highlight": highlightHoverRow,
            "column--highlight": highlightHoverColumn
          }
        },
        [
          /*  隐藏列 */
          h("div", { class: "vxe-table-hidden-column", ref: "hideColumn" }, this.$slots.default),
          /*  主头部 */
          showHeader ? h("vxe-table-header", { ref: "tableHeader", props: { tableData, tableColumn, visibleColumn, collectColumn, size: vSize, isGroup } }) : _e(),
          /* 主内容 */
          h("vxe-table-body", { ref: "tableBody", props: { tableData, tableColumn, visibleColumn, collectColumn, size: vSize, isGroup, noDataIconSize } }),
          /* 底部汇总 */
          showFooter ? h("vxe-table-footer", { props: { footerData, footerMethod, tableColumn, visibleColumn, size: vSize }, ref: "tableFooter" }) : _e(),
          /* 左侧固定列 */
          leftList && leftList.length && overflowX ? this.renderFixed(h, "left") : _e(),
          /* 右侧固定列 */
          rightList && rightList.length && overflowX ? this.renderFixed(h, "right") : _e(),
          /*  表头固定操作 -- add by xu */
          this.renderHeadEnd(h),
          /*  悬浮行 -- add by xu */
          this.renderHoverRowSlot(h),
          this.renderHoverRow(h),
          /* 列宽线 */
          isResizable ? h("div", { class: "vxe-table--resizable-bar", style: overflowX ? { "padding-bottom": `${scrollbarHeight}px` } : null, ref: "resizeBar" }) : _e(),
          h("div", { class: `vxe-table${id}-wrapper ${this.$vnode.data.staticClass || ""}`, ref: "tableWrapper" }, [
            /* 筛选 */
            hasFilter ? h("vxe-table-filter", { props: { optimizeOpts, filterStore }, ref: "filterWrapper" }) : _e(),
            /*  快捷菜单 */
            isCtxMenu ? h("vxe-table-context-menu", { props: { ctxMenuStore }, ref: "ctxWrapper" }) : _e(),
            /*  Ellipsis tooltip  */
            hasTip ? h("vxe-tooltip", { ref: "tooltip", props: tooltipConfig }) : _e(),
            /* valid error tooltip */
            hasTip && editRules && (validOpts.message === "default" ? !height : validOpts.message === "tooltip")
              ? h("vxe-tooltip", { class: "vxe-table--valid-error", props: validOpts.message === "tooltip" || tableData.length === 1 ? vaildTipOpts : null, ref: "validTip" })
              : _e()
          ])
        ]
      );
    },
    /**
     * 渲染浮固定列
     */
    renderFixed(h, fixedType) {
      const {
        tableData,
        tableColumn,
        visibleColumn,
        collectColumn,
        isGroup,
        vSize,
        showHeader,
        showFooter,
        columnStore,
        footerData
      } = this;
      const fixedColumn = columnStore[`${fixedType}List`];
      return h("div", {
        class: `vxe-table--fixed-${fixedType}-wrapper`,
        ref: `${fixedType}Container`
      }, [
        showHeader ? h("vxe-table-header", {
          props: {
            fixedType,
            tableData,
            tableColumn,
            visibleColumn,
            collectColumn,
            size: vSize,
            fixedColumn,
            isGroup
          },
          ref: `${fixedType}Header`
        }) : null,
        h("vxe-table-body", {
          props: {
            fixedType,
            tableData,
            tableColumn,
            visibleColumn,
            collectColumn,
            fixedColumn,
            size: vSize,
            isGroup
          },
          ref: `${fixedType}Body`
        }),
        showFooter ? h("vxe-table-footer", {
          props: {
            fixedType,
            footerData,
            tableColumn,
            visibleColumn,
            size: vSize,
            fixedColumn
          },
          ref: `${fixedType}Footer`
        }) : null
      ]);
    },
    // 表头 更多,新增按钮
    renderHeadEnd(h) {
      const { headEndConfig, $scopedSlots, showHeader, _e } = this;
      const { headEnd } = $scopedSlots;//
      const _this = this;

      if (!showHeader || (!headEnd && !headEndConfig)) {
        return _e();
      }

      const rowList = [];
      if (headEnd) {
        rowList.push(h("span", { class: "vxe-head-end-wrapper" }, headEnd({})));
      }
      if (headEndConfig) {
        const attrs = { type: "custom", iconSize: 18 };
        let btnNode;
        if (headEndConfig.type === "add") { // 新增行
          const style = "padding: 0 10px 0 10px";
          attrs.icon = "tianjia-liebiao";
          attrs.color = "darkBlue";
          attrs.title = headEndConfig.title;
          btnNode = h("en-button", {
            style,
            attrs,
            on: {
              click: function() {
                let newRow = {};
                if (headEndConfig.rowDataMethod) {
                  newRow = headEndConfig.rowDataMethod(newRow);
                }
                if (!newRow) return;
                // 此处 适用于 往每一行前面添加
                _this.createRow(newRow).then(newRow => {
                  if (!_this.data) {
                    _this.data = [];
                  }
                  _this.data.unshift(newRow);
                  _this.$nextTick(() => _this.setActiveRow(newRow));// 选中当前行, 编辑状态会编辑
                  // 插入到第一行 (不能用下面的方法, 数据源不会改变)
                  // $table.tableFullData.unshift(newRow);
                  // $table.refreshData().then(() => $table.setActiveRow(newRow));
                });
              }
            }
          });
        } else if (headEndConfig.type === "more") { // 更多
          const on = {};
          if (headEndConfig.moreChangeMethod) {
            on.change = function(params) {
              headEndConfig.moreChangeMethod(params);
            };
          }
          if (headEndConfig.handleMinLimit) {
            on.handleMinLimit = function(params) {
              headEndConfig.handleMinLimit(params);
            };
          }
          if (headEndConfig.changeLineHeight) {
            on.changeLineHeight = function(params) {
              headEndConfig.changeLineHeight(params);
            };
          }
          btnNode = h("en-table-head-end-more", { props: { ...headEndConfig, columns: this.tableFullColumn, $table: this }, on });
        }

        if (btnNode) {
          rowList.push(h("span", { class: "vxe-head-end-wrapper" }, [btnNode]));
        }
      }

      return rowList;
    },

    renderHoverRowSlot(h) {
      const { $scopedSlots, fullAllDataRowIdData, _e } = this;
      const hoverRowSlot = $scopedSlots ? $scopedSlots.hoverRow : undefined;// 悬浮插槽

      if (!hoverRowSlot || !fullAllDataRowIdData) {
        return _e();
      }

      const rowList = [];

      // Object.keys(fullAllDataRowIdData).forEach(rowId => {
      //   const rowData = fullAllDataRowIdData[rowId];
      //   rowList.push(h("span", { class: "vxe-hover-row-right-wrapper", attrs: { "hover-rowid": rowId } }, hoverRowSlot({ row: rowData.row, rowIndex: this.getRowIndex(rowData.row) })));
      // })

      const row = this.hoverRowData || {};
      // ref: "rowHover"
      rowList.push(h("span", {
        class: "vxe-hover-row-right-wrapper",
        attrs: { "hover-rowid": CellUtils.getRowid(this, row) },
        ref: "rowHoverSlot" },
      hoverRowSlot({ row, rowIndex: this.getRowIndex(row) })));

      return rowList;
    },
    /* 悬浮行 */
    renderHoverRow(h) {
      const { hoverRowConfig, _e } = this;
      if (!hoverRowConfig) {
        return _e();
      }

      const _this = this;
      const { changeMethod } = hoverRowConfig;
      const slots = [];
      const on = {};

      if (changeMethod) {
        on["button-click"] = (index, buttonData) => {
          const row = _this.hoverRowData;
          const rowIndex = _this.getRowIndex(row);
          changeMethod({ row, rowIndex, btnIndex: index, btnData: buttonData })
        };
      }

      // 每行不同的插槽不知道如何处理
      if (this.$scopedSlots.hoverRowLeft) {
        slots.push(h("template", { slot: "left" }, this.$scopedSlots.hoverRowLeft()));
      }
      if (this.$scopedSlots.hoverRowRight) {
        slots.push(h("template", { slot: "right" }, this.$scopedSlots.hoverRowRight()));
      }

      // "row--hover": true
      return h("span", { class: { "vxe-hover-row-right-wrapper": true }, ref: "rowHover" }, [
        h("en-expand-buttons", {
          on,
          attrs: {
            data: _this.hoverRowButtonDatas
          },
          style: {
            display: _this.hoverRowButtonDatas.length ? "" : "none"
          },
          ref: "rowHoverButtons" })
      ], slots);
    }
  }

};
export default Render;
