// import GlobalConfig from "../../conf";
import { Renderer } from "../../v-x-e-table";
import { Utils } from "../../tools";

export default {
  name: "VxeTableFilter",
  props: {
    filterStore: Object,
    optimizeOpts: Object
  },
  inject: ["$table"],
  render(h) {
    const { filterStore, optimizeOpts } = this;
    return h(
      "div",
      {
        class: [
          "vxe-table--filter-wrapper",
          "filter--prevent-default",
          {
            "t--animat": optimizeOpts.animat,
            "filter--active": filterStore.visible
          }
        ],
        style: filterStore.style
      },
      [
        h(
          "div",
          { class: "vxe-table--filter-body " + this.filterRenderClassName() },
          this.renderOptions(h)
        ),
        this.renderFooter(h)
      ]
    );
  },
  methods: {
    renderOptions(h) {
      const { $parent: $table, filterStore } = this;
      // let { vSize } = $table;
      const { args, column = {} } = filterStore;
      const { slots, filterRender } = column;
      const compConf = filterRender ? Renderer.get(filterRender.name) : null;
      if (slots && slots.filter) {
        // debugger;
        return slots.filter.call(
          $table,
          Object.assign(
            {
              $table,
              context: this
            },
            args
          ),
          h
        );
      } else if (compConf && compConf.renderFilter) {
        return compConf.renderFilter.call($table, h, filterRender, args, this);
      }
      return null;
    },
    renderFooter(h) {
      const { column = {} } = this.filterStore;
      return h("div", { class: "vxe-table--filter-footer" }, [
        column.filterSortConfig && column.filterSortConfig.hideColumnSetting
          ? h("span")
          : h("en-table-column-setting", { on: { hideColumn: this.confirmHideColumn, fixedColumn: this.confirmFixedColumn } }),
        // this.renderColumnSetting(h),
        h("en-button", { style: { width: "62px", minWidth: "62px" }, on: { click: this.confirmFilter } }, "筛选", null)
      ]);
    },
    renderColumnSetting(h) {
      return h("el-popover", {
        props: {
          placement: "bottom-start",
          popperClass: "column-setting-popver",
          value: this.popverVisible,
          width: 200,
          trigger: "click"
        }
      }, [
        h("span", {
          slot: "reference"
        }, [
          h("en-icon", {
            attrs: {
              color: "#4694df"
            },
            props: {
              name: "icongengduo",
              size: 14
            }
          }),
          h("span", {
            class: "setting-text"
          }, "设置列")
        ]),
        h("ul", {
          class: "column-setting-container"
        }, [
          h("li", {
            class: "column-setting-item",
            on: {
              click: this.confirmHideColumn
            }
          }, [
            h("en-icon", {
              attrs: {
                color: "#9876cf"
              },
              props: {
                name: "iconpiliangchuli-weidu",
                size: 12
              }
            }),
            h("span", {
              class: "setting-text",
              style: {
                top: "-1px"
              }
            }, "隐藏此列")
          ]),
          h("li", {
            class: "column-setting-item",
            on: {
              click: this.confirmFixedColumn
            }
          }, [
            h("en-icon", {
              attrs: {
                color: "#f7cd5c"
              },
              props: {
                name: "iconsuodinglie",
                size: 12
              }
            }),
            h("span", {
              class: "setting-text",
              style: {
                top: "-1px"
              }
            }, "固定此列")
          ])
        ])
      ])
    },
    confirmFixedColumn() {
      const { tableColumn } = this.$table;
      this.popverVisible = false;
      let lockedNumber = tableColumn.findIndex(column => column.field === this.filterStore.column.field);
      for (const item of this.$table.tableColumn) {
        if (!item.isCustomColumn() || item.fixed === "right") {
          continue
        }
        const locked = item.visible && lockedNumber > 0
        if (locked) {
          --lockedNumber
        }
        item.fixed = locked ? "left" : undefined
      }
      this.handleColumnChange();
    },
    confirmHideColumn() {
      const { tableColumn } = this.$table;
      this.popverVisible = false;
      if (tableColumn.length < 2) {
        return;
      }
      const { column } = this.filterStore;
      this.$table.hideColumn(column)
      this.handleColumnChange();
    },
    handleColumnChange() {
      const { tableColumn } = this.$table;
      this.$forceUpdate()
      this.$table.refreshColumn()
      const fieldSort = []
      for (const column of tableColumn) {
        const obj = Object.assign({}, column)
        obj.own = undefined
        fieldSort.push(obj)
      }
      Utils.emitEvent(this.$table, "column-change", {
        fieldSort: JSON.stringify(fieldSort),
        fixedColumnNum: tableColumn.filter(item => item.fixed === "left" && !["index-selection", "index"].includes(item.type)).length
      })
    },
    filterRenderClassName() {
      const { filterStore } = this;
      const { column = {} } = filterStore;
      const { filterRender } = column;

      if (filterRender) {
        if (filterRender.name === "ElInput") {
          return "vxe-default-input";
        }
        if (filterRender.name === "ElSelect") {
          return "vxe-default-select";
        }
        if (filterRender.name === "EnDatePicker") {
          return "vxe-default-datePicker";
        }
      }

      return "";
    },
    /** ***********************
     * Publish methods
     *************************/
    // 确认筛选 (modify by xu)
    confirmFilter() {
      const popover = this;
      popover.$parent.confirmFilterEvent();

      // if (popover.$refs) {
      //   for (const ref of popover.$refs.popover) {
      //     ref.doClose(); // 代码写的双向绑定 为毛不起作用, 先这么做吧
      //   }
      // }
    },
    // 重置筛选
    resetFilter() {
      this.$parent.resetFilterEvent();
    }
    /** ***********************
     * Publish methods
     *************************/
  }
};
