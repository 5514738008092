/*
 * @Author: 梁平贤
 * @Description: file content
 * @Date: 2020-05-11 17:25:02
 * @LastEditors: 梁平贤
 * @LastEditTime: 2020-05-11 17:43:21
 * @FilePath: /en-components/packages/components/en-input-range/index.js
 */
import component from "./src";
component.install = function(Vue) {
  Vue.component(component.name, component);
};
export default component;
