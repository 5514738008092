/*
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-10-11 11:42:39
 * @LastEditTime: 2019-10-11 11:42:39
 */
import component from "./src";
component.install = function(Vue) {
  Vue.component(component.name, component);
};
export default component;
