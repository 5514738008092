/**
 * project: enfry-components
 * fileName: array
 * Created by 徐俊 on 2019/12/05
 * Copyright © 2019 Enfry Ltd. All rights reserved.
 */

// 数组部分
export const XArray = {

  /**
   * 获得object对象 在数组的索引index, 不存在 返回-1
   * @param array 数组
   * @param object 对象  或者 回调函数(返回true 即删除, 否则不删除)
   * @returns {number} 索引号
   */
  indexOfObject(array, object) {
    let index = -1;
    if (!array || !object) {
      return index;
    }

    let tmpIndex = 0;
    for (const item of array) {
      if (item === object || (object instanceof Function && object(item))) {
        index = tmpIndex;
        break;
      }
      tmpIndex++;
    }

    return index;
  },

  /**
   * 删除array 对象object
   * @param array 数组
   * @param object 需要删除的对象 或者 回调函数(返回true 即删除, 否则不删除)
   */
  removeObject(array, object) {
    if (!array || !object) {
      return;
    }

    for (let index = 0; index < array.length;) {
      const item = array[index];
      if (item === object || (object instanceof Function && object(item))) {
        array.splice(index, 1);
      } else {
        ++index;
      }
    }
  }

};

export default XArray;
