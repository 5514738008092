var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"data-mappingt",attrs:{"title":"数据映射","visible":_vm.dialogVisible,"width":"900px","close-on-click-modal":false,"close-on-press-escape":false,"append-to-body":""},on:{"close":_vm.close}},[_c('en-table',{ref:"multipleTable",attrs:{"data":_vm.tableData,"border":"","stripe":"","max-height":"400","edit-config":{},"head-end-config":{ type: 'add' }},scopedSlots:_vm._u([{key:"hoverRow",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [_c('en-expand-buttons',{attrs:{"data":_vm.buttonData},on:{"button-click":function($event){return _vm.handleDeleteButtonClick(row, rowIndex, arguments[1], arguments[0])}}})]}}])},[_c('en-table-column',{attrs:{"type":"index","label":"序号","width":"50"}}),_c('en-table-column',{attrs:{"label":"目标字段","prop":"name","field":"name"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('el-select',{model:{value:(row.source),callback:function ($$v) {_vm.$set(row, "source", $$v)},expression:"row.source"}},_vm._l((_vm.options),function(option){return _c('el-option',{key:option.value,attrs:{"value":option.value,"label":option.label}})}),1)]}}])}),_c('en-table-column',{attrs:{"label":"来源字段","prop":"name","field":"name"},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('el-select',{model:{value:(row.source),callback:function ($$v) {_vm.$set(row, "source", $$v)},expression:"row.source"}},_vm._l((_vm.options),function(option){return _c('el-option',{key:option.value,attrs:{"value":option.value,"label":option.label}})}),1)]}}])})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('en-button',{on:{"click":_vm.save}},[_vm._v("\n      确定\n    ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }