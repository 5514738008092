<template>
  <transition name="down" @after-leave="handleAfterLeave">
    <div
      v-show="status && type"
      class="en-loading-container"
      :style="{ background: background }"
    >
      <en-result
        :type="type"
        class="en-result"
        v-bind="loadingConfig"
      ></en-result>
    </div>
  </transition>
</template>

<script>
export default {
  name: "EnLoading",
  data() {
    return {
      type: "loading",
      loadingConfig: {},
      status: true,
      background: null
    };
  },
  methods: {
    handleAfterLeave() {
      this.$emit("after-leave");
    }
  }
};
</script>
<style lang="scss" scoped>
.en-loading-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 48px 32px;
  background: #ffffff;
  z-index: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  &-icon {
    margin-bottom: 15px;
    text-align: center;
  }
  .icon-img {
    margin-bottom: 15px;
  }
  /deep/{
    .title {
      color: #FFFFFF;
      font-size: 24px;
      line-height: 1.8;
      text-align: center;
    }
    .subtitle {
      color: #FFFFFF;
      font-size: 14px;
      line-height: 1.6;
      text-align: center;
    }
  }
  &-extra {
    margin-top: 32px;
    text-align: center;
    & > * {
      margin-right: 8px;
    }
  }
}

.down-leave {
  opacity: 1;
}

.down-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  opacity: 0;
  // transform: translateY(100%);
}

.down-enter {
  opacity: 0;
  // transform: translateY(100%);
}
.down-enter-active {
  transition: all 0.4s ease;
}
</style>
