var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"en-tabs",class:_vm.type},[_c('div',{ref:"reference",staticClass:"en-tabs-list"},[_c('div',{staticClass:"active_bar",class:{
      'is-overflow':_vm.activeIsOverFlow
      },style:({
        transform: 'translateX(' + _vm.ofLeft + 'px)',
        width: _vm.barWidth + 'px'
      })}),_vm._l((_vm.showTabList),function(item){return _c('div',{key:item.id,ref:_vm.value === item.id ? 'activeTab' : '',refInFor:true,staticClass:"en-tabs-item",class:{
      'tabs-active': _vm.value === item.id,
      disabled: item.disabled,
      'tabs-fixed-width': _vm.isFixedWidth
      },style:(_vm.widthStyle),attrs:{"title":item.name},on:{"click":function($event){return _vm.changeTabs(item)}}},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_vm._t("search")],2)}),(_vm.additionalTabList.length > 0)?_c('div',{staticClass:"en-tabs-item",class:{
            'tabs-fixed-width': _vm.isFixedWidth
               },style:(_vm.widthStyle)},[_c('el-dropdown',{on:{"command":_vm.changeTabs}},[_c('span',{staticClass:"el-dropdown-link"},[_c('en-icon',{staticClass:"more-button",attrs:{"name":"iconfujian-gengduo","size":"18"}})],1),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.additionalTabList),function(item){return _c('el-dropdown-item',{key:item.id,attrs:{"disabled":item.disabled,"command":item}},[_vm._v("\n            "+_vm._s(item.name)+"\n          ")])}),1)],1)],1):_vm._e()],2),_c('div',{staticClass:"en-tabs-right-container"},[_vm._t("right")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }