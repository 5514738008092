<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-10-11 11:42:39
 * @LastEditTime: 2020-05-06 15:26:53
 -->
<template>
  <div class="en-color-setting">
    <div class="content">
      <en-icon
        v-if="showLeftIcon"
        name="tubiaoyanse-shuxing"
        size="small"
        :class="{'disabled-icon':disabled}"
      >
      </en-icon>
      <el-color-picker
        v-model="color"
        color-format="hex"
        :predefine="predefineColors"
        size="mini"
        :disabled="disabled"
      >
      </el-color-picker>
    </div>
  </div>
</template>
<script>
export default {
  name: "EnColorSetting",
  props: {
    value: {
      type: String,
      default: null
    },
    // 是否展示左侧图标
    "showLeftIcon": {
      type: Boolean,
      default: true
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      color: null,
      predefineColors: [
        "#4694df",
        "#26c393",
        "#f76b6b",
        "#ffad2c",
        "#9367eb",
        "#d69e79",
        "#49bef2",
        "#f7bf27"
      ]
    };
  },
  watch: {
    color(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.color = val;
    }
  },
  mounted() {
    this.color = this.value;
  }
};
</script>
<style lang="scss" scoped>
.en-color-setting {
  display: inline-block;
  height: 18px;
  .content {
    margin: 0 auto;
    position: relative;
    top: 50%; /*偏移*/
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    .en-icon.disabled-icon {
      fill: rgba($color: #a9b5c6, $alpha: 0.5);
    }
    .en-icon {
      fill: #a9b5c6;
    }
    .el-color-picker {
      margin-left: 8px;
      width: 18px;
      height: 18px;
    }
    & /deep/ .el-color-picker__trigger {
      width: 19px;
      height: 19px;
      padding: 1px;
    }
    & /deep/ .el-icon-arrow-down {
      display: none;
    }
    & /deep/ .el-color-picker__mask{
      border-radius: 2px;
      top: 2px;
      left: 2px;
      height: 15px;
      width: 15px;
    }
  }
}
</style>

