/**
 * project: enfry-components
 * fileName: selection
 * Created by 徐俊 on 2020/07/29
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import XEUtils from "xe-utils/methods/xe-utils";
import { Utils, DomTools } from "../../tools";
import CellUtils from "../../cell/src/cellUtils";

const Selection = {
  methods: {
    setRadioRow(row, column) {
      if (!column) {
        this.tableColumn.forEach(column => {
          if (column.isRadioType()) {
            this.setRadioRow(row, column);
          }
        });
        return;
      }
      const { selectConfig } = column;
      if (selectConfig.selectRow !== row) {
        this.clearRadioRow(column);
      }
      selectConfig.selectRow = row;
      return this.$nextTick();
    },
    clearRadioRow(column) {
      if (!column) {
        this.tableColumn.forEach(column => {
          if (column.isRadioType()) {
            this.clearRadioRow(column);
          }
        });
        return;
      }

      this.selectRow = null;
      return this.$nextTick();
    },
    getRadioRow() {
      return this.selectRow;
    },

    /**
      * 处理选中源 此方法应该需要修改了 selection
      */
    handleSelected(params, evnt) {
      const { mouseConfig = {}, editStore, elemStore } = this;
      const { actived, selected } = editStore;
      const { row, column, cell } = params;
      const selectMethod = () => {
        if (selected.row !== row || selected.column !== column) {
          if (actived.row !== row) {
            if (this.keyboardConfig || this.mouseConfig) {
              this.clearChecked(evnt);
              this.clearIndexChecked();
              this.clearHeaderChecked();
              this.clearSelected(evnt);
            }
            this.clearActived(evnt);
            selected.args = params;
            selected.row = row;
            selected.column = column;
            if (mouseConfig.selected) {
              const listElem = elemStore["main-body-list"];
              const rowid = CellUtils.getRowid(this, row);
              const trElem = listElem.querySelector(`[data-rowid="${rowid}"]`);
              const tdElem = trElem.querySelector(`.${column.id}`);
              DomTools.addClass(tdElem, "col--selected");
            }
            // 如果配置了批量选中功能，则为批量选中状态
            if (mouseConfig.checked) {
              const headerElem = elemStore["main-header-list"];
              this.handleChecked([[cell]]);
              if (headerElem) {
                this.handleHeaderChecked([[headerElem.querySelector(`.${column.id}`)]]);
              }
              this.handleIndexChecked([[cell.parentNode.querySelector(".col--index")]]);
            }
          }
        }
        return this.$nextTick();
      };
      return selectMethod();
    },
    /**
      * 处理默认勾选
      */
    handleSelectionDefChecked() {
      // 处理多选选框默认勾选
      const { selectConfig = {}, fullDataRowIdData } = this;
      const { checkAll, checkRowIds } = selectConfig;
      if (checkAll) {
        this.setAllSelection(true);
      } else if (checkRowIds) {
        const defSelection = [];
        checkRowIds.forEach(rowid => {
          if (fullDataRowIdData[rowid]) {
            defSelection.push(fullDataRowIdData[rowid].row);
          }
        });
        this.setSelection(defSelection, true);
      }

      // 处理单选框默认勾选
      const { checkRowId: rowid } = selectConfig;
      if (rowid && fullDataRowIdData[rowid]) {
        this.setRadioRow(fullDataRowIdData[rowid].row);
      }
    },
    /* 是否选中了某行*/
    isCheckedRow({ row, column }) {
      if (!column) {
        return this.tableColumn.some(col => {
          return col.isSystemSelectionConfig() ? this.isCheckedRow({ row, column: col }) : false;
        });
      }

      if (!column.isSelectionType()) {
        return false;
      }

      let checked;
      const { checkField: property } = column && column.selectConfig ? column.selectConfig : this.selectConfig;
      const { selection, checkMethod } = column ? column.selectConfig : {};
      if (checkMethod) {
        checked = checkMethod({ row, column });
      } else if (property) {
        checked = XEUtils.get(row, property);
      } else {
        checked = selection && selection.indexOf(row) > -1;
      }
      return !!checked;
    },
    setSelection(rows, value) {
      if (rows && !XEUtils.isArray(rows)) {
        rows = [rows];
      }
      if (!this.tableColumn || this.tableColumn.length === 0) {
        return this.$nextTick(() => {
          rows.forEach(row => this.handleSelectRow({ row }, !!value));
        });
      } else {
        rows.forEach(row => this.handleSelectRow({ row }, !!value));
        return this.$nextTick();
      }
    },
    /**
      * 多选，行选中事件
      * value 选中true 不选false 不确定-1
      */
    checkSelectRow({ row, column }, value) {
      const { tableFullData, treeConfig } = this;
      const { selectConfig } = column;
      const { checkField: property, checkStrictly, selection, checkMethod, treeIndeterminates } = selectConfig;

      if (treeConfig && !checkStrictly) {
        if (value === -1) {
          treeIndeterminates.push(row);
          XEUtils.set(row, property, false);
        } else {
          // 更新子节点状态
          XEUtils.eachTree(
            [row],
            (item, $rowIndex) => {
              if (row === item || (!checkMethod || checkMethod({ row: item, $rowIndex }))) {
                if (value) {
                  selection.push(item);
                } else {
                  XEUtils.remove(selection, select => select === item);
                }

                XEUtils.set(item, property, value);
              }
            },
            treeConfig
          );
          XEUtils.remove(treeIndeterminates, item => item === row);
        }
        // 如果存在父节点，更新父节点状态
        const matchObj = XEUtils.findTree(tableFullData, item => item === row, treeConfig);
        if (matchObj && matchObj.parent) {
          let parentStatus;
          const vItems = checkMethod ? matchObj.items.filter((item, $rowIndex) => checkMethod({ row: item, $rowIndex })) : matchObj.items;
          const indeterminatesItem = matchObj.items.find(item => treeIndeterminates.indexOf(item) > -1);
          if (indeterminatesItem) {
            parentStatus = -1;
          } else {
            const selectItems = matchObj.items.filter(item => XEUtils.get(item, property));
            parentStatus = selectItems.filter(item => vItems.indexOf(item) > -1).length === vItems.length ? true : selectItems.length || value === -1 ? -1 : false;
          }
          return this.checkSelectRow({ row: matchObj.parent, column }, parentStatus);
        }
      } else {
        if (value) {
          if (selection.indexOf(row) === -1) {
            selection.push(row);
          }
        } else {
          XEUtils.remove(selection, item => item === row);
        }

        XEUtils.set(row, property, value);
      }

      this.checkSelectionStatus(column);
    },
    /**
      * 多选，行选中事件
      * value 选中true 不选false 不确定-1
      */
    handleSelectRow({ row, column }, value) {
      // debugger
      if (!column) {
        // debugger
        this.tableColumn.forEach(column => {
          if (column.isSelectionType()) {
            this.handleSelectRow({ row, column }, value);
          }
        });
        return;
      }

      this.checkSelectRow({ row, column }, value);
      this.checkSelectionStatus(column);
    },
    handleToggleCheckRowEvent(params, evnt) {
      if (!params || !params.column || !params.column.selectConfig) {
        return;
      }

      const { checkField: property, selection, checkDisabledMethod } = params.column.selectConfig;
      let isDisabled = !!checkDisabledMethod;
      if (checkDisabledMethod) {
        isDisabled = checkDisabledMethod(params);
      }
      const { row } = params;
      const value = property ? !XEUtils.get(row, property) : selection.indexOf(row) === -1;
      if (evnt) {
        if (isDisabled) return;
        this.triggerCheckRowEvent(evnt, params, value);
      } else {
        this.handleSelectRow(params, value);
      }
    },
    triggerCheckRowEvent(evnt, params, value) {
      const column = params ? params.column : undefined;
      this.handleSelectRow(params, value);
      let selection = this.getSelectRecords(column);
      // 复选框保留状态需要取selectConfig里的selection
      if (column && column.selectConfig && column.selectConfig.reserve) {
        selection = column.selectConfig.selection
      }
      Utils.emitEvent(this, "select-change", [
        Object.assign(
          {
            selection,
            checked: value,
            $table: this
          },
          params
        ),
        evnt
      ]);
    },
    /**
      * 多选，切换某一行的选中状态
      */
    toggleRowSelection(row) {
      this.handleToggleCheckRowEvent({ row });
      return this.$nextTick();
    },
    setAllSelection(value, column) {
      if (!column) {
        this.tableColumn.forEach(column => {
          if (column.isSystemSelectionConfig()) {
            this.setAllSelection(value, column);
          }
        });
        return;
      }

      let { tableFullData } = this;
      const { editStore, treeConfig } = this;
      const { selectConfig = {} } = column;
      const { checkField: property, selection, reserve, checkStrictly, checkMethod } = selectConfig;
      const { insertList } = editStore;
      const selectRows = [];
      // 包含新增的数据
      if (insertList.length) {
        tableFullData = tableFullData.concat(insertList);
      }
      // debugger

      if (!checkStrictly && property) {
        // property必然存在
        if (property) {
          const indexKey = `${treeConfig ? "$" : ""}rowIndex`;
          let setOrClearFn;
          if (value) {
            // 选中函数
            setOrClearFn = (row, rowIndex) => {
              if (!checkMethod || checkMethod({ row, [indexKey]: rowIndex })) {
                XEUtils.set(row, property, value);
                selectRows.push(row);
              }
            };
          } else {
            // 清除选中函数
            setOrClearFn = (row, rowIndex) => {
              if (!checkMethod || (checkMethod({ row, [indexKey]: rowIndex }) ? 0 : selection.indexOf(row) > -1)) {
                XEUtils.set(row, property, value);
              }
            };
          }

          if (treeConfig) {
            XEUtils.eachTree(tableFullData, setOrClearFn, treeConfig);
          } else {
            tableFullData.forEach(setOrClearFn);
          }
        }
        selectConfig.selection = value && reserve ? selection.concat(selectRows.filter(row => selection.indexOf(row) === -1)) : selectRows;
      }

      selectConfig.treeIndeterminates = [];
      this.checkSelectionStatus(column);
    },
    checkSelectionStatus(column) {
      // debugger
      if (!column) {
        this.tableColumn.forEach(column => {
          if (column.isSystemSelectionConfig()) {
            // column.selectConfig.treeIndeterminates = [];
            this.checkSelectionStatus(column);
          }
        });
        return;
      }

      const selectConfig = column.selectConfig;
      if (!selectConfig) return;

      let { tableFullData } = this;
      const { editStore } = this;
      const { selection } = selectConfig;// checkField: property, checkStrictly, checkMethod, treeIndeterminates
      const { insertList } = editStore;

      // 包含新增的数据
      if (insertList.length) {
        tableFullData = tableFullData.concat(insertList);
      }

      selectConfig.isAllSelected = tableFullData.length && tableFullData.every(row => selection.indexOf(row) > -1);
      selectConfig.isIndeterminate = !selectConfig.isAllSelected && tableFullData.some(row => selection.indexOf(row) > -1);

      // if (!checkStrictly) {
      //   if (property) {
      //     selectConfig.isAllSelected = tableFullData.length && tableFullData.every(
      //       checkMethod
      //         ? (row, rowIndex) => !checkMethod({ row, column, rowIndex }) || XEUtils.get(row, property)
      //         : row => XEUtils.get(row, property)
      //     );
      //     selectConfig.isIndeterminate = !selectConfig.isAllSelected && tableFullData.some(row => XEUtils.get(row, property) || treeIndeterminates.indexOf(row) > -1);
      //   } else {
      //     selectConfig.isAllSelected = tableFullData.length && tableFullData.every(
      //       checkMethod
      //         ? (row, rowIndex) => !checkMethod({ row, column, rowIndex }) || selection.indexOf(row) > -1
      //         : row => selection.indexOf(row) > -1
      //     );
      //     selectConfig.isIndeterminate = !selectConfig.isAllSelected && tableFullData.some(row => treeIndeterminates.indexOf(row) > -1 || selection.indexOf(row) > -1);
      //   }
      // }
    },
    // 保留选中状态
    reserveCheckSelection() {
      const { fullDataRowIdData } = this;

      this.tableColumn.forEach(column => {
        const selectConfig = column.selectConfig;
        if (!selectConfig) return;

        const { reserve, selection } = selectConfig;
        const tmpSelection = this.getSelectRecords(column);
        if (tmpSelection && tmpSelection.length > 0) {
          // 添加默认选中
          for (const row of tmpSelection) {
            if (selection.indexOf(row) === -1) {
              this.checkSelectRow({ column, row }, true);
            }
          }
        }

        const rowkey = CellUtils.getRowkey(this);
        if (reserve && selection.length) {
          selectConfig.selection = selection.map(row => {
            const rowid = "" + XEUtils.get(row, rowkey);
            return fullDataRowIdData[rowid] ? fullDataRowIdData[rowid].row : row;
          });
        }
      });
    },
    /**
      * 多选，选中所有事件
      */
    triggerCheckAllEvent(evnt, params, value) {
      const column = params ? params.column : undefined;
      this.setAllSelection(value, column);
      let selection = this.getSelectRecords(column);
      if (column && column.selectConfig && column.selectConfig.reserve) {
        selection = column.selectConfig.selection
      }
      Utils.emitEvent(this, "select-change", [
        Object.assign(
          {
            selection,
            checked: value,
            $table: this
          },
          params
        ),
        evnt
      ]);
    },
    /**
      * 多选，切换所有行的选中状态
      */
    toggleAllSelection() {
      this.triggerCheckAllEvent(undefined, undefined, !this.isAllSelected);
      return this.$nextTick();
    },
    clearSelection() {
      const { tableFullData, treeConfig } = this;

      this.tableColumn.forEach(column => {
        if (!column.selectConfig) return;

        const { checkField: property } = column.selectConfig;
        if (property) {
          if (treeConfig) {
            XEUtils.eachTree(tableFullData, item => XEUtils.set(item, property, false), treeConfig);
          } else {
            tableFullData.forEach(item => XEUtils.set(item, property, false));
          }
        }

        column.selectConfig.isAllSelected = false;
        column.selectConfig.isIndeterminate = false;
        column.selectConfig.selection = [];
        column.selectConfig.treeIndeterminates = [];
      });

      return this.$nextTick();
    },
    // 更新头部checkbox状态
    updateHeaderCheckboxStatus() {
      const { tableFullData } = this;
      this.tableColumn.forEach(column => {
        if (!column.selectConfig) return;
        const selectConfig = column.selectConfig;
        if (!selectConfig) return;
        const { selection } = selectConfig;
        selectConfig.isAllSelected = tableFullData.length && tableFullData.every(row => selection.indexOf(row) > -1);
        selectConfig.isIndeterminate = !selectConfig.isAllSelected && tableFullData.some(row => selection.indexOf(row) > -1);
      });
    },
    /**
      * 单选，行选中事件
      */
    triggerRadioRowEvent(evnt, params) {
      const { row, column } = params;
      if (!column || !row) return;

      const isChange = column.selectConfig.selectRow !== row;
      this.setRadioRow(row, column);
      if (isChange) {
        Utils.emitEvent(this, "select-change", [
          Object.assign(
            {
              selection: [row],
              checked: true,
              $table: this
            },
            params
          ),
          evnt
        ]);
        // Utils.emitEvent(this, "radio-change", [ params, evnt ]);
      }
    }
  }

};

export default Selection;

