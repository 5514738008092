import DomTools from "./src/dom";
import GlobalEvent from "./src/event";
import XArray from "./src/array";
import XObject from "./src/object";
import Utils from "./src/utils";

export * from "./src/dom";
export * from "./src/event";
export * from "./src/array";
export * from "./src/object";
export * from "./src/utils";

export default {
  Utils,
  DomTools,
  GlobalEvent,
  XArray,
  XObject
};
