<template>
  <div class="en-and-or-column">
    <div class="left">
      <slot></slot>
    </div>
    <div
      class="right"
      @click.stop="deleteRow"
    >
      <en-icon
        name="shibai"
        size="20"
        style="color:red"
      >
      </en-icon>
    </div>
  </div>
</template>

<script>

export default {
  name: "EnAndOrColumn",
  props: {
    name: {
      type: [Number, String],
      default: 1
    },
    containerIndex: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteRow() {
      this.$emit("deleteRow", { name: this.name, containerIndex: this.containerIndex });
    }
  }
};
</script>
<style scoped  lang="scss">
.en-and-or-column {
  width: 100%;
  height: 43px;
  overflow: hidden;
  white-space: nowrap;
  .left {
    // display: inline-block;
    vertical-align: middle;
    display: inline-flex;
    justify-content: flex-start;
  }
  .right {
    display: none;
    height: 32px;
    line-height: 32px;
    padding: 3px;
  }
  &:hover .right {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
