/**
 * project: user
 * fileName: utils
 * Created by 徐俊 on 2020/07/24
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

const paramsMethods = {
  updateResponseData(attachmentData, file) {
    if (!attachmentData || !file) {
      return;
    }
    const { response } = file;

    if (!response) {
      return;
    }
    // 文件地址
    if (Number(response.code) === 0 && response.data) {
      attachmentData.url = response.data.fileUrl;
      attachmentData.code = response.data.fileCode;
    } else {
      attachmentData.progess = 0;
      attachmentData.status = "fail"; // 接口请求成功了, 但是实际上传失败了
    }
  },
  // 获得附件数据
  getAttachmentData(file) {
    const {
      id, uid, name, size, attachmentSize, status, percentage, raw
    } = file;
    const data = {
      id,
      uid: id || uid || (Date.now() + this.tempIndex++),
      name,
      size: size || attachmentSize, // 每个地方size命名有问题
      attachmentSize: size || attachmentSize,
      status: status || "success",
      progress: percentage || 0,
      fileData: raw
    };

    // 文件后缀名
    const extension = name.substr(name.lastIndexOf(".") + 1);
    data.extension = extension ? extension.toLowerCase() : "";
    // 文件类型
    if (this.imgExt.indexOf(data.extension) >= 0) {
      data.dataType = "000";// 图片
    } else { // if (this.fileExt.indexOf(data.extension) >= 0)
      data.dataType = "001";// 文件
    }

    // 文件地址
    this.updateResponseData(data, file);
    return data;
  },
  // 事件的参数
  getEventParams(file, fileList, otherParams) {
    let curFile;// 当前文件
    let progress = 0.0;// 总进度
    if (!fileList || !fileList.length) { // error的时候会空
      fileList = [file];
    }
    fileList.forEach((item) => {
      progress += (item.status === "success" || item.status === "fail" ? 100.0 : item.percentage);
    });

    if (file) {
      curFile = this.allFileList.find((item) => item.uid === file.uid);
      curFile.progress = file.percentage;// 进度条
      curFile.status = file.status;
      this.updateResponseData(curFile, file); // 文件地址
    }

    return {
      file: curFile,
      progress: progress / fileList.length,
      fileList: this.allFileList,
      ...otherParams
    };
  }
};

const Utils = {
  methods: {
    // 是否全部上传完成
    isAllUpload(fileList) {
      return !fileList.some((file) => file.status !== "success" && file.status !== "fail");
    },
    // 文件是否成功
    isSuccess(file) {
      return file.status === "success";
    },
    // 文件是否添加完成
    isReady(file) {
      return file.status === "ready";
    },
    // 是否所有文件都准备好了 (选择文件完成)
    isAllReady(fileList) {
      return !fileList.some((file) => this.isReady(file));
    },
    alertDelayMsg(msg) {
      setTimeout(() => { // 多文件提示会重叠
        this.$message.error(msg);
      }, 0.01);
    },
    ...paramsMethods
  }
};
export default Utils;
