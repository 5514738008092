/*
 * @Author: Fenngding
 * @Date: 2019-10-10 16:10:37
 * @LastEditors: Fengding
 * @LastEditTime: 2019-10-11 13:40:25
 * @Email: fenngding@live.com
 * @Description:
 */

/**
 * 查看对象类型: string,number,null,undinfed,
 *
 * @export
 */
import thistype from "thistype";
export default thistype;
