import XEUtils from "xe-utils/methods/xe-utils"
import CellUtils from "../../cell/src/cellUtils";
/**
 *
 * @param compName
 * @returns {*}
 */
export default function(compName) {
  const isInput = compName === "input"
  const getAttrs = isInput ? ({ type, name, readonly, disabled, maxlength, autocomplete }) => {
    return {
      type,
      name,
      readonly,
      disabled,
      maxlength,
      autocomplete
    }
  } : ({ name, readonly, disabled, maxlength, autocomplete, rows, form }) => {
    return {
      name,
      readonly,
      disabled,
      maxlength,
      autocomplete,
      rows,
      form
    }
  }
  return {
    name: XEUtils.camelCase(`Vxe-${compName}`),
    props: {
      value: [String, Number],
      name: String,
      type: {
        type: String,
        default: "text"
      },
      autocomplete: String,
      readonly: Boolean,
      disabled: Boolean,
      placeholder: String,
      maxlength: [String, Number],
      rows: {
        type: [String, Number],
        default: 2
      },
      form: String,
      size: String
    },
    computed: {
      vSize() {
        return this.size || this.$parent.size || this.$parent.vSize
      }
    },
    render(h) {
      const { $listeners, value, vSize, placeholder } = this
      const attrs = getAttrs(this)
      if (placeholder) {
        attrs.placeholder = CellUtils.getFuncText(placeholder)
      }
      return h("div", {
        class: [
          "vxe-input--wrapper",
          `type--${compName}`,
          {
            [`size--${vSize}`]: vSize,
            "is--disabled": this.disabled
          }
        ]
      }, [
        h(compName, {
          class: `vxe-${compName}`,
          domProps: { value },
          attrs,
          on: XEUtils.objectMap($listeners, (cb, type) => evnt => {
            const value = evnt.target.value
            const params = type === "input" ? value : { value }
            this.$emit(type, params, evnt)
          })
        })
      ])
    }
  }
}
