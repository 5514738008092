<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: pengyu
 * @Date: 2019-09-24 15:05:10
 * @LastEditTime: 2021-07-06 15:19:44
 -->
<template>
  <div class="en-search">
    <!-- 加tabindex是为了点击的时候搜索框blur事件能判断来源 目前好像不需要了 -->
    <div
      ref="searchIcon"
      class="search-icon"
      :class="{ 'icon-hidden': mode === 'expand' }"
      tabindex="0"
      @click="handleSearchIconClick"
    >
      <en-icon name="sousuo-danchuang" size="16"></en-icon>
    </div>
    <div
      class="search-content"
      :class="{ 'content-hidden': mode !== 'expand' }"
    >
      <el-input
        ref="searchInput"
        v-model="currentValue"
        clearable
        placeholder="请输入内容"
        @keyup.enter.native="handleEnterPressed"
        @blur="handleInputBlur($event)"
        @clear="handleClear"
      >
        <template slot="suffix">
          <en-icon
            name="sousuo-danchuang"
            size="16"
            @click.native="searchPressed"
          ></en-icon>
        </template>
      </el-input>
    </div>
  </div>
</template>
<script>
export default {
  name: "EnSearch",
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    showBorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // expand or collapse  展开or收起
      mode: "expand"
    };
  },
  computed: {
    // 监听值改变
    currentValue: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit("change", newValue);
        this.$emit("input", newValue); // 通过 input 事件更新 model
      }
    }
  },
  created() {
    if (this.value.length === 0) {
      this.mode = "collapse";
    } else {
      this.mode = "expand";
    }
    if (this.showBorder) {
      this.mode = "expand";
    }
  },
  methods: {
    // 回车按钮
    handleEnterPressed() {
      this.$emit("search", this.currentValue);
    },
    // 搜索按钮点击(搜索框内的)
    searchPressed() {
      this.$emit("search", this.currentValue);
    },
    // 输入变化
    handleChange(value) {
      this.currentValue = value;
      this.$emit("change", value);
    },
    // 点击按钮切换搜索框
    handleSearchIconClick() {
      if (this.mode === "collapse") {
        this.mode = "expand";
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      } else if (this.mode === "expand") {
        if (this.currentValue.length === 0) {
          this.mode = "collapse";
        }
        // 搜索
        this.$emit("search", this.currentValue);
      }
    },
    // 搜索框 失去焦点
    handleInputBlur(event) {
      // 目前不需要这个
      // if(event.relatedTarget === this.$refs.searchIcon){
      //   // 点击搜索按钮触发的blur事件,后续的不执行了
      //   return;
      // }
      if (this.currentValue.length === 0) {
        !this.showBorder && (this.mode = "collapse");
        this.$emit("search", this.currentValue);
      }
    },
    // 清空
    handleClear() {
      this.currentValue = "";
      this.$emit("search", this.currentValue);
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.en-search {
  & /deep/ .el-input__suffix-inner {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  // background-color: red;
  position: relative;
  .search-icon {
    float: right;
    width: 50px;
    height: 100%;
    outline: none;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-right: 5px;
  }
  .search-icon.icon-hidden {
    display: none;
  }
  .search-content {
    width: 100%;
    float: right;
    overflow: hidden;
  }
  .search-content.content-hidden {
    width: 0px;
  }
  & /deep/ .en-icon {
    fill: #a9b5c6;
  }
  display: inline-block;
  height: 32px;
  width: 400px;
}
</style>
