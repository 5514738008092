<!--
 * @Author: zql
 * @Date: 2020-03-13 15:29:14
 * @LastEditTime: 2020-06-02 14:35:08
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \enfry-components\packages\components\en-result\src\welcome\welcome.vue
-->
<template>
  <div ref="Welcome" class="welcome">
    <img
      slot="icon"
      class="icon-img"
      src="http://cdn.enfry.com/nodata-home11268.png"
      :alt="this.title"
    />
    <div class="title" v-html="title"></div>
    <div class="subtitle" v-html="subTitle"></div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  props: {
    type: { type: String, default: null },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: [String, Number],
      default: "normal"
    },
    title: {
      type: String,
      default: "欢迎使用en+管理平台，"
    },
    subTitle: {
      type: String,
      default: "可以通过点击左侧菜单开始您一天的工作"
    }
  }
};
</script>
<style lang="scss" scoped>
.welcome {
  text-align: center;
  .icon-img {
    height: 120px;
    width: 100px;
  }
  .title {
    color: #91a1b7;
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }
}
</style>
