/**
 * @Author 李煜
 * @Date 2020/7/14
 * @Description viewer service
 */
import Viewer from "./js"

const ViewerService = (options) => {
  const { element } = options
  delete options.element
  const instance = new Viewer(element, options)
  return instance
}

export default ViewerService
