<template>
<!--  <el-button></el-button>-->
  <button
    :class="[
      'en-button',
      'el-button',
      'en-button--' + buttonType,
      {
        'en-btn-selected':selected,
       'en-btn-disabled':buttonDisabled,
       'en-btn-selected-disabled':selected && buttonDisabled,
       'en-btn-normal':!selected && !buttonDisabled,
       'is--btn':!round && !circle && !isTextButton && $slots.default,
       'is--round':round,
       'is--circle':circle,
       'is--text':isTextButton
      }
    ]"
    :style="isMouseEnter ? buttonCustomHoverStyle : buttonStyle"
    type="button"
    @click="handleClick"
    @mouseenter="isCustomStyle && !selected && !buttonDisabled ? handleMouseEnter() : undefined"
    @mouseleave="isCustomStyle && !selected && !buttonDisabled ? handleMouseLeave() : undefined"
  >
    <slot name="left"/>
    <i v-if="loading" class="el-icon-loading"></i>
    <i v-if="elIcon && !loading" :class="elIcon"></i>
    <svg
      v-if="svgIcon"
      :class="{'en-icon-svg':true, 'en-icon-gap':$slots.default || $slots.right}"
      :style="iconStyle"
      :fill="iconColor"
    >
      <use :xlink:href="'#'+ svgIcon"></use>
    </svg>
    <span v-if="$slots.default"><slot></slot></span>
    <slot name="right"></slot>
  </button>
</template>

<script>

export default {
  name: "EnButton",
  props: { // 缺少的几种plain,autofocus,native-type 用不上
    type: String, // 按钮类型
    round: Boolean, // 按钮类型
    circle: Boolean, // 按钮类型
    loading: Boolean, // 按钮加载标识
    text: Boolean, // 是否是文本按钮
    icon: String, // 按钮图标(支持ele图标和自定义图标)
    disabled: Boolean, // 按钮是否禁用
    // 额外新增
    selected: Boolean, // 选中 (非聚焦)
    // 文本+图标颜色, 若设置iconColor,以iconColor为准(undefined/null/""默认颜色  #001122/red/001122为自定义颜色)
    color: String, // 颜色
    backgroundColor: String, // 背景颜色
    borderColor: String, // 边框颜色
    iconSize: [Number, String], // 按钮图标大小
    // 图标颜色(undefined/""为文本颜色 null为图标原始颜色 #001122/red/001122为自定义颜色)
    iconColor: String,
    stopPropagation: Boolean, // 是否阻止点击事件转发(也可以使用 @click.native.stop)
    data: String || Number || Boolean || Function || Object || Array || Symbol// 外部缓存数据(点击事件传出)
  },
  data() {
    return {
      // 图标名称(没有使用unicode/css方式, 不和el-button保持一致了, 不想再次引入iconfont.css了, 用svg实现)
      elIcon: "", // el图标
      svgIcon: "", // svg图标
      isMouseEnter: false// 鼠标是否移入

    }
  },
  // created() {
  //   console.log("2222");
  // },
  // inject: {
  //   elForm: { default: "" },
  //   elFormItem: { default: "" }
  // },
  computed: {
    // 拿到正确的样式class名称
    buttonType() {
      // /转一下, 不写两个相同样式了
      let name = this.type

      switch (name) {
        case "success":
        case "green":
          name = "green"
          break
        case "danger":
        case "red":
          name = "red"
          break
        case "info":
        case "gray":
          name = "gray"
          break
        case "warning":
        case "yellow":
          name = "yellow"
          break
        case "normal":
        case "white":
          name = "white"
          break
        case "text":
          name = "text"
          break
        case "custom":
          break
        default:// '' undefined null primary default
          name = "blue"
          break
      }

      if (this.text && this.type !== "text") {
        name += "-text"
      }

      return name
    },
    isTextButton() {
      return this.text || this.type === "text"
    },
    buttonDisabled() {
      return this.disabled || this.loading || (this.elForm || {}).disabled
    },
    isCustomStyle() {
      return !!(this.color || this.backgroundColor || this.type === "custom")
    },
    iconStyle() {
      const style = {}

      if (this.iconColor !== "null") { // null表示原始颜色 (undefined 不走这, 为原始颜色)
        const alpha = (this.selected ? 0.6 : 1) * (this.buttonDisabled ? 0.6 : 1)
        let rgb = this.rgbColorWithHex(this.iconColor || this.color, alpha)

        if (!rgb) {
          rgb = "currentColor"
        }
        style.fill = rgb
      }

      if (this.iconSize) {
        style.width = style.height = this.iconSize + "px"
      }

      return this.cssSytleString(style)
    },
    // 自定义样式(正常)
    buttonStyle() {
      if (!this.isCustomStyle) {
        return undefined
      }

      const alpha = (this.selected ? 0.6 : 1) * (this.buttonDisabled ? 0.6 : 1)
      const style = this.buttonStyleDic(alpha)
      return this.cssSytleString(style)
    },
    // 自定义样式列表(hover)
    buttonCustomHoverStyle() {
      const alpha = 0.6 * (this.selected ? 0.9 : 1) * (this.buttonDisabled ? 0.9 : 1)
      const style = this.buttonStyleDic(alpha)
      return this.cssSytleString(style)
    }
  },
  watch: {
    "icon": {
      handler(newVal) {
        // /无效数据
        if (newVal === undefined || newVal === null || newVal === "") {
          this.elIcon = ""
          this.svgIcon = ""
          return
        }

        // /如果是el图标
        if (newVal.indexOf("el-icon") === 0) {
          this.elIcon = newVal
          this.svgIcon = ""
          return
        }

        // 固化图标处理
        newVal = this.fixedIconName(newVal)
        // 修正图标名称
        newVal = this.correctedIconName(newVal)

        // /刷新UI
        this.elIcon = ""
        this.svgIcon = newVal
      },
      immediate: true
    }

  },
  methods: {
    cssSytleString(styleList) {
      let style = ""
      for (const key in styleList) {
        style += key + ":" + styleList[key] + ";"
      }

      return style
    },
    // 处理颜色值
    getHexColor(color) {
      if (!color) {
        return undefined// 原始颜色
      }

      if (!this.$colors) { // 懒加载,不想最开始就初始化出来
        this.$colors = {
          "blue": "#7393bb",
          "darkBlue": "#4895df",
          "lightBlue": "#91a1b7",
          "green": "#58cb7e",
          "darkGreen": "#20b789",
          "red": "#f76b6b",
          "darkRed": "#f54646",
          "gray": "#606060",
          "darkGay": "#333333",
          "lightGray": "#aeaeae",
          "yellow": "#f7bf27",
          "darkYellow": "#f78528"
        }
      }

      const sysColor = this.$colors[color]
      if (sysColor) {
        color = sysColor
      } else {
        const reg = /(^[0-9a-fA-f]{3}|^[0-9a-fA-f]{6}|^[0-9a-fA-f]{8})$/
        if (reg.test(color)) {
          color = "#" + color
        }
      }

      return color.toLowerCase()
    },
    rgbColorWithHex(hexColor, alpha) {
      const colorList = this.rgbColorListWithHex(hexColor, alpha)
      if (colorList) {
        return "rgba(" + colorList.join(",") + ")"
      } else {
        return undefined
      }
    },
    // 把FFF 转成 (255,255,255)
    rgbColorListWithHex(hexColor, alpha) {
      if (!hexColor) {
        return undefined
      }
      hexColor = this.getHexColor(hexColor)

      // 十六进制颜色值的正则表达式
      const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6,8})$/

      // 如果是16进制颜色
      if (reg.test(hexColor)) {
        // debugger;
        if (hexColor.length === 4) {
          let sColorNew = "#"
          for (let i = 1; i < 4; i += 1) {
            sColorNew += hexColor.slice(i, i + 1).concat(hexColor.slice(i, i + 1))
          }
          hexColor = sColorNew
        }
        // 处理六位的颜色值
        const sColorChange = []
        for (let i = 1; i < 7; i += 2) {
          sColorChange.push(parseInt("0x" + hexColor.slice(i, i + 2), 16))
        }

        if (hexColor.length <= 7 || alpha !== undefined) { // 考虑到alpha:0
          sColorChange.push(alpha === undefined ? 1 : alpha)
        } else {
          sColorChange.push(parseInt("0x" + hexColor.slice(7, 9), 16) / 255.0)
        }
        return sColorChange
      }
      return undefined
    },
    buttonStyleDic(alpha) {
      const style = {}
      let rgb = this.rgbColorWithHex(this.color, alpha)
      if (rgb) {
        style.color = rgb
      } else if (this.type === "custom") {
        style.color = "#ffffff"
      }

      rgb = this.rgbColorWithHex(this.backgroundColor, alpha)
      if (rgb) {
        style.backgroundColor = rgb
      } else if (this.type === "custom") {
        style.background = "none"
      }

      rgb = this.rgbColorWithHex(this.borderColor, alpha)
      if (rgb) {
        style.borderColor = rgb
      } else if (this.isTextButton || style.background) {
        style.borderStyle = "none"
      } else if (this.type === "custom") { // 不明白为什么要乘以系数 边框颜色和背景颜色才差不多
        style.borderColor = alpha < 1 ? this.rgbColorWithHex(this.backgroundColor, alpha * 0.5) : style.backgroundColor
      }
      return style
    },
    // /固化图标处理
    fixedIconName(icon) {
      if (icon === "new") { // 新增
        icon = "icontianjia"
      } else if (icon === "add") { // 添加
        icon = "icontianjia-anniutoubu"
      } else if (icon === "edit") { // 编辑
        icon = "iconbianji"
      } else if (icon === "delete") { // 删除
        icon = "iconshanchu"
      } else if (icon === "search") { // 搜索
        icon = "iconsousuo"
      } else if (icon === "filter") { // 筛选
        icon = "iconshaixuan"
      } else if (icon === "save") { // 保存
        icon = "iconbaocun"
      }
      return icon
    },
    // 修正图标名称
    correctedIconName(icon) {
      if (icon.indexOf("icon") !== 0) {
        icon = "icon" + icon
      }
      return icon
    },

    // 点击事件
    handleClick(event) {
      if (this.buttonDisabled) {
        return;
      }
      if (this.stopPropagation) {
        event.stopPropagation();// 阻止冒泡
      }
      this.$emit("click", this.data, event)
    },
    handleMouseEnter() {
      this.isMouseEnter = true
    },
    handleMouseLeave() {
      this.isMouseEnter = false
    }

  }
}
</script>

<style lang="scss">
  @import "button.scss";

</style>
