/**
 * project: enfry-components
 * fileName: page
 * Created by 徐俊 on 2020/04/21
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

/**
 * 获得pageModel信息(最佳合适的页面信息)
 * @param pageNo 页码
 * @param pageSize 页面大小
 * @returns {{pageNo: number, pageSize: *}}
 * @constructor -
 */
export const pageModel = function(pageNo = 1, pageSize) {
  if (!pageSize) {
    pageSize = window.screen.height / 50; // 50暂定为表格预估高度
  }

  const list = pageSizeList();
  for (let i = list.length - 1; i >= 0; i--) {
    const size = list[i];
    if (size < pageSize) { // 取最佳值(简单逻辑)
      pageSize = size;
      break;
    }

    if (i === 0) { // 没有合适的, 取最小值
      pageSize = list[i];
    }
  }

  return { pageNo, pageSize };
}

/**
 * 返回分页大小列表
 * @returns {Array}
 * @constructor -
 */
export const pageSizeList = function() {
  return [10, 20, 50, 100];
}
