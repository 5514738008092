/**
 * @description 获取整年日历数据，包含上下月
 * @param year 年份
 * @param beginData 第一天是礼拜几【1是礼拜一，0是礼拜天】
 * @param showWeek 是否需要显示是礼拜几【1是礼拜一，2是礼拜二，3是礼拜3，4是礼拜四，5是礼拜五，6是礼拜六，0是礼拜天】
 * @return {Array}
 */
function getWholeYearData(year, beginData, showWeek) {
  const arr = [];
  for (let i = 1; i <= 12; i++) {
    arr.push({
      month: i,
      dateArr: getWholeMonthData(year, i, beginData, showWeek)
    });
  }
  return arr;
}

/**
 * @description 获取整年日历数据，不包含上下月
 * @param year 年份
 * @param beginDate 第一天是礼拜几【1是礼拜一，0是礼拜天】
 * @param showWeek 是否需要告知当前天是礼拜几
 * @return {Array}
 */
function getYearData(year, beginDate, showWeek) {
  const arr = [];
  for (let i = 1; i <= 12; i++) {
    arr.push({
      month: i,
      dateArr: getMonthData(year, i, beginDate, showWeek)
    });
  }
  return arr;
}

/**
 * @description 获取指定月份下的所有日历面板数据
 * @param year 生成的日历的年份
 * @param month 生成的日历的月份
 * @param beginDate 日历面板的第一天是礼拜几，充分自定义[0-6]
 * @param showWeek 是否需要显示是礼拜几
 */
function getWholeMonthData(year, month, beginDate, showWeek) {
  return setPrevMonthData(year, month, beginDate === 1 ? 1 : 0, false, showWeek);
}

/**
 * @description 获取整个日历面板的数据（不包含上下月）
 * @param year 生成日历的年份
 * @param month 生成日历的月份
 * @param beginDate  日历面板的第一天是礼拜几，充分自定义[0-6]
 * @param showWeek 是否需要告知是礼拜几
 */
function getMonthData(year, month, beginDate, showWeek) {
  return setPrevMonthData(year, month, beginDate === 1 ? 1 : 0, true, showWeek);
}

/**
 * @description 获取一个月的第一天是周几
 * @param year
 * @param month
 */
function getFirstDayOfMonth(year, month) {
  return new Date(year, month - 1, 1).getDay();
}

/**
 * @description 设置日历面板中上月份的数据
 * @param year
 * @param month
 * @param beginDay 日历面板的第一天是礼拜几，充分自定义[0-6]
 * @param noData 不显示数据
 * @param showWeek 是否需要告知当前天是礼拜几
 */
function setPrevMonthData(year, month, beginDay, noData, showWeek) {
  let num = 0;
  const arr = [];
  const beginDate = getFirstDayOfMonth(year, month);
  const prevMonthInfo = {
    year: parseInt(month, 10) === 1 ? year - 1 : year,
    month: parseInt(month, 10) === 1 ? 12 : parseInt(month, 10) - 1,
    date: 0,
    dateLength: 0,
    timeStamp: 0
  };
  /**
   * @description 如果是礼拜天
   */
  if (beginDay === 0) {
    num = beginDate;
  } else {
    num = 7 - beginDay;
  }
  if (num > 0) {
    prevMonthInfo.dateLength = getMonthDateLen(prevMonthInfo.year, prevMonthInfo.month);
  }
  for (let i = prevMonthInfo.dateLength - num + 1; i <= prevMonthInfo.dateLength; i++) {
    const obj = {
      year: noData ? "" : prevMonthInfo.year,
      month: noData ? "" : prevMonthInfo.month,
      date: noData ? "" : i,
      isOtherMonth: true,
      timeStamp: noData ? "" : getTimeStamp(prevMonthInfo.year, prevMonthInfo.month, i)
    };
    // 如果需要告知是礼拜几，直接回塞
    if (showWeek) {
      obj.weekday = getCurrentDay(obj.year, obj.month, obj.date);
    }
    arr.push(obj);
  }
  return setCurrentMonthData(year, month, arr, noData, showWeek);
}

/**
 * @description 设置日历面板中本月的数据
 * @param year
 * @param month
 * @param dateArr
 * @param noData 不显示非本月数据
 * @param showWeek 是否需要告知当前天是礼拜几
 */
function setCurrentMonthData(year, month, dateArr, noData, showWeek) {
  const currentMonthLen = getMonthDateLen(year, month);
  for (let i = 1; i <= currentMonthLen; i++) {
    const obj = {
      year: year,
      month: month,
      date: i,
      timeStamp: getTimeStamp(year, month, i)
    };
    // 如果需要告知是礼拜几，直接回塞
    if (showWeek) {
      obj.weekday = getCurrentDay(obj.year, obj.month, obj.date);
    }
    dateArr.push(obj);
  }
  return setNextMonthData(year, month, dateArr, noData, showWeek);
}

/**
 * @description 设置日历面板中下月份的数据
 * @param year
 * @param month
 * @param dateArr
 * @param noData
 * @param showWeek
 */
function setNextMonthData(year, month, dateArr, noData, showWeek) {
  const nextMonthInfo = {
    year: parseInt(month, 10) === 12 ? year + 1 : year,
    month: parseInt(month, 10) === 12 ? 1 : month + 1,
    date: 0,
    timeStamp: 0
  };
  const maxLen = noData && dateArr.length <= 35 ? 35 : 42;
  for (let i = 1, num = maxLen - dateArr.length; i <= num; i++) {
    const obj = {
      year: noData ? "" : nextMonthInfo.year,
      month: noData ? "" : nextMonthInfo.month,
      date: noData ? "" : i,
      isOtherMonth: true,
      timeStamp: noData ? "" : getTimeStamp(nextMonthInfo.year, nextMonthInfo.month, i)
    };
    // 如果需要告知是礼拜几，直接回塞
    if (showWeek) {
      obj.weekday = getCurrentDay(obj.year, obj.month, obj.date);
    }
    dateArr.push(obj);
  }
  return dateArr;
}

/**
 * @description 获取一个月有多少天
 * @param year
 * @param month
 */
function getMonthDateLen(year, month) {
  return new Date(year, month, 0).getDate();
}

/**
 * @description 获取一天零点的时间戳
 * @param year
 * @param month
 * @param date
 */
function getTimeStamp(year, month, date) {
  return new Date(year + "/" + month + "/" + date + " 00:00:00").getTime();
}

/**
 * @description 获取指定天是礼拜几
 * @param year
 * @param month
 * @param date
 * @return {number}
 */
function getCurrentDay(year, month, date) {
  return new Date(year + "/" + month + "/" + date).getDay();
}

export {
  // 获取当月所有数据（包含上下月）
  getWholeMonthData,
  // 获取当月日历面板数据（不包含上下月）
  getMonthData,
  // 获取整年数据（包含上下月的数据）
  getWholeYearData,
  // 获取整年数据（不包含上下月）
  getYearData,
  // 获取指定年月日的时间戳（零点）
  getTimeStamp,
  // 获取指定月份有多少天
  getMonthDateLen,
  // 获取一个月的第一天是星期几
  getFirstDayOfMonth,
  // 获取指定日期是礼拜几
  getCurrentDay
};
