<template>
  <svg
    class="en-icon"
    aria-hidden="true"
    :class="isSelf ? '' : size"
    :style="{ width: isSelf ? width : '', height: isSelf ? width : '' }"
    @click="handleClick"
  >
    <title v-if="title">{{title}}</title>
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  name: "EnIcon",
  props: {
    // 图标名称
    name: {
      type: String,
      default: ""
    },
    // 图标大小类型，三选一，big、normal、small、默认normal
    size: {
      type: [String, Number],
      default: "normal"
    },
    // 图标title
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isSelf: false,
      width: ""
    };
  },
  computed: {
    iconName() {
      if (this.name.startsWith("icon")) {
        return "#" + this.name;
      } else {
        return "#icon" + this.name;
      }
    }
  },
  mounted() {
    // 设置默认尺寸数据
    const defaultWidth = [
      {
        type: "small",
        size: "18"
      },
      {
        type: "normal",
        size: "34"
      },
      {
        type: "big",
        size: "66"
      }
    ];
    // 如果没有传递size，直接使用32*32
    if (!this.size || this.size === "") {
      this.isSelf = true;
      this.width = "34";
    } else {
      this.isSelf = true;
      let width = "";
      defaultWidth.map(item => {
        if (this.size === item.type) {
          this.isSelf = false;
          width = item.size;
        }
      });
      this.width = parseFloat(this.isSelf ? this.size : width) + 2;
    }
  },
  methods: {
    handleClick(payload) {
      this.$emit("handleClick", payload);
    }
  }
};
</script>

<style lang="scss">
.en-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.1em;
  fill: currentColor;
  overflow: hidden;
  box-sizing: border-box;
  &.small {
    width: 18px;
    height: 18px;
  }

  &.normal {
    width: 34px;
    height: 34px;
  }

  &.big {
    width: 66px;
    height: 66px;
  }
}
</style>
