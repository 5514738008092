<template>
  <div class="en-md" v-highlight ref="mdContainer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "EnMd",
  directives: {
    highlight(el) {
      const blocks = el.querySelectorAll("pre code");
      blocks.forEach(block => {
        if (window.hljs) {
          window.hljs.highlightBlock(block);
        }
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.replaceTitleTarget();
      this.checkUrlParams();
    });
  },
  methods: {
    /**
     * @description 将标题标签增加ID字段为其文本
     */
    replaceTitleTarget() {
      const html = this.$refs.mdContainer.childNodes[0];
      // 找出所有的H1-H5标签
      const h1Arr = html.querySelectorAll("h1");
      const h2Arr = html.querySelectorAll("h2");
      const h3Arr = html.querySelectorAll("h3");
      const h4Arr = html.querySelectorAll("h4");
      const h5Arr = html.querySelectorAll("h5");
      // 进行URL处理
      h1Arr.forEach(item => {
        item.innerHTML = this.urlOperation(item);
      });
      h2Arr.forEach(item => {
        item.innerHTML = this.urlOperation(item);
      });
      h3Arr.forEach(item => {
        item.innerHTML = this.urlOperation(item);
      });
      h4Arr.forEach(item => {
        item.innerHTML = this.urlOperation(item);
      });
      h5Arr.forEach(item => {
        item.innerHTML = this.urlOperation(item);
      });
    },
    /**
     * @description URL处理，移除所有的空格
     */
    urlOperation(nodeData) {
      const newText = nodeData.innerText.replace(/\s+/g, "");
      return (
        "<a class='anchor' href='#" +
        newText +
        "' id='" +
        newText +
        "'></a>" +
        nodeData.innerHTML
      );
    },
    /**
     * @description 检测地址栏中是否存在URL参数，如果存在，跳转至对应的路径
     */
    checkUrlParams() {
      if (!this.$route.hash) {
        return;
      }
      setTimeout(() => {
        // 由于中文会乱码，导致无法找到对应的DOM元素，所以需要进行转码
        this.$refs.mdContainer
          .querySelector(decodeURI(this.$route.hash))
          .scrollIntoView(true);
      }, 20);
    }
  }
};
</script>

<style lang="scss">
.en-md {
  color: #000000;
  word-wrap: break-word;
  padding: 10px 30px;
  font-size: 14px;
  border: 1px solid #dedede;
  border-radius: 4px 4px 4px;
  box-shadow: 0 1px 2px 1px #dedede;
  margin: 10px 0 10px;

  blockquote {
    color: #7f8fa4;
    font-size: inherit;
    padding: 8px 16px;
    margin: 16px 0;
    border-left: 3px solid #eaeaea;
    background-color: #fcfcfc;

    p {
      color: #7f8fa4 !important;
      margin: 0;
      font-size: inherit;
      line-height: 1.5;
    }
  }

  pre {
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
  }

  a {
    color: #1b69b6;
    text-decoration: none;
  }

  code {
    font-family: "Menlo", "Liberation Mono", "Consolas", "DejaVu Sans Mono",
      "Ubuntu Mono", "Courier New", "andale mono", "lucida console", monospace;
    white-space: pre-wrap;
    word-wrap: normal;
    padding: 2px 4px;
    font-size: 90%;
    color: #c0341d;
    background-color: #fcefed;
    border-radius: 3px;

    &.hljs {
      width: 100%;
      display: block;
      padding: 0.5em;
      background: #23241f;
      color: #f8f8f2;

      .javascript {
        opacity: 1;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    width: 100%;
    line-height: 1.1;
    position: relative;

    .anchor {
      left: -16px;
      position: absolute;
      outline: none;
      color: #1b69b6;
      transition: background-color 100ms linear, color 100ms linear,
        border 100ms linear;

      &:after {
        content: url(./icon/icon_anchor.svg);
        visibility: hidden;
      }
    }

    &:hover {
      .anchor {
        &:after {
          visibility: visible;
        }
      }
    }
  }

  h1 {
    font-size: 1.75em;
    font-weight: 600;
    margin: 24px 0 16px;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaeaea;
    color: rgba(0, 0, 0, 0.85);
  }

  h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin: 24px 0 16px;
    padding-bottom: 0.3em;
    border-bottom: 1px solid #eaeaea;
    color: rgba(0, 0, 0, 0.85);
  }

  h3 {
    margin: 24px 0 16px;
    font-size: 1.3em;
  }

  ul {
    padding: 0;
    margin: 0 0 16px !important;

    li {
      margin-left: 28px;
      padding-left: 0;
      list-style: inherit;
      line-height: 1.6em;
    }
  }

  table {
    width: 100%;
    border: none;
    display: table;
    text-align: center;
    border-collapse: collapse;

    thead {
      tr {
        th {
          background-color: #eff3f5;
          font-size: 14px;
          color: #4f4f4f;
          line-height: 22px;
          border: 1px solid #ddd;
          padding: 8px;
          word-break: normal !important;
          vertical-align: middle;
        }
      }
    }

    tr {
      line-height: 45px;

      td {
        font-size: 14px;
        color: #4f4f4f;
        line-height: 22px;
        border: 1px solid #ddd;
        padding: 8px;
        word-break: normal !important;
        vertical-align: middle;
      }
    }
  }
}
</style>
