<template>
  <!-- 简介:这是一个提示弹框,包含body footer两个部分,并且两个部分可自定义插槽改变内容 -->
  <!-- dialogType有两种类型选择,title:只有一种标题提示文字,message:标题+内容 -->
  <!-- 可传入: (title:标题提示文字),(message:内容提示文字) -->
  <div class="dialog-tips-contain">
    <el-dialog
      title=""
      :show-close="false"
      :visible.sync="dialogVisible"
      @close="close"
    >
      <div class="dialog-contain">
        <div class="header-contain">
          <button
            type="button"
            aria-label="Close"
            class="el-dialog__headerbtn"
            @click="closeClick"
          >
            <i
              class="el-dialog__close el-icon el-icon-close"
              style="color:#color: #909399;"
            ></i>
          </button>
        </div>

        <div class="dialog-body-contain">
          <slot name="body">
            <div class="tips-contain">
              <div class="icon-contain">
                <en-icon
                  class="icon"
                  size="normal"
                  :name="iconName"
                  :style="{
                    fill: dialogType === 'message' ? '#F76B6B' : '#4694DF'
                  }"
                ></en-icon>
              </div>

              <div class="text-contain">
                <span class="title">{{ title }}</span>
                <span v-show="messageShow" class="message">{{ message }}</span>
              </div>
            </div>
          </slot>
        </div>

        <div class="dialog-bottom-contain">
          <slot name="footer">
            <div class="button-contain">
              <el-button
                size="mini"
                class="button"
                :style="messageShow ? buttonStyle : ''"
                @click="confirmClick"
              >
                确定
              </el-button>
            </div>
          </slot>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    // "title":只有一种标题提示文字
    // "message":有两种文字:标题+内容
    dialogType: {
      type: String,
      default: "message"
    },
    title: {
      type: String,
      default: "温馨提示"
    },
    message: {
      type: String,
      default: "您确定当前操作后将不可恢复,请谨慎操作!"
    }
  },
  data() {
    return {
      iconName: "xiaoxi",
      messageShow: false,
      buttonStyle: "background: #F76B6B !important;"
    };
  },
  watch: {
    dialogType() {
      this.updateDialogType();
    }
  },
  mounted() {
    this.updateDialogType();
  },
  methods: {
    updateDialogType() {
      if (this.dialogType === "message") {
        this.iconName = "tishi";
        this.messageShow = true;
      } else {
        this.iconName = "xiaoxi";
        this.messageShow = false;
      }
    },
    closeClick() {
      this.$emit("update:dialogVisible", false);
    },
    confirmClick() {
      this.closeClick();
      this.$emit("getEnTipsDialogConfirm");
    },
    close() {
      this.closeClick();
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-contain {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  width: 500px;
  background-color: #fff;
  border-radius: 5px;
  max-height: 100%;
  .header-contain {
    border-radius: 5px 5px 0px 0px;
    padding: 10px 20px 0px;
    height: 40px;
    background-color: #fff;
    text-align: left;
  }
  .dialog-body-contain {
    margin: 0px 20px 10px;
    text-align: left;
    // border: #eee 1px solid;
    max-height: 500px;
    .tips-contain {
      display: flex;
      align-items: flex-start;
      .icon-contain {
        width: 32px;
        height: 32px;
      }

      .text-contain {
        margin-left: 5px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        min-height: 32px;
        justify-content: center;
        .title {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: 500;
          color: #333;
        }
        .message {
          margin-top: 5px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #333;
        }
      }
    }
  }
  .dialog-bottom-contain {
    padding: 0px;
    margin: 10px 20px;
    text-align: left;
    .button-contain {
      text-align: right;
      .button {
        width: 72px;
        height: 32px;
        border-radius: 4px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #fff !important;
        background: #4694df !important;
        border: #4694df 0px solid !important;
      }
    }
  }
}
</style>
<style lang="css" scoped>
.dialog-tips-contain >>> .el-dialog__header {
  padding: 0px;
  margin: 0px;
  height: 0px;
}
.dialog-tips-contain >>> .el-dialog__body {
  padding: 0px;
  margin: 0px;
}
.dialog-tips-contain >>> .el-pagination {
  text-align: center;
}
</style>
