// add by xujun

export const VXETableMenuType = {
  custom: undefined, // 自定义
  cut: 1, // 剪切
  pasteFront: 2, // 粘贴前面
  pasteBack: 3, // 粘贴后面
  pasteSub: 4// 粘贴到子集
};

export default { VXETableMenuType };
