/**
 * @Author 李煜
 * @Date 2020/7/14
 * @Description viewer 指令
 */
import Viewer from "./js"

const viewerDirective = {}

viewerDirective.install = (Vue) => {
  if (Vue.prototype.$isServer) return

  const createViewer = (el, binding) => {
    const instance = new Viewer(el, binding.value)
    el.$viewer = instance
  }
  const updateViewer = (el, binding) => {
    const instance = el.$viewer
    if (!instance) {
      console.warn("[viewer update] 未初始化的viewer")
      createViewer(el, binding)
      return
    }
    const { modifiers } = binding
    const { rebuild } = modifiers
    if (rebuild) {
      instance.destroy()
      createViewer(el, binding)
    } else {
      instance.update()
    }
  }
  Vue.directive("viewer", {
    bind(el, binding) {
      createViewer(el, binding)
    },
    componentUpdated(el, binding) {
      if (!el.$viewer) {
        createViewer(el, binding)
      } else {
        updateViewer(el, binding)
      }
    },
    unbind(el) {
      if (el.$viewer) {
        el.$viewer.destroy()
      }
      el.$viewer = null
    }
  })
}

export default viewerDirective
