<!--
 * @Author: Fenngding
 * @Date: 2019-10-14 15:27:06
 * @LastEditors: Fengding
 * @LastEditTime: 2019-10-18 16:04:43
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="en-file-select-board-container">
    <div class="tree-select-area">
      <en-tree v-bind="$attrs" v-on="$listeners"> </en-tree>
    </div>
  </div>
</template>
<script>
export default {
  name: "EnFileSelectBoard",
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
<style scoped lang="scss">
.en-file-select-board-container {
  background: rgba(255, 255, 255, 1);
  .tree-select-area {
    margin-top: 10px;
    // 设置被节点被选中的效果
    /deep/ .is-current.is-focusable:not(.tree-node-level1) {
      & > .el-tree-node__container .el-tree-node__content.single-node {
        background: rgba(245, 248, 252, 1);
        color: #4694df;
      }
    }
    /deep/ .tree-node-level1 {
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(232, 236, 242, 1);
      border-radius: 4px;
      margin-bottom: 10px;
      /deep/ .loading-more {
        color: #aeaeae;
      }
      & > .el-tree-node__container {
        .el-tree-node__content {
          padding-left: 10px !important;
        }
      }
      &.is-expanded {
        & > .el-tree-node__container {
          background-color: #4694df !important;
          color: rgba(255, 255, 255, 1) !important;
          border-radius: 4px;
          overflow: hidden;
          .el-tree-node__content {
            background-color: #4694df !important;
            color: rgba(255, 255, 255, 1) !important;
            padding-left: 10px !important;
          }
          .el-tree-node__expand-icon {
            .en-icon {
              color: rgba(255, 255, 255, 1) !important;
            }
          }
        }
      }
      /deep/ .tree-node-level2.is-expanded {
        border-top: 1px solid #e8ecf2;
        border-bottom: 1px solid #e8ecf2;
      }
    }
  }
}
</style>
