<!--
 * @Author: Louis
 * @Date: 2019-08-08 15:34:12
 * @LastEditors: Louis
 * @LastEditTime: 2019-08-29 13:51:23
 * @Description: 增加右侧插槽
 -->
<template>
  <components
    class="en-card"
    :is="type"
    :iconName="iconName"
    :iconColor="iconColor"
    :iconBg="iconBg"
    :width="width"
    :height="height"
    :title="title"
    :subTitle="subTitle"
    :description="description"
    :showTips="showTips"
    :disabled="disabled"
    :dataInfo="dataInfo"
    :editAble="editAble"
    :menuList="menuList"
    :menuLabel="menuLabel"
    :menuKey="menuKey"
    @change="change"
  >
    <slot name="tag" slot="tag"></slot>
    <slot name="icon" slot="icon"></slot>
    <slot name="content" slot="content"></slot>
    <slot name="menu" slot="menu"></slot>
    <slot name="right" slot="right"></slot>
    <slot name="description" slot="description"></slot>
  </components>
</template>

<script>
import normal from "./child/normal";
import big from "./child/big";

export default {
  name: "EnCard",
  components: {
    normal,
    big
  },
  props: {
    // 图标名称
    iconName: {
      type: String,
      default: ""
    },
    // 图标颜色
    iconColor: {
      type: String,
      default: ""
    },
    // 图标底色
    iconBg: {
      type: String,
      default: ""
    },
    // 卡片宽度
    width: {
      type: [String, Number],
      default: ""
    },
    // 卡片高度
    height: {
      type: [String, Number],
      default: ""
    },
    // 卡片标题
    title: {
      type: String,
      default: ""
    },
    // 卡片副标题
    subTitle: {
      type: String,
      default: ""
    },
    // 卡片描述
    description: {
      type: String,
      default: ""
    },
    // 是否需要显示提示信息
    showTips: {
      type: [Boolean, String],
      default: false
    },
    // 卡片类型=>目前只提供 常用尺寸、大尺寸两种尺寸
    type: {
      type: String,
      default: "normal",
      validator: (val) => {
        return ["normal", "big"].indexOf(val) > -1;
      }
    },
    // 是否禁用
    disabled: {
      type: [Boolean, String],
      default: false
    },
    // 卡片数据源信息
    dataInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 是否可编辑=>与menuList搭配使用
    editAble: {
      type: [String, Boolean],
      default: false
    },
    // 下拉菜单数组
    menuList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 下拉菜单显示文本字段
    menuLabel: {
      type: String,
      default: "name"
    },
    // 下拉菜单唯一标识字段
    menuKey: {
      type: String,
      default: "id"
    }
  },
  methods: {
    /**
     * @description 点击触发回调事件
     * @param indexData 点击的下拉菜单的下标值
     * @returns change 回调的事件方法
     * @returns this.dataInfo 该组件接收到的数据源
     * @returns indexData 点击的下拉菜单的下标值
     */
    clickItemData(indexData) {
      this.$emit("change", this.dataInfo ? this.dataInfo : "", indexData);
    },
    /**
     * @description 点击菜单时触发
     */
    change(menuData, dataInfo) {
      this.$emit("change", menuData, dataInfo);
    }
  }
};
</script>

<style lang="scss" scoped>
  .en-card {
    text-align: left;
  }
</style>
