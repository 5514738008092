import { Renderer } from "../../v-x-e-table"
import ColumnUtils from "./columnUtils"
import Utils from "../../tools/src/utils"
import Business from "../../tools/src/business"
import dayjs from "dayjs"

let columnUniqueId = 0

class ColumnConfig {
  constructor(_vm, renMaps) {
    if (!_vm) {
      return;
    }
    if (_vm.cellRender && _vm.editRender) {
      Utils.warn("vxe.error.cellEditRender")
    }
    this.id = ColumnUtils.getColumnId(++columnUniqueId)// `col_${++columnUniqueId}`,
    this.updateColumnConfig(_vm, renMaps)
  }

  /**
   * 更新所有信息
   * @param _vm ColumnConfig
   * @param renMap 渲染字典
   */
  updateColumnConfig(_vm, renMap) {
    Object.assign(this, {
      // 基本属性
      data: _vm.data,
      type: _vm.type,
      width: _vm.width,
      minWidth: _vm.minWidth,
      resizable: _vm.resizable,
      padding: _vm.padding, // 左右是否有间距
      fixed: _vm.fixed,
      align: _vm.align,
      headerAlign: _vm.headerAlign,
      renderCustomHeader: _vm.renderCustomHeader,
      showOverflow: _vm.showOverflow,
      showHeaderOverflow: _vm.showHeaderOverflow,
      indexMethod: _vm.indexMethod,
      formatter: _vm.formatter,
      filterSortConfig: _vm.filterSortConfig,
      filterSortMethod: _vm.filterSortMethod,
      filterSortStore: _vm.filterSortStore,
      filterRender: _vm.filterRender,
      headData: _vm.headData,
      treeNode: _vm.treeNode,
      cellRender: _vm.cellRender,
      editRender: _vm.editRender,
      // 自定义参数
      params: _vm.params,
      // 渲染属性
      visible: true,
      level: 1,
      rowSpan: 1,
      colSpan: 1,
      renderWidth: 0,
      renderHeight: 0,
      resizeWidth: 0,
      renderLeft: 0,
      model: {},
      // 单元格插槽，只对 grid 有效
      slots: _vm.slots,
      own: _vm
    })
    this.refreshProps();
    this.refreshSelectConfig(_vm.selectConfig)// 单选多选配置
    this.refreshRenderMap(_vm, renMap)// 渲染函数
  }

  /**
   * 是否是用户自定义列
   * @returns {boolean|*}
   */
  isCustomColumn() {
    return !this.isSystemType() || (this.selectConfig && !this.selectConfig.isSystem)
  }

  /**
   * 是否是系统内置的类型 (索引, 单选, 多选等等)
   * @param type 类型,可不填,默认当前类型
   * @returns {boolean}
   */
  isSystemType(type) {
    if (!type) {
      type = this.type
    }
    switch (type) {
      case "index":
      case "radio":
      case "selection":
      case "index-selection":// 选择有定制开发
      {
        return true
      }
    }
    return false
  }

  /**
   * 是否是系统多选选择配置项
   * @returns {*|boolean}
   */
  isSystemSelectionConfig() {
    return this.selectConfig && this.selectConfig.isSystem && this.isSelectionType()
  }

  /**
   * 是否是系统单选配置项
   * @returns {*|boolean}
   */
  isSystemRadioConfig() {
    return this.selectConfig && this.selectConfig.isSystem && this.isRadioType()
  }

  /**
   * 是否是单选/多选类型
   * @returns {boolean}
   */
  isSelectionRadioType() {
    return this.isSelectionType() || this.isRadioType()
  }

  /**
   * 是否是多选类型
   * @returns {boolean}
   */
  isSelectionType() {
    return this.type === "selection" || this.type === "index-selection"
  }

  /**
   * 是否是单选类型
   * @returns {boolean}
   */
  isRadioType() {
    return this.type === "radio"
  }
  // 是否是编辑列
  isEditColumn() {
    return this.editRender || (this.slots && this.slots.edit);
  }
  /**
   * 刷新属性
   */
  refreshProps() {
    let headData, property, title;
    const _vm = this.own;
    if (_vm) {
      property = _vm.field || _vm.prop;
      title = _vm.title || _vm.label;
      headData = _vm.data;
    } else {
      headData = this.data;
    }

    if (headData) {
      if (!property) {
        const { field, nameVariable, prop } = headData;
        property = field || nameVariable || prop;
      }
      if (!title) {
        const { fieldName, nameVariableName, name, label } = headData;//, headTitle: title
        title = fieldName || nameVariableName || name || label;
      }
      this.uid = headData.id;
    }

    // 兼容老数据
    if (property) {
      this.prop = property;// 为什么加上, 对外使用, 内部请勿使用
      this.field = property;// 为什么加上, 对外使用, 内部请勿使用
      this.property = property;
    }

    if (title) {
      this.label = title;// 为什么加上, 对外使用, 内部请勿使用
      this.title = title;
    }
  }
  /**
   * 更新渲染函数
   * @param _vm
   * @param renMap 渲染字典
   */
  refreshRenderMap(_vm, renMap) {
    if (!_vm) {
      _vm = this.own
    }

    if (!_vm && !renMap) {
      return
    }

    if (!renMap) {
      renMap = _vm.createRenderMap(_vm.$table)
    }

    const { renderHeader, renderCell, renderData } = renMap

    this.renderHeader = renderHeader || _vm.renderHeader
    this.renderCell = renderCell || _vm.renderCell
    this.renderData = renderData
  }

  /**
   * 刷新单选复选配置信息
   * @param config 单选/复选配置项
   */

  refreshSelectConfig(config) {
    if (!this.isSelectionRadioType()) {
      return
    }

    if (!config) {
      config = { "isSystem": true }// 标识为全局选中
    }

    // debugger
    const newConfig = {}
    Object.assign(newConfig, this.own.$table.selectConfig)
    Object.assign(newConfig, config)

    if (!newConfig.checkField) { // && !newConfig.checkMethod
      if (this.property) {
        newConfig.checkField = "$" + this.property + "-" + "checked"
      } else {
        newConfig.checkField = "$checked" + this.id
      }
    }

    if (!newConfig.treeIndeterminates) {
      newConfig.treeIndeterminates = []
    }

    if (!newConfig.selection) {
      newConfig.selection = []
    }

    if (!newConfig.selectRow) {

    }
    newConfig.selectRow = undefined// 单选选中
    newConfig.isIndeterminate = false
    newConfig.isAllSelected = false

    // 是否全选
    // isAllSelected: false,
    // 多选属性，有选中且非全选状态
    //   isIndeterminate: false,
    //   // 树节点不确定状态的列表
    //   treeIndeterminates: [],
    //   // 多选属性，已选中的列
    //   selection: [],

    this.selectConfig = newConfig
  }

  /**
   * 刷新默认值(enfry定制开发 width, fixed)
   * @author xujun
   */
  refreshColumnDefaultValueIfNeed() {
    const { editRender, property } = this
    // 宽高定制开发
    if (!property && this.isSystemSelectionConfig()) { // 自带属性不管
      if (!this.width) {
        this.width = 50
      }
      if (!this.fixed && (this.type === "index" || this.type === "index-selection")) { // 序号列默认左侧浮动
        this.fixed = "left"
      }

      if (!this.align) {
        this.align = "center"
      }

      this.padding = false
    }

    // padding 定制开发
    // debugger;
    // visible 定制开发
    if (editRender && !editRender.type && editRender.name) {
      const compConf = Renderer.get(editRender.name)
      if (compConf && compConf.visible) {
        editRender.type = "visible"
      }
    }

    // 筛选相关
    this.refreshFilterSortStore();
  }
  // 筛选排序相关
  refreshFilterSortStore() {
    const { filterSortConfig } = this;
    if (!filterSortConfig) {
      return;
    }
    const { filterSortStore, own, property, data: headData } = this;
    const { params } = own.$table;
    let { sortable, filterable } = filterSortConfig;
    const { sortKey = "noSort", filterKey = "noSearch" } = filterSortConfig;
    if (headData) {
      if (!sortable) {
        sortable = Number(headData[sortKey]) === 1;
      }

      if (!filterable) {
        filterable = Number(headData[filterKey]);
      }
    }

    if (!this.filterRender) { // 不存在默认渲染器
      const fieldType = Business.fieldType(headData);
      if (fieldType === 7) { // 日期
        const dateType = Business.elDateTypeWithTimeFormat(headData.timeFormat);
        const dateFormat = Business.dateFormatWithTimeFormat(headData.timeFormat);

        // 日期默认值处理
        const endDay = dayjs();
        const beginDay = endDay.subtract(1, "month");
        filterSortStore.value = dateType.indexOf("range").length === -1 ? endDay.toDate() : [beginDay.toDate(), endDay.toDate()];
        this.filterRender = { name: "EnDatePicker",
          props: {
            type: dateType,
            format: dateFormat,
            startPlaceholder: "请选择开始时间",
            endPlaceholder: "请选择结束时间",
            unlinkPanels: true, // 取消联动
            pickerOptions: filterSortConfig.pickerOptions
          }
        }
      } else if (fieldType === 3 || fieldType === 4) { // 数字金额
        filterSortStore.value = [];
        this.filterRender = { name: "EnInputRange" };
      } else if (fieldType === 5) { // 下拉选
        this.filterRender = { name: "ElInput" };
      } else { // 文本框 1
        this.filterRender = { name: "ElInput" };
      }
    }

    // 默认值处理
    if (params) {
      const { supportSearchFieldList } = params;
      if (supportSearchFieldList) {
        const ret = supportSearchFieldList.some(item => item.field === property);
        if (!sortable) {
          sortable = ret;
        }
        if (!filterable) {
          filterable = ret;
        }
      }
    }

    filterSortStore.sortable = sortable;
    filterSortStore.filterable = filterable;
    filterSortStore.sort = filterSortConfig.sort;
    filterSortStore.value = filterSortConfig.value;
    filterSortStore.valueText = filterSortConfig.valueText;
  }

  /**
   * 修改editRender, 如果有必要
   * @param editRender
   * @returns {*} 最新的editRender
   */
  refreshEditRenderIfNeeded(editRender) {
    if (!editRender) {
      this.editRender = editRender
      return this.editRender
    }

    if (this.attrs !== editRender.attrs) {
      this.editRender.attrs = editRender.attrs
    }
    if (this.props !== editRender.props) {
      this.editRender.props = editRender.props
    }
    return this.editRender
  }
}

export default ColumnConfig;
