/*
 * @Author: Fenngding
 * @Date: 1985-10-26 16:15:00
 * @LastEditors: Fengding
 * @LastEditTime: 2019-10-19 17:59:20
 * @Email: fenngding@live.com
 * @Description:
 */
import { addClass, removeClass } from "element-ui/src/utils/dom"

const listen = {
  beforeEnter(el) {
    addClass(el, "collapse-transition")
    if (!el.dataset) {
      el.dataset = {}
    }
    el.dataset.oldPaddingTop = el.style.paddingTop
    el.dataset.oldPaddingBottom = el.style.paddingBottom
    el.style.height = "0"
    el.style.paddingTop = 0
    el.style.paddingBottom = 0
  },
  enter(el) {
    el.dataset.oldOverflow = el.style.overflow
    if (el.scrollHeight !== 0) {
      el.style.height = el.scrollHeight + "px"
      el.style.paddingTop = el.dataset.oldPaddingTop
      el.style.paddingBottom = el.dataset.oldPaddingBottom
    } else {
      el.style.height = ""
      el.style.paddingTop = el.dataset.oldPaddingTop
      el.style.paddingBottom = el.dataset.oldPaddingBottom
    }
    el.style.overflow = "hidden"
  },
  afterEnter(el) {
    // for safari: remove class then reset height is necessary
    removeClass(el, "collapse-transition")
    el.style.height = ""
    el.style.overflow = el.dataset.oldOverflow
  },
  beforeLeave(el) {
    if (!el.dataset) {
      el.dataset = {}
    }
    el.dataset.oldPaddingTop = el.style.paddingTop
    el.dataset.oldPaddingBottom = el.style.paddingBottom
    el.dataset.oldOverflow = el.style.overflow
    el.style.height = el.scrollHeight + "px"
    el.style.overflow = "hidden"
  },
  leave(el) {
    if (el.scrollHeight !== 0) {
      // for safari: add class after set height, or it will jump to zero height suddenly, weired
      addClass(el, "collapse-transition")
      el.style.height = 0
      el.style.paddingTop = 0
      el.style.paddingBottom = 0
    }
  },
  afterLeave(el) {
    removeClass(el, "collapse-transition")
    el.style.height = ""
    el.style.overflow = el.dataset.oldOverflow
    el.style.paddingTop = el.dataset.oldPaddingTop
    el.style.paddingBottom = el.dataset.oldPaddingBottom
  }
}

export default {
  name: "EnCollapseArea",
  functional: true,
  render(h, context) {
    const { children } = context
    const data = { on: listen }
    return h("transition", data, children)
  }
}
