/**
 * @Author 李煜
 * @Date 2020/7/14
 * @Description en-viewer 组件
 */
import Viewer from "./src/Viewer"
import directive from "./src/directive"
import service from "./src/main"

export default {
  install(Vue) {
    Vue.use(directive)
    Vue.component(Viewer.name, Viewer)
    Vue.prototype.$viewerInstance = null
    Vue.prototype.$viewImage = (options) => {
      const o = {
        hidden() {
          Vue.prototype.$viewerInstance.destroy()
          Vue.prototype.$viewerInstance = null
        }
      }
      if (Vue.prototype.$viewerInstance && Vue.prototype.$viewerInstance.destory) {
        Vue.prototype.$viewerInstance.destroy()
        Vue.prototype.$viewerInstance = null
      }
      Vue.prototype.$viewerInstance = service(Object.assign(options, o))
      Vue.prototype.$viewerInstance.view()
    }
  },
  directive,
  service
}
