<!--
 * @Author: zql
 * @Date: 2020-03-13 15:29:14
 * @LastEditTime: 2020-06-02 14:35:39
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \enfry-components\packages\components\en-result\src\loading\loading.vue
-->
<template>
  <div ref="loading" class="loading">
    <img
      slot="icon"
      class="icon-img"
      src="http://cdn.enfry.com/loadingbg11344.gif"
      :alt="this.title"
    />
   <div class="title" v-html="title"></div>
    <div class="subtitle" v-html="subTitle"></div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    type: { type: String, default: null },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: [String, Number],
      default: "normal"
    },
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: "加载中..."
    }
  }
};
</script>
<style lang="scss" scoped>
.loading {
  text-align: center;
}
</style>
