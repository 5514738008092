<template>
  <el-popover
    placement="bottom-start"
    popper-class="column-setting-popver"
    :visible-arrow="false"
    :offset="-12"
    title=""
    v-model="popverVisible"
    width="200"
    trigger="click"
    content="">
      <ul class="column-setting-container">
        <li class="column-setting-item" @click="hideColumn">
          <en-icon
            name="iconyincangcilie"
            color="#9876cf"
            size="12"
          >
          </en-icon>
          <span class="setting-text" style="top: -1px;">隐藏此列</span>
        </li>
        <li class="column-setting-item" @click="fixedColumn">
          <en-icon
            name="iconsuodinglie"
            size="12"
            color="#f7cd5c"
          >
          </en-icon>
          <span class="setting-text" style="top: -1px;">固定此列</span>
        </li>
      </ul>
      <span slot="reference" style="cursor: pointer;">
        <en-icon
          name="icongengduo"
          :color="settingIconColor"
          size="16"
        >
        </en-icon>
        <span class="setting-text">设置列</span>
      </span>
  </el-popover>
</template>

<script>
export default {
  name: "EnTableColumnSetting",
  data() {
    return {
      popverVisible: false
    }
  },
  computed: {
    settingIconColor() {
      return this.popverVisible ? "#4694df" : "#636c78"
    }
  },
  methods: {
    hideColumn() {
      this.$emit("hideColumn");
      this.popverVisible = false;
    },
    fixedColumn() {
      this.$emit("fixedColumn");
      this.popverVisible = false;
    }
  }
}
</script>

<style lang="scss">
.column-setting-popver {
  padding: 0;
  margin-top: 18px !important;
  .column-setting-container {
    margin: 0;
    padding: 0;
    .column-setting-item {
      padding-left: 10px;
      height: 36px;
      line-height: 36px;
      color: #636c78;
      cursor: pointer;
      &:hover {
        background: #f5f8fc;
      }
    }
  }
}
.setting-text {
  position: relative;
  left: 6px;
  top: -3px;
  font-size: 12px;
}

</style>
