<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-20 17:11:58
 * @LastEditTime: 2020-04-28 17:42:37
 -->

<template>
  <el-dialog
    title="触发条件"
    :visible="dialogVisible"
    class="notification-condition"
    width="1100px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    @close="close"
  >
    <div class="header">
      <en-button @click="addSection">
        添加
      </en-button>
    </div>
    <div class="content">
      <en-and-or
        v-for="(data, index) in conditionConfig"
        :key="index"
        :length-of-and="data.length"
        :index="index"
        :line-height="43"
        :padding="17"
        @add="addRow(data)"
        @deleteItem="deleteSection"
      >
        <en-and-or-column
          v-for="(column, columnIndex) in data"
          :key="columnIndex"
          :name="columnIndex"
          @deleteRow="deleteRow(data, columnIndex, index)"
        >
          <en-select
            v-model="column.conditionType"
            placeholder="请选择"
            :options="triggerOptions"
            class="column"
          ></en-select>
          <!-- 操作按钮 -->
          <en-select
            v-if="column.conditionType === '1'"
            v-model="column.btnKey"
            placeholder="请选择操作按钮"
            class="column operation-button"
            collapse-tags
            filterable
            :options="conditionButtons"
          ></en-select>
          <!-- 字段变化 -->
          <en-select
            v-if="column.conditionType === '3'"
            v-model="changeFileds"
            placeholder="请选择字段"
            class="column"
            collapse-tags
            filterable
            multiple
            :options="changeOptions"
          ></en-select>
          <!-- 业务字段 -->
          <!-- 选择字段 -->
          <en-select
            v-if="column.conditionType === '2'"
            v-model.number="column.fieldType"
            placeholder="请选择字段"
            :options="changeOptions"
            class="column"
          ></en-select>
          <!-- 选择比较符 -->
          <en-select
            v-if="column.conditionType === '2'"
            v-model="column.operator"
            placeholder="请选择条件"
            :options="operatorOptions(column.fieldType)"
            class="column"
          ></en-select>
          <!-- 为空不为空后面就没了 -->
          <!-- 文本等于不等于包含不包含是输入框 1,2,3,4-->
          <span
            v-if="
              column.conditionType === '2' &&
                column.fieldType === 1 &&
                parseInt(column.operator) <= 4
            "
          >
            <el-input
              v-model="column.value"
              placeholder="请输入"
              class="column"
            >
            </el-input>
          </span>
          <!-- 数值 金额 时间  -->
          <span
            v-if="
              column.conditionType === '2' &&
                (column.fieldType === 3 ||
                  column.fieldType === 4 ||
                  column.fieldType === 7) &&
                ['1', '2', '5', '6', '7', '8'].indexOf(column.operator) > -1
            "
          >
            <en-select
              v-model="column.valueType"
              placeholder="请选择"
              class="column"
              :options="compareColumns(column)"
            ></en-select>
            <el-input
              v-model="column.value"
              placeholder="请输入"
              class="column"
            >
            </el-input>
          </span>
          <!-- 单选 -->
          <span v-if="column.conditionType === '2' && column.fieldType === 5">
            <en-select
              v-if="
                column.conditionType === '2' &&
                  column.fieldType === 3 &&
                  parseInt(column.operator) <= 4
              "
              v-model.number="value"
              placeholder="请选择"
              class="column"
              :options="conditionButtons"
            ></en-select>

            <en-select
              v-if="column.conditionType === '2'"
              v-model.number="value"
              class="column"
              placeholder="请选择"
              :options="conditionButtons"
            ></en-select>
          </span>
        </en-and-or-column>
      </en-and-or>
    </div>
    <div slot="footer" class="dialog-footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </el-dialog>
</template>
<script>
import notificationConditionData from "./notificationConditionData.js";

export default {
  name: "NotificationCondition",
  mixins: [notificationConditionData],
  props: {
    // 触发条件类型, = 父组件的type
    type: {
      type: String,
      default: "business"
    },
    // 是否显示弹框
    dialogVisible: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      // 字段变化 要取模板上的字段
      changeOptions: [
        {
          label: "文本",
          value: 1,
          fieldType: 1
        },
        {
          label: "数值",
          value: 3,
          fieldType: 3
        },
        {
          label: "金额",
          value: "4",
          fieldType: 4
        },
        {
          label: "单选",
          value: 5,
          fieldType: 5
        },
        {
          label: "时间",
          value: 7,
          fieldType: 7
        }
      ],
      changeFileds: [],
      value: ""
    };
  },
  methods: {
    // 关闭弹框
    close() {
      this.$emit("update:dialog-visible");
    },
    addSection() {
      this.conditionConfig.push([{ ...this.defaultAddButton }]);
    },
    addRow(data) {
      // 增加一行数据
      data.push({ ...this.defaultAddButton });
    },
    deleteSection(index) {
      this.conditionConfig.splice(index, 1);
    },
    deleteRow(data, index, dataIndex) {
      if (data.length === 1) {
        this.conditionConfig.splice(dataIndex, 1);
      } else {
        data.splice(index, 1);
      }
    },
    // 保存
    save() {
      // this.up
      this.$emit("update:datas", this.conditionConfig);
      this.close();
    }
  }
};
</script>
<style lang="scss" scoped>
.notification-condition {
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  .content {
    padding-top: 20px;
    height: 500px;
    overflow-y: auto;
  }
  .column {
    width: 170px;
    display: inline-block;
    margin-right: 12px;
  }
}
</style>
