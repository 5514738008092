import "xe-utils";
import "./src/styles/index.scss";
import enTable from "./src/packages/vxe-table";

enTable.setup({
  // 默认表格参数
  showOverflow: true,
  showHeaderOverflow: true,
  align: null,
  headerAlign: null,
  stripe: true,
  border: true,
  resizable: true,
  fit: true,
  enableRowKey: true, // 启用rowKey
  showHeader: true,
  highlightCurrentRow: false, // 高亮当前行
  highlightCurrentColumn: false, // 高亮当前列

  highlightHoverRow: true, // 高亮悬浮行
  highlightHoverColumn: false, // 高亮悬浮列

  highlightSelectRow: true, // 高亮选中行
  highlightSelectColumn: false, // 高亮选中列(未实现)

  icon: {
    sortAsc: "el-icon-caret-top",
    sortDesc: "el-icon-caret-bottom",
    filter: "el-icon-arrow-down",
    // edit: "vxe-icon--edit-outline",
    tree: "el-icon-plus",
    treeLoading: "el-icon-loading",
    treeExpand: "el-icon-minus"
    // refresh: "vxe-icon--refresh",
    // custom: "vxe-icon--menu",
    // jumpPrev: "vxe-icon--d-arrow-left",
    // jumpNext: "vxe-icon--d-arrow-right",
    // prevPage: "vxe-icon--arrow-left",
    // nextPage: "vxe-icon--arrow-right",
    // zoomIn: "vxe-icon--zoomin",
    // zoomOut: "vxe-icon--zoomout",
    // modalClose: "vxe-icon--close",
    // modalInfo: "vxe-icon--info",
    // modalSuccess: "vxe-icon--success",
    // modalWarning: "vxe-icon--warning",
    // modalError: "vxe-icon--error",
    // modalQuestion: "vxe-icon--question",
    // modalLoading: "vxe-icon--refresh roll",
    // caretBottom: "vxe-icon--caret-bottom",
    // dropdownBottom: "vxe-icon--arrow-bottom"
  }
});
// component.install = function(Vue) {
//   Vue.component(component.name, component);
// };
export default enTable;
