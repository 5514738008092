var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dataInfo.getCellRowSpan > 0 && _vm.dataInfo.getCellColSpan > 0)?_c('td',{staticClass:"components-excel-cell",style:({
    'text-align': _vm.dataInfo.getTextAlign,
    'font-weight': _vm.dataInfo.getFontWeight ? 'bold' : 'normal',
    'background-color': _vm.dataInfo.getBackGround,
    'color': _vm.dataInfo.getFontColor,
    'border-left-width': _vm.dataInfo.getLeftBorderWidth,
    'border-top-width': _vm.dataInfo.getTopBorderWidth,
    'border-right-width': _vm.dataInfo.getRightBorderWidth,
    'border-bottom-width': _vm.dataInfo.getBottomBorderWidth,
    'border-left-style': _vm.dataInfo.getLeftBorderStyle ? 'dashed': 'solid',
    'border-top-style': _vm.dataInfo.getTopBorderStyle ? 'dashed': 'solid',
    'border-right-style': _vm.dataInfo.getRightBorderStyle ? 'dashed': 'solid',
    'border-bottom-style': _vm.dataInfo.getBottomBorderStyle ? 'dashed': 'solid',
    'border-left-color': _vm.dataInfo.getLeftBorderColor,
    'border-top-color': _vm.dataInfo.getTopBorderColor,
    'border-right-color': _vm.dataInfo.getRightBorderColor,
    'border-bottom-color': _vm.dataInfo.getBottomBorderColor,
    'width':_vm.dataInfo.getCellWidth && _vm.dataInfo.getCellWidth > 0 ? _vm.dataInfo.getCellWidth + 'px' : null,
    'height':_vm.dataInfo.getCellHeight && _vm.dataInfo.getCellHeight > 0 ? _vm.dataInfo.getCellHeight + 'px' : null,
  }),attrs:{"rowSpan":_vm.dataInfo.getCellRowSpan,"colSpan":_vm.dataInfo.getCellColSpan},on:{"click":_vm.cellClick}},[(_vm.store.config.editAble)?_c('el-input',{model:{value:(_vm.dataInfo.data.name),callback:function ($$v) {_vm.$set(_vm.dataInfo.data, "name", $$v)},expression:"dataInfo.data.name"}}):_vm._e(),(!_vm.store.config.editAble)?_c('span',[_vm._v("\n    "+_vm._s(_vm.dataInfo.data.name)+"\n  ")]):_vm._e(),(_vm.store.config.showDelete && _vm.dataInfo.data.name)?_c('en-icon',{staticClass:"components-excel-cell-icon",attrs:{"name":"shanchu-liebiao","size":"small"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.removeCellDataInfo($event)}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }