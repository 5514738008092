/*
 * @Author: Fenngding
 * @Date: 2019-10-10 15:39:22
 * @LastEditors: Fengding
 * @LastEditTime: 2019-10-10 15:41:01
 * @Email: fenngding@live.com
 * @Description:
 */
import directive from "./src";
import service from "./src/service";

export default {
  install(Vue) {
    Vue.directive(directive.name, directive);
    Vue.prototype.$enLoading = service;
    Vue.prototype.$enLoadingInstance = null;
    Vue.prototype.$showLoading = (options) => {
      if (Vue.prototype.$enLoadingInstance) {
        return;
      }
      Vue.prototype.$enLoadingInstance = Vue.prototype.$enLoadingInstance = service(options);
    }
    Vue.prototype.$hideLoading = () => {
      if (Vue.prototype.$enLoadingInstance) {
        Vue.prototype.$enLoadingInstance.close();
        Vue.prototype.$enLoadingInstance = null;
      }
    }
  },
  directive,
  service
};
