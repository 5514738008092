/*
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-09-24 13:31:45
 * @LastEditTime: 2019-10-22 11:55:29
 */
import component from "./src";

component.install = function(Vue) {
  Vue.component(component.name, component);
};
export default component;
