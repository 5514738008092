/**
 * project: enfry-components
 * fileName: index
 * Created by 徐俊 on 2020/03/09
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import EnTableHeadEndMore from "./src/index";

EnTableHeadEndMore.install = function(Vue) {
  Vue.component(EnTableHeadEndMore.name, EnTableHeadEndMore);
};

export const HeadEndMore = EnTableHeadEndMore;
export default EnTableHeadEndMore;
