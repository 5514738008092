<template>
  <div class="dialog-contain">
    <el-dialog title="" :show-close="false" :visible.sync="dialogVisible">
      <div class="export-body-contain">
        <span class="title">模板和数据导出</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{ fill: '#A9B5C6' }"
          ></en-icon>
        </div>

        <div class="content-contain">
          <dataSelect
            :option-list="objectList"
            title="导出对象"
            class="export-object"
            @getDropSelectValue="getDropObjectValue"
          />
          <dataSelect
            :option-list="contentList"
            title="导出内容"
            class="export-content"
            @getDropSelectValue="getDropContentValue"
          />

          <radioChoose
            v-show="contentAttrShow"
            :check-list="contentAttrList"
            @getCheckboxSelectValue="getContentChooseValue"
          />

          <dataSelect
            :option-list="typeList"
            title="导出类型"
            class="export-type"
            @getDropSelectValue="getDropTypeValue"
          />

          <radioChoose
            v-show="typeAttrShow"
            :check-list="typeAttrList"
            @getCheckboxSelectValue="getTypeChooseValue"
          />

          <en-button
            size="small"
            type="blue"
            class="confirm-button"
            @click="confirmClick"
          >
            确定
          </en-button>
        </div>
      </div>
    </el-dialog>

    <en-tips-dialog
      :dialog-visible.sync="dialogTipsVisible"
      :dialog-type="dialogType"
      :title="dialogTitle"
      :message="dialogTips"
      @getEnTipsDialogConfirm="getEnTipsDialogConfirm"
      @getEnTipsDialogVisible="getEnTipsDialogVisible"
    ></en-tips-dialog>
  </div>
</template>

<script>
import dataSelect from "./drop-select";
import radioChoose from "./radio-choose";
import enTipsDialog from "./en-tips-dialog";
import {
  dataObjectList as objList,
  attributesList as attrList,
  ENDataExport
} from "./DataExport";

export default {
  name: "EnDataExport",
  components: {
    dataSelect,
    radioChoose,
    enTipsDialog
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // dialogExportVisible: false,
      dialogTipsVisible: false,
      dialogType: "message",
      dialogTips: "",
      dialogTitle: "温馨提示:",
      dataSource: new ENDataExport(),
      contentAttrShow: false,
      typeAttrShow: false,
      objectList: [],
      contentList: [
        {
          id: 1,
          type: "main",
          name: "主表属性"
        },
        {
          id: 2,
          type: "detail",
          name: "明细表属性"
        }
      ],
      typeList: [
        {
          id: 1,
          type: "nil",
          name: "空白模板"
        },
        {
          id: 2,
          type: "data",
          name: "含数据"
        }
      ],
      contentAttrList: [],
      typeAttrList: [
        {
          id: 1,
          fieldName: "含停用数据"
        },
        {
          id: 2,
          fieldName: "含停用属性"
        }
      ],
      // 以下为选中数据
      selectObj: "",
      selectContent: "",
      selectContentList: [],
      selectType: "",
      selectTypeList: []
    };
  },
  watch: {
    dialogVisible(val) {
      this.$emit("update:dialogVisible", val);
    }
  },
  mounted() {
    // 导出对象
    this.objectList = objList();
    // 导出内容->明细表属性
    this.contentAttrList = attrList();
    // 增加一些测试数据
    const contentAttrList = [...this.contentAttrList];
    for (let i = 0; i < 10; i++) {
      contentAttrList.push({
        id: 1 + i,
        fieldName: "测试兼岗信息" + i
      });
    }
    this.contentAttrList = [...contentAttrList];
  },
  methods: {
    closeClick() {
      this.dialogVisible = false;
    },
    getDropObjectValue(val) {
      this.objectList.forEach(obj => {
        if (obj.id === val) {
          this.dataSource.exportObject = obj;
        }
      });
      this.selectObj = this.dataSource.exportObject;
    },
    getDropContentValue(val) {
      this.contentAttrShow = val === 2;
      this.selectContent = val;
      this.contentList.forEach(obj => {
        if (val === obj.id) {
          obj.contentAttrList = [
            ...this.dataSource.exportContent.contentAttrList
          ];
          this.dataSource.exportContent = obj;
        }
      });
    },
    getDropTypeValue(val) {
      this.typeAttrShow = val === 2;
      this.selectType = val;
      this.typeList.forEach(obj => {
        if (val === obj.id) {
          obj.typeAttrList = [...this.dataSource.exportType.typeAttrList];
          this.dataSource.exportType = obj;
        }
      });
    },
    getContentChooseValue(val) {
      const list = [];
      val.forEach(obj => {
        list.push(obj);
      });
      this.selectContentList = [...list];
      this.dataSource.exportContent.contentAttrList = [...list];
    },
    getTypeChooseValue(val) {
      const list = [];
      val.forEach(obj => {
        list.push(obj);
      });
      this.selectTypeList = [...list];
      this.dataSource.exportType.typeAttrList = [...list];
    },
    getEnTipsDialogConfirm() {
      this.dialogTipsVisible = false;
      if (this.dialogType !== "message") {
        this.dialogVisible = false;
      }
    },
    getEnTipsDialogVisible(val) {
      this.dialogTipsVisible = val;
    },
    confirmClick() {
      const msg = this.dataSource.checkExportSelectData();
      if (msg.length > 0) {
        this.dialogTips = msg;
        this.dialogTitle = "温馨提示:";
        this.dialogType = "message";
        this.dialogTipsVisible = false;
        this.dialogTipsVisible = true;
      } else {
        this.dialogTitle = "确定下载当前模板吗?";
        this.dialogType = "title";
        this.dialogTipsVisible = false;
        this.dialogTipsVisible = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.export-body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 900px;
  height: 460px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }
  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }
  .content-contain {
    position: relative;
    top: 52px;
    left: 20px;
    width: 860px;
    height: 394px;
    text-align: left;

    .export-object {
      position: relative;
    }
    .export-content {
      position: relative;
      margin-top: 12px;
    }
    .export-type {
      position: relative;
      margin-top: 12px;
    }

    .confirm-button {
      position: absolute;
      bottom: 14px;
      right: 20px;
      width: 72px;
      height: 32px;
      // background: rgba(73, 190, 242, 1);
      border-radius: 4px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #fff;
      line-height: 12px;
    }
  }
}

.dialog-contain /deep/ .el-dialog__header {
  padding: 0px;
  margin: 0px;
  height: 0px;
}

.dialog-contain /deep/ .el-dialog__body {
  padding: 0px;
  margin: 0px;
}
</style>
