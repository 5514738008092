<template>
  <el-form
    class="en-time-control"
    :label-width="controlLeftWidth"
    :style="`background: ${controlBg}`"
  >
    <el-form-item :label="controlLeftText">
      <el-select
        v-model="controlType"
        :disabled="timeType.length"
        placeholder="请选择使用控件类型"
      >
        <el-option label="当前业务的时间类型字段" value="system"></el-option>
        <el-option label="时间点" value="point"></el-option>
        <el-option label="重复周期" value="circle"></el-option>
      </el-select>

      <!-- 当前业务 字段 -->
      <template v-if="controlType === 'system'">
        <el-select v-model="systemTime" placeholder="请选择时间节点">
          <el-option
            v-for="item in systemTimeList"
            :key="item.value"
            :label="item.label || item.value"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select v-model="systemScene" placeholder="请选择偏移设置">
          <el-option label="当时" value="rightnow"></el-option>
          <el-option label="之前" value="before"></el-option>
          <el-option label="之后" value="after"></el-option>
        </el-select>
        <template v-if="systemScene !== 'rightnow'">
          <el-select
            v-model="systemUnit"
            placeholder="请选择偏移单位"
            @change="systemMount = 0"
          >
            <el-option label="分" value="minutes"></el-option>
            <el-option label="时" value="hour"></el-option>
            <el-option label="天" value="day"></el-option>
          </el-select>

          <el-autocomplete
            v-model="systemMount"
            :fetch-suggestions="querySystemMount"
            placeholder="请输入偏移值"
          >
            <template slot-scope="{ item }">
              <div class="name">{{ item.value }}</div>
            </template>
          </el-autocomplete>
        </template>
      </template>

      <!-- 时间点 -->
      <template v-if="controlType === 'point'">
        <el-select
          v-model="pointTime"
          placeholder="请选择时间点类型"
          @change="pointTimeChangeFn"
        >
          <el-option label="系统当前时间" value="system"></el-option>
          <el-option label="自定义时间点" value="user-defined"></el-option>
          <el-option label="指定时间" value="select"></el-option>
        </el-select>
        <!-- 指定时间/系统时间 -->
        <el-date-picker
          v-if="pointTime !== 'user-defined'"
          v-model="pointTimeVal"
          :format="pointTimeValFormat"
          :readonly="pointTime === 'system'"
          :disabled="pointTime === 'system'"
          @change="pointTimeValChangeFn"
          type="datetime"
          placeholder="选择日期时间"
        >
        </el-date-picker>
        <!-- 自定义时间 -->
        <el-select v-else v-model="pointTimeVal" placeholder="请选择时间节点">
          <el-option
            v-for="item in pointTimeValArr"
            :key="item.value"
            :label="item.label || item.value"
            :value="item.value"
          ></el-option>
        </el-select>
      </template>

      <!-- 重复周期 -->
      <template v-if="controlType === 'circle'">
        <el-select
          v-model="circleType"
          placeholder="请选择定时周期"
          @change="(weekVal = ''), (circleDate = '')"
        >
          <el-option label="每天" value="day"></el-option>
          <el-option label="每周" value="week"></el-option>
          <el-option label="每月" value="month"></el-option>
          <el-option label="每年" value="year"></el-option>
        </el-select>
        <!-- 每天 -->
        <template v-if="circleType === 'day'">
          <el-select v-model="dayType" placeholder="请选择定时方式">
            <el-option label="定时" value="point"></el-option>
            <el-option label="间隔" value="gap"></el-option>
          </el-select>
          <!-- 每天 - 时间点 -->
          <template v-if="dayType === 'point'">
            <el-date-picker
              v-model="circleVal"
              :format="pointTimeValFormat"
              type="datetime"
              placeholder="请选择时间点"
            >
            </el-date-picker>
          </template>
          <!-- 每天 -- 间隔 -->
          <template v-if="dayType === 'gap'">
            <el-date-picker
              align="left"
              format="yyyy-MM-dd HH:mm"
              v-model="circleVal"
              type="datetimerange"
              :range-separator="rangeSeparator"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
            <el-autocomplete
              v-model="gapVal"
              :fetch-suggestions="queryGapValArr"
              placeholder="请选择间隔时长"
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.value }}</div>
              </template>
            </el-autocomplete>
            <div class="el-input__inner hour">
              小时
            </div>
          </template>
        </template>
        <!-- 每周 -->
        <template v-if="circleType === 'week'">
          <el-select v-model="weekVal" multiple placeholder="请选择">
            <el-option
              v-for="item in weekArr"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-time-picker
            v-model="circleVal"
            format="HH:mm"
            value-format="HH:mm"
            placeholder="选择时间"
          >
          </el-time-picker>
        </template>
        <!-- 每月 -->
        <template v-if="circleType === 'month'">
          <el-select v-model="circleDate" multiple>
            <el-option
              v-for="item in 31"
              :key="item"
              :value="item"
              :label="item"
            ></el-option>
          </el-select>
          <el-time-picker
            v-model="circleVal"
            format="HH:mm"
            value-format="HH:mm"
            placeholder="选择时间"
          >
          </el-time-picker>
        </template>
        <!-- 每年 -->
        <template v-if="circleType === 'year'">
          <el-date-picker
            @change="circleTypeChangeFn"
            format="MM-dd HH"
            v-model="circleVal"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </template>
      </template>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: "en-time-control",
  watch: {
    // 重置
    circleType() {
      this.circleVal = "";
    },
    circleVal(v) {
      console.log(v);
    },
    // 监听最大时间间距
    systemMount(newV, oldV) {
      const newVal = Number(newV);
      let max = 24;
      if (this.systemUnit === "minutes") {
        max = 60;
      } else if (this.systemUnit === "day") {
        max = 365;
      }
      if (newVal > max) {
        this.$message({
          message: `最大值: ${max} ${this.systemUnit}`,
          type: "warning"
        });
        this.systemMount = max;
      }
    },
    // 监听最大重复时间周期
    gapVal(v) {
      const newVal = Number(v);
      if (newVal > 24) {
        this.$message({
          message: `最大值: 24`,
          type: "warning"
        });
        this.systemMount = 24;
      }
    }
  },
  props: {
    timeType: {
      type: String,
      default: ""
    },
    controlLeftWidth: {
      type: String,
      default: "80px"
    },
    controlLeftText: String,
    controlBg: {
      type: String,
      default: "transparent"
    },
    pointTimeValFormat: {
      type: String,
      default: "yyyy-MM-dd HH:mm"
    },
    pointTimeValArr: {
      type: Array,
      default() {
        return [
          {
            value: "[自定义时间节点1]"
          }
        ];
      }
    },
    systemTimeList: {
      type: Array,
      default() {
        return [
          {
            value: "[XXX系统完成]"
          }
        ];
      }
    },
    rangeSeparator: {
      type: String,
      default: "至"
    }
  },
  beforeMount() {
    if (this.timeType) {
      this.controlType = this.timeType;
    }
  },
  computed: {
    systemMountArr() {
      const arr = [];
      let i = 0;
      let max = 24;
      if (this.systemUnit === "minutes") {
        max = 60;
      } else if (this.systemUnit === "day") {
        max = 365;
      }
      while (i < max) {
        i++;
        arr.push({ value: i });
      }
      return arr;
    },
    gapValArr() {
      const arr = [];
      let i = 0;
      while (i < 24) {
        i++;
        arr.push({
          value: i
        });
      }
      return arr;
    },
    $val() {
      if (this.controlType === "point") {
        return {
          pointTime: this.pointTime,
          pointTimeVal: this.pointTimeVal
        };
      } else if (this.controlType === "system") {
        return {
          systemScene: this.systemScene,
          systemTime: this.systemTime,
          systemMount: this.systemMount,
          systemUnit: this.systemUnit
        };
      } else if (this.controlType === "circle") {
        return {
          circleType: this.circleType,
          dayType: this.dayType,
          weekVal: this.weekVal,
          circleDate: this.circleDate
        };
      } else {
        return {};
      }
    }
  },
  data() {
    return {
      controlType: "point", // 控件类型
      systemScene: "", // 系统 - 时间节点 - 偏移类型
      systemTime: "", // 系统 - 时间节点
      systemMount: "", // 系统 - 时间节点 - 偏移值
      systemUnit: "hour", // 系统 - 时间节点 - 偏移单位 hour/day/minutes
      pointTime: "", // 时间节点
      pointTimeVal: "", // 时间节点 - 值
      circleType: "day", // 重复周期
      dayType: "", // 重复周期 - 每天 - 定时/间隔
      circleVal: "", // 重复周期 - 时间值
      weekVal: "", // 重复周期 - 每周 - (多选)周几
      weekArr: [
        {
          label: "周一",
          value: "monday"
        },
        {
          label: "周二",
          value: "tuesday"
        },
        {
          label: "周三",
          value: "Wednesday"
        },
        {
          label: "周四",
          value: "Thursday"
        },
        {
          label: "周五",
          value: "Friday"
        },
        {
          label: "周六",
          value: "Saturday"
        },
        {
          label: "周日",
          value: "Sunday"
        }
      ],
      gapVal: "", // 间隔值
      circleDate: "" // 重复周期 - 每月 - (多选)几号
    };
  },
  methods: {
    pointTimeChangeFn(val) {
      if (val === "system") {
        this.pointTimeVal = new Date().toString();
      } else {
        this.pointTimeVal = "";
      }
    },
    queryGapValArr(keyword, fn) {
      fn(this.gapValArr);
    },
    querySystemMount(k, fn) {
      fn(this.systemMountArr);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .effect {
  padding: 10px;
}
.el-form-item {
  padding: 20px 0;
}

/deep/ .el-form-item__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  > div {
    width: calc(50% - 4px);
    margin-bottom: 15px;
    margin-right: 4px;
  }
  .hour {
    height: 32px;
    line-height: 30px;
    background: #fbfbfb;
    cursor: not-allowed;
  }
}
</style>
