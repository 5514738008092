<template>
  <el-popover
    ref="popover"
    v-model="show"
    placement="bottom"
    trigger="click"
    width="300"
    popper-class="en-table-head-end-more-popper-class"
    @show="handleShow(true)"
    @hide="handleShow(false)"
  >
    <!--弹框区域-->
    <div v-if="show && columns" class="head-end-more-wrapper">
        <!-- 头部tab -->
      <div class="header-tab">
        <div v-for="tab in tabList" :key="tab.key" class="tab-item" :class="{'active-tab': tab.key === activeTab}" @click="activeTab = tab.key">{{tab.name}}</div>
      </div>
      <!--表头列表区域-->
      <div class="main-box">
        <div class="main-list" v-show="activeTab === 'columnSetting'">
          <div v-for="column in dataList" :key="column.id">
            <div :class="['item', {'hide':!column.isCustomColumn()}]" @click="handleClickItem(column)">
              <!--列头-->
              <span :class="{'checked':column.visible}">{{ column.title }}</span>
              <div>
                <!--锁n定-->
                <en-icon
                  v-show="column.fixed === 'left'"
                  name="suodinglie"
                  size="10"
                  class="locked"
                ></en-icon>
                <!--选中-->
                <en-icon
                  v-show="column.visible"
                  name="yixuan"
                  size="12"
                  class="checked"
                ></en-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="row-setting" v-show="activeTab === 'rowSetting'">
          <p>行高设置</p>
          <div class="setting-btns">
            <span v-for="btn in lineHeightSettingBtns" @click="changeLineHeight(btn)" :key="btn.key" class="setting-btn" :class="{'active-btn': btn.key === activeBtn}">{{btn.name}}</span>
          </div>
        </div>
      </div>
      <!--固定几列区域-->
      <div class="bottom-box" v-show="activeTab === 'columnSetting'">
        <div class="visible-count">已显示{{visibleCount}}列</div>
        <div>
          <en-icon name="suodinglie" size="10" class="icon"></en-icon>
          <span>固定前</span>
          <el-input
            ref="input"
            v-model="lockedNumber"
            class="input"
            @input="handleInputChanged"
            @focus="handleInputFocus"
          ></el-input>
          <span>列</span>
        </div>
      </div>
    </div>

    <!--更多按钮-->
    <en-button
      slot="reference"
      style="padding: 0 5px"
      icon="gengduo-liebiaoyouce"
      color="gray"
      type="custom"
      icon-size="15"
    ></en-button>
  </el-popover>
</template>

<script>
import XEUtils from "xe-utils/methods/xe-utils"
import Sortable from "sortablejs"

export default {
  name: "EnTableHeadEndMore",
  props: {
    columns: Array,
    maxLockedNumber: {
      type: [String, Number],
      default: 5
    },
    minVisibleColumn: {
      type: [String, Number],
      default: 1
    }
  },
  inject: ["$table"],
  data() {
    return {
      show: false,
      tabList: [
        {
          name: "列设置",
          key: "columnSetting"
        },
        {
          name: "行设置",
          key: "rowSetting"
        }
      ],
      activeTab: "columnSetting",
      lineHeightSettingBtns: [
        {
          name: "标准",
          key: "default"
        },
        {
          name: "紧凑",
          key: "medium"
        },
        {
          name: "宽松",
          key: "big"
        }
      ],
      activeBtn: this.$table.settingSize || "default"
    }
  },
  computed: {
    // czm add
    lockedNumber: {
      get() {
        return this.dataList.filter(item => item.fixed === "left" && !["index-selection", "index"].includes(item.type)).length
      },
      set(val) {
        this.dataList.filter(item => !["index-selection", "index"].includes(item.type)).forEach((item, index) => {
          if (index < val) {
            this.$set(item, "fixed", "left")
          } else {
            item.fixed = ""
          }
        })
      }
    },
    visibleCount() {
      return this.columns.filter(column => column.visible && !column.type).length;
    },
    dataList() {
      return [].concat(this.columns.filter(item => item.type !== "operation"))// 不能直接返回this.columns 会触发表格修改 过滤掉操作栏
      // return this.columns;
    }
  },
  methods: {
    changeLineHeight({ key }) {
      this.activeBtn = key;
      this.$table.settingSize = key;
      this.$emit("changeLineHeight", key);
      this.reloadData(true, true);
    },
    // 点击行
    handleClickItem(column) {
      if (this.dataList.filter(item => item.visible && item.type !== "index" && item.type !== "index-selection").length <= this.minVisibleColumn) {
        this.$emit("handleMinLimit");
        return;
      }
      if (column.visible) {
        this.$table.hideColumn(column)
      } else {
        this.$table.showColumn(column)
      }
      // column.visible = !column.visible;

      this.reloadData(true, true)

      // this.$set(column,"visible",!column.visible);
    },
    // /弹出框 隐藏\显示
    handleShow(isShow) {
      this.show = isShow

      if (isShow) {
        this.$nextTick(this.createdSortableRowIfNeeded)
      } else {
        this.destroySortableRowIfNeeded()
        // 消失的时候需要调用接口---后台需要的字段
        const fieldSort = []

        for (const column of this.columns) {
          const obj = Object.assign({}, column)
          obj.own = undefined
          fieldSort.push(obj)
        }
        this.$emit("change", {
          fixedColumnNum: this.lockedNumber,
          fieldSort: JSON.stringify(fieldSort)
        })
      }
    },
    // 输入框文本变化,处理锁定行
    handleInputChanged() {
      this.reloadData(true, true)
    },
    // 输入框聚焦,选中文本
    handleInputFocus() {
      this.$refs.input.select()
    },

    createdSortableRowIfNeeded() {
      if (this.sortableRow) {
        return
      }
      const _this = this
      const selector = this.$refs.popover.$refs.popper.querySelector("div.main-list")
      this.sortableRow = Sortable.create(selector, {
        // handle: ".drag-btn",
        animation: 150,
        ghostClass: "drag-background-class",
        onEnd: ({ newIndex, oldIndex }) => {
          const currRow = _this.columns.splice(oldIndex, 1)[0]
          _this.columns.splice(newIndex, 0, currRow)
          _this.reloadData(true, true)// 锁定可能需要修改
        }
      })
    },
    destroySortableRowIfNeeded() {
      if (this.sortableRow) {
        this.sortableRow.destroy()
        this.sortableRow = undefined
      }
    },
    reloadData(reloadList, reloadTable) {
      let lockedNumber = this.lockedNumber && this.lockedNumber.length > 1 ? this.lockedNumber.substr(this.lockedNumber.length - 1, 1) : this.lockedNumber
      lockedNumber = parseInt(lockedNumber, 10)
      if (XEUtils.isNaN(lockedNumber)) {
        lockedNumber = 0
      } else {
        lockedNumber = Math.min(lockedNumber, this.maxLockedNumber)// 最多5个
      }

      if (lockedNumber !== this.lockedNumber) {
        this.lockedNumber = lockedNumber
      }

      for (const item of this.columns) {
        if (!item.isCustomColumn() || item.fixed === "right") {
          continue
        }
        const locked = item.visible && lockedNumber > 0
        if (locked) {
          --lockedNumber
        }
        item.fixed = locked ? "left" : undefined
      }

      if (reloadList) {
        // 强刷一次, 数组对象内部变化 不监听变化, 不用splice了
        this.$forceUpdate()
      }

      if (reloadTable) {
        this.$table.refreshColumn()
      }
    }

  }
}
</script>

<style>
  .en-table-head-end-more-popper-class {
    padding: 0 !important;
  }
</style>

<style scoped lang="scss">
  @import '../../../styles/variable';

  .head-end-more-wrapper {
    font-size: 12px;
    $locked-color: #A9B5C6;
    .header-tab {
      display: flex;
      text-align: center;
      height: 46px;
      line-height: 46px;
      cursor: pointer;
      border-bottom: 1px solid $vxe-table-border-color;
      .tab-item {
        width: 50%;
        &.active-tab {
          position: relative;
          color: $vxe-input-selected-color;
          &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 80%;
            background: $vxe-input-selected-color;
          }
        }
      }
    }

    .main-box {
      padding: 10px 0;
      max-height: 600px;
      overflow: scroll;
      .hide {
        display: none !important;
      }
      .row-setting {
        padding-left: 20px;
        p {
          margin: 6px 0 16px 0;
          color: #636c78;
        }
        .setting-btns {
          .setting-btn {
            display: inline-block;
            cursor: pointer;
            width: 62px;
            height: 26px;
            text-align: center;
            line-height: 26px;
            border-radius: 4px;
            margin-right: 10px;
            color: #26c393;
            background: #eefaf7;
            &.active-btn {
              color: #fff;
              background: #6ba9e5;
            }
          }
        }
      }
      .item {
        padding: 0 18px;
        cursor: pointer;
        line-height: 36px;
        height: 36px;
        display: flex;
        background-color: white;
        justify-content: space-between;

        .hidden {
          display: none;
        }

        .checked {
          color: $vxe-input-selected-color;
          display: inline-block;
        }

        .locked {
          color: $locked-color;
          margin-right: 5px;
          display: inline-block;
        }
      }

      .drag-background-class {
        .item {
          background-color: $vxe-table-row-hover-background-color !important;
        }

        border: dashed $vxe-input-selected-color 1px;
        border-radius: 3px;
      }

    }

    .bottom-box {
      line-height: 40px;
      border-top: 1px solid $vxe-table-border-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      .visible-count {
        color: #91a1b7;
      }

      .icon {
        color: $locked-color;
        margin-right: 5px;
      }

      .input {
        width: 81px;
        margin: 0 5px;
      }
    }
  }

</style>
