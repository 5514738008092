
/**
 * 三阶贝塞尔曲线ease-in-out
 * @param {number} k
 */
function easeInOutCubic(k) {
  return (k *= 2) < 1 ? 0.5 * k * k * k : 0.5 * ((k -= 2) * k * k + 2);
}

/**
 * @param {HTMLElement} ele 元素节点
 * @param {number} change 改变量
 * @param {number} duration 动画持续时长
 */
function moveScrollBar(ele, change, duration, type) {
  // 使用闭包保存定时器标识
  let handle;
  // 返回动画函数
  return () => {
    // 开始时间
    const startTime = performance.now();
    // 防止启动多个定时器
    cancelAnimationFrame(handle);
    /**
     * 动画函数
     *
     */
    function _animation() {
      // 这一帧开始的时间
      const current = performance.now();
      // 这一帧内元素移动的距离
      const timeRatio = (current - startTime) / duration;
      const ratio = easeInOutCubic(timeRatio);
      const dimensions = change * ratio;
      ele[type] = dimensions;
      // 判断动画是否执行完
      if ((current - startTime) / duration < 1) {
        handle = requestAnimationFrame(_animation);
      } else {
        cancelAnimationFrame(handle);
      }
    }
    // 第一帧开始
    if (change > 0) {
      handle = requestAnimationFrame(_animation);
    }
  };
}

export default {
  data() {
    return {
      _scrollSavedTarget: null,
      _isBindScrollRecoveryEvent: false,
      _scrollType: false
    };
  },
  directives: {
    recordScroll: {
    // 指令的定义
      inserted: function(el, binding, vnode) {
        const _this = vnode.context;
        if (_this.$route.meta.keepAlive) {
          _this._isBindScrollRecoveryEvent = true;
          _this._scrollType = binding.modifiers.smooth;
          if (binding.value) {
            _this._scrollSavedTarget = el.querySelector(binding.value);
          } else {
            _this._scrollSavedTarget = el;
          }
        }
      }
    }
  },
  activated() {
    if (this._isBindScrollRecoveryEvent && this.$route.meta.savedPosition) {
      this.$nextTick(() => {
        const { x, y } = this.$route.meta.savedPosition;
        if (this._scrollType) {
          moveScrollBar(this._scrollSavedTarget, y, 1000, "scrollTop")();
          moveScrollBar(this._scrollSavedTarget, x, 1000, "scrollLeft")();
        } else {
          setTimeout(() => {
            this._scrollSavedTarget.scrollTop = y;
            this._scrollSavedTarget.scrollLeft = x;
          }, 0);
        }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this._isBindScrollRecoveryEvent) {
      const x = this._scrollSavedTarget.scrollLeft;
      const y = this._scrollSavedTarget.scrollTop;
      this.$route.meta.savedPosition = { x, y };
    }
    next();
  }
};
