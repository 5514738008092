import XEUtils from "xe-utils/methods/xe-utils";
import VXETable from "./v-x-e-table";
import Table from "./table";
import Column from "./column";
import Header from "./header";
import Body from "./body";
import Footer from "./footer";
import Filter from "./filter";
import FilterToolbar from "./filterToolbar";
import HeadEndMore from "./headEndMore";
import ColumnSetting from "./columnSetting";
import Loading from "./loading";
import Menu from "./menu";
import Radio from "./radio";
import Input from "./input";
import Button from "./button";
import Tooltip from "./tooltip";

import Export from "./export";
import Keyboard from "./keyboard";
import Resize from "./resize";
import zhCNLocat from "./locale/lang/zh-CN";

import VXETablePluginElement from "../plugins/element/index";
import "../plugins/element/style.scss";

// 按需加载的组件
export const components = [
  Table,
  Column,
  Header,
  Body,
  Footer,
  Filter,
  FilterToolbar,
  HeadEndMore,
  ColumnSetting,
  Loading,
  Menu,
  Radio,
  Input,
  Button,
  Tooltip,

  Export,
  Keyboard,
  Resize
];

/**
 * 默认安装
 * @param Vue
 * @param options
 */
function install(Vue, options) {
  if (XEUtils.isPlainObject(options)) {
    VXETable.setup(options);
  }
  components.map(component => Vue.use(component));
  VXETable.use(VXETablePluginElement);// 组件 add by xu
}
// 默认中文
VXETable.setup({ i18n: (key, value) => XEUtils.get(zhCNLocat, key) });

// 插件
// VXETable.use(VXETablePluginElement);//
// if (typeof window !== "undefined" && window.Vue) {
//   install(window.Vue);
// }

VXETable.install = install;

export * from "./table";
export * from "./column";
export * from "./header";
export * from "./body";
export * from "./footer";
export * from "./filter";
export * from "./filterToolbar";
export * from "./headEndMore";
export * from "./loading";
export * from "./menu";
export * from "./radio";
export * from "./input";
export * from "./button";
export * from "./tooltip";
export * from "./export";
export * from "./keyboard";
export * from "./resize";
export * from "./v-x-e-table";

export default VXETable;

