/**
 * project: enfry-components
 * fileName: scroll
 * Created by 徐俊 on 2020/07/29
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import XEUtils from "xe-utils/methods/xe-utils";
import { DomTools } from "../../tools";

const browse = DomTools.browse;
const isWebkit = browse["-webkit"] && !browse.edge;
const debounceScrollYDuration = browse.msie ? 40 : 20;

const Scroll = {
  methods: {
    /**
     * 处理固定列的显示状态
     */
    checkScrolling() {
      const { tableBody, leftContainer, rightContainer } = this.$refs;
      const bodyElem = tableBody ? tableBody.$el : null;
      if (bodyElem) {
        if (leftContainer) {
          DomTools[bodyElem.scrollLeft > 0 ? "addClass" : "removeClass"](leftContainer, "scrolling--middle");
        }
        if (rightContainer) {
          DomTools[bodyElem.clientWidth < bodyElem.scrollWidth - bodyElem.scrollLeft ? "addClass" : "removeClass"](rightContainer, "scrolling--middle");
        }
      }
    },
    /**
     * 获取虚拟滚动状态
     */
    getVirtualScroller() {
      const { $refs, scrollXLoad, scrollYLoad } = this;
      const bodyElem = $refs.tableBody.$el;
      return {
        scrollX: scrollXLoad,
        scrollY: scrollYLoad,
        scrollTop: bodyElem.scrollTop,
        scrollLeft: bodyElem.scrollLeft
      };
    },
    /**
     * 横向 X 可视渲染事件处理
     */
    triggerScrollXEvent(evnt) {
      const { $refs, visibleColumn, scrollXStore } = this;
      const { startIndex, renderSize, offsetSize, visibleSize } = scrollXStore;
      const scrollBodyElem = $refs.tableBody.$el;
      const scrollLeft = scrollBodyElem.scrollLeft;
      let toVisibleIndex = 0;
      let width = 0;
      let preload = false;
      for (let index = 0; index < visibleColumn.length; index++) {
        width += visibleColumn[index].renderWidth;
        if (scrollLeft < width) {
          toVisibleIndex = index;
          break;
        }
      }
      if (scrollXStore.visibleIndex !== toVisibleIndex) {
        const marginSize = Math.min(Math.floor((renderSize - visibleSize) / 2), visibleSize);
        if (scrollXStore.visibleIndex > toVisibleIndex) {
          // 向左
          preload = toVisibleIndex - offsetSize <= startIndex;
          if (preload) {
            scrollXStore.startIndex = Math.max(0, toVisibleIndex - Math.max(marginSize, renderSize - visibleSize));
          }
        } else {
          // 向右
          preload = toVisibleIndex + visibleSize + offsetSize >= startIndex + renderSize;
          if (preload) {
            scrollXStore.startIndex = Math.max(0, Math.min(visibleColumn.length - renderSize, toVisibleIndex - marginSize));
          }
        }
        if (preload) {
          this.updateScrollXData();
        }
        scrollXStore.visibleIndex = toVisibleIndex;
      }
      this.clostTooltip();
    },
    /**
     * 纵向 Y 可视渲染事件处理
     */
    triggerScrollYEvent(evnt) {
      // webkit 浏览器使用最佳的渲染方式
      if (isWebkit && this.scrollYStore.adaptive) {
        this.loadScrollYData(evnt);
      } else {
        this.debounceScrollY(evnt);
      }
    },
    debounceScrollY: XEUtils.debounce(
      function(evnt) {
        this.loadScrollYData(evnt);
      },
      debounceScrollYDuration,
      { leading: false, trailing: true }
    ),
    /**
     * 纵向 Y 可视渲染处理
     */
    loadScrollYData(evnt) {
      const { afterFullData, scrollYStore } = this;
      const { startIndex, renderSize, offsetSize, visibleSize, rowHeight } = scrollYStore;
      const scrollBodyElem = evnt.target;
      const scrollTop = scrollBodyElem.scrollTop;
      const toVisibleIndex = Math.ceil(scrollTop / rowHeight);
      let preload = false;
      if (scrollYStore.visibleIndex !== toVisibleIndex) {
        const marginSize = Math.min(Math.floor((renderSize - visibleSize) / 2), visibleSize);
        if (scrollYStore.visibleIndex > toVisibleIndex) {
          // 向上
          preload = toVisibleIndex - offsetSize <= startIndex;
          if (preload) {
            scrollYStore.startIndex = Math.max(0, toVisibleIndex - Math.max(marginSize, renderSize - visibleSize));
          }
        } else {
          // 向下
          preload = toVisibleIndex + visibleSize + offsetSize >= startIndex + renderSize;
          if (preload) {
            scrollYStore.startIndex = Math.max(0, Math.min(afterFullData.length - renderSize, toVisibleIndex - marginSize));
          }
        }
        if (preload) {
          this.updateScrollYData();
        }
        scrollYStore.visibleIndex = toVisibleIndex;
      }
    },
    // 计算可视渲染相关数据
    computeScrollLoad() {
      return this.$nextTick().then(() => {
        const { vSize, scrollXLoad, scrollYLoad, scrollYStore, scrollXStore, visibleColumn, optimizeOpts } = this;
        const { scrollX, scrollY } = optimizeOpts;
        const tableBody = this.$refs.tableBody;
        const tableBodyElem = tableBody ? tableBody.$el : null;
        const tableHeader = this.$refs.tableHeader;
        if (tableBodyElem) {
          // 计算 X 逻辑
          if (scrollXLoad) {
            const firstColumn = visibleColumn[0];
            const cWidth = firstColumn ? firstColumn.renderWidth : 40;
            const visibleXSize = XEUtils.toNumber(scrollX.vSize || Math.ceil(tableBodyElem.clientWidth / cWidth));
            scrollXStore.visibleSize = visibleXSize;
            // 自动优化
            if (!scrollX.oSize) {
              scrollXStore.offsetSize = visibleXSize;
            }
            if (!scrollX.rSize) {
              scrollXStore.renderSize = visibleXSize + 2;
            }
            this.updateScrollXData();
          } else {
            this.updateScrollXSpace();
          }
          // 计算 Y 逻辑
          if (scrollYLoad) {
            let rHeight;
            if (scrollY.rHeight) {
              rHeight = scrollY.rHeight;
            } else {
              let firstTrElem = tableBodyElem.querySelector("tbody>tr");
              if (!firstTrElem && tableHeader) {
                firstTrElem = tableHeader.$el.querySelector("thead>tr");
              }
              if (firstTrElem) {
                rHeight = firstTrElem.clientHeight;
              }
            }
            // 默认的行高
            if (!rHeight) {
              switch (vSize) {
                case "medium":
                  rHeight = 44;
                  break;
                case "small":
                  rHeight = 40;
                  break;
                case "mini":
                  rHeight = 36;
                  break;
                default:
                  rHeight = 48;
                  break;
              }
            }
            const visibleYSize = XEUtils.toNumber(scrollY.vSize || Math.ceil(tableBodyElem.clientHeight / rHeight));
            scrollYStore.visibleSize = visibleYSize;
            scrollYStore.rowHeight = rHeight;
            // 自动优化
            if (!scrollY.oSize) {
              scrollYStore.offsetSize = visibleYSize;
            }
            if (!scrollY.rSize) {
              scrollYStore.renderSize = browse.firefox ? visibleYSize * 6 : browse.edge ? visibleYSize * 10 : isWebkit ? visibleYSize + 2 : visibleYSize * 6;
            }
            this.updateScrollYData();
          } else {
            this.updateScrollYSpace();
          }
        }
        this.$nextTick(this.updateStyle);
      });
    },
    updateScrollXData() {
      const { visibleColumn, scrollXStore } = this;
      this.tableColumn = visibleColumn.slice(scrollXStore.startIndex, scrollXStore.startIndex + scrollXStore.renderSize);
      this.updateScrollXSpace();
    },
    // 更新横向 X 可视渲染上下剩余空间大小
    updateScrollXSpace() {
      const { $refs, elemStore, visibleColumn, scrollXStore, scrollXLoad, tableWidth, scrollbarWidth } = this;
      const { tableHeader, tableBody, tableFooter } = $refs;
      const headerElem = tableHeader ? tableHeader.$el.querySelector(".vxe-table--header") : null;
      const bodyElem = tableBody.$el.querySelector(".vxe-table--body");
      const footerElem = tableFooter ? tableFooter.$el.querySelector(".vxe-table--footer") : null;
      const leftSpaceWidth = visibleColumn.slice(0, scrollXStore.startIndex).reduce((previous, column) => previous + column.renderWidth, 0);
      let marginLeft = "";
      if (scrollXLoad) {
        marginLeft = `${leftSpaceWidth}px`;
      }
      if (headerElem) {
        headerElem.style.marginLeft = marginLeft;
      }
      bodyElem.style.marginLeft = marginLeft;
      if (footerElem) {
        footerElem.style.marginLeft = marginLeft;
      }
      const containerList = ["main"];
      containerList.forEach(name => {
        const layoutList = ["header", "body", "footer"];
        layoutList.forEach(layout => {
          const xSpaceElem = elemStore[`${name}-${layout}-xSpace`];
          if (xSpaceElem) {
            xSpaceElem.style.width = scrollXLoad ? `${tableWidth + (layout === "header" ? scrollbarWidth : 0)}px` : "";
          }
        });
      });
      this.$nextTick(this.updateStyle);
    },
    updateScrollYData() {
      this.handleTableData();
      this.updateScrollYSpace();
    },
    // 更新纵向 Y 可视渲染上下剩余空间大小
    updateScrollYSpace() {
      const { elemStore, scrollYStore, scrollYLoad, afterFullData } = this;
      const bodyHeight = afterFullData.length * scrollYStore.rowHeight;
      const topSpaceHeight = Math.max(scrollYStore.startIndex * scrollYStore.rowHeight, 0);
      const containerList = ["main", "left", "right"];
      let marginTop = "";
      let ySpaceHeight = "";
      if (scrollYLoad) {
        marginTop = `${topSpaceHeight}px`;
        ySpaceHeight = `${bodyHeight}px`;
      }
      containerList.forEach(name => {
        const layoutList = ["header", "body", "footer"];
        const tableElem = elemStore[`${name}-body-table`];
        if (tableElem) {
          tableElem.style.marginTop = marginTop;
        }
        layoutList.forEach(layout => {
          const ySpaceElem = elemStore[`${name}-${layout}-ySpace`];
          if (ySpaceElem) {
            ySpaceElem.style.height = ySpaceHeight;
          }
        });
      });
      this.$nextTick(this.updateStyle);
    },
    scrollTo(scrollLeft, scrollTop) {
      const bodyElem = this.$refs.tableBody.$el;
      if (XEUtils.isNumber(scrollLeft)) {
        const tableFooter = this.$refs.tableFooter;
        if (tableFooter) {
          tableFooter.$el.scrollLeft = scrollLeft;
        } else {
          bodyElem.scrollLeft = scrollLeft;
        }
      }
      if (XEUtils.isNumber(scrollTop)) {
        const rightBody = this.$refs.rightBody;
        if (rightBody) {
          rightBody.$el.scrollTop = scrollTop;
        }
        bodyElem.scrollTop = scrollTop;
      }
      return this.$nextTick();
    },
    scrollToRow(row, column, isDelay) {
      if (row && this.fullAllDataRowMap.has(row)) {
        DomTools.rowToVisible(this, row);
      }
      return this.scrollToColumn(column, isDelay || XEUtils.isBoolean(column));
    },
    scrollToColumn(column, isDelay) {
      if (column && this.fullColumnMap.has(column)) {
        DomTools.colToVisible(this, column);
      }
      if (isDelay && this.scrollYLoad) {
        return new Promise(resolve => setTimeout(() => resolve(this.$nextTick()), 50));
      }
      return this.$nextTick();
    },
    clearScroll() {
      this.lastScrollLeft = 0;
      this.lastScrollTop = 0;
      Object.assign(this.scrollXStore, {
        startIndex: 0,
        visibleIndex: 0
      });
      Object.assign(this.scrollYStore, {
        startIndex: 0,
        visibleIndex: 0
      });
      this.$nextTick(() => {
        const tableBody = this.$refs.tableBody;
        const tableBodyElem = tableBody ? tableBody.$el : null;
        const tableFooter = this.$refs.tableFooter;
        const tableFooterElem = tableFooter ? tableFooter.$el : null;
        if (tableBodyElem) {
          tableBodyElem.scrollTop = 0;
          tableBodyElem.scrollLeft = 0;
        }
        if (tableFooterElem) {
          tableFooterElem.scrollLeft = 0;
        }
      });
      return this.$nextTick();
    }
  }

};

export default Scroll;
