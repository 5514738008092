<!--
 * @Author: zql
 * @Date: 2020-03-13 15:29:14
 * @LastEditTime: 2020-06-02 14:34:05
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \enfry-components\packages\components\en-result\src\nomsg\no-msg.vue
-->
<template>
  <div ref="NoMsg" class="nomsg">
    <img
      slot="icon"
      class="icon-img"
      src="http://cdn.enfry.com/no-msg11317.png"
      :alt="this.title"
    />
    <div class="title" v-html="title"></div>
    <div class="subtitle" v-html="subTitle"></div>
  </div>
</template>

<script>
export default {
  name: "Nomsg",
  props: {
    type: { type: String, default: null },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: [String, Number],
      default: "normal"
    },
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: "暂无消息"
    }
  }
};
</script>
<style lang="scss" scoped>
.nomsg {
  text-align: center;
  .icon-img {
    height: 84px;
    width: 120px;
  }
}
</style>
