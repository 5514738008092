var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.node.hide)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.node.visible),expression:"node.visible"}],ref:"node",staticClass:"el-tree-node en-tree-node",class:_vm.setLevelClass,attrs:{"role":"treeitem","tabindex":"-1","aria-expanded":_vm.expanded,"aria-disabled":_vm.node.disabled,"aria-checked":_vm.node.checked,"draggable":_vm.tree.draggable},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick($event)},"contextmenu":function ($event) { return this$1.handleContextMenu($event); },"dragstart":function($event){$event.stopPropagation();return _vm.handleDragStart($event)},"dragover":function($event){$event.stopPropagation();return _vm.handleDragOver($event)},"dragend":function($event){$event.stopPropagation();return _vm.handleDragEnd($event)},"drop":function($event){$event.stopPropagation();return _vm.handleDrop($event)}}},[_c('div',{staticClass:"el-tree-node__content",class:{
      hover: _vm.hover
    },style:({ 'padding-left': (_vm.node.level - 1) * _vm.tree.indent + 'px' })},[_c('span',{class:[
        { 'is-leaf': _vm.node.isLeaf, expanded: !_vm.node.isLeaf && _vm.expanded,'is-head-leaf':_vm.node.isLeaf&&!_vm.expanded&&_vm.node.level===1 },
        'el-tree-node__expand-icon'
      ],on:{"click":function($event){$event.stopPropagation();return _vm.handleExpandIconClick($event)}}},[(_vm.treeIcon)?_c('en-icon',{style:({
          color: _vm.node.data.iconColor ? _vm.node.data.iconColor : '#A9B5C6'
        }),attrs:{"name":_vm.treeIcon,"size":"10"}}):_c('div',{staticStyle:{"width":"10px"}})],1),(_vm.node.loading && !_vm.node.loaded)?_c('span',{staticClass:"el-tree-node__loading-icon el-icon-loading"}):_vm._e(),(_vm.showCheckbox && _vm.disabledShowCheck)?_c('el-checkbox',{attrs:{"indeterminate":_vm.node.indeterminate,"disabled":!!_vm.node.disabled},on:{"change":_vm.handleCheckChange},nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.node.checked),callback:function ($$v) {_vm.$set(_vm.node, "checked", $$v)},expression:"node.checked"}}):_vm._e(),_c('div',{staticClass:"en-tree-node__label-wrapper"},[_c('node-content',{attrs:{"node":_vm.node},nativeOn:{"mouseenter":function($event){return _vm.onNodeEnter($event)},"mouseleave":function($event){return _vm.onNodeLeave($event)}}}),(_vm.showAssist || _vm.node.assist)?_c('div',{staticClass:"en-tree-node__assist-wrapper"},[_vm._v("\n         \n        "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.node.showAssist),expression:"node.showAssist"}],staticClass:"en-tree-node__assist-wrapper-fixd",style:({
            left: _vm.assistConfig.left + 'px',
            top: _vm.assistConfig.top + 'px'
          })},[_c('node-assist',{attrs:{"node":_vm.node}})],1)]):_vm._e()],1)],1),_c('el-collapse-transition',[(!_vm.renderAfterExpand || _vm.childNodeRendered)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],staticClass:"el-tree-node__children",attrs:{"role":"group","aria-expanded":_vm.expanded}},[_vm._l((_vm.node.childNodes),function(child){return _c('en-tree-node',{key:_vm.getNodeKey(child),attrs:{"render-content":_vm.renderContent,"render-assist":_vm.renderAssist,"content-format":_vm.contentFormat,"assist-format":_vm.assistFormat,"render-after-expand":_vm.renderAfterExpand,"show-checkbox":_vm.showCheckbox,"node":child,"expand-icon":_vm.expandIcon,"collpase-icon":_vm.collpaseIcon,"show-assist":_vm.showAssist},on:{"node-expand":_vm.handleChildNodeExpand}})}),(!_vm.node.pageLoaded && _vm.node.pageSize && _vm.node.loaded)?_c('div',{staticClass:"loading-area",style:({ 'padding-left': _vm.node.level * _vm.tree.indent + 8 + 'px' })},[(!_vm.node.loading)?_c('div',{staticClass:"loading-more",on:{"click":function($event){$event.stopPropagation();return _vm.lazyLoadMore($event)}}},[_vm._v("\n          加载更多 "+_vm._s(_vm.node.remaining > 0 ? '(' + _vm.node.remaining + ')' : '')+"\n        ")]):_c('span',{staticClass:"el-tree-node__loading-icon el-icon-loading"})]):_vm._e()],2):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }