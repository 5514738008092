/**
 * project: enfry-components
 * fileName: utils
 * Created by 徐俊 on 2020/08/05
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import GlobalConfig from "../../conf";

function outLog(type) {
  return function(message) {
    const msg = `[vxe-table] ${GlobalConfig.i18n(message)}`
    console[type](msg)
    return msg
  }
}

export const Utils = {
  warn: outLog("warn"),
  error: outLog("error"),
  // 触发事件
  emitEvent(_vm, type, args) {
    if (_vm.$listeners[type]) {
      _vm.$emit.apply(_vm, [type].concat(args))
    }
  },

  getSize({ size, $parent }) {
    return size || ($parent && [
      "medium",
      "small",
      "mini"
    ].indexOf($parent.size) > -1 ? $parent.size : null)
  },
  hasChildrenList(item) {
    return item && item.children && item.children.length > 0
  }
}

export default Utils;
