<template>
  <div class="en-setp-list">
    <div
      v-for="item in myOptions"
      :key="item.id"
    >
      <div class="one-step-contain">
        <div
          v-show="item.stepNum > 1"
          class="step-line"
          :style="item.currentStep ? lineSelectStyle:''"
        />

        <div class="step-contain">
          <span
            class="step-num"
            :style="item.currentStep ? stepNumSeleltStyle:''"
          >{{ item.stepNum }}</span>
          <span
            class="step-text"
            :style="item.currentStep ? stepTextSeleltStyle:''"
          >{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnStepList",
  props: {
    stepOptions: {
      type: Array,
      default: function() {
        return []
      }
    },
    currentStep: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      myOptions: [],
      // 0%,80%,50%
      lineSelectStyle:
        "background-image: linear-gradient( to right, #4694df 0%, #4694df 100%, transparent 50% );",
      stepNumSeleltStyle: "background-color: #4694DF;",
      stepTextSeleltStyle: "color: #4694DF;"
    }
  },
  watch: {
    currentStep() {
      this.updataOptions()
    },
    stepOptions() {
      this.updataOptions()
    }
  },
  mounted() {
    this.updataOptions()
  },
  methods: {
    updataOptions() {
      const list = []
      for (let index = 0; index < this.stepOptions.length; index++) {
        const item = {}
        item.name = this.stepOptions[index]
        item.stepNum = index + 1
        if (this.currentStep === index + 1) {
          item.currentStep = true
        } else {
          item.currentStep = false
        }
        if (this.currentStep > this.stepOptions.length && index === this.stepOptions.length - 1) {
          item.currentStep = true
        }
        list.push(item)
      }
      this.myOptions = list
    }
  }
}
</script>

<style lang="scss" scoped>
  .en-setp-list {
    position: relative;
    top: 0px;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .one-step-contain {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .step-contain {
      .step-num {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: #4694df80;
        display: inline-block;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
      }

      .step-text {
        margin: 0 5px;
        height: 14px;
        line-height: 14px;
        font-size: 13px;
        text-align: center;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: #4694df80;
      }
    }

    .step-line {
      margin-top: 1px;
      margin-right: 5px;
      width: 64px;
      height: 2px;
      background-image: linear-gradient(
          to right,
          #4694df80 0%,
          #4694df80 100%,
          transparent 50%
      );
      background-size: 16px 2px;
      background-repeat: repeat-x;
    }
  }
</style>
