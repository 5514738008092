<template>
  <div class="en-button-list">
    <div
      v-for="item in list"
      :key="item[idKey]"
      :class="{ active: value === item[idKey] }"
      class="en-button-item"
      @click="changeItem(item)"
    >
      <en-icon v-if="showIcon" :name="item.iconName" size="small"> </en-icon>
      <div class="label">
        {{ item[labelKey] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnButtonList",
  props: {
    // 选中的按钮ID
    value: {
      type: String,
      default: ""
    },
    // 按钮组列表承载字段
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 显示按钮文本的字段
    labelKey: {
      type: String,
      default: "name"
    },
    // 按钮的ID字段
    idKey: {
      type: String,
      default: "id"
    },
    // 图标名称的承载字段
    iconKey: {
      type: String,
      default: "iconName"
    },
    // 是否需要显示图标
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    changeItem(itemData) {
      this.$emit("input", itemData[this.idKey]);
      this.$emit("change", itemData);
    }
  }
};
</script>

<style lang="scss" scoped>
.en-button-list {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;

  .en-button-item {
    display: flex;
    justify-content: flex-start;
    margin-right: 12px;
    padding: 6px 8px;
    border: 1px solid #e8ecf2;
    -webkit-border-radius: 4px 4px 4px;
    -moz-border-radius: 4px 4px 4px;
    border-radius: 4px 4px 4px;
    cursor: pointer;
    min-width: 72px;
    transition: 0.3s ease-in-out all;
    outline: none;

    .en-icon {
      color: #a9b5c6;
      margin-right: 10px;
    }

    .label {
      color: #636c78;
      font-size: 12px;
      line-height: 18px;
    }

    &.active {
      background-color: #4694df;
      border-color: none;
      border: none;
      .en-icon {
        color: #ffffff;
      }

      .label {
        color: #ffffff;
      }
    }

    &:hover {
      border-color: #4694df;
    }
    &:focus {
      border: none;
      outline: none;
    }
    &::-moz-focus-inner {
      border-color: transparent;
    }
  }
}
</style>
