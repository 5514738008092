<!--
 * @Author: Louis
 * @Date: 2019-08-23 10:16:29
 * @LastEditors: pengyu
 * @LastEditTime: 2020-12-23 15:07:33
 * @Description: 增加页签API
 -->
<template>
  <div class="en-tabs" :class="type">
    <div class="en-tabs-list" ref="reference">
      <!-- 滑块 -->
      <div
        class="active_bar"
        :style="{
          transform: 'translateX(' + ofLeft + 'px)',
          width: barWidth + 'px'
        }"
        :class="{
        'is-overflow':activeIsOverFlow
        }"
      ></div>
      <div
        v-for="item in showTabList"
        :key="item.id"
        :ref="value === item.id ? 'activeTab' : ''"
        class="en-tabs-item"
        :class="{
        'tabs-active': value === item.id,
        disabled: item.disabled,
        'tabs-fixed-width': isFixedWidth
        }"
        :style="widthStyle"
        :title="item.name"
        @click="changeTabs(item)"
      >
        <span class="name">{{ item.name }}</span>
        <slot name="search"></slot>
      </div>
      <!--隐藏起来的页签-->
      <div class="en-tabs-item"
           :class="{
              'tabs-fixed-width': isFixedWidth
                 }"
           :style="widthStyle"
           v-if="additionalTabList.length > 0">
        <el-dropdown @command="changeTabs">
          <span class="el-dropdown-link">
             <en-icon name="iconfujian-gengduo" size="18" class="more-button"></en-icon>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in additionalTabList"
                              :key="item.id"
                              :disabled="item.disabled"
                              :command="item">
              {{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
    </div>
    <div class="en-tabs-right-container">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import {
  addResizeListener,
  removeResizeListener
} from "element-ui/src/utils/resize-event";

export default {
  name: "EnTabs",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    withRouter: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "dark",
      validator: function(value) {
        return ["white", "dark"].indexOf(value) > -1;
      }
    },
    // 固定宽度,当 数量*固定宽度 > 本身宽度时,展示...
    fixedWidth: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      ofLeft: null,
      barWidth: 0,
      tabWidth: 0
    };
  },

  watch: {
    value() {
      this.resetBarStyle();
    }
  },
  mounted() {
    // 增加监听
    addResizeListener(this.$el, this.handleResize);
    this.$nextTick(() => {
      this.resetBarStyle();
    });
  },
  beforeDestroy() {
    removeResizeListener(this.$el, this.handleResize);
  },
  computed: {
    // active是否超过范围了
    activeIsOverFlow() {
      return this.isFixedWidth && this.ofLeft >= parseInt(this.fixedWidth, 10) * this.mostShowTabCount;
    },
    // tab数据
    showTabList() {
      if (this.isFixedWidth && this.list.length > this.mostShowTabCount) {
        return this.list.slice(0, this.mostShowTabCount);
      }
      return this.list;
    },
    // 收起来的tab
    additionalTabList() {
      if (this.isFixedWidth && this.list.length > this.mostShowTabCount) {
        return this.list.slice(this.mostShowTabCount, this.list.length);
      }
      return [];
    },
    // 计算最多展示多少个tab addBy: lpx
    mostShowTabCount() {
      const itemWidth = parseInt(this.fixedWidth, 10);
      let count = (this.tabWidth - itemWidth / 2) / itemWidth;
      count = Math.floor(count);
      return count;
    },
    // 是否为固定宽度
    isFixedWidth() {
      return parseInt(this.fixedWidth, 10) > 0;
    },
    // 绑定宽度
    widthStyle() {
      if (this.isFixedWidth) {
        return { "flex-basis": `${parseInt(this.fixedWidth, 10)}px` };
      }
      return {};
    }
  },
  methods: {
    // 宽度变化监听 addBy: lpx
    handleResize() {
      if (this.isFixedWidth) {
        this.tabWidth = this.$refs.reference.getBoundingClientRect().width;
      }
    },
    /**
     * @description 更改页签
     * @param tabData 页签数据
     */
    changeTabs(tabData) {
      if (tabData.disabled) {
        return;
      }
      if (this.withRouter) {
        this.$router.push({ path: tabData.router });
      } else {
        this.$emit("input", tabData.id);
        this.$emit("change", tabData);
      }
    },
    resetBarStyle() {
      setTimeout(() => {
        const activeDom = this.$refs.activeTab;
        if (!activeDom) return;
        const domItem = activeDom[0];
        if (domItem) {
          this.barWidth = domItem.offsetWidth;
          this.ofLeft = domItem.offsetLeft;
        } else {
          // 找不到.给一个很大的值,用于隐藏
          this.ofLeft = 1000000;
        }
      }, 5);
    }
  }
};
</script>

<style lang="scss" scoped>
  .en-tabs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 46px;
    font-size: 13px;
    padding: 0 20px;
    background-color: #ffffff;
    border-bottom: 1px solid #e8ecf2;
    color: #333333;
    .en-tabs-right-container {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .en-tabs-list {
      display: flex;
      justify-content: flex-start;
      position: relative;
      flex: 1;
      .active_bar {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        background-color: #409eff;
        z-index: 1;
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        list-style: none;
        &.is-overflow {
          display: none;
        }
      }

      .en-tabs-item {
        cursor: pointer;
        padding: 0 12px 0 12px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        display: flex;
        justify-content: center;
        width: 120px;
        .name {
          display: inline-block;
          max-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .more-button {
          color: rgb(169, 181, 198);
          &:hover {
            color: #4694df;
          }
        }
        &.tabs-fixed-width {
          flex-grow: 0;
          flex-shrink: 0;
        }
        &:hover {
          color: #409eff;
        }
        &.tabs-active {
          cursor: default;
          color: #409eff;
        }
        &.disabled {
          color: #d7dce6;
          cursor: not-allowed;
        }
      }
    }

    &.dark {
      background-color: rgb(45, 54, 69);
      color: #ffffff;
      border-bottom-color: rgb(45, 54, 69);
    }
  }
</style>
