/*
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-09-24 15:05:05
 * @LastEditTime: 2019-09-24 15:05:05
 */
import component from "./src";
component.install = function(Vue) {
  Vue.component(component.name, component);
};
export default component;
