<!--
 * @Author: zql
 * @Date: 2020-03-13 15:29:14
 * @LastEditTime: 2020-06-02 14:32:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \enfry-components\packages\components\en-result\src\nodata\no-data.vue
-->
<template>
  <div ref="NoData" class="nodata" :class="iconSize + '-size'">
    <img
      class="icon-img"
      src="http://cdn.enfry.com/nodata11310.png"
      :alt="this.title"
    />
    <div class="title" v-html="title"></div>
    <div class="subtitle" v-html="subTitle"></div>
  </div>
</template>

<script>
export default {
  name: "NoData",
  props: {
    type: { type: String, default: null },
    icon: {
      type: String,
      default: null
    },
    iconSize: {
      type: [String, Number],
      default: "normal"
    },
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: "很抱歉，当前无数据哟~"
    }
  }
};
</script>
<style lang="scss" scoped>
.nodata {
  text-align: center;
  .icon-img {
    height: 106px;
    width: 110px;
  }
}
.small-size {
  padding: 10px;
  .icon-img {
    width: 51px;
    height: 50px;
    margin-bottom: 5px;
  }
  .subtitle {
    font-size: 12px;
  }
}
</style>
