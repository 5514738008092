export default {
  data() {
    return {
      hoverOption: -1,
      treeOption: null,
      treeLevelNodes: null,
      treeHoverNode: null,
      treeHoverOption: null
    };
  },

  computed: {
    optionsAllDisabled() {
      if (this.mode === "tree") {
        return true;
      }
      return this.options.filter(option => option.visible).every(option => option.disabled);
    }
  },

  watch: {
    hoverIndex(val) {
      if (typeof val === "number" && val > -1) {
        this.hoverOption = this.options[val] || {};
      }
      this.options.forEach(option => {
        option.hover = this.hoverOption === option;
      });
    },
    treeHoverNode(val) {
      if (val) {
        this.treeHoverOption = this.getOption(val.value);
      } else {
        this.treeHoverOption = null;
      }
      this.options.forEach(option => {
        option.hover = this.treeHoverOption === option;
      });
    }
  },

  methods: {
    navigateOptions(direction) {
      if (!this.allowVisible) {
        if (this.visible) {
          this.visible = false;
        }
        this.$emit("not-allow-visible");
        return;
      }
      if (!this.visible) {
        this.visible = true;
        return;
      }
      if (this.options.length === 0 || this.filteredOptionsCount === 0) return;
      if (this.mode === "tree") {
        this.navigateTreeOption(direction);
        return;
      }
      if (direction === "left" || direction === "right") return;
      if (!this.optionsAllDisabled) {
        if (direction === "next") {
          this.hoverIndex++;
          if (this.hoverIndex === this.options.length) {
            this.hoverIndex = 0;
          }
        } else if (direction === "prev") {
          this.hoverIndex--;
          if (this.hoverIndex < 0) {
            this.hoverIndex = this.options.length - 1;
          }
        }
        const option = this.options[this.hoverIndex];
        if (option.disabled === true ||
          option.groupDisabled === true ||
          !option.visible) {
          this.navigateOptions(direction);
        }
        this.$nextTick(() => this.scrollToOption(this.hoverOption));
      }
    },
    navigateTreeOption(direction) {
      const { treeOption } = this;
      if (!treeOption) {
        return;
      }
      const { store } = treeOption;
      const { root } = store;
      const { childNodes } = root;
      if (direction === "next") {
        if (!this.treeHoverOption) {
          this.treeLevelNodes = childNodes;
          this.treeHoverNode = childNodes[0];
        } else if (!this.treeHoverNode.nextSibling) { // 如果没有下一个，直接转到当前层级第一个
          this.treeHoverNode = this.treeLevelNodes[0];
        } else {
          this.treeHoverNode = this.treeHoverNode.nextSibling;
        }
      } else if (direction === "prev") {
        if (!this.treeHoverOption) {
          this.treeLevelNodes = childNodes;
          this.treeHoverNode = childNodes[childNodes.length - 1]
        } else if (!this.treeHoverNode.previousSibling) {
          this.treeHoverNode = this.treeLevelNodes[this.treeLevelNodes.length - 1];
        } else this.treeHoverNode = this.treeHoverNode.previousSibling;
      } else if (direction === "right") {
        if (!this.treeHoverOption) {
          return;
        }
        if (!this.treeHoverNode.isLeaf ||
          this.treeHoverNode.childNodes.length > 0 ||
          this.treeHoverNode.shouldLoadData()
        ) {
          if (!this.treeHoverNode.expanded) { // 未展开
            this.treeHoverNode.expand(null, true);
            // this.$nextTick(() => {
            //   this.treeLevelNodes = this.treeHoverNode.childNodes;
            //   this.treeHoverNode = this.treeLevelNodes[0]
            //   this.$nextTick(() => this.treeScrollToOption(this.treeHoverOption));
            // })
            return
          }
          this.treeLevelNodes = this.treeHoverNode.childNodes;
          this.treeHoverNode = this.treeLevelNodes[0]
        }
      } else if (direction === "left") {
        if (!this.treeHoverOption) {
          return;
        }
        if (this.treeHoverNode.parent && this.treeHoverNode.parent.parent) { // 有父节点
          this.treeLevelNodes = this.treeHoverNode.parent.parent.childNodes;
          this.treeHoverNode = this.treeHoverNode.parent
        }
      }

      console.log(this.treeHoverNode.label);
      const option = this.getOption(this.treeHoverNode);
      if ((direction === "prev" || direction === "next") && !option && !option.hide && option.visible) {
        this.navigateTreeOption(direction);
      }

      this.$nextTick(() => this.treeScrollToOption(this.treeHoverOption));
    }
  }
};
