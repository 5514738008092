/*
 * @Author: Fenngding
 * @Date: 2019-10-14 15:41:51
 * @LastEditors: Fengding
 * @LastEditTime: 2019-10-14 15:41:51
 * @Email: fenngding@live.com
 * @Description:
 */
import component from "./src";
component.install = function(Vue) {
  Vue.component(component.name, component);
};
export default component;
