<template>
  <components
    :is="type"
    class="en-transfer"
  >
    <template #left-title>
      <slot name="left-title"></slot>
    </template>
    <template #right-title>
      <slot name="right-title"></slot>
    </template>
    <template #left-content="{ item, data, node }">
      <slot name="left-content" :item="item" :data="data" :node="node"></slot>
    </template>
    <template #left-assist="{ data, node }">
      <slot name="left-assist" :data="data" :node="node"></slot>
    </template>
    <template #right-content="{ item }">
      <slot name="right-content" :item="item"></slot>
    </template>
  </components>
</template>

<script>
import tree from "./components/tree";
import normal from "./components/normal";

export default {
  name: "EnTransfer",
  components: {
    tree,
    normal
  },
  props: {
    /**
     * @description 穿梭框类型
     */
    type: {
      type: String,
      default: "tree",
      validator: (val) => {
        return ["tree", "normal"].indexOf(val) > -1;
      }
    },
    /**
     * @description 穿梭框标题数组
     */
    title: {
      type: Array,
      default: () => {
        return ["左侧", "右侧"];
      }
    },
    /**
     * @description 右侧面板数据
     */
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    /**
     * @description 是否需要显示标题
     */
    showTitle: {
      type: [Boolean, String],
      default: true
    },
    /**
     * @description 是否需要显示搜索框
     */
    showSearch: {
      type: [Boolean],
      default: false
    },
    // 右侧是否可以拖拽排序,默认false
    canSort: {
      type: Boolean,
      default: false
    },
    /**
     * @description 数据的唯一标识字段
     */
    idKey: {
      type: String,
      default: "id"
    },
    /**
     * @description 数据的文本显示字段
     */
    labelKey: {
      type: String
    },
    /**
     * @description 数据的子级承载字段
     */
    childrenKey: {
      type: String
    },
    /**
     * @description 右侧面板是否需要显示头像
     */
    showHead: {
      type: [Boolean, String],
      default: false
    },
    /**
     * @description 向右选择数据后是否刷新左边面板
     */
    resetLeft: {
      type: [Boolean, String],
      default: true
    },
    /**
     * @description show-head=true时生效，右侧面板数据头像的 URL 承载字段
     */
    imgPath: {
      type: String,
      default: ""
    },
    /**
     * @description  右侧数据是否需要支持自定义图标
     */
    selfIcon: {
      type: [Boolean, String],
      default: false
    },
    /**
     * @description  数据是否需要禁止传递（或选择），如果需要禁用某一数据的选择时使用，数据内部需要有 disabled 字段
     */
    disabled: {
      type: [Boolean, String],
      default: false
    },
    /**
     * @description  是否需要排除功能，当exclude=true时生效，右侧的面板数据将会多一个字段isExcluded，
     当isExcluded=true时，数据为被排除的数据
     */
    exclude: {
      type: [Boolean, String],
      default: false
    },
    /**
     * @description  是否只能选择子级节点（根节点不让选择，部分业务需要，业务开发者选择使用）
     */
    selectChild: {
      type: [Boolean, String],
      default: false
    },
    /**
     * @description  是否只能选择一个子级节点，select-child=true时生效，目前只支持两层数据，若有超出的，联系作者处理
     */
    singleSelect: {
      type: [Boolean, String],
      default: false
    },
    /**
     * @description  是否需要右侧整体自定义，当self-define=true时生效，生效后右侧面板除去复选框外，所有逻辑需要业务自己处理
     */
    selfDefine: {
      type: [Boolean, String],
      default: false
    },
    /**
     * @description 搜索框提示文本
     */
    placeholder: {
      type: String,
      default: "请输入内容"
    },
    /**
     * @description 左侧面板数据源
     */
    list: {
      type: Array,
      default: () => {
        return [];
      }
    },
    /**
     * @description tree模式有效，tree配置选项
     */
    props: {
      default() {
        return {
          children: "children",
          label: "label",
          value: "value",
          disabled: "disabled"
        };
      }
    },
    /**
     * @description tree模式有效，assist提示
     */
    showAssist: {
      type: Boolean
    },
    /**
     * @description tree模式有效，自定义搜索
     */
    filterNodeMethod: Function,
    /**
     * @description tree模式有效，控制选择数量，条件同en-tree
     */
    multiple: {
      type: Number,
      default: 0
    },
    /**
     * @description tree模式有效，是否懒加唉或者分页
     */
    lazy: Boolean,
    /**
     * @description tree模式有效，lazy为true调用函数
     */
    load: Function,
    /**
     * @description tree模式有效，lazy为true 分页处理
     */
    pageSize: {
      type: Number,
      default: 0
    }
  },
  methods: {
    closeDialog() {
      this.$emit("input", false);
    }
  },
  beforeMount() {
    console.log("$slots", this.$slots);
  }
};
</script>

<style lang="scss">
  .en-transfer {
    width: 100%;
    height: 530px;
    display: flex;
    justify-content: flex-start;

    .en-transfer-panel {
      width: calc(50% - 40px);
      border: 1px solid #E7EBF1;
      border-radius: 8px 4px 4px 8px;
      padding-bottom: 5px;

      .en-transfer-panel-title {
        font-size: 12px;
        color: #333333;
        padding: 14px 20px 14px;
        border-bottom: 1px solid #E7EBF1;
        font-weight: bold;
      }

      .en-transfer-search-title {
        width: calc(100% - 20px);
        margin: 10px 10px 10px;

        .el-input__inner {
          width: 100%;
        }
      }
    }

    .en-transfer-tool {
      width: 80px;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-button {
        margin: 10px 0;
        width: 60px;
      }
    }

    .en-transfer-panel-body {
      height: calc(100% - 48px);
      overflow-y: auto;

      &.with-search {
        height: calc(100% - 100px);
      }

      .en-transfer-panel-item {
        width: 100%;
        padding: 0 20px;
        height: 36px;
        line-height: 36px;
        display: flex;
        justify-content: flex-start;

        .el-checkbox__label {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          line-height: 36px;

          .en-transfer-panel-item-container {
            width: 100%;
            height: 36px;
            line-height: 36px;
            display: flex;
            justify-content: flex-start;

            .el-icon {
              margin: 12px 0;
            }

            .en-transfer-panel-item-user-logo {
              margin-top: 3px;
              margin-right: 5px;
            }
            .en-transfer-panel-item-label {
              margin-left: 6px;
              display: flex;
              justify-content: space-between;
            }
          }

        }

        .el-checkbox__inner {
          margin: 10px 0;
        }

        &:hover {
          background-color: #F5F7FB;

          .en-transfer-panel-item-label {
            color: #4694df;
          }
        }

      }

      .el-tree-node__label {
        margin-left: 6px;
      }
    }
  }
</style>
