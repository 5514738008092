<template>
  <div class="en-time-table">
    <!-- <div class="time">
      <div class="header">
        时间
      </div>
      <div class="rows">
      </div>
    </div> -->

    <el-table
      :data="tableData"
      height="100%"
      border
      stripe
      row-class-name="no-hover"
    >
      <template slot="append">
        <div
          class="time-line"
          :style="{'width':331*6+'px'}"
        >
        </div>
        <div class="shadow-line">
        </div>
      </template>
      <el-table-column
        prop="date"
        label="时间"
        width="70"
        fixed="left"
      >
        <template slot-scope="scope">
          <div
            class="time"
            :style="scope.$index === 55 ? {'color':'#F76B6B'}:{}"
          >
            {{ timeShowWithScope(scope) }}
          </div>
          <div
            v-if="scope.$index === 0"
            class="highlight-time"
            :style="{top:(35 + 2.5 * 46) + 'px'}"
          >
            03:33
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="331px"
      >
        <template slot-scope="scope">
          <div
            v-if="scope.$index === 1"
            class="block"
          >
            <time-resource
              name="ddddd"
              time="11:00-12:00"
              height="60"
              top="60"
              type="blue"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="331px"
      >
        <template slot-scope="scope">
          <div
            v-if="scope.$index === 1"
            class="block"
          >
            <time-resource
              name="ddddd"
              time="11:00-12:00"
              height="50"
              top="60"
              type="blue"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="331px"
      >
        <template slot-scope="scope">
          <div
            v-if="scope.$index === 1"
            class="block"
          >
            <time-resource
              name="ddddd"
              time="11:00-12:00"
              height="80"
              top="60"
              type="blue"
            />
            <time-resource
              name="ddddd"
              time="11:00-12:00"
              height="40"
              top="150"
              type="red"
            />
            <time-resource
              name="ddddd"
              time="11:00-12:00"
              height="60"
              top="190"
              type="orange"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="331px"
      >
        <template slot-scope="scope">
          <div
            v-if="scope.$index === 1"
            class="block"
          >
            <time-resource
              name="ddddd"
              time="11:00-12:00"
              height="30"
              top="10"
              type="gray"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="331px"
      >
        <template slot-scope="scope">
          <div
            v-if="scope.$index === 1"
            class="block"
          >
            <time-resource
              name="ddddd"
              time="11:00-12:00"
              height="46"
              top="55"
              type="red"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="331px"
      >
        <template slot-scope="scope">
          <div
            v-if="scope.$index === 1"
            class="block"
          >
            <time-resource
              name="ddddd"
              time="11:00-12:00"
              height="33"
              top="55"
              type="orange"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import TimeResource from "./TimeResource";
export default {
  name: "EnTimeTable",
  components: { TimeResource },
  props: {
    // 资源列宽
    columnWidth: {
      type: Number,
      default: 331
    },
    // 数据
    datas: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return { tableData: [] };
  },
  mounted() {
    this.tableData = new Array(24);
    this.tableData.fill({
      date: "08:00",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄"

    });
    const date = new Date("2014-07-10");
    console.log(date);
  },
  methods: {
    timeShowWithScope(scope) {
      if (scope.$index < 9) {
        return "0" + (scope.$index + 1) + ":00";
      } else {
        return (scope.$index + 1) + ":00";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.en-time-table {
  height: 100%;
  overflow-y: scroll;
  position: relative;
  .highlight-time {
    position: absolute;
    color: #f76b6b;
    left: 0;
    z-index: 22;
    width: 70px;
    text-align: center;
  }
  // 阴影
  & /deep/ .el-table__header-wrapper::after,
  & /deep/ .el-table__fixed-header-wrapper::after {
    content: "";
    position: absolute;
    top: 50px;
    height: 10px;
    width: 100%;
    box-shadow: inset 0px 15px 10px -15px rgba($color: #000000, $alpha: 0.2);
    z-index: 200;
  }
  // 时间红线
  .time-line {
    position: absolute;
    margin-left: 70px;
    height: 1px;
    background-color: #f76b6b;
    left: 0;
    top: 145px;
    z-index: 2;
    &::before {
      content: "";
      border: 3px solid #f76b6b; /*设置红色*/
      border-radius: 3px; /*设置圆角*/
      position: absolute;
      z-index: 2;
      left: 0%;
      margin-right: -5px;
      margin-top: -3px;
    }
  }
  // 去掉hover
  & /deep/ .no-hover:hover > td {
    background-color: initial !important;
  }
  .el-table {
    width: 100%;
    // 这个控制红线的位置能否展示出来
    & /deep/ .el-table__append-wrapper {
      overflow: auto;
    }
    & /deep/ .el-table__row /deep/ td,
    & /deep/ .el-table__row /deep/ th {
      padding: 0px 0px;
      height: 46px;
    }
    & /deep/ .el-table__header td,
    & /deep/ .el-table__header th {
      padding: 0px 0px;
      height: 50px;
      background: rgba(246, 250, 253, 1);
      border-bottom: 0;
      text-align: center;
      // box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1) !important;
    }
    & /deep/ .el-table__row td:first-child {
      border-left: 0;
      border-bottom: 0;
      background-color: #fff;
    }
    .time {
      position: absolute;
      width: 70px;
      text-align: center;
      left: 0;
      top: 35px;
      z-index: 11;
    }
  }
}
</style>
