<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 彭博
 * @Date: 2019-11-15 09:19:35
 * @LastEditTime: 2020-07-27 11:38:48
 -->
<template>
  <div class="en-expand-buttons" @click.stop="bgClick">
    <MultiButton
      v-bind="$attrs"
      :class="{ 'collapse-buttons': !expand }"
      @button-click="buttonClick"
    >
      <template v-slot:left>
        <slot name="left"> </slot>
      </template>
      <template v-slot:right>
        <slot name="right"> </slot>
      </template>
    </MultiButton>
    <el-tooltip
      class="tool-tips"
      effect="dark"
      :content="expand ? '收起' : '展开'"
      :disabled="!$attrs.showToolTips"
    >
      <en-icon
        v-if="needExpandFeature"
        name="gengduo-shouqi-liebiao"
        color="#58CB7E"
        :size="$attrs.size || 'small'"
        class="expand-icon"
        :class="{ 'expand-icon-rotate': !expand }"
        @click.stop.native="handleExpandClick"
      ></en-icon>
    </el-tooltip>
  </div>
</template>
<script>
import MultiButton from "./multi-button.vue";

export default {
  name: "EnExpandButtons",
  components: { MultiButton },
  props: {
    // 最少展示的数量,意思是不超过这个数量,不会展示收起按钮,如果设置为0,则一直会展示收起按钮
    minNumber: {
      type: [Number, String],
      default: 2
    },
    // 默认展开的状态 true
    defaultExpand: {
      type: Boolean,
      default: true
    },
    // 当前行下标 列表中使用
    lineIndex: {
      type: [Number, String],
      default: ""
    }
  },
  data() {
    return {
      // 是否是展开
      expand: false,
      // 是否已经展开完毕(收起完毕) 延迟设置expand用户控制展示名字
      expanded: false
    };
  },
  computed: {
    // 是否需要expand的功能
    needExpandFeature() {
      return this.$attrs.data.length > parseInt(this.minNumber, 10);
    }
  },
  mounted() {
    this.expand = this.defaultExpand;
    this.expanded = this.defaultExpand;
  },
  methods: {
    bgClick(event) {
      event.stopPropagation();
    },

    // 展开按钮点击
    handleExpandClick() {
      this.expand = !this.expand;
      setTimeout(() => {
        this.expanded = !this.expanded;
      }, 500);
    },
    // 点击事件
    buttonClick(index, buttonData) {
      this.$emit("button-click", index, buttonData, this.lineIndex); // 点击事件 新增行数下标返回值 需props传入
    }
  }
};
</script>
<style lang="scss" scoped>
.en-expand-buttons {
  display: inline-block;
  text-align: right;
  background-color: #f5f8fc;
  opacity: 0.9;
  line-height: 18px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  .expand-icon {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 12px;
    margin-right: 20px;
    vertical-align: middle;
    box-sizing: border-box;
    cursor: pointer;
    transition: transform 0.5s;
    pointer-events: auto;
  }
  .expand-icon-rotate {
    transform: rotate(90deg);
  }
  .en-multi-button {
    margin-top: 5px;
    margin-bottom: 5px;
    pointer-events: auto;
    vertical-align: middle;
    overflow: hidden;
    transition: max-width 0.5s ease;
    max-width: 500px;
    & /deep/ .buttons {
      padding-right: 3px;
    }
  }
  .collapse-buttons {
    max-width: 0;
  }
}
</style>
