/**
 * 计算公式配置死循环校验
 * 核心逻辑:
 * a. 等式左边的字段不能出现两次(出现两次已经是配置不合理)
 * b. 循环代入法,找到可能导致左右两侧存在相同字段的配置
 *
 *
 * 参数格式: key代表字段的唯一标识符
 *         target代表目标赋值字段,也就是等式左边的字段
 *         source代表参与计算的字段,也就是等式右边的字段
 * [
 *   {
 *    target: key1,
 *    source: [key2, key3]
 *   },
 *   {
 *    target: key2,
 *    source: [key2, key3]
 *   },
 * ]
*/
const deadCycleCheck = function(formulas) {
  // 获取所有的字段target
  let errorFormula;
  const allTargetsArray = formulas.map(ele => ele.target);
  allTargetsArray.sort();
  for (let i = 0; i < allTargetsArray.length; i++) { // 按照属性判断属性合并列数
    if (i + 1 < allTargetsArray.length && allTargetsArray[i] === allTargetsArray[i + 1]) {
      errorFormula = allTargetsArray[i];
      break;
    }
  }
  // 转成set对比
  const allTargetsSet = new Set(allTargetsArray);
  if (allTargetsSet.size !== allTargetsArray.length) {
    // 长度不一致说明有相同的元素
    return {
      error: 1,
      code: errorFormula
    };
  }
  // 代入
  for (let i = 0; i < formulas.length; i++) {
    // 将要带入的字段
    const replaceFormula = formulas[i];
    for (let j = i + 1; j < formulas.length; j++) {
      // 存在则替换
      const judgeFormula = formulas[j];
      judgeFormula.source = listDuplicateRemoval(judgeFormula.source);
      const index = judgeFormula.source.indexOf(replaceFormula.target);
      if (index !== -1) {
        // 替换元素
        judgeFormula.source.splice(index, 1, ...replaceFormula.source);
        // 替换完成之后判断是否死循环
        if (judgeFormula.source.indexOf(judgeFormula.target) !== -1) {
          errorFormula = judgeFormula;
          break;
        }
      }
    }
    if (errorFormula) {
      break;
    }
  }
  if (errorFormula) {
    return {
      error: 2,
      code: errorFormula.target
    };
  }
  return { error: 0 };
};

// 数组去重得到新的数组
const listDuplicateRemoval = (list) => {
  const set = new Set(list);
  return Array.from(set);
};

export { deadCycleCheck };
