<!--
* @Author: 梁平贤
* @Description: 且或中的级联选择器
-->
<template>
  <div class="en-cascade-compare">
    <div class="content">
      <!-- 关联规则选取数据源, 默认本数据源 -->
      <en-select
        v-if="type === 'rule'"
        v-model.number="column.dataSource"
        placeholder="请选择字段"
        :options="ruleOptions"
        :style="bindLengthCss"
      ></en-select>
      <en-select
        v-model.number="column.fieldType"
        placeholder="请选择字段"
        :options="changeOptions"
        :style="bindLengthCss"
        @change="fieldChangeHandle"
      ></en-select>
      <!-- 选择比较符 -->
      <en-select
        v-model="column.operator"
        placeholder="请选择条件"
        :options="operatorOptions(column.fieldType)"
        :style="bindLengthCss"
      ></en-select>
      <!-- 为空不为空后面就没了 -->
      <div v-if="isOperatorNil" class="nil"></div>
      <!-- 文本等于不等于包含不包含是输入框 1,2,3,4-->
      <el-input v-if="isTextColumnAndNotNil" :style="bindLengthCss"></el-input>
      <!-- 选择数值的逻辑 -->
      <en-select
        v-if="isNumberColumnAndNotNit"
        v-model="column.valueType"
        placeholder="请选择"
        :style="bindLengthCss"
        :options="compareColumns(column)"
      ></en-select>
      <en-input-number
        v-if="isNumberColumnAndNotNit"
        v-model.number="value"
        :precision="2"
        :max="300"
        :min="-300"
        :style="bindLengthCss"
      ></en-input-number>
      <!-- 选择金额的逻辑 -->
      <en-select
        v-if="isAmountColumnAndNotNit"
        v-model="column.valueType"
        placeholder="请选择"
        :style="bindLengthCss"
        :options="compareColumns(column)"
      ></en-select>
      <en-input-number
        v-if="isAmountColumnAndNotNit"
        v-model.number="value"
        :precision="2"
        :max="300"
        :min="-300"
        :style="bindLengthCss"
      >
      </en-input-number>
      <!-- 选择日期的逻辑 -->
      <en-select
        v-if="isDateColumnAndNotNit"
        :options="compareColumns(column)"
        v-model="column.dateSelectType"
        placeholder="请选择"
        :style="bindLengthCss"
      ></en-select>
      <en-select
        v-if="isDateColumnAndNotNit && isDateUserDefinedColumn"
        :options="dateGlobalVariables"
        v-model="column.dateGlobalType"
        placeholder="请选择"
        :style="bindLengthCss"
      ></en-select>
      <!-- 选择自定义的时间,时间格式需要根据配置来.还需要处理下 -->
      <el-date-picker
        v-if="
          isDateColumnAndNotNit && isDateUserDefinedColumn && isDateUserFillIn
        "
        v-model="column.defaultValue"
        type="daterange"
        range-separator="-"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd HH:mm:ss"
        :style="bindLengthCss"
      />
      <!-- 选择选项的逻辑 -->
      <en-select
        v-if="isSelectColumnAndNotNit"
        :options="compareColumns(column)"
        :style="bindLengthCss"
      ></en-select>
    </div>
  </div>
</template>
<script>
import { BussinessFieldType } from "../../../headerFiles/BusinessCommonHeader.js";
import deepcopy from "deepcopy";
export default {
  name: "EnCascadeCompare",
  props: {
    datas: Array,
    // 字段的长度, 每个输入框或者选择框的长度
    columnLength: {
      type: [String, Number],
      default: 300
    },
    type: {
      type: String,
      default: "rule",
      validator: function(val) {
        // 目前有业务建模模板定义和关联规则,这两个地方的处理不一样. 关联规则需要选数据源
        return ["business", "rule"];
      }
    }
  },
  data() {
    return {
      // 选择的条件信息
      column: {
        fieldType: 1,
        value: "",
        operator: 1,
        valueType: 1,
        dataSource: 1,
        dateSelectType: 1,
        dateGlobalType: 1,
        dateFillIn: ""
      },
      // 关联规则选取数据源
      ruleOptions: [
        {
          label: "本数据源",
          value: 1
        },
        {
          label: "关联数据源",
          value: 2
        }
      ],
      // 字段变化 要取模板上的字段
      changeOptions: [
        {
          label: "文本",
          value: 1,
          fieldType: 1
        },
        {
          label: "数值",
          value: 3,
          fieldType: 3
        },
        {
          label: "金额",
          value: 4,
          fieldType: 4
        },
        {
          label: "单选",
          value: 5,
          fieldType: 5
        },
        {
          label: "时间",
          value: 7,
          fieldType: 7
        }
      ],
      dateGlobalVariables: [
        {
          label: "变量1",
          value: 1
        },
        {
          label: "变量2",
          value: 2
        },
        {
          label: "变量3",
          value: 3
        },
        {
          label: "变量4",
          value: 5
        },
        {
          label: "自定义",
          value: 999
        }
      ]
    };
  },
  computed: {
    // 绑定的css
    bindLengthCss() {
      return {
        width: `${parseInt(this.columnLength, 10)}px`,
        "flex-basis": `${parseInt(this.columnLength, 10)}px`,
        "margin-left": "6px"
      };
    },
    // 判断是不是为空:所有的为空之后,全是没有任何东西了
    isOperatorNil() {
      return (
        parseInt(this.column.operator, 10) === 13 ||
        parseInt(this.column.operator, 10) === 14
      );
    },
    // 文本的对比: 文本其他的都是填一个文本
    isTextColumnAndNotNil() {
      return this.column.fieldType === 1 && !this.isOperatorNil;
    },
    // 数值的对比: 选字段或者填一个固定的数值
    isNumberColumnAndNotNit() {
      return this.column.fieldType === 3 && !this.isOperatorNil;
    },
    // 金额的对比: 选模板上的金额字段或者填一个固定的金额
    isAmountColumnAndNotNit() {
      return this.column.fieldType === 4 && !this.isOperatorNil;
    },
    // 日期的对比: 选模板上字段或者自定义日期?
    isDateColumnAndNotNit() {
      return this.column.fieldType === 7 && !this.isOperatorNil;
    },
    // 日期的对比: 选模板上字段或者自定义日期
    isDateUserDefinedColumn() {
      return this.column.dateSelectType === 999;
    },
    // 自己填写的日期
    isDateUserFillIn() {
      return this.column.dateGlobalType === 999;
    },
    // 单选的对比:
    isSelectColumnAndNotNit() {
      return this.column.fieldType === 5 && !this.isOperatorNil;
    },
    // 操作符
    operatorOptions() {
      return function(filedType) {
        switch (filedType) {
          case BussinessFieldType.date:
          case BussinessFieldType.inputNumber:
          case BussinessFieldType.inputAmount: {
            return [
              { value: 5, label: "大于" },
              { value: 6, label: "大于或等于" },
              { value: 8, label: "小于" },
              { value: 7, label: "小于或等于" },
              { value: 1, label: "等于" },
              { value: 2, label: "不等于" },
              { value: 13, label: "不为空" },
              { value: 14, label: "为空" }
            ];
          }
          case BussinessFieldType.select:
            return [
              { value: 9, label: "不包含于" },
              { value: 10, label: "包含于" },
              { value: 13, label: "不为空" },
              { value: 14, label: "为空" },
              // { value: "3", label: "包含" },  这两项去掉了
              // { value: "4", label: "不包含" },
              { value: 11, label: "属于" },
              { value: 12, label: "不属于" }
            ];
          case BussinessFieldType.inputCommon:
          case BussinessFieldType.multiSelect:
            return [
              { value: 1, label: "等于" },
              { value: 2, label: "不等于" },
              { value: 3, label: "包含" },
              { value: 4, label: "不包含" },
              { value: 13, label: "不为空" },
              { value: 14, label: "为空" }
            ];
          default:
            return [];
        }
      };
    },
    compareColumns() {
      return function(column) {
        // 排除当前字段, 把模板上的其他字段拿出来 还需要固定加上 固定数值/固定金额
        if (column.fieldType === BussinessFieldType.inputNumber) {
          return [
            {
              value: 1,
              label: "固定数值"
            }
          ];
        } else if (column.fieldType === BussinessFieldType.inputAmount) {
          return [
            {
              value: 1,
              label: "固定金额"
            }
          ];
        } else if (column.fieldType === BussinessFieldType.date) {
          return [
            {
              value: 1,
              label: "时间字段1"
            },
            {
              value: 2,
              label: "时间字段2"
            },
            {
              value: 999,
              label: "自定义"
            }
          ];
        } else if (column.fieldType === BussinessFieldType.select) {
          return [
            {
              value: 1,
              label: "单选多选"
            },
            {
              value: 2,
              label: "单选多选"
            }
          ];
        }
      };
    }
  },
  mounted() {
    this.conditionConfig = deepcopy(this.datas);
  },
  methods: {
    fieldChangeHandle() {
      console.log("字段变更");
      console.log(this.column.fieldType);
      this.column.operator = this.operatorOptions(
        this.column.fieldType
      )[0].value;
    }
  }
};
</script>
<style lang="scss" scoped>
.en-cascade-compare {
  display: inline-block;
  margin-left: -6px;
  .content {
    display: flex;
    .en-select,
    .el-input {
      display: inline-block;
    }
  }
}
</style>
