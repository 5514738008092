/**
 * project: enfry-components
 * fileName: index
 * Created by 徐俊 on 2019/11/30
 * Copyright © 2019 Enfry Ltd. All rights reserved.
 */

import EnTableFilterToolbar from "./src/index";

EnTableFilterToolbar.install = function(Vue) {
  Vue.component(EnTableFilterToolbar.name, EnTableFilterToolbar);
};

export const FilterToolbar = EnTableFilterToolbar;
export default EnTableFilterToolbar;
