<template>
  <div class="components-excel">
    <excel-panel
      v-if="(showPanel || showPanel === 'true') && isDone"
      :data-info="panelList"
      :store="store"
    >
    </excel-panel>
    <excel-content
      v-if="isDone"
      :class="{'show-panel': showPanel || showPanel === 'true'}"
      :store="store"
      @cellClick="cellClick"
    >
    </excel-content>
  </div>
</template>

<script>
import excelPanel from "./panel";
import excelContent from "./content";
import Store from "./model";

export default {
  name: "EnExcel",
  components: {
    excelPanel,
    excelContent
  },
  props: {
    /**
     * @description 是否需要显示左侧的面板
     */
    showPanel: {
      type: [String, Boolean],
      default: true
    },
    /**
     * @description 右侧绑定的数据源信息
     */
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    /**
     * @description 左侧面板数据
     */
    panelList: {
      type: Object,
      default: () => {
        return {};
      }
    },
    /**
     * @description 表格有多少行
     */
    row: {
      type: [String, Number],
      default: 10
    },
    /**
     * @description 表格有多少列
     */
    col: {
      type: [String, Number],
      default: 10
    },
    /**
     * @description 拖拽到的字段是否允许删除
     */
    showDelete: {
      type: [Boolean, String],
      default: true
    },
    editAble: {
      type: [Boolean, String],
      default: false
    },
    /**
     * @description 是否需要显示行列下标
     */
    showHead: {
      type: [Boolean, String],
      default: true
    }
  },
  data() {
    return {
      store: {},
      isDone: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initTableInfo();
    });
  },
  methods: {
    initTableInfo() {
      this.store = new Store();
      this.setConfigInfo();
      if (this.value.length > 0) {
        this.store.init(this.value);
      } else {
        this.store.setDefaultList(this.row, this.col);
      }
      this.isDone = true;
    },
    /**
     * @description 配置信息设置
     */
    setConfigInfo() {
      this.store.config.couldDelete = this.couldDelete !== "false" && this.couldDelete !== false;
      this.store.config.editAble = this.editAble !== "false" && this.editAble !== false;
      this.store.config.row = this.row;
      this.store.config.col = this.col;
      this.store.showHead = this.showHead !== "false" && this.showHead !== false;
    },
    /**
     * @description 单元格点击事件
     * @param res
     */
    cellClick(res) {
      this.$emit("cellClick", res);
    }
  }
};
</script>

<style lang="scss" scoped>
  .components-excel {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    border: 1px solid #d7dce6;

    .components-excel-content {
      width: 100%;

      &.show-panel {
        width: calc(100% - 280px);
      }
    }
  }
</style>
