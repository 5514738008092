<!--
 * @Description: zzzzz
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-29 14:13:42
 * @LastEditTime: 2019-10-24 11:34:59
 -->
<template>
  <el-dialog
    title="操作按钮"
    :visible="dialogVisible"
    class="operating-buttons"
    width="700px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    @close="close"
  >
    <div class="content">
      <!-- :model="notificationData" -->
      <el-form ref="form" label-position="left" label-width="61px">
        <!-- prop="defaultValue.title" -->
        <el-form-item label="按钮名称">
          <el-input
            v-model="copiedOperateData.name"
            placeholder="请输入按钮名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作对象" class="margin-top">
          <el-select
            v-model="copiedOperateData.operateType"
            class="object-select"
            placeholder="请选择"
          >
            <el-option
              v-for="item in objectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="selectRefInfo"
            placeholder="请选择"
            class="template-select"
            value-key="refName"
          >
            <el-option
              v-for="item in templateOptions"
              :key="item.refId"
              :label="item.refName"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="对应按钮" class="margin-top">
          <el-select
            v-model="copiedOperateData.buttonKey"
            placeholder="请选择"
            class="button-select"
            disabled
          >
            <el-option label="新增" value="add"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="shouldShowDataMapping"
          label="数据映射"
          class="margin-top"
        >
          <data-mapping
            v-if="dataMappingDialogVisiable"
            :dialog-visible.sync="dataMappingDialogVisiable"
          />
          <en-image-setting
            name="caozuoanniu-tongzhijianmo"
            size="small"
            fill="#4694DF"
            @click.native="dataMappingDialogVisiable = true"
          >
            设置
          </en-image-setting>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </el-dialog>
</template>
<script>
import deepcopy from "deepcopy";
import DataMapping from "./DataMapping.vue";

export default {
  name: "OperatingButtons",
  components: { DataMapping },
  props: {
    // 触发条件类型, = 父组件的type
    type: {
      type: String,
      default: "business"
    },
    operateData: {
      type: Object,
      default: function() {
        return {};
      }
    },
    // 弹框是否展示
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataMappingDialogVisiable: false,
      copiedOperateData: {
        buttonKey: "add",
        dataMapping: [],
        name: "dddd",
        operateType: "2",
        refId: "249623951344402432",
        refName: "a"
      },

      // 选择的模板数据...需要处理
      selectRefInfo: {
        refId: "249623951344402432",
        refName: "a"
      },
      objectOptions: [
        {
          label: "业务建模",
          value: "2"
        }
      ],
      templateOptions: [
        {
          refId: "249623951344402432",
          refName: "a"
        },
        {
          refId: "249623951344402431",
          refName: "b"
        }
      ]
    };
  },
  computed: {
    shouldShowDataMapping() {
      // return true;
      return this.type === "business";
    }
  },
  mounted() {
    this.copiedOperateData = deepcopy(this.operateData);
  },
  methods: {
    // 关闭弹框
    close() {
      this.$emit("update:dialog-visible");
    },
    // 保存
    save() {
      this.$emit("update:operateData", this.copiedOperateData);
      this.close();
    }
  }
};
</script>
<style lang="scss" scoped>
.operating-buttons {
  .el-form {
    .el-form-item.margin-top {
      margin-top: 16px;

      .button-select {
        width: 100%;
      }

      .object-select {
        width: 220px;
      }

      .template-select {
        width: 365px;
        margin-left: 12px;
      }
    }
  }
}
</style>
