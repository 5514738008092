
/**
 * project: enfry-components
 * fileName: utils
 * Created by 徐俊 on 2020/08/06
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import Business from "../../tools/src/business";
import XEUtils from "xe-utils/methods/xe-utils";
import { XArray, XObject } from "../../tools";

const FilterUtils = {

  /**
 * 获得筛选排序数据
 * @param column 列对象
 * @param type 类型 (传 filter/sort)
 * @returns {Function}
 */
  getFilterSortData(column, type, del) {
    if (!column || !column.filterSortStore || !type) {
      return undefined;
    }
    const { filterSortStore, filterSortConfig, data } = column;
    const ret = {};
    if (type === "sort") {
      if (filterSortStore.sort === "asc") {
        ret.sortType = "000"
      } else if (filterSortStore.sort === "desc") {
        ret.sortType = "001"
      } else {
        ret.sortType = "003"
      }
      ret.sort = filterSortStore.sort;
    } else {
      if (filterSortConfig && filterSortConfig.filterValueMethod) {
        const { property } = column;
        const value = filterSortConfig.filterValueMethod({ column, property, field: property, prop: property, type });
        ret.value = value;
        if (del) {
          Object.assign(filterSortStore, { value: "", valueText: "" });
        } else {
          Object.assign(filterSortStore, Array.isArray(value) ? value[0] || { value: "", valueText: "" } : value);
        }
        // 无需格式化的时候直接取value
        if (ret.value.notFormat) {
          return value;
        }
      } else { // 默认金额 input区间输入框的值没绑定上
        if (data && Number(data.type) === 4 && column.filterSortStore.value === undefined) {
          column.filterSortStore.value = [0, 0];
        }
        ret.value = column.filterSortStore.value;
      }
      ret.valueText = this.getValueText(column, ret.value);
    }
    return ret;
  },
  /**
 * 筛选排序数据是否为空
 * @param column 列对象
 * @param type  类型 (传 filter/sort)
 * @returns {*|boolean}
 */
  isEmptyFilterSortData(column = {}, type) {
    if (!column || !column.filterSortStore || !type) {
      return true;
    }
    const ret = this.getFilterSortData(column, type);
    return XObject.isEmpty(ret.sort) && XObject.isEmpty(ret.value);
  },

  /**
 * 获得筛选排序数据
 * @param column 列数据
 * @param type 类型 (传 filter/sort)
 * @param needCondition 需要条件 (传 ture/false)
 * @param $table 需要条件 回填的时候column会没有own属性所以取$tabel
 * @returns {{column, property, field, prop, data: *, type: *}}
 */
  getColumnFilterSortInfo(column, type, needCondition, $table, del) {
    const { property } = column;
    const info = {
      column, property, field: property, prop: property, type, ...this.getFilterSortData(column, type, del)
    };
    if (needCondition) {
      const filterData = [];
      let sortData = {};
      const dataList = column.own ? column.own.$table.filterStore.dataList : $table.filterStore.dataList;
      for (const item of dataList) {
        const headData = item.column.data;
        // eslint-disable-next-line
        const { column, ...rest } = item;
        // const obj = Object.assign({}, headData);
        const obj = {
          fieldType: Business.fieldType(headData),
          ...rest,
          ...column.data
        };
        // 表头筛选的opType为null
        if (column.filterSortStore && column.filterSortStore.isConfirmed) {
          obj.opType = null
        }
        // 之前取property 会有bug
        obj.nameVariable = item.property;

        if (item.type === "sort") { // sortType排序 无003 升序000 降序001
          obj.sortType = item.sortType;
          sortData = obj;
        } else {
          obj.value = this.getValueText(item.column, item.value, true);
          obj.valueText = item.valueText;
          filterData.push(obj);
        }
      }
      info.filterData = JSON.stringify(filterData);
      info.sortData = JSON.stringify(sortData);
    }

    return info;
  },
  /**
 * 获得筛选排序列表
 * @param $table
 * @param column 如果为undefined 全部重新生成
 * @param type 类型 (传 filter/sort)
 * @param del 是否是删除掉字段，因为自定义的会走filterValueMethod导致删除不成功 (传Boolean)
 * @returns {Array}
 */
  getFilterSortList($table = {}, column = {}, type, del) {
    let list;
    if (column && type) { // 这里要保持原有顺序
      list = $table.filterStore.dataList;

      const index = XArray.indexOfObject(list, (item) => (item.column === column || (item.uid && (item.uid === column.uid)) || item.property === column.property) && item.type === type);
      if (index < 0) {
        if (type === "sort") { // 只能存在一个排序字段
          XArray.removeObject(list, (item) => {
            const equal = item.type === type;
            if (equal) {
              item.column.filterSortStore.sort = null;
            }
            return equal;
          });
        }
        if (!this.isEmptyFilterSortData(column, type)) {
          list.push(this.getColumnFilterSortInfo(column, type, false, this, del));
        }
      } else {
        const item = list[index];
        if (!this.isEmptyFilterSortData(column, type) && !del) {
          if (item.column !== column) { // 可能是默认条件
            item.column = column;
          }
          Object.assign(item, this.getFilterSortData(column, type, del));
        } else {
          list.splice(index, 1);
        }
      }
    } else {
      list = [];// 清空数据
      $table.visibleColumn.forEach(column => {
        if (column.filterSortStore) { // 排序清一下,  筛选不清了
          column.filterSortStore.sort = null;
        }
      });
    }

    return list;
  },
  filterSortStoreWithHeadData(headData) {
    return {
      opType: headData.opType,
      opTypeText: Business.getOpTypeData(headData.opType).name,
      value: this.getValueWithHeadData(headData.value),
      valueText: headData.valueText,
      isDefault: true
    };
  },
  // 获取value
  getValueWithHeadData(headData) {
    let value;
    const fieldType = Business.fieldType(headData);
    const { inputNumber, inputAmount } = Business.businessFieldType;
    if (fieldType === inputNumber || fieldType === inputAmount) {
      value = headData.value ? headData.value.split(",") : [];
    } else if (fieldType === 7) {
      const format = Business.dateStringWithTimeFormat(headData.timeFormat);
      const valueList = headData.value ? headData.value.split(",") : [];
      value = [];
      valueList.value.forEach(item => {
        const date = XEUtils.toStringDate(item, format);
        if (date) {
          value.push(date);
        }
      });
    } else {
      value = headData.value;
    }

    return value;
  },

  // 筛选值 valueText
  getValueText(column, value, isCondition) {
    const { filterSortStore } = column;
    if (filterSortStore && filterSortStore.isDefault) { // 默认值
      const { valueText } = filterSortStore;
      return valueText || "";
    }

    let ret;
    const fieldType = Business.fieldType(column.data);
    const { inputNumber, inputAmount, select, date, multiSelect } = Business.businessFieldType;
    if (fieldType === inputNumber || fieldType === inputAmount) { // 数值 金额
      ret = this.getNumberValueText(value, column.data, isCondition);
    } else if (fieldType === select || fieldType === multiSelect) { // 单选/多选
      ret = this.getSelectValueText(value, column.data, isCondition);
    } else if (fieldType === date) { // 日期
      ret = this.getDateValueText(value, column.data, isCondition);
    } else {
      ret = this.realValueText(value, column.data, isCondition);
    }

    return ret || "";
  },
  getNumberValueText(value, headData, isCondition) {
    const list = [];
    if (value instanceof Array) {
      value.forEach(item => {
        list.push(this.realValueText(item, headData, isCondition));
      });
    } else {
      list.push(value);
    }

    let ret;
    if (list.length === 0) {
      return ret;
    }

    const first = list[0];
    const second = list.length === 1 ? "" : list[1];

    if (first === undefined && second === undefined) {
      ret = "";
    } else if (first === undefined) {
      ret = isCondition ? ("," + second) : ("<=" + second);
    } else if (second === undefined) {
      ret = isCondition ? first : ("<=" + first);
    } else {
      ret = first + "," + second;
    }

    return ret;
  },
  getDateValueText(value, headData, isCondition) {
    const list = [];
    if (value instanceof Array) {
      value.forEach(item => {
        list.push(this.realValueText(item, headData, isCondition));
      });
    } else {
      list.push(value);
    }

    return list.join(isCondition ? "," : "至");
  },

  getSelectValueText(value, headData, isCondition) {
    const list = [];
    if (value instanceof Array) {
      value.forEach(item => {
        list.push(this.getSelectValueText(item, headData, isCondition));
      });
    } else {
      list.push(isCondition ? value.value : value.valueText);
    }

    return list.join(isCondition ? "," : "、");
  },

  // 显示文本 (单条数据)
  realValueText(value, headData = {}, isCondition) {
    let val;

    if (value instanceof Array) {
      return value.map(item => this.realValueText(item, headData)).join(isCondition ? "," : "、");
    } else if (value instanceof Date) {
      const format = Business.dateFormatWithTimeFormat(headData.timeFormat);
      val = XEUtils.toDateString(value, format);
    } else {
      val = value;
    }
    return val;
  }
}

export default FilterUtils;
