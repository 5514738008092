/**
 * project: enfry-components
 * fileName: page
 * Created by 徐俊 on 2020/05/29
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import { pageModel } from "../components/en-pagination/src/page";

export default pageModel;
