// import XEUtils from "xe-utils/methods/xe-utils";

const elementResizeDetectorMaker = require("element-resize-detector");
/**
 * project: enfry-components
 * fileName: tableUtils
 * Created by 徐俊 on 2019/11/15
 * Copyright © 2019 Enfry Ltd. All rights reserved.
 */

export const TableUtils = {
  // 是否隐藏了
  isDisplayNone(elem) {
    let pElem = elem;
    while (pElem && pElem.style) {
      if (pElem.style.display === "none") {
        return true;
      } else {
        pElem = pElem.parentElement;
      }
    }
    return false;
  },
  /**
   * 表格宽度变化监听(用于多个表格  并且使用了 display:none 导致dom宽度为0)
   */
  addTableWidthMoniterIfNeed($table) {
    const self = $table;
    if (self.$tableWidthMoniter) {
      return;
    }

    if (self.$el && self.$el.clientWidth === 0) {
      self.$tableWidthMoniter = elementResizeDetectorMaker({ strategy: "scroll" });// <- For ultra performance.
      self.$tableWidthMoniter.listenTo(self.$el, function() {
        if (self.$el.clientWidth > 0 && self.$el.clientWidth !== self.$tableWidth) {
          self.$tableWidth = self.$el.clientWidth;
          self.reLayout();
          TableUtils.removeTableWidthMoniterIfNeed(self);
        }
      });
    }
  },
  /**
   * 删除表格宽度监听
   * @param $table
   */
  removeTableWidthMoniterIfNeed($table) {
    const self = $table;
    if (self.$tableWidthMoniter && self.$el) {
      self.$tableWidthMoniter.uninstall($table.$el);
      self.$tableWidthMoniter = undefined;
    }
  }
};
