import CellInfo from "./cellOperation";

export default class Store {
  constructor() {
    // 所有的单元格
    this.allCells = [];
    // 拖拽选中单元格的坐标信息
    this.dragInfo = {
      // 起点行
      sR: null,
      // 结束行
      eR: null,
      // 起点列
      sC: null,
      // 结束列
      eC: null
    };
    // 拖拽开始时选中的节点坐标信息
    this.startCellInfo = {
      row: null,
      col: null
    };
    // 列头
    this.colIndex = [];
    // 顶部菜单栏的一些数据信息=>用来做检测判断
    this.toolBarInfo = {
      // 选中的单元格是否全部加粗
      allWeight: false,
      // 选中的单元格是否可拆分
      canSplit: false
    };
    // 单元格的一些配置信息
    this.config = {
      // 总共的行数
      row: null,
      // 总共的列数
      col: null,
      // 是否需要显示删除图标
      showDelete: true,
      // 是否可手动输入
      editAble: false
    };
    // 选中的最左上角的单元格行列坐标
    this.beginCellInfo = null;
    // 选中的单元格
    this.selectedCells = [];
    // 被合并的单元格
    this.mergedCells = [];
    // 包含对应的合并关系
    this.mergedCellsList = [];
    // 拖拽的数据源
    this.dragData = {};
    // 右键菜单的坐标数据
    this.menuData = {
      col: null,
      row: null
    };
    // 是否需要展示序号
    this.showHead = true;
  }

  /**
   * @description 初始化
   * @param list
   */
  init(list) {
    this.allCells = list;
    list.forEach((item, index) => {
      item.forEach((cItem, cIndex) => {
        return new CellInfo(this, cItem.data, cItem.id, index, cIndex, this.getColIndexInfo(cIndex), index + 1);
      });
    });
  }

  /**
   * @description 获取列头标识
   * @param n
   * @return {string}
   */
  getColIndexInfo(n) {
    var ordA = "A".charCodeAt(0);
    var ordZ = "Z".charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while (n >= 0) {
      s = String.fromCharCode(n % len + ordA) + s;
      n = Math.floor(n / len) - 1;
    }
    return s;
  }

  /**
   * @description 获取所有数据
   * @return {[]}
   */
  getAllCells() {
    return this.allCells;
  }

  /**
   * @description 设置选中的单元格
   * @return {Array}
   * @constructor
   */
  reGetSelectedCells() {
    this.selectedCells = [];
    this.mergedCellsList = [];
    this.allCells.forEach(rItem => {
      rItem.forEach(cItem => {
        // 如果单元格是覆盖状态，并且单元格是显示的，将其塞入至选中的数组内
        if (cItem.wasDuring) {
          if (cItem.getCellRowSpan > 0 &&
            cItem.getCellColSpan > 0) {
            this.selectedCells.push(cItem);
          }
          this.mergedCellsList.push(cItem);
          // 如果不存在起点单元格的坐标信息，将单元格的坐标信息赋值
          if (!this.beginCellInfo) {
            this.beginCellInfo = cItem;
          }
        }
      });
    });
    this.toolBarInfo.canSplit = this.selectedCells.length === 1 &&
      (this.selectedCells[0].getCellColSpan > 1 ||
        this.selectedCells[0].getCellRowSpan > 1);
    this.checkWeightInfo();
  }

  /**
   * @description 清除选中
   */
  clearSelectedCells() {
    this.selectedCells.forEach(item => {
      item.wasDuring = false;
      item.removeDuringClass();
    });
    if (this.toolBarInfo.canSplit) {
      this.mergedCellsList.forEach(item => {
        item.wasDuring = true;
        item.setDuringClass();
      });
      this.toolBarInfo.canSplit = false;
    }
    this.selectedCells = [];
    this.selectedCells = [];
    this.beginCellInfo = null;
    this.dragInfo = {
      sR: null,
      eR: null,
      sC: null,
      eC: null
    };
  }

  /**
   * @description 检测单元格是否在覆盖范围内
   */
  checkCellDuringStatus() {
    this.allCells.forEach(item => {
      item.forEach(cItem => {
        if (cItem.row <= this.dragInfo.eR && cItem.col <= this.dragInfo.eC && cItem.row >= this.dragInfo.sR && cItem.col >= this.dragInfo.sC) {
          cItem.setDuringClass();
        } else {
          cItem.removeDuringClass();
        }
      });
    });
  }

  /**
   * @description 设置默认表格
   * @param row 行数
   * @param col 列数
   * @return {Array}
   */
  setDefaultList(row, col) {
    row = row || 10;
    col = col || 10;
    this.allCells = [];
    for (let r = 0; r < row; r++) {
      const rowObj = [];
      for (let c = 0; c < col; c++) {
        const cellCon = new CellInfo(this, {}, new Date().getTime() + "_" + r + "_" + c, r, c, this.getColIndexInfo(c), r + 1);
        rowObj.push(cellCon);
      }
      this.allCells.push(rowObj);
    }
  }

  /**
   * @description 更改选中的单元格边框颜色
   * @param color
   */
  changeBorderColor(color) {
    this.reGetSelectedCells();
    this.selectedCells.forEach(item => {
      // 设置单元格基础边框颜色=>底部边框及右侧边框
      item.setBasicBorderColor(color);
      // 如果是第一行的，需要设置顶部边框样式
      if (item.row === 0) {
        item.setTopBorderColor(color);
      }
      // 如果不是第一行的，需要设置上一行的当前个单元格的底部边框样式
      if (item.row !== 0) {
        this.allCells[item.row - 1][item.col].setBottomBorderColor(color);
      }
      // 如果是第一列的，需要设置左侧边框样式
      if (item.col === 0) {
        item.setLeftBorderColor(color);
      }
      // 如果不是第一列的，需要设置上一列的当前个单元格的左侧边框样式
      if (item.col !== 0) {
        this.allCells[item.row][item.col - 1].setRightBorderColor(color);
      }
    });
  }

  /**
   * @description 更改选中的单元格边框宽度
   * @param width
   */
  changeBorderWidth(width) {
    this.reGetSelectedCells();
    this.selectedCells.forEach(item => {
      // 设置单元格基础边框颜色=>底部边框及右侧边框
      item.setBasicBorderWidth(width);
      // 如果是第一行的，需要设置顶部边框样式
      if (item.row === 0) {
        item.setTopBorderWidth(width);
      }
      // 如果不是第一行的，需要设置上一行的当前个单元格的底部边框样式
      if (item.row !== 0) {
        this.allCells[item.row - 1][item.col].setBottomBorderWidth(width);
      }
      // 如果是第一列的，需要设置左侧边框样式
      if (item.col === 0) {
        item.setLeftBorderWidth(width);
      }
      // 如果不是第一列的，需要设置上一列的当前个单元格的左侧边框样式
      if (item.col !== 0) {
        this.allCells[item.row][item.col - 1].setRightBorderWidth(width);
      }
    });
  }

  /**
   * @description 将单元格边框设置成无边框
   */
  changeNoBorderInfo() {
    const borderColor = "#e8ecf2";
    const borderWidth = "1px";
    const borderType = "isDashed";
    this.reGetSelectedCells();
    this.selectedCells.forEach(item => {
      item.setBasicBorderWidth(borderWidth);
      item.setBasicBorderColor(borderColor);
      item.setBasicBorderStyle(borderType);
      // 如果是第一行的，需要设置顶部边框样式
      if (item.row === 0) {
        item.setTopBorderWidth(borderWidth);
        item.setTopBorderColor(borderColor);
        item.setTopBorderStyle(borderType);
      }
      // 如果不是第一行的，需要设置上一行的当前个单元格的底部边框样式
      if (item.row !== 0) {
        this.allCells[item.row - 1][item.col].setBottomBorderWidth(borderWidth);
        this.allCells[item.row - 1][item.col].setBottomBorderColor(borderColor);
        this.allCells[item.row - 1][item.col].setBottomBorderStyle(borderType);
      }
      // 如果是第一列的，需要设置左侧边框样式
      if (item.col === 0) {
        item.setLeftBorderWidth(borderWidth);
        item.setLeftBorderColor(borderColor);
        item.setLeftBorderStyle(borderType);
      }
      // 如果不是第一列的，需要设置当前行的前一列的边框样式
      if (item.col !== 0) {
        this.allCells[item.row][item.col - 1].setRightBorderWidth(borderWidth);
        this.allCells[item.row][item.col - 1].setRightBorderColor(borderColor);
        this.allCells[item.row][item.col - 1].setRightBorderStyle(borderType);
      }
    });
  }

  /**
   * @description 将单元格设置成所有边框
   */
  changeAllBorderInfo() {
    const borderColor = "#000000";
    const borderWidth = "1px";
    const borderType = "isSolid";
    this.reGetSelectedCells();
    this.selectedCells.forEach(item => {
      item.setBasicBorderWidth(borderWidth);
      item.setBasicBorderColor(borderColor);
      item.setBasicBorderStyle(borderType);
      // 如果是第一行的，需要设置顶部边框样式
      if (item.row === 0) {
        item.setTopBorderWidth(borderWidth);
        item.setTopBorderColor(borderColor);
        item.setTopBorderStyle(borderType);
      }
      // 如果不是第一行的，需要设置上一行的当前个单元格的底部边框样式
      if (item.row !== 0) {
        this.allCells[item.row - 1][item.col].setBottomBorderWidth(borderWidth);
        this.allCells[item.row - 1][item.col].setBottomBorderColor(borderColor);
        this.allCells[item.row - 1][item.col].setBottomBorderStyle(borderType);
      }
      // 如果是第一列的，需要设置左侧边框样式
      if (item.col === 0) {
        item.setLeftBorderWidth(borderWidth);
        item.setLeftBorderColor(borderColor);
        item.setLeftBorderStyle(borderType);
      }
      // 如果不是第一列的，需要设置当前行的前一列的边框样式
      if (item.col !== 0) {
        this.allCells[item.row][item.col - 1].setRightBorderWidth(borderWidth);
        this.allCells[item.row][item.col - 1].setRightBorderColor(borderColor);
        this.allCells[item.row][item.col - 1].setRightBorderStyle(borderType);
      }
    });
  }

  /**
   * @description 将单元格设置成实线边框
   * @param type
   */
  changeSolidBorderInfo(type) {
    const borderWidth = type === "isSolidBorder" ? "2px" : "1px";
    const borderType = "isSolid";
    this.reGetSelectedCells();
    this.changeBorderWidth(borderWidth);
    this.selectedCells.forEach(item => {
      item.setBasicBorderStyle(borderType);
      // 如果是第一行的，需要设置顶部边框样式
      if (item.row === 0) {
        item.setTopBorderStyle(borderType);
      }
      // 如果不是第一行的，需要设置上一行的当前个单元格的底部边框样式
      if (item.row !== 0) {
        this.allCells[item.row - 1][item.col].setBottomBorderStyle(borderType);
      }
      // 如果是第一列的，需要设置左侧边框样式
      if (item.col === 0) {
        item.setLeftBorderStyle(borderType);
      }
      // 如果不是第一列的，需要设置当前行的前一列的边框样式
      if (item.col !== 0) {
        this.allCells[item.row][item.col - 1].setRightBorderStyle(borderType);
      }
    });
  }

  /**
   * @description 将单元格设置成实线边框
   */
  changeDashedBorderInfo() {
    const borderWidth = "1px";
    const borderType = "isDashed";
    this.reGetSelectedCells();
    this.changeBorderWidth(borderWidth);
    this.selectedCells.forEach(item => {
      item.setBasicBorderStyle(borderType);
      // 如果是第一行的，需要设置顶部边框样式
      if (item.row === 0) {
        item.setTopBorderStyle(borderType);
      }
      // 如果不是第一行的，需要设置上一行的当前个单元格的底部边框样式
      if (item.row !== 0) {
        this.allCells[item.row - 1][item.col].setBottomBorderStyle(borderType);
      }
      // 如果是第一列的，需要设置左侧边框样式
      if (item.col === 0) {
        item.setLeftBorderStyle(borderType);
      }
      // 如果不是第一列的，需要设置当前行的前一列的边框样式
      if (item.col !== 0) {
        this.allCells[item.row][item.col - 1].setRightBorderStyle(borderType);
      }
    });
  }

  /**
   * @description 更改单元格对齐方式
   * @param align
   */
  changeCellTextAlign(align) {
    this.reGetSelectedCells();
    this.selectedCells.forEach(item => {
      item.setTextAlign(align);
    });
  }

  /**
   * @description 更改单元格字体加粗属性
   */
  changeCellFontWeight() {
    this.reGetSelectedCells();
    this.selectedCells.forEach(item => {
      item.setFontWeight(!this.toolBarInfo.allWeight);
    });
  }

  /**
   * @description 设置行高
   * @param height
   */
  changeCellHeight(height) {
    this.reGetSelectedCells();
    this.allCells[this.selectedCells[0].row].forEach(item => {
      item.setCellHeight(height);
    });
  }

  /**
   * @description 更改单元格背景颜色
   * @param color
   */
  changeCellBg(color) {
    this.reGetSelectedCells();
    this.selectedCells.forEach(item => {
      item.setBackGround(color);
    });
  }

  /**
   * @description 更改单元格字体颜色
   * @param color
   */
  changeCellFontColor(color) {
    this.reGetSelectedCells();
    this.selectedCells.forEach(item => {
      item.setFontColor(color);
    });
  }

  /**
   * @description 更改单元格列宽
   * @param width
   */
  changeCellWidth(width) {
    this.reGetSelectedCells();
    this.selectedCells.forEach(item => {
      item.setCellWidth(width);
    });
  }

  /**
   * @description 清除单元格样式
   */
  clearStyleInfo() {
    this.allCells.forEach(item => {
      item.forEach(cItem => {
        cItem.clearCellStyleInfo();
      });
    });
  }

  /**
   * @description 拖动选择单元格，进行单元格的数据信息记录
   * @param cellData 单元格信息
   * @param getSelected 是否需要获取选中的单元格
   */
  setCellPositionInfo(cellData, getSelected) {
    // 如果不存在起始单元格数据信息
    if (this.dragInfo.sR === null && this.dragInfo.sC === null) {
      this.dragInfo.sR = cellData.row;
      this.dragInfo.sC = cellData.col;
      this.dragInfo.eR = cellData.row;
      this.dragInfo.eC = cellData.col;
      this.startCellInfo = {
        row: cellData.row,
        col: cellData.col
      };
    } else {
      this.checkPositionInfo(cellData);
      if (getSelected) {
        this.reGetSelectedCells();
      }
    }
  }

  /**
   * @description 检测拖到的单元格的坐标体系
   * @param cellData
   */
  checkPositionInfo(cellData) {
    const cRow = cellData.row + cellData.getCellRowSpan - 1;
    const cCol = cellData.col + cellData.getCellColSpan - 1;
    // 判断拖动的方向，以起点为准心
    // 如果是向下拖动
    if (cRow > this.startCellInfo.row) {
      this.dragInfo.eR = cRow;
      // 向右拖动
      if (cCol > this.startCellInfo.col) {
        this.dragInfo.eC = cCol;
        // 向左拖动
      } else {
        this.dragInfo.eC = this.startCellInfo.col;
        this.dragInfo.sC = cCol;
      }
      // 向上拖动
    } else {
      this.dragInfo.sR = cRow;
      this.dragInfo.eR = this.startCellInfo.row;
      // 向右拖动
      if (cCol > this.startCellInfo.col) {
        this.dragInfo.eC = cCol;
        // 向左拖动
      } else {
        this.dragInfo.eC = this.startCellInfo.col;
        this.dragInfo.sC = cCol;
      }
    }
    // 如果存在合并的单元格，需要检测坐标是否会发生碰撞
    if (this.mergedCells.length > 0) {
      this.checkMergeCellInfo();
      // 否则直接执行单元格状态变更
    } else {
      this.checkCellDuringStatus();
    }
  }

  /**
   * @description 执行拖拽时与合并单元格的碰撞检查
   */
  checkMergeCellInfo() {
    // 暂存之前的起始行列坐标信息
    const minR = this.dragInfo.sR;
    const maxR = this.dragInfo.eR;
    const minC = this.dragInfo.sC;
    const maxC = this.dragInfo.eC;
    // 遍历合并的单元格是否有影响到此拖拽情况的
    this.mergedCells.forEach(item => {
      // 合并单元格的起点行
      const minRow = item.row;
      // 合并单元格的终点行
      const maxRow = item.row + item.getCellRowSpan - 1;
      // 合并单元格的起点列
      const minCol = item.col;
      // 合并单元格的终点列
      const maxCol = item.col + item.getCellColSpan - 1;
      // 如果行列都在范围内
      if (maxC >= minCol && minC <= maxCol && maxR >= minRow && minR <= maxRow) {
        // 如果终点的行小于合并单元格的终点行
        if (maxR < maxRow) {
          // 重置终点行
          this.dragInfo.eR = maxRow;
        }
        // 如果起点的行大于合并单元格的起点行
        if (minR > minRow) {
          // 重置起点行
          this.dragInfo.sR = minRow;
        }
        // 如果起点的列大于合并单元格的起点列
        if (minC > minCol) {
          // 重置起点裂
          this.dragInfo.sC = minCol;
        }
        // 如果终点的列小于合并单元格的终点列
        if (maxC < maxCol) {
          // 重置终点列
          this.dragInfo.eC = maxCol;
        }
      }
    });
    // 如果存在坐标被修改过，那么说明坐标还未确定，需要重新执行一次
    if (this.dragInfo.sC !== minC || this.dragInfo.eC !== maxC || this.dragInfo.eR !== maxR || this.dragInfo.sR !== minR) {
      this.checkMergeCellInfo();
      // 坐标确认之后，执行单元格的状态变更
    } else {
      this.checkCellDuringStatus();
    }
  }

  /**
   * @description 执行选中的单元格合并
   */
  mergeCellOp() {
    this.reGetSelectedCells();
    let rSpan = this.beginCellInfo.getCellRowSpan;
    let cSpan = this.beginCellInfo.getCellColSpan;
    this.selectedCells.forEach(item => {
      // 同行的计算出对应的列
      if (item.row === this.beginCellInfo.row && item.col !== this.beginCellInfo.col) {
        cSpan += item.getCellColSpan;
      }
      // 同列的计算出对应的行
      if (item.row !== this.beginCellInfo.row && item.col === this.beginCellInfo.col) {
        rSpan += item.getCellRowSpan;
      }
      // 不是开始的单元格，行列值都设置为0
      if (item !== this.beginCellInfo) {
        item.setCellColSpan(0);
        item.setCellRowSpan(0);
      }
    });
    this.beginCellInfo.setCellRowSpan(rSpan);
    this.beginCellInfo.setCellColSpan(cSpan);
    // 存储合并的单元格信息
    this.mergedCells.push(this.beginCellInfo);
  }

  /**
   * @description 执行选中的单元格拆分
   */
  splitCellOp() {
    this.mergedCellsList.forEach(item => {
      item.setCellRowSpan(1);
      item.setCellColSpan(1);
    });
  }

  /**
   * @description 记录右键菜单点击的数据信息
   * @param data
   */
  setMenuDataInfo(data) {
    this.menuData = data;
  }

  /**
   * @description 在点击的行上方插入指定行
   * @param num
   */
  insertBeforeRow(num) {
    this.insertRowInfoOp(num);
  }

  /**
   * @description 在点击的行下方插入指定行
   * @param num
   */
  insertAfterRow(num) {
    this.insertRowInfoOp(num, true);
  }

  /**
   * @description 插入行处理
   * @param num
   * @param isDown
   */
  insertRowInfoOp(num, isDown) {
    if (this.mergedCells.length !== 0) {
      this.checkInsertRowEffect(num, isDown);
    } else {
      this.insertFullRow(num, isDown);
    }
    this.resetCellPositionInfo(num);
  }

  /**
   * @description 插入一整行
   * @param num
   * @param isDown
   */
  insertFullRow(num, isDown) {
    for (let n = 0; n < num; n++) {
      const newRow = this.produceNewRow();
      const r = this.menuData.row;
      this.allCells.splice(isDown ? r + 1 : r, 0, newRow);
    }
  }

  /**
   * @description 检查插入行时，合并单元格对其的影响
   * @param num
   * @param isDown
   */
  checkInsertRowEffect(num, isDown) {
    const cRow = this.menuData.row;
    // 检测是否受影响
    let flag = false;
    this.mergedCells.forEach(item => {
      const minR = item.row;
      const rSpan = item.getCellRowSpan;
      const maxR = minR + rSpan - 1;
      // 受影响的需要处理
      if (!((isDown ? cRow >= maxR : cRow > maxR) || (isDown ? cRow < minR : cRow <= minR))) {
        item.setCellRowSpan(rSpan + num);
        flag = true;
      }
    });
    // 如果受影响，对行数据进行处理后再行塞入
    if (flag) {
      this.insertOpRow(num, isDown);
      // 都不受影响的，直接进行塞入
    } else {
      this.insertFullRow(num, isDown);
    }
  }

  /**
   * @description 插入处理后的行数据信息
   * @param num
   * @param isDown
   */
  insertOpRow(num, isDown) {
    const cRow = isDown ? this.menuData.row + 1 : this.menuData.row - 1;
    const cIndex = isDown ? this.menuData.row + 1 : this.menuData.row;
    for (let i = 0; i < num; i++) {
      const newRow = this.produceNewRow();
      this.allCells[cRow].forEach((item, index) => {
        if (parseInt(item.getCellRowSpan, 10) !== 1) {
          newRow[index].setCellRowSpan(0);
          newRow[index].setCellColSpan(0);
        }
      });
      this.allCells.splice(cIndex, 0, newRow);
    }
  }

  /**
   * @description 生成新的一行单元格
   * @return {Array}
   */
  produceNewRow() {
    const colNum = this.allCells[0].length;
    const newRow = [];
    for (let i = 0; i < colNum; i++) {
      const cell = new CellInfo(this, {}, new Date().getTime() + "_" + i, +"_" + i, i, i);
      const sameCell = this.allCells[this.menuData.row][i];
      // 样式设置
      this.setRowCellStyleInfo(cell, sameCell);
      newRow.push(cell);
    }
    return newRow;
  }

  /**
   * @description 同步单元格样式
   * @param cell
   * @param sameCell
   */
  setRowCellStyleInfo(cell, sameCell) {
    cell.setLeftBorderColor(sameCell.getLeftBorderColor);
    cell.setLeftBorderStyle(sameCell.getLeftBorderStyle);
    cell.setLeftBorderWidth(sameCell.getLeftBorderWidth);
    cell.setRightBorderColor(sameCell.getRightBorderColor);
    cell.setRightBorderStyle(sameCell.getRightBorderStyle);
    cell.setRightBorderWidth(sameCell.getRightBorderWidth);
    cell.setBottomBorderColor(sameCell.getBottomBorderColor);
    cell.setBottomBorderStyle(sameCell.getBottomBorderStyle);
    cell.setBottomBorderWidth(sameCell.getBottomBorderWidth);
  }

  /**
   * @description 左侧插入列
   * @param num
   */
  insertBeforeCol(num) {
    this.insertColInfoOp(num, true);
  }

  /**
   * @description 右侧插入列
   * @param num
   */
  insertAfterCol(num) {
    this.insertColInfoOp(num);
  }

  /**
   * @description 插入列处理
   * @param num
   * @param isLeft
   */
  insertColInfoOp(num, isLeft) {
    // 如果不存在合并的单元格，直接进行插入
    if (this.mergedCells.length === 0) {
      this.insertFullCol(num, isLeft);
    } else {
      this.checkInsertColEffect(num, isLeft);
    }
    this.resetCellPositionInfo();
  }

  /**
   * @description 在插入列前检测是否收到合并单元格的影响
   * @param num
   * @param isLeft
   */
  checkInsertColEffect(num, isLeft) {
    const cCol = this.menuData.col;
    let flag = false;
    this.mergedCells.forEach(item => {
      const minC = item.col;
      const cSpan = item.getCellColSpan;
      const maxC = minC + cSpan - 1;
      if (((isLeft ? cCol <= maxC : cCol < maxC) && cCol > minC) || ((isLeft ? cCol > minC : cCol >= minC) && cCol < maxC)) {
        flag = true;
        item.setCellColSpan(cSpan + num);
      }
    });
    // 如果插入列影响到了合并的单元格，需要进行数据处理后再行插入
    if (flag) {
      this.insertOpCol(num, isLeft);
      // 否则直接插入一整列单元格即可
    } else {
      this.insertFullCol(num, isLeft);
    }
  }

  /**
   * @description 插入处理后的列数据信息
   * @param num
   * @param isLeft
   */
  insertOpCol(num, isLeft) {
    const cCol = this.menuData.col;
    const nCol = isLeft ? cCol : cCol + 1;
    this.allCells.forEach(item => {
      let flag = true;
      if (item[nCol].getCellColSpan !== 1) {
        flag = false;
      }
      // 每行都插入单元格
      for (let i = 0; i < num; i++) {
        const newCell = new CellInfo(this, {}, new Date().getTime(), null, null);
        newCell.setCellColSpan(flag ? 1 : 0);
        newCell.setCellRowSpan(flag ? 1 : 0);
        item.splice(nCol, 0, newCell);
      }
    });
  }

  /**
   * @description 插入一整列
   * @param num
   * @param isLeft
   */
  insertFullCol(num, isLeft) {
    const cCol = isLeft ? this.menuData.col : this.menuData.col + 1;
    for (let i = 0; i < num; i++) {
      this.allCells.forEach(item => {
        const sameCol = item[this.menuData.col];
        const newCol = new CellInfo(this, {}, new Date().getTime(), null, null);
        this.setColCellStyleInfo(newCol, sameCol);
        item.splice(cCol, 0, newCol);
      });
    }
  }

  /**
   * @description 设置单元格样式信息
   * @param cell
   * @param sameCell
   */
  setColCellStyleInfo(cell, sameCell) {
    cell.setLeftBorderColor(sameCell.getLeftBorderColor);
    cell.setLeftBorderStyle(sameCell.getLeftBorderStyle);
    cell.setLeftBorderWidth(sameCell.getLeftBorderWidth);
    cell.setBottomBorderColor(sameCell.getBottomBorderColor);
    cell.setBottomBorderStyle(sameCell.getBottomBorderStyle);
    cell.setBottomBorderWidth(sameCell.getBottomBorderWidth);
    cell.setRightBorderColor(sameCell.getRightBorderColor);
    cell.setRightBorderStyle(sameCell.getRightBorderStyle);
    cell.setRightBorderWidth(sameCell.getRightBorderWidth);
    cell.setTopBorderColor(sameCell.getTopBorderColor);
    cell.setTopBorderStyle(sameCell.getTopBorderStyle);
    cell.setTopBorderWidth(sameCell.getTopBorderWidth);
  }

  /**
   * @description 删除指定的一行
   */
  deleteSingleRow() {
    if (this.mergedCells.length === 0) {
      this.allCells.splice(this.menuData.row, 1);
    } else {
      let flag = true;
      this.allCells[this.menuData.row].forEach(item => {
        if (item.getCellRowSpan !== 1) {
          flag = false;
        }
      });
      // 如果当前行的单元格删除不受合并单元格的影响，直接进行删除
      if (flag) {
        this.allCells.splice(this.menuData.row, 1);
      } else {
        this.checkDeleteRowEffect();
      }
    }
    this.resetCellPositionInfo();
  }

  /**
   * @description 检测删除行时受影响的合并单元格
   * @description 并将受影响的单元格的行合并值-1
   */
  checkDeleteRowEffect() {
    const cRow = this.menuData.row;
    this.mergedCells.forEach(item => {
      const minR = item.row;
      const rSpan = item.getCellRowSpan;
      const cSpan = item.getCellColSpan;
      const maxR = minR + rSpan - 1;
      // 如果删除的行不是当前合并单元格所在的行
      if (cRow > minR && cRow <= maxR) {
        // 先将此单元格的行合并值-1
        item.setCellRowSpan(rSpan - 1);
        // 如果删除的行是当前合并单元格所在的行
      } else if (cRow >= minR && cRow <= maxR) {
        // 找到下一行的当前单元格
        const nCell = this.allCells[cRow - 1][item.col];
        // 设置下一行的单元格的行列合并值为当前单元格的行列合并值-1
        nCell.setCellRowSpan(rSpan - 1);
        nCell.setCellColSpan(cSpan - 1);
      }
    });
    // 最后删除对应点击的行单元格数据信息
    this.allCells.splice(cRow, 1);
  }

  /**
   * @description 删除指定的一列
   */
  deleteSingleCol() {
    if (this.mergedCells.length === 0) {
      this.allCells.forEach(item => {
        item.splice(this.menuData.col, 1);
      });
    } else {
      this.checkDeleteColEffect();
      // 处理未受影响的列
      this.allCells.forEach(item => {
        const colSpan = item[this.menuData.col].getCellColSpan;
        if (colSpan === 1) {
          item.splice(this.menuData.col, 1);
        }
      });
    }
    this.resetCellPositionInfo();
  }

  /**
   * @description 检测删除列时受影响的合并单元格
   * @description 并将受影响的单元格的列合并值-1
   */
  checkDeleteColEffect() {
    this.mergedCells.forEach((item, index) => {
      const cCol = this.menuData.col;
      const colSpan = item.getCellColSpan;
      const minCol = item.col;
      const maxCol = minCol + colSpan - 1;
      if (cCol >= minCol && cCol <= maxCol) {
        this.mergedCells.split(index, 1);
      }
    });
  }

  /**
   * @description 重置单元格的行坐标信息
   */
  resetCellPositionInfo() {
    this.allCells.forEach((item, index) => {
      item.forEach((cItem, cIndex) => {
        cItem.setRowLineNum(index);
        cItem.setColLineNum(cIndex);
        cItem.setCellRowIndex(index + 1);
        cItem.setCellColIndex(this.getColIndexInfo(cIndex));
        cItem.id = new Date().getTime() + "_" + index + "_" + cIndex;
      });
    });
    this.config.row = this.allCells.length;
    this.config.col = this.allCells[0].length;
    this.clearSelectedCells();
  }

  /**
   * @description 检测是否为加粗状态
   * @description 如果是多个单元格，只要一个不是就不是
   */
  checkWeightInfo() {
    let flag = true;
    this.selectedCells.forEach(item => {
      if (!item.style.fontWeight) {
        flag = false;
      }
    });
    this.toolBarInfo.allWeight = flag;
  }

  get getColNum() {
    return this.config.col;
  }
}
