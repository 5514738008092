<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-08-09 14:37:36
 * @LastEditTime: 2020-04-28 17:42:23
 -->
<template>
  <div class="notification-dialog">
    <en-dialog
      en-fullscreen
      title="通知内容"
      class="notification-content"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body
      @close="close"
    >
      <div class="content">
        <div class="middle">
          <div class="clear">
            <en-button
              type="text"
              color="blue"
              class="clear-btn"
              @click="clearItem"
            >
              清空
            </en-button>
          </div>
          <en-text-config
            ref="enTextConfig"
            :initialCode="operatingData.code"
            :initialFields="operatingData.fields"
          ></en-text-config>
        </div>

        <div class="bottom">
          <div class="item">
            <div
              class="name"
              :class="{ 'item-select': currrentSelectRule === 1 }"
              @click="switchCurrentSelectRule(1)"
            >
              业务字段
            </div>
            <div v-if="currrentSelectRule === 1" class="item-right">
              <en-select
                v-model="selectColumn"
                placeholder="请选择"
                class="flex-item"
                :options="options"
                value-key="id"
                :default-props="{ label: 'name', value: null }"
                @click-option="handleSelectChange"
              >
              </en-select>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <en-button @click="save">
          确定
        </en-button>
      </div>
    </en-dialog>
  </div>
</template>
<script>
import deepcopy from "deepcopy";
export default {
  name: "NotificationContent",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    datas: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },

  data() {
    return {
      currrentSelectRule: 1,
      // 默认的字段
      options: [
        {
          id: "operatorId",
          fieldNameVariable: "operatorId",
          name: "操作人",
          fieldName: "操作人",
          text: "操作人",
          fieldType: "5",
          area: "OTHER",
          selectable: true,
          flg: 1
        },
        {
          id: "operatorDept",
          fieldNameVariable: "operatorDept",
          name: "操作人部门",
          fieldName: "操作人部门",
          text: "操作人部门",
          fieldType: "5",
          area: "OTHER",
          selectable: true,
          flg: 1
        },
        {
          id: "operatorTime",
          fieldNameVariable: "operatorTime",
          name: "操作时间",
          fieldName: "操作时间",
          text: "操作时间",
          fieldType: "7",
          area: "OTHER",
          selectable: true,
          flg: 1
        }
      ],
      // 用户填的东西
      userDefined: "",
      // 用户选的字段
      selectColumn: [],
      operatingData: {}
    };
  },
  computed: {},
  mounted() {
    this.operatingData = deepcopy(this.datas);
  },
  methods: {
    // 保存 = 确定
    save() {
      const saveObject = this.$refs.enTextConfig.parsingEditor();
      if (saveObject.code.length === 0) {
        // 什么也没填
        this.$message("请设置通知内容");
        return;
      }
      this.$emit("update:datas", saveObject);
      this.close();
    },
    // 关闭弹框
    close() {
      this.$emit("update:dialog-visible");
    },
    // 删除所有填充的
    clearItem() {
      this.$refs.enTextConfig.clear();
    },
    // 切换选项
    switchCurrentSelectRule(value) {
      this.currrentSelectRule = value;
    },
    // 添加
    handleSelectChange() {
      switch (this.currrentSelectRule) {
        case 1: {
          if (this.selectColumn.length === 0) {
            this.$message("请选择业务字段");
            return;
          }
          // // 模板字段
          // let fieldName = this.selectColumn.map(el => el.fieldName).reduce((a,b)=>{
          //   return `${a}+${b}`;
          // });
          // let fieldNameVariable = this.selectColumn.map(el => el.fieldNameVariable).reduce((a,b)=>{
          //   return  `${a}_${b}`;
          // },);
          // this.operatingData.push({
          //   fieldName,fieldNameVariable,name:fieldName
          // });
          // this.$refs
          this.$refs.enTextConfig.addField({
            name: this.selectColumn.fieldName
          });
          break;
        }
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.notification-content {
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .middle {
      width: 100%;
      border: 1px solid rgba(232, 236, 242, 1);
      border-radius: 4px;
      //
      flex-basis: 70%;
      flex-shrink: 1;
      overflow-y: auto;
      .clear {
        width: 100%;
        height: 40px;
        background: rgba(245, 247, 250, 1);
        padding-right: 12px;
        .clear-btn {
          float: right;
          height: 40px;
        }
      }
      .en-text-config {
        height: calc(100% - 40px);
      }
    }
    .bottom {
      flex-shrink: 0;
      padding: 5px 13px;
      width: 100%;
      flex-basis: 30%;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(220, 229, 236, 1);
      border-radius: 4px;
      margin-top: 11px;
      .item {
        display: flex;
        margin-top: 12px;
        .name {
          height: 32px;
          line-height: 32px;
          flex-basis: 100px;
          font-size: 12px;
          text-align: center;
          border: 1px solid rgba(220, 229, 236, 1);
          border-radius: 4px;
        }
        .item-select {
          background: rgba(70, 148, 223, 1);
          color: hsl(0, 0%, 100%);
        }
        .item-right {
          flex: 1;
          margin-left: 12px;
          display: flex;
          .flex-item {
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
