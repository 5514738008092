/*
 * @Author: Fenngding
 * @Date: 2019-08-08 11:07:35
 * @LastEditors: Fengding
 * @LastEditTime: 2019-08-08 11:07:35
 * @Email: fenngding@live.com
 * @Description:
 */
import Vue from "vue";
import enResult from "./container";
const isServer = Vue.prototype.$isServer;
const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g;
const MOZ_HACK_REGEXP = /^moz([A-Z])/;
const ieVersion = isServer ? 0 : Number(document.documentMode);
const camelCase = function(name) {
  return name.replace(SPECIAL_CHARS_REGEXP, function(_, separator, letter, offset) {
    return offset ? letter.toUpperCase() : letter;
  }).replace(MOZ_HACK_REGEXP, "Moz$1");
};
// 获取样式
const getStyle = ieVersion < 9 ? function(element, styleName) {
  if (isServer) {
    return;
  }
  if (!element || !styleName) {
    return null;
  }
  styleName = camelCase(styleName);
  if (styleName === "float") {
    styleName = "styleFloat";
  }
  try {
    switch (styleName) {
      case "opacity":
        try {
          return element.filters.item("alpha").opacity / 100;
        } catch (e) {
          return 1.0;
        }
      default:
        return (element.style[styleName] || element.currentStyle ? element.currentStyle[styleName] : null);
    }
  } catch (e) {
    return element.style[styleName];
  }
} : function(element, styleName) {
  if (isServer) {
    return;
  }
  if (!element || !styleName) {
    return null;
  }
  styleName = camelCase(styleName);
  if (styleName === "float") {
    styleName = "cssFloat";
  }
  try {
    var computed = document.defaultView.getComputedStyle(element, "");
    return element.style[styleName] || computed ? computed[styleName] : null;
  } catch (e) {
    return element.style[styleName];
  }
};

/* istanbul ignore next */
const setStyle = (element, styleName, value) => {
  if (!element || !styleName) {
    return;
  }

  if (typeof styleName === "object") {
    for (var prop in styleName) {
      if (styleName.hasOwnProperty(prop)) {
        setStyle(element, prop, styleName[prop]);
      }
    }
  } else {
    styleName = camelCase(styleName);
    if (styleName === "opacity" && ieVersion < 9) {
      element.style.filter = isNaN(value) ? "" : "alpha(opacity=" + value * 100 + ")";
    } else {
      element.style[styleName] = value;
    }
  }
};
// 插入Loading
const insertDom = (el, value) => {
  const { type, ...others } = value || {};
  if (!el.domInserted) {
    el.instance.status = true;
    const radius = getStyle(el, "border-radius");
    setStyle(el.mask, "border-radius", radius);
    el.appendChild(el.mask);
    el.instance.type = type;
    el.instance.loadingConfig = others;
    el.domInserted = true;
  } else {
    el.instance.status = true;
    el.instance.type = type;
    el.instance.loadingConfig = others;
  }
};

const toggleLoading = (el, binding) => {
  // debugger
  let bingingValue = binding.value || {};

  switch (typeof bingingValue) {
    case "boolean":// fasle进不来
    case "number":// 0 进不来
      bingingValue = { type: "loading" };
      break;
    case "string":// null undefined 空字符串 进不来
      bingingValue = { type: bingingValue };
      break;
  }

  if (bingingValue.type) {
    Vue.nextTick(() => {
      insertDom(el, bingingValue);
    });
  } else {
    el.instance.status = false;
    el.instance.type = null;
  }
};

const Mask = Vue.extend(enResult);
export default {
  name: "en-loading",
  bind: function(el, binding) {
    /**
     * 这里把Loading组件实例挂载到el上，然后再把el传参到toggleLoading中判断
     */
    const mask = new Mask({
      el: document.createElement("div")
      // data: { hello:1 }
    });
    el.instance = mask;
    el.mask = mask.$el;
    el.style.position = "relative";
    binding.value && toggleLoading(el, binding);
  },
  update: function(el, binding) {
    toggleLoading(el, binding);
  },
  unbind: function(el, binding) {
    if (el.domInserted) {
      el.mask &&
      el.mask.parentNode &&
      el.mask.parentNode.removeChild(el.mask);
    }
    el.instance && el.instance.$destroy();
  }
};
