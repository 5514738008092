/*
 * @Description:
 * @Author:
 * @LastEditors: Please set LastEditors
 * @Date: 2019-09-19 09:39:10
 * @LastEditTime: 2020-03-18 11:31:38
 */
// import "babel-polyfill";
import { microSync } from "./masterSlave";
import thistype from "thistype";
import PopupManager from "element-ui/lib/utils/popup/popup-manager";

const $tools = {}; // 全局工具函数
const $headerFiles = {}; // 全局头文件
/**
 * 注册全局组件
 *
 * @param {*} Vue
 */
function registerComponents(Vue) {
  const requireComponent = require.context(
    // 其组件目录的相对路径
    "./components",
    // 是否查询其子目录
    true,
    // 匹配基础组件文件名的正则表达式
    /en-[A-Za-z\-]*\/index.js$/
  );
  requireComponent.keys().forEach(fileName => {
    // 获取组件配置
    const componentConfig = requireComponent(fileName);
    const componentContext = componentConfig.default || componentConfig;
    // 全局注册组件
    if (thistype(componentContext, "array")) {
      componentContext.forEach(component => Vue.use(component));
    } else {
      Vue.use(componentContext);
    }
  });
}

/**
 * 引入高阶组件
 *
 * @param {*} Vue
 */
function registerAdvanceComponents(Vue) {
  const requireComponent = require.context(
    // 其组件目录的相对路径
    "./advanceComponents",
    // 是否查询其子目录
    true,
    // 匹配基础组件文件名的正则表达式
    /en-[A-Za-z\-]*\/index.js$/
  );
  requireComponent.keys().forEach(fileName => {
    // 获取组件配置
    const componentConfig = requireComponent(fileName);
    // 获取组件配置
    const componentContext = componentConfig.default || componentConfig;
    // 全局注册组件
    Vue.use(componentContext);
  });
}

/**
 * 注册mixin
 *
 * @param {*} Vue
 */
function registerMixins(Vue) {
  const requireMixin = require.context(
    // mixins目录的相对路径
    "./mixins",
    // 是否查询其子目录
    true,
    // 匹配基础混入文件名的正则表达式
    /en-[A-Za-z\-]*\/index.js$/
  );
  requireMixin.keys().forEach(fileName => {
    // 获取mixin配置
    const mixinConfig = requireMixin(fileName);
    // 如果这个注入选项是通过 `export default` 导出的，
    // 那么就会优先使用 `.default`，
    // 否则回退到使用模块的根。
    const mixinContext = mixinConfig.default || mixinConfig;
    // 全局注册mixin
    Vue.mixin(mixinContext);
  });
}

/**
 * 注册指令
 *
 * @param {*} Vue
 */
function registerDirectives(Vue) {
  const requireDirectives = require.context(
    // 指令目录的相对路径
    "./directives",
    // 是否查询其子目录
    true,
    // 匹配基础指令文件名的正则表达式
    /en-[A-Za-z\-]*\/index.js$/
  );
  requireDirectives.keys().forEach(fileName => {
    // 获取指令配置
    const directiveConfig = requireDirectives(fileName);
    // const directiveName = fileName.replace(/^\.\/(.*)\/index\.\w+$/, "$1");
    // 如果这个注入选项是通过 `export default` 导出的，
    // 那么就会优先使用 `.default`，
    // 否则回退到使用模块的根。
    const directiveContext = directiveConfig.default || directiveConfig;
    // 全局注册指令
    // Vue.directive(directiveName, directiveContext);
    Vue.use(directiveContext);
  });
}

/**
 * 注册全局工具方法
 *
 * @param {*} Vue
 */
function registerMethods(Vue) {
  const requireMethods = require.context(
    // 全局方法目录的相对路径
    "./methods",
    // 是否查询其子目录
    false,
    // 匹配基础方法文件名的正则表达式
    /.*\.js$/
  );
  requireMethods.keys().forEach(fileName => {
    // 获取工具方法配置
    const methodConfig = requireMethods(fileName);
    const methodName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1");
    // 如果这个注入选项是通过 `export default` 导出的，
    // 那么就会优先使用 `.default`，
    // 否则回退到使用模块的根。
    const methodContext = methodConfig.default || methodConfig;
    $tools[methodName] = methodContext;
  });
}

/**
 *注册头文件
 *
 * @param {*} Vue
 */
function registerHeaderFiles(Vue) {
  const requireHeaderFiles = require.context(
    // 全局头文件目录的相对路径
    "./headerFiles",
    // 是否查询其子目录
    false,
    // 匹配头文件名的正则表达式
    /.*\.js$/
  );
  requireHeaderFiles.keys().forEach(fileName => {
    // 获取头文件配置
    const headerFileConfig = requireHeaderFiles(fileName);
    const headerFileName = fileName.replace(/^\.\/(.*)\.\w+$/, "$1");
    // 如果这个注入选项是通过 `export default` 导出的，
    // 那么就会优先使用 `.default`，
    // 否则回退到使用模块的根。
    const headerFileContext = headerFileConfig.default || headerFileConfig;
    $headerFiles[headerFileName] = headerFileContext;
  });
}

const initZIndex = (attr) => {
  if (attr) {
    Object.defineProperty(PopupManager, "zIndex", attr);
  }
}

const install = function(Vue, opts = {}) {
  if (opts.enZIndex) {
    initZIndex(opts.enZIndex);
  }
  registerComponents(Vue);
  registerAdvanceComponents(Vue);
  registerMixins(Vue);
  registerDirectives(Vue);
  registerMethods(Vue);
  registerHeaderFiles(Vue);
  Vue.prototype.$pushState = function(model, path) {
    history.pushState({ path: `/${model}${path}` }, null, `/${model}${path}`);
  };
};

// 以下过程能在cdn的情况下直接使用,而不用Vue.use
// if (typeof window !== "undefined" && window.Vue) {
//   install(window.Vue);
// }

export default {
  install,
  microSync,
  $headerFiles,
  $tools,
  initZIndex
};
