/* eslint-disable */

import XEUtils from "xe-utils/methods/xe-utils"
import { Utils, DomTools } from "../../tools"

const getAllColumns = (columns) => {
  const result = []
  columns.forEach((column) => {
    if (column.visible) {
      if (column.children && column.children.length && column.children.some(column => column.visible)) {
        result.push(column)
        result.push.apply(result, getAllColumns(column.children))
      } else {
        result.push(column)
      }
    }
  })
  return result
}

const convertToRows = (originColumns) => {
  let maxLevel = 1
  const traverse = (column, parent) => {
    if (parent) {
      column.level = parent.level + 1
      if (maxLevel < column.level) {
        maxLevel = column.level
      }
    }
    if (column.children && column.children.length && column.children.some(column => column.visible)) {
      let colSpan = 0
      column.children.forEach((subColumn) => {
        if (subColumn.visible) {
          traverse(subColumn, column)
          colSpan += subColumn.colSpan
        }
      })
      column.colSpan = colSpan
    } else {
      column.colSpan = 1
    }
  }

  originColumns.forEach((column) => {
    column.level = 1
    traverse(column)
  })

  const rows = []
  for (let i = 0; i < maxLevel; i++) {
    rows.push([])
  }

  const allColumns = getAllColumns(originColumns)

  allColumns.forEach((column) => {
    if (column.children && column.children.length && column.children.some(column => column.visible)) {
      column.rowSpan = 1
    } else {
      column.rowSpan = maxLevel - column.level + 1
    }
    rows[column.level - 1].push(column)
  })

  return rows
}

export default {
  name: "VxeTableHeader",
  props: {
    tableData: Array,
    tableColumn: Array,
    visibleColumn: Array,
    collectColumn: Array,
    fixedColumn: Array,
    size: String,
    fixedType: String,
    isGroup: Boolean
  },
  data() {
    return { headerColumn: [] }
  },
  watch: {
    tableColumn() {
      this.uploadColumn()
    }
  },
  created() {
    this.uploadColumn()
  },
  mounted() {
    let { $parent: $table, $el, $refs, fixedType } = this
    let { elemStore } = $table
    let prefix = `${fixedType || "main"}-header-`
    elemStore[`${prefix}wrapper`] = $el
    elemStore[`${prefix}table`] = $refs.table
    elemStore[`${prefix}colgroup`] = $refs.colgroup
    elemStore[`${prefix}list`] = $refs.thead
    elemStore[`${prefix}x-space`] = $refs.xSpace
    elemStore[`${prefix}repair`] = $refs.repair
  },
  render(h) {
    let {
      _e,
      $parent: $table,
      fixedType,
      headerColumn,
      tableColumn,
      fixedColumn
    } = this
    let {
      $listeners: tableListeners,
      resizable,
      border : showBorder,
      enableColumnKey,
      headerRowClassName,
      headerCellClassName,
      headerCellStyle,
      showHeaderOverflow: allColumnHeaderOverflow,
      headerAlign: allHeaderAlign,
      align: allAlign,
      highlightCurrentColumn,
      mouseConfig = {},
      scrollXLoad,
      overflowX,
      getColumnIndex,
      dragConfig,
      headEndConfig
    } = $table
    // 横向滚动渲染
    // debugger
    if (scrollXLoad) {
      if (fixedType) {
        tableColumn = fixedColumn
      }
    }
    return h("div", { class: ["vxe-table--header-wrapper", fixedType ? `fixed-${fixedType}--wrapper` : "body--wrapper", headEndConfig ? "head-end-config-header" : ""] }, [
      fixedType ? _e() : h("div", {
        class: "vxe-body--x-space",
        ref: "xSpace"
      }),
      h("table", {
        class: "vxe-table--header",
        style: "table-layout: fixed;",
        attrs: {
          cellspacing: 0,
          cellpadding: 0,
          border: 0
        },
        ref: "table"
      }, [
        /**
         * 列宽
         */
        h("colgroup", { ref: "colgroup" }, tableColumn.map((column, columnIndex) => {
          return h("col", {
            attrs: { name: column.id },
            key: columnIndex
          })
        }).concat([h("col", { attrs: { name: "col-gutter" } })])),
        /**
         * 头部
         */
        h("thead", { ref: "thead" }, headerColumn.map((cols, $rowIndex) => {
          return h("tr", {
            class: [
              "vxe-header--row", headerRowClassName ? XEUtils.isFunction(headerRowClassName) ? headerRowClassName({
                $table,
                $rowIndex,
                fixed: fixedType
              }) : headerRowClassName : ""
            ]
          }, cols.map((column, $columnIndex) => {
            // column.id = "col_2";
            let { showHeaderOverflow, headerAlign, align } = column
            let isColGroup = column.children && column.children.length
            let fixedHiddenColumn = fixedType ? column.fixed !== fixedType && !isColGroup : column.fixed && overflowX
            let headOverflow = XEUtils.isUndefined(showHeaderOverflow) || XEUtils.isNull(showHeaderOverflow) ? allColumnHeaderOverflow : showHeaderOverflow
            let headAlign = headerAlign || align || allHeaderAlign || allAlign
            let showEllipsis = headOverflow === "ellipsis"
            let showTitle = headOverflow === "title"
            let showTooltip = headOverflow === true || headOverflow === "tooltip"
            let hasEllipsis = showTitle || showTooltip || showEllipsis
            let thOns = {}
            // 确保任何情况下 columnIndex 都精准指向真实列索引
            let columnIndex = getColumnIndex(column)
            if (showTitle || showTooltip) {
              thOns.mouseover = evnt => {
                if ($table._isResize) {
                  return
                }
                if (showTitle) {
                  DomTools.updateCellTitle(evnt)
                } else if (showTooltip) {
                  $table.triggerHeaderTooltipEvent(evnt, {
                    $table,
                    $rowIndex,
                    column,
                    columnIndex,
                    $columnIndex,
                    fixed: fixedType
                  })
                }
              }
            }
            if (showTooltip) {
              thOns.mouseout = evnt => {
                if ($table._isResize) {
                  return
                }
                $table.clostTooltip()
              }
            }
            thOns.mouseleave = evnt => {
              column.filterSortStore.tooltipVisible = false;
            }
            thOns.click = evnt => {
              if (highlightCurrentColumn || tableListeners["header-cell-click"] || mouseConfig.checked) {
                $table.triggerHeaderCellClickEvent(evnt, {
                  $table,
                  $rowIndex,
                  column,
                  columnIndex,
                  $columnIndex,
                  fixed: fixedType,
                  cell: evnt.currentTarget
                })
              }
              // 点击表头排序
              $table.triggerSortEvent(column, this);
            }
            if (tableListeners["header-cell-dblclick"]) {
              thOns.dblclick = evnt => Utils.emitEvent($table, "header-cell-dblclick", [
                {
                  $table,
                  $rowIndex,
                  column,
                  columnIndex,
                  $columnIndex,
                  fixed: fixedType,
                  cell: evnt.currentTarget
                }, evnt
              ])
            }
            // 按下事件处理
            if (mouseConfig.checked) {
              thOns.mousedown = evnt => $table.triggerHeaderCellMousedownEvent(evnt, {
                $table,
                $rowIndex,
                column,
                columnIndex,
                $columnIndex,
                fixed: fixedType,
                cell: evnt.currentTarget
              })
            }
            const basicTemp = h("th", {
              class: [
                "vxe-header--column",
                column.id,
                {
                  [`col--${headAlign}`]: headAlign,
                  "col--fixed": column.fixed && column.type !== "index-selection",
                  "col--index": column.type === "index",
                  "col--group": isColGroup,
                  "col--ellipsis": hasEllipsis,
                  "fixed--hidden": fixedHiddenColumn,
                  "is--firstCol": column.isFirstCol,
                  "is--lastCol": column.isLastCol,
                  "is--sortable": column.filterSortStore && column.filterSortStore.sortable,
                  "is--filter": column.filterSortStore && column.filterSortStore.filterable,
                  "is--sortable--active": column.filterSortStore && column.filterSortStore.sortable && column.filterSortStore.sort,
                  "is--filter--active": column.filterSortStore && column.filterSortStore.filterable && column.filterSortStore.isConfirmed && column.filterSortStore.valueText 
                    || column.filterSortStore && column.filterSortStore.isConfirmed && column.filterSortStore.filterable && column.filterSortStore.value,
                  "filter--active": column.filterSortStore && column.filterSortStore.value
                },
                headerCellClassName ? XEUtils.isFunction(headerCellClassName) ? headerCellClassName({
                  $table,
                  $rowIndex,
                  column,
                  columnIndex,
                  $columnIndex,
                  fixed: fixedType
                }) : headerCellClassName : ""
              ],
              style: headerCellStyle ? XEUtils.isFunction(headerCellStyle) ? headerCellStyle({
                $table,
                $rowIndex,
                column,
                columnIndex,
                $columnIndex,
                fixed: fixedType
              }) : headerCellStyle : "",
              attrs: {
                "data-colid": column.id,
                colspan: column.colSpan,
                rowspan: column.rowSpan
              },
              on: thOns,
              key: enableColumnKey || dragConfig || isColGroup ? column.id : columnIndex
            }, [
              h("div", {
                class: [
                  "vxe-cell", {
                    "vxe-header-flex-center": column.filterSortStore && (column.filterSortStore.sortable || column.filterSortStore.filterable),
                    "c--title": showTitle,
                    "c--tooltip": showTooltip,
                    "c--ellipsis": showEllipsis,
                    "c--padding": column.padding !== undefined ? column.padding : true
                  }
                ]
              }, column.renderHeader(h, {
                $table,
                $rowIndex,
                column,
                columnIndex,
                $columnIndex,
                fixed: fixedType,
                isHidden: fixedHiddenColumn
              })),
              /**
               * 列宽拖动
               */
              !fixedHiddenColumn && !isColGroup && (XEUtils.isBoolean(column.resizable) ? column.resizable : resizable) ? h("div", {
                class: ["vxe-resizable", { "is--line": !showBorder }],
                on: {
                  mousedown: evnt => this.resizeMousedown(evnt, {
                    $table,
                    $rowIndex,
                    column,
                    columnIndex,
                    $columnIndex,
                    fixed: fixedType,
                    isHidden: fixedHiddenColumn
                  })
                }
              }) : null
            ])
            if (column.filterSortStore.sortable && !column.filterSortConfig.tooltipDisabled && $table.headerSortTooltipVisible) {
              return h("elTooltip", {
                props: {
                //   value:  column.filterSortStore.tooltipVisible,
                  content: column.filterSortStore.sort === "asc" ? "点击降序" : "点击升序",
                  placement: "top"
                }
              }, [
                basicTemp
              ])
            }
            return basicTemp
          }).concat([h("th", { class: "col--gutter" })]))
        }))
      ]),
      /**
       * 其他
       */
      h("div", {
        class: "vxe-table--repair",
        ref: "repair"
      })
    ])
  },
  methods: {
    uploadColumn() {
      this.headerColumn = this.isGroup ? convertToRows(this.collectColumn) : [this.$parent.scrollXLoad && this.fixedType ? this.fixedColumn : this.tableColumn]
    },
    resizeMousedown(evnt, params) {
      let { column } = params
      let { $parent: $table, $el, fixedType } = this
      let { tableBody, leftContainer, rightContainer, resizeBar: resizeBarElem } = $table.$refs
      let { target: dragBtnElem, clientX: dragClientX } = evnt
      let cell = dragBtnElem.parentNode
      let dragLeft = 0
      let minInterval = 36 // 列之间的最小间距
      let tableBodyElem = tableBody.$el
      let pos = DomTools.getOffsetPos(dragBtnElem, $el)
      let dragBtnWidth = dragBtnElem.clientWidth
      let dragMinLeft = pos.left - cell.clientWidth + dragBtnWidth + minInterval
      let dragPosLeft = pos.left + Math.floor(dragBtnWidth / 2)
      let domMousemove = document.onmousemove
      let domMouseup = document.onmouseup
      let isLeftFixed = fixedType === "left"
      let isRightFixed = fixedType === "right"

      // 计算左右侧固定列偏移量
      let fixedOffsetWidth = 0
      if (isLeftFixed || isRightFixed) {
        let siblingProp = isLeftFixed ? "nextElementSibling" : "previousElementSibling"
        let tempCellElem = cell[siblingProp]
        while (tempCellElem) {
          if (DomTools.hasClass(tempCellElem, "fixed--hidden")) {
            break
          } else if (!DomTools.hasClass(tempCellElem, "col--group")) {
            fixedOffsetWidth += tempCellElem.offsetWidth
          }
          tempCellElem = tempCellElem[siblingProp]
        }
        if (isRightFixed && rightContainer) {
          dragPosLeft = rightContainer.offsetLeft + fixedOffsetWidth
        }
      }

      // 处理拖动事件
      let updateEvent = function(evnt) {
        evnt.stopPropagation()
        evnt.preventDefault()
        let offsetX = evnt.clientX - dragClientX
        let left = dragPosLeft + offsetX
        let scrollLeft = fixedType ? 0 : tableBodyElem.scrollLeft
        if (isLeftFixed) {
          // 左固定列（不允许超过右侧固定列、不允许超过右边距）
          left = Math.min(left, (rightContainer ? rightContainer.offsetLeft : tableBodyElem.clientWidth) - fixedOffsetWidth - minInterval)
        } else if (isRightFixed) {
          // 右侧固定列（不允许超过左侧固定列、不允许超过左边距）
          dragMinLeft = (leftContainer ? leftContainer.clientWidth : 0) + fixedOffsetWidth + minInterval
          left = Math.min(left, dragPosLeft + cell.clientWidth - minInterval)
        }
        dragLeft = Math.max(left, dragMinLeft)
        resizeBarElem.style.left = `${dragLeft - scrollLeft}px`
      }
      $table._isResize = true
      DomTools.addClass($table.$el, "c--resize")
      resizeBarElem.style.display = "block"
      document.onmousemove = updateEvent
      document.onmouseup = function(evnt) {
        document.onmousemove = domMousemove
        document.onmouseup = domMouseup
        column.resizeWidth = column.renderWidth + (isRightFixed ? dragPosLeft - dragLeft : dragLeft - dragPosLeft)
        resizeBarElem.style.display = "none"
        $table._isResize = false
        $table._lastResizeTime = Date.now()
        $table.analyColumnWidth()
        $table.recalculate(true)
        DomTools.removeClass($table.$el, "c--resize")
        if ($table.$toolbar) {
          $table.$toolbar.updateResizable()
        }
        Utils.emitEvent($table, "resizable-change", [params])
      }
      updateEvent(evnt)
    }
  }
}
