<template>
  <el-dialog
    v-if="value"
    :visible="value"
    class="en-data-source"
    title="数据来源"
    :width="width"
    @close="closeDialog"
  >
    <!-- 数据来源区块 -->
    <div class="en-data-source-container">
      <div class="left-list">
        <div
          v-for="item in list"
          :key="item.id"
          :class="{ active: item.id === activeTab }"
          class="left-list-item"
          @click="changeTabInfo(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="right-picker">
        <el-input
          v-model="keywords"
          class="right-picker-filter"
          placeholder="搜索关键字"
        >
        </el-input>
        <div class="right-picker-list">
          <div
            v-for="item in currentList"
            :key="item.id"
            class="right-picker-item"
          >
            <div class="item-title">
              {{ item.name }}
            </div>
            <div class="item-picker-list">
              <div
                v-for="el in item.children"
                :key="el.id"
                class="item-picker-item"
              >
                <el-checkbox-group v-model="selectedList">
                  <el-checkbox :key="el.id" :label="el.id">
                    {{ el.name }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="el-dialog__footer">
      <div class="check-num">
        已选中<span>{{ selectedList.length }}</span
        >项
      </div>
      <en-button @click="confirm">
        确定
      </en-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "EnDataSource",
  props: {
    // 是否显示弹窗=>Boolean、Default:false
    value: {
      type: Boolean,
      default: false
    },
    // 弹窗宽度=>String、Default:1100px
    width: {
      type: String,
      default: "1100px"
    },
    // 请求地址=>String、Default:null
    requestUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      list: [],
      currentList: [],
      selectedList: [],
      activeTab: "",
      keywords: ""
    };
  },
  mounted() {
    this.getListData();
  },
  methods: {
    /**
     * @description 获取列表数据
     */
    getListData() {
      for (let i = 1; i < 6; i++) {
        this.list.push({
          id: (Math.random() * 21321321321).toFixed(0),
          name: "列表—" + i
        });
      }
      this.activeTab = this.list[0].id;
      this.getCurrentList();
    },
    /**
     * @description 切换列表类型
     * @param itemData
     */
    changeTabInfo(itemData) {
      this.activeTab = itemData.id;
      this.getCurrentList();
    },
    /**
     * @description 获取右侧数据列表
     */
    getCurrentList() {
      this.currentList = [];
      let obj = {};
      for (let i = 0; i < 10; i++) {
        obj = {
          id: (Math.random() * 21321382198).toFixed(0),
          name: "分类—" + i,
          children: []
        };
        for (let h = 0; h < 10; h++) {
          obj.children.push({
            id: (Math.random() * 21321321312).toFixed(0),
            name: "子类_" + h
          });
        }
        this.currentList.push(obj);
      }
    },
    /**
     * @description 关闭弹窗
     */
    closeDialog() {
      this.$emit("input", false);
    },
    /**
     * @description 确定选择
     */
    confirm() {
      this.closeDialog();
      const arr = [];
      this.currentList.map(item => {
        this.selectedList.map(cItem => {
          if (item.children && item.children.length > 0) {
            item.children.map(sItem => {
              if (sItem.id === cItem) {
                arr.push(sItem);
              }
            });
          }
        });
      });
      this.closeDialog();
      this.$emit("change", arr);
    }
  }
};
</script>

<style lang="scss" scoped>
.en-data-source {
  .en-data-source-container {
    display: flex;
    justify-content: flex-start;

    .left-list {
      width: 310px;
      padding: 0 10px 10px;
      border: 1px solid #e8ecf2;
      border-radius: 4px 4px 4px;

      .left-list-item {
        text-align: left;
        margin-top: 10px;
        color: #636c78;
        padding: 0 10px;
        font-size: 12px;
        cursor: pointer;
        line-height: 38px;
        background-color: #ffffff;
        border-radius: 4px 4px 4px;
        border: 1px solid #e8ecf2;

        &.active {
          color: #ffffff;
          background-color: #4694df;
          border-color: #4694df;
        }
      }
    }

    .right-picker {
      margin-left: 10px;
      width: calc(100% - 320px);
      padding: 10px 10px 10px;
      -webkit-border-radius: 4px 4px 4px;
      -moz-border-radius: 4px 4px 4px;
      border-radius: 4px 4px 4px;
      border: 1px solid #e8ecf2;

      .right-picker-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: 400px;
        overflow-y: auto;

        .right-picker-item {
          margin-top: 20px;

          .item-title {
            text-align: left;
            font-size: 12px;
            color: #333333;
            font-weight: bold;
          }

          .item-picker-list {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .item-picker-item {
              margin-top: 10px;
              width: calc(100% / 3);

              /deep/ .el-checkbox {
                width: 100%;
                display: flex;
                justify-content: flex-start;

                .el-checkbox__label {
                  text-align: left;
                  width: calc(100% - 30px);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  line-height: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    justify-content: space-between;
    line-height: 32px;
    padding: 20px 0 14px;

    .check-num {
      font-size: 12px;
      color: #636c78;

      span {
        padding: 0 5px;
        color: #4694df;
      }
    }
  }
}
</style>
