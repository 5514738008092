var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"en-select-dropdown__item el-select-dropdown__item",class:{
    selected: _vm.itemSelected,
    'is-disabled': _vm.disabled || _vm.groupDisabled || _vm.limitReached,
    hover: _vm.hover
  },on:{"mouseenter":_vm.hoverItem,"click":function($event){$event.stopPropagation();return _vm.selectOptionClick($event)}}},[(_vm.select.multiple)?_c('span',{staticClass:"en-select-check"}):_vm._e(),_c('span',{on:{"mouseenter":_vm.onNodeEnter,"mouseleave":_vm.onNodeLeave}},[_vm._t("default",[_c('span',{style:({ color: _vm.node.color }),attrs:{"title":_vm.node.title}},[_vm._v("\n        "+_vm._s(_vm.currentLabel)+"\n      ")])],{"data":_vm.node.data,"node":_vm.node})],2),(_vm.showAssist || _vm.node.assist)?_c('div',{staticClass:"en-select-node__assist-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNodeAssist),expression:"showNodeAssist"}],staticClass:"en-select-node__assist-wrapper-fixd",style:({
            left: _vm.assistConfig.left + 'px',
            top: _vm.assistConfig.top + 'px'
          })},[_c('node-assist',{attrs:{"node":_vm.node}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }