<template>
  <div
    class="en-image-setting"
    :class="{'disabled':disabled}"
  >
    <div
      class="temp"
      @click="settingClick"
    >
      <en-icon
        :name="name"
        :size="size"
        :style="settingIconStyle"
      >
      </en-icon>
      <div
        class="text"
        :style="{color:disabled?'#636C7890':'#636C78'}"
      >
        <slot>
        </slot>
      </div>
      <en-icon
        v-if="complete"
        name="wancheng"
        :size="size"
        :style="{fill:disabled ? '#58CB7E80':'#58CB7E'}"
        class="complete-icon"
      >
      </en-icon>
    </div>
  </div>
</template>
<script>
export default {
  name: "EnImageSetting",
  props: {
    name: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "small"
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    fill: {
      type: String,
      default: "#4694DF"
    },
    // 是否完成设置
    complete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    settingIconStyle() {
      return { "fill": this.disabled ? this.fill + "80" : this.fill }
    }
  },
  methods: {
    settingClick(evt) {
      if (this.disabled) {
        evt.stopPropagation()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .en-image-setting.disabled {
    cursor: not-allowed;
  }

  .en-image-setting {
    display: inline-block;
    cursor: pointer;
  }

  .text {
    margin-left: 6px;
    display: inline-block;
    padding: 0;
    font-size: 12px;
    vertical-align: middle;
  }

  .en-icon {
    vertical-align: middle;
  }

  .complete-icon {
    display: inline-block;
    margin-left: 12px;
  }
</style>

