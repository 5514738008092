<template>
  <x-upload
    ref="upload"
    v-if="$slots.default || btnTitle"
    :action="uploadUrl"
    :show-file-list="false"
    :on-change="handleChange"
    :on-progress="onProgress ? handleProgress : undefined"
    :on-exceed="onExceed || undefined"
    :before-add="handleBeforeAdd"
    :limit="limit"
    name="storage_file"
    multiple
  >
    <en-button v-if="btnTitle"
               :stopPropagation="false"
               :icon="btnTitle.indexOf('图片') >= 0 ? 'gaoqingtupian' : 'fujianhetupian-kongjian'"
               type="blue"
               icon-size="18"
               text
               class="add-btn">{{btnTitle}}</en-button>
    <slot></slot>
  </x-upload>
</template>

<script>
// import { mapGetters } from "vuex";
import Utils from "./utils";
import XUpload from "./xupload";

export default {
  name: "EnUpload",
  components: { XUpload },
  data() {
    return {
      tempIndex: 0,
      allFileList: [], // 文件总列表
      imgExt: ["gif", "jpg", "jpeg", "bmp", "png"], // 图片类型
      fileExt: ["pdf", "doc", "docx", "txt", "xls", "xlsx", "ppt", "pptx", "rar", "zip"] // 文件类型
    };
  },
  mixins: [Utils],
  comments: { XUpload },
  created() {
  },
  props: {
    title: String, // 标题(设置后带图标, 若不设置且没有默认插槽根据limitType而定)
    fileList: Array, // 文件列表
    showFileList: Boolean, // 显示文件列表(暂未实现EN+效果)
    limitNum: [String, Number], // 限制数量
    limitType: [String, Number], // 文件类型 (与后台数据保持一致 1附件和图片、2仅附件、3仅图片)
    onChange: Function, // 单文件响应.  添加每个文件后 或者 每个文件上传成功、上传失败后 都会响应
    onAllChange: Function, // 整体响应. 添加所有文件 或者  所有文件上传成功、上传失败后 才会响应
    onProgress: Function, // 进度条 每个文件 都会响应
    onExceed: Function // 文件超出个数的钩子
  },
  methods: {

    // 添加文件 上传文件成功失败的改动
    handleChange(file, fileList) {
      const { onChange, onAllChange } = this;
      const isAllUpload = this.isAllUpload(fileList);
      const otherParams = { isAllUpload };
      // 每个文件变更调用
      if (onChange) {
        onChange(this.getEventParams(file, fileList, otherParams));
      }

      // 全部变更完成调用
      if (onAllChange) {
        if (this.isAllReady(fileList)) {
          onAllChange(this.getEventParams(undefined, fileList, otherParams));
        } else if (isAllUpload) {
          onAllChange(this.getEventParams(undefined, fileList, otherParams));
        }
      }

      // 上传完成 清掉数据
      if (isAllUpload) {
        this.allFileList = [];
        this.$refs.upload.clearFiles();
      }
    },
    // 进度改变
    handleProgress(event, file, fileList) {
      file.percentage = event.percent;
      const params = this.getEventParams(file, fileList);
      this.onProgress(params);
    },
    // 文件即将添加
    handleBeforeAdd(file) {
      const data = this.getAttachmentData(file);
      if (!data.dataType || (Number(this.limitType) === 2 && data.dataType === "000") || (Number(this.limitType) === 3 && data.dataType === "001")) {
        this.alertDelayMsg(`${data.name}格式不合法, 仅支持${this.supportExt.join("、")}文件格式`);
        return false;
      }
      const limitSize = data.dataType === "000" ? 5 : 20;// 文件20M , 图片5M
      const isInLimitSize = file.size / 1024 / 1024 < limitSize;//

      if (!isInLimitSize) {
        this.alertDelayMsg(`${data.name}不能超过${limitSize}M`);
        return false;
      }

      this.allFileList.push(data);

      return true;
    }
  },
  watch: {
    fileList: {
      immediate: true,
      handler(fileList) {
        this.allFileList = (fileList || []).map((item) => {
          item.uid = item.id || item.uid || (Date.now() + this.tempIndex++);
          item.status = item.status || "success";
          return item;
        });
      }
    }
  },
  computed: {
    btnTitle() {
      if (this.title) {
        return this.title;
      }

      if (this.limitType && !this.$scopedSlots.default) {
        if (Number(this.limitType) === 3) {
          return "上传图片";
        } else {
          return "上传附件";
        }
      }
      return undefined;
    },
    // 限制数量
    limit() {
      const limitNum = Number(this.limitNum);// undefined 为max
      return Math.max(limitNum, 0);// 负数为0
    },
    // 当前支持的文件后缀
    supportExt() {
      let ext;
      switch (Number(this.limitType)) {
        case 2:
          ext = [].concat(this.fileExt);
          break;
        case 3:
          ext = [].concat(this.imgExt);
          break;
        default:
          ext = [].concat(this.imgExt, this.fileExt);
          break;
      }

      return ext;
    },
    uploadUrl() {
      // https://static.enfry.cn/Storage-api/upload.api?token=2FF852BF3FD8C0F6E7A7C6127FD7F526&appKey=enfrytest&ts=1597031994330
      const store = this.$store || {};// 为啥这么写, 因为组件库demo没有用VUEX
      const getters = store.getters || {};
      const userInfo = getters.userInfo || {};
      return userInfo.attachmentUploadUrl;
    }
  }
};
</script>

<style scoped></style>
