<template>
  <div class="en-tree-transfer">
    <!-- 左侧面板 -->
    <div class="en-transfer-panel">
      <div
        class="en-transfer-panel-title"
        v-if="$parent.showTitle && $parent.title.length === 2 && $parent.showTitle !== 'false'"
      >
        <slot name="left-title">
          <el-checkbox
            v-model="leftPanel.checkedAll"
            :indeterminate="leftPanel.isIndeterminate"
            @change="toggleLeftCheckAll"
          >
            {{ $parent.title[0]}}
          </el-checkbox>
          {{ leftPanel.checkedList.length + " /" + leftPanel.list.length }}
        </slot>
      </div>
      <el-input
        class="en-transfer-search-title"
        v-if="$parent.showSearch"
        :placeholder="$parent.placeholder"
        suffix-icon="el-icon-search"
        v-model="keywords"
      >
      </el-input>
      <div
        class="en-transfer-panel-body"
        :class="{'with-search': $parent.showSearch}"
      >
        <el-checkbox-group v-model="leftPanel.checkedList">
          <el-checkbox
            v-for="item in leftPanel.list"
            :key="item[currentIdKey]"
            class="en-transfer-panel-item"
            :label="item"
            :disabled="item.disabled"
          >
            <slot name="left-content" :item="item">
              {{ item[currentLabel] }}
            </slot>
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <!-- 中间面板 -->
    <div class="en-transfer-tool">
      <div class="en-transfer-tool-container">
        <en-button
          :disabled="leftPanel.checkedList.length === 0"
          @click="dataToRight"
          icon="el-icon-arrow-right"
        >
        </en-button>
        <en-button
          :type="rightPanel.checkedList.length > 0 ? '' : 'white'"
          icon="el-icon-arrow-left"
          :disabled="rightPanel.checkedList.length === 0"
          @click="dataToLeft"
        >
        </en-button>
        <!-- 排除按钮 -->
        <en-button
          v-if="$parent.exclude && $parent.exclude !== 'false'"
          icon="el-icon-close"
          :disabled="leftPanel.checkedList.length === 0"
          type="white"
          @click="excludeToRight"
        >
        </en-button>
      </div>
    </div>
    <!-- 右侧面板 -->
    <div class="en-transfer-panel">
      <div
        class="en-transfer-panel-title"
        v-if="$parent.showTitle && $parent.title.length === 2 && $parent.showTitle !== 'false'"
      >
        <slot name="left-title">
          <el-checkbox
            v-model="rightPanel.checkedAll"
            :indeterminate="rightPanel.isIndeterminate"
            @change="toggleRightCheckAll"
          >
            {{ $parent.title[1]}}
          </el-checkbox>
          {{ rightPanel.checkedList.length + " /" + rightPanel.list.length }}
        </slot>
      </div>
      <div class="en-transfer-panel-body">
        <el-checkbox-group v-model="rightPanel.checkedList">
          <draggable
            group="item"
            animation="150"
            :list="rightPanel.list"
            class="en-drag-item-content"
            scroll="true"
            :disabled="!$parent.canSort"
          >
            <el-checkbox
              v-for="item in rightPanel.list"
              :key="item[currentIdKey]"
              class="en-transfer-panel-item"
              :label="item"
              :disabled="item.disabled"
            >

              <!-- 除去复选框，右侧整体不涉及自定义 -->
              <div class="en-transfer-panel-item-container">
                <slot name="right-content" :item="item">
                  <!-- 若数据是排除的 -->
                  <i
                    class="el-icon el-icon-close"
                    v-if="item.isExclude && $parent.isExclude !== 'false'"
                    style="color:#FF0000;"
                  >
                  </i>
                  <!-- 若数据是带头像的 -->
                  <!--<img-->
                    <!--v-if="-->
                <!--$parent.showHead &&-->
                <!--$parent.showHead !== 'false' &&-->
                <!--item[$parent.imgPath]-->
              <!--"-->
                    <!--:src="item[$parent.imgPath] ? item[$parent.imgPath] : ''"-->
                    <!--alt=""-->
                  <!--/>-->
                  <en-user-logo v-if="$parent.showHead && $parent.showHead !== 'false'"
                                :user-name="item[currentLabel]"
                                :image-url="item[$parent.imgPath]"
                                size="30px"
                                class="en-transfer-panel-item-user-logo"
                  >

                  </en-user-logo>

                  <div class="en-transfer-panel-item-label">
                    {{ item[currentLabel] }}
                    <!--                  <slot-->
                    <!--                    :item="item"-->
                    <!--                    name="right"-->
                    <!--                    v-if="$parent.selfIcon && $parent.selfIcon !== 'false'"-->
                    <!--                  >-->
                    <!--                  </slot>-->
                  </div>
                </slot>
              </div>
              <!-- 除去复选框外，所有皆自定义 -->
              <!--            <slot-->
              <!--              name="container"-->
              <!--              v-if="$parent.selfDefine || $parent.selfDefine === 'true'"-->
              <!--              :item="item"-->
              <!--            >-->
              <!--            </slot>-->
            </el-checkbox>
          </draggable>

        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keywords: "",
      leftPanel: {
        list: [],
        checkedList: [],
        checkedAll: false,
        isIndeterminate: false
      },
      rightPanel: {
        list: [],
        checkedList: [],
        checkedAll: false,
        isIndeterminate: false
      }
    };
  },
  computed: {
    currentIdKey() {
      return this.$parent.idKey || this.$parent.props.value;
    },
    currentLabel() {
      return this.$parent.labelKey || this.$parent.props.label;
    }
  },
  created() {
    this.setPanelList();
  },
  watch: {
    keywords() {
      this.filterWords();
    },
    "$parent.value": {
      deep: true,
      immediate: true,
      handler() {
        this.reSetPanelList();
      }
    }
  },
  methods: {
    /**
     * @description 初始化设置，将左右两侧数据进行默认填充
     */
    setPanelList() {
      this.leftPanel.list = this.$parent.list;
      this.rightPanel.list = this.$parent.value;
      if (this.$parent.singleSelect) {
        this.resetSingleSelect();
      }
    },
    /**
     * @description 初始化设置，将左右两侧数据进行默认填充
     */
    reSetPanelList() {
      if (this.$parent.resetLeft) {
        this.leftPanel.list = this.$parent.list;
      }
      this.rightPanel.list = this.$parent.value;
      if (this.$parent.singleSelect) {
        this.resetSingleSelect();
      }
    },
    /**
     * @description 如果只允许单选数据节点时，需清空之前选择的节点，然后设置当前节点的选中
     */
    resetSingleSelect(data) {
      if (data.id) {
        this.$refs.tree.setCheckedKeys([]);
        this.leftPanel.checkedList = [];
      }
    },
    /**
     * @description 全选/取消左侧面板数据
     * @param val 是否已全选=>布尔值
     */
    toggleLeftCheckAll(val) {
      const list = [];
      this.leftPanel.list.forEach(item => {
        if (!item.disabled) {
          list.push(item);
        }
      });
      this.leftPanel.checkedList = val ? list : [];
      const len = this.leftPanel.checkedList;
      this.leftPanel.isIndeterminate = len > 0 && len < this.leftPanel.list.length;
    },
    /**
     * @description 全选/取消右侧面板数据
     * @param val 是否已全选=>布尔值
     */
    toggleRightCheckAll(val) {
      const list = [];
      this.rightPanel.list.forEach(item => {
        if (!item.disabled) {
          list.push(item);
        }
      });
      this.rightPanel.checkedList = val ? list : [];
      const len = this.rightPanel.checkedList;
      this.rightPanel.isIndeterminate = len > 0 && len < this.rightPanel.list.length;
    },
    /**
     * @description 数据朝右选择
     */
    dataToRight() {
      // 遍历选中的数组，如果在右侧，不存在该数据，将该数据塞入至右侧，如果存在，则无视
      this.leftPanel.checkedList.forEach(item => {
        let isExist = false;
        this.rightPanel.list.forEach(cItem => {
          if (cItem[this.currentIdKey] === item[this.currentIdKey]) {
            // 如果右侧的数据是被排除的，将被排除的属性覆盖掉
            if (cItem.isExclude) {
              cItem.isExclude = false;
            }
            isExist = true;
          }
        });
        if (!isExist) {
          this.rightPanel.list.push(item);
        }
      });
      this.leftPanel.checkedList = [];
      this.leftPanel.checkedAll = false;
      this.emitDataInfo();
    },
    /**
     * @description 清除右侧数据
     */
    dataToLeft() {
      this.rightPanel.checkedList.forEach(item => {
        this.rightPanel.list.forEach((cItem, cIndex) => {
          if (item[this.currentIdKey] === cItem[this.currentIdKey]) {
            this.rightPanel.list.splice(cIndex, 1);
          }
        });
      });
      this.rightPanel.checkedList = [];
      this.rightPanel.checkedAll = false;
      this.emitDataInfo();
    },
    /**
     * @description 排除选中的数据
     */
    excludeToRight() {
      // 遍历左侧选中数据，并与右侧已选择的数据做对比，若右侧的数据在左侧选中的数据中存在，那么将该数据作为排除项
      this.leftPanel.checkedList.forEach(item => {
        let isExist = false;
        this.rightPanel.list.forEach(cItem => {
          if (cItem[this.currentIdKey] === item[this.currentIdKey]) {
            isExist = true;
            cItem.isExclude = true;
          }
        });
        // 如果左侧选中的数据在右侧面板内不存在，则将该数据作为一个新值进行右侧数据面板的填塞
        if (!isExist) {
          const obj = Object.assign({}, item);
          obj.isExclude = true;
          this.rightPanel.list.push(obj);
        }
      });
      this.$set(this.rightPanel.list, this.rightPanel.list);
      this.leftPanel.checkedList = [];
      this.leftPanel.checkedAll = false;
      this.emitDataInfo();
    },
    /**
     * @description 筛选数据
     */
    filterWords() {
      const list = [];
      if (this.keywords === "") {
        this.leftPanel.list = JSON.parse(JSON.stringify(this.$parent.list));
      } else {
        this.leftPanel.list.forEach(item => {
          if (item[this.currentLabel].indexOf(this.keywords) !== -1) {
            list.push(item);
          }
        });
        this.leftPanel.list = JSON.parse(JSON.stringify(list));
      }
    },
    /**
     * @description 回传数据
     */
    emitDataInfo() {
      this.$parent.$emit("input", this.rightPanel.list);
    }
  }
};
</script>
