import {
  CLASS_LOADING,
  CLASS_TRANSITION,
  EVENT_LOAD,
  EVENT_TRANSITION_END,
  EVENT_VIEWED
} from "./constants"
import {
  addClass,
  addListener,
  assign,
  forEach,
  getImageNameFromURL,
  getImageNaturalSizes,
  getTransforms,
  removeClass,
  removeListener,
  setData,
  setStyle
} from "./utilities"

export default {
  render() {
    this.initContainer()
    this.initViewer()
    this.initList()
    this.renderViewer()
  },

  initBody() {
    // if (!this.element.isElement) {
    //   return
    // }
    let { ownerDocument } = this.element
    if (!ownerDocument) {
      // eslint-disable-next-line prefer-destructuring
      ownerDocument = document.body.ownerDocument
    }
    const body = ownerDocument.body || ownerDocument.documentElement

    this.body = body
    this.scrollbarWidth = window.innerWidth - ownerDocument.documentElement.clientWidth
    this.initialBodyPaddingRight = body.style.paddingRight
    this.initialBodyComputedPaddingRight = window.getComputedStyle(body).paddingRight
  },

  /**
   * 初始化宽高
   */
  initContainer() {
    this.containerData = {
      width: window.innerWidth,
      height: window.innerHeight
    }
  },

  /**
   * 初始化viewer可视化宽高
   */
  initViewer() {
    const { options, parent } = this
    let viewerData

    if (options.inline) { // 行内模式，宽高
      viewerData = {
        width: Math.max(parent.offsetWidth, options.minWidth),
        height: Math.max(parent.offsetHeight, options.minHeight)
      }

      this.parentData = viewerData
    }

    if (this.fulled || !viewerData) {
      viewerData = this.containerData
    }

    this.viewerData = assign({}, viewerData)

    // 设置左右按钮高度位置
    const footerHeight = this.footer.offsetHeight
    const prevNextElTop = ((viewerData.height - footerHeight) / 2)
    setStyle(this.prevEl, {
      top: `${prevNextElTop}px`
    })
    setStyle(this.nextEl, {
      top: `${prevNextElTop}px`
    })
  },

  /**
   * 重置大小
   */
  renderViewer() {
    if (this.options.inline && !this.fulled) {
      setStyle(this.viewer, this.viewerData)
    }
  },

  /**
   * 初始化底部图片导航
   */
  initList() {
    const { element, options, list } = this
    const items = []

    // initList may be called in this.update, so should keep idempotent
    list.innerHTML = ""

    forEach(this.images, (image, index) => {
      let src = this.getImageURL(image, "thumUrl")
      if (!src && element.isElement) {
        // src = element.isElement ? image.src : this.getImageURL(image, 'thumUrl')
        // eslint-disable-next-line prefer-destructuring
        src = image.src
      }
      // const alt = image.alt || getImageNameFromURL(src) // 获取alt
      let alt = element.isElement ? image.alt : ""
      if (!alt) {
        alt = getImageNameFromURL(src)
      }
      const url = this.getImageURL(image) // 获取url

      if (src || url) {
        const item = document.createElement("li")
        const img = document.createElement("img")

        // 图片的一些属性
        if (element.isElement) {
          forEach(options.inheritedAttributes, (name) => {
            const value = image.getAttribute(name)

            if (value !== null) {
              img.setAttribute(name, value)
            }
          })
        }
        img.src = src || url // 先缩略，在url
        img.alt = alt
        img.setAttribute("data-index", index)
        img.setAttribute("data-original-url", url || src) // 先大图，再小图
        img.setAttribute("data-viewer-action", "view")
        img.setAttribute("role", "button")
        item.appendChild(img)
        list.appendChild(item)
        items.push(item)
      }
    })

    this.items = items

    forEach(items, (item) => {
      const image = item.firstElementChild

      setData(image, "filled", true)

      if (options.loading) {
        addClass(item, CLASS_LOADING)
      }

      // 底部图片导航image 加载完成事件
      addListener(image, EVENT_LOAD, (event) => {
        if (options.loading) {
          removeClass(item, CLASS_LOADING)
        }

        this.loadImage(event)
      }, {
        once: true
      })
    })

    // 如果开启了css3动画，给底部图片导航添加动画
    if (options.transition) {
      addListener(element, EVENT_VIEWED, () => {
        addClass(list, CLASS_TRANSITION)
      }, {
        once: true
      })
    }
  },

  /**
   * 让选中图片在中间
   * @param index
   */
  renderList(index) {
    const {
      navbar, navbarWrapper, toolbar
    } = this
    const navbarWrapperWidth = navbarWrapper.offsetWidth
    const oldShowNum = this.listShowNum
    const showNum = Math.floor((navbarWrapperWidth - 80) / 110)
    if (Number(oldShowNum) !== showNum) {
      let newWidth
      if (showNum > this.length) {
        newWidth = (this.length * 110 - 10).toFixed(0)
        setStyle(navbar, {
          width: `${newWidth}px`
        })
      } else {
        newWidth = (showNum * 110 - 10).toFixed(0)
        setStyle(navbar, {
          width: `${newWidth}px`
        })
      }
      const toolbarRight = ((navbarWrapperWidth - 80 - newWidth) / 2 + 40).toFixed(0)
      setStyle(toolbar, {
        right: `${toolbarRight}px`
      })
      navbar.setAttribute("data-num", showNum)
      this.listShowNum = showNum
      this.listPageNo = 0 // 如果个数有变动，重置pageNo，好让下面处理滚动
      this.listLastPageNo = Math.ceil(this.length / this.listShowNum)
    }

    const i = index || this.index
    // const width = this.items[i].offsetWidth || 30
    const outerWidth = 110 // 1 pixel of `margin-left` width

    // Place the active item in the center of the screen
    setStyle(this.list, assign({
      width: outerWidth * this.length
    }))
    const pageNo = Math.ceil((i + 1) / this.listShowNum) // 得到现在是第几页
    this.renderPage(pageNo)
  },

  /**
   * 页面跳转
   * @param pageNo
   */
  renderPage(pageNo) {
    const {
      listLeft, listRight
    } = this
    const lastPage = this.listLastPageNo // 总共几页
    if (this.listPageNo !== pageNo) {
      this.listPageNo = pageNo
      setStyle(this.list, getTransforms({
        translateX: (this.listShowNum * 110) * (pageNo - 1) * -1
      }))
    }
    if (this.listPageNo === 1) {
      addClass(listLeft, "hidden")
    } else {
      removeClass(listLeft, "hidden")
    }
    if (this.listPageNo < lastPage) {
      removeClass(listRight, "hidden")
    } else {
      addClass(listRight, "hidden")
    }
  },

  resetList() {
    const { list } = this

    list.innerHTML = ""
    removeClass(list, CLASS_TRANSITION)
    setStyle(list, getTransforms({
      translateX: 0
    }))
  },

  initImage(done) {
    // viewerData 全屏模式下是window的高宽
    const { options, image, viewerData } = this
    const { viewWidth: viewAreaWidth, viewHeight: viewAreaHeight } = options
    const footerHeight = this.footer.offsetHeight
    const viewerWidth = viewerData.width // 宽度
    const viewerHeight = Math.max(viewerData.height - footerHeight, footerHeight) // 减去底部的高度高度
    const oldImageData = this.imageData || {}
    // eslint-disable-next-line prefer-const
    let sizingImage

    this.imageInitializing = {
      abort: () => {
        sizingImage.onload = null
      }
    }

    sizingImage = getImageNaturalSizes(image, options, (naturalWidth, naturalHeight) => {
      const aspectRatio = naturalWidth / naturalHeight // 宽高比
      let width = viewerWidth // 全屏模式，window宽度
      let height = viewerHeight // 全屏模式，window减去底部高度

      this.imageInitializing = false

      // 图片以哪个边为准渲染
      if (viewerHeight * aspectRatio > viewerWidth) { // 高度乘以比列，如果大于可视化宽度，则高度变小
        height = viewerWidth / aspectRatio
      } else {
        width = viewerHeight * aspectRatio
      }
      // 得到的高宽，是显示比列最佳的高宽, 但是是根据1：1显示

      if (width > viewAreaWidth) {
        const tempAspectRatio = viewAreaWidth / width
        width = viewAreaWidth
        height *= tempAspectRatio
      } else if (height > viewAreaHeight) {
        const tempAspectRatio = viewAreaHeight / height
        height = viewAreaHeight
        width *= tempAspectRatio
      } else {
        // 缩放0.9显示
        width = Math.min(width * 0.9, naturalWidth)
        height = Math.min(height * 0.9, naturalHeight)
      }

      const imageData = {
        naturalWidth,
        naturalHeight,
        aspectRatio,
        ratio: width / naturalWidth,
        width,
        height,
        left: (viewerWidth - width) / 2,
        top: (viewerHeight - height) / 2
      }
      const initialImageData = assign({}, imageData)

      if (options.rotatable) {
        imageData.rotate = oldImageData.rotate || 0
        initialImageData.rotate = 0
      }

      if (options.scalable) {
        imageData.scaleX = oldImageData.scaleX || 1
        imageData.scaleY = oldImageData.scaleY || 1
        initialImageData.scaleX = 1
        initialImageData.scaleY = 1
      }

      this.imageData = imageData
      this.initialImageData = initialImageData

      if (done) {
        done()
      }
    })
  },

  renderImage(done) {
    const { image, imageData } = this

    setStyle(image, assign({
      width: imageData.width,
      height: imageData.height,

      // XXX: Not to use translateX/Y to avoid image shaking when zooming
      marginLeft: imageData.left,
      marginTop: imageData.top
    }, getTransforms(imageData)))

    if (done) {
      if ((this.viewing || this.zooming) && this.options.transition) {
        const onTransitionEnd = () => {
          this.imageRendering = false
          done()
        }

        this.imageRendering = {
          abort: () => {
            removeListener(image, EVENT_TRANSITION_END, onTransitionEnd)
          }
        }

        addListener(image, EVENT_TRANSITION_END, onTransitionEnd, {
          once: true
        })
      } else {
        done()
      }
    }
  },

  resetImage() {
    // this.image only defined after viewed
    if (this.viewing || this.viewed) {
      const { image } = this

      if (this.viewing) {
        this.viewing.abort()
      }

      image.parentNode.removeChild(image)
      this.image = null
    }
  }
}
