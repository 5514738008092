import Cell from "../../cell"
import ColumnUtils from "./columnUtils";
// import GlobalConfig from "../../conf";

export default {
  name: "EnTableColumn",
  data() {
    return {
      filterSortStore: {
        value: null, // 筛选数据
        valueText: null, // 筛选文本
        sort: null, // 排序 asc desc
        sortable: false, // 可排序
        filterable: false, // 可筛选
        isConfirmed: false, // 是否确认筛选了
        tooltipVisible: false // 筛选tooltip是否展示
      }
    }
  },
  props: {
    // 渲染类型 index,radio,selection,expand
    type: String,
    // 在 v3.0 中废弃 prop
    prop: String,
    // 在 v3.0 中废弃 label
    label: String,
    // 列属性
    field: String,
    // 列标题
    title: String,
    // 列宽度
    width: [Number, String],
    // 列最小宽度，把剩余宽度按比例分配
    minWidth: [Number, String],
    // 是否允许拖动列宽调整大小
    resizable: {
      type: Boolean,
      default: true
    },
    // 将列固定在左侧或者右侧
    fixed: String,
    // 列对齐方式
    align: String,
    // 表头对齐方式
    headerAlign: String,
    // 当内容过长时显示为省略号
    showOverflow: {
      type: [Boolean, String],
      default: true
    },
    // 当表头内容过长时显示为省略号
    showHeaderOverflow: {
      type: [Boolean, String],
      default: true
    },
    // 格式化显示内容
    formatter: [
      Function,
      Array,
      String
    ],
    // 自定义索引方法
    indexMethod: Function,
    // 是否服务端排序
    selectConfig: Object, // 控制每列单独选中效果
    // 配置筛选条件数组
    // 集成筛选排序为一体操作
    // data: Object, //存取数据 (可能是字符串, 数组, 字典)
    // sortable: Boolean,  是否允许排序
    // filterable: Boolean,  是否允许筛选
    // sortBy: [ String, Array ],
    data: Object, // 表头数据 (传入表头数据后, 不用再写 field/title 筛选之类的)
    filterSortConfig: Object,
    // 自定义筛选排序方法
    filterSortMethod: Function,
    // 筛选模板配置项
    filterRender: Object,
    // 指定为树节点
    treeNode: Boolean,
    // 单元格渲染配置项
    cellRender: Object,
    // 单元格编辑渲染配置项
    editRender: Object,
    // 额外的参数
    params: Object,
    // 自定义表头
    renderCustomHeader: Function
  },
  inject: ["$table"],
  created() {
    this.columnConfig = ColumnUtils.createColumn(this, this.$table)
    // 注册 watcher (table或者column复用时, 并不会调用created方法, 所以数据会存在不相等的情况 add by xu)
    // ColumnUtils.registerNormalWatchers(this);
    // ColumnUtils.registerComplexWatchers(this);
    this.$nextTick(() => {
      ColumnUtils.registerNormalWatchers(this)
      ColumnUtils.registerComplexWatchers(this)
    })
  },
  mounted() {
    ColumnUtils.assemColumn(this)
  },
  destroyed() {
    ColumnUtils.destroyColumn(this)
  },
  render(h) {
    // debugger;
    return h("div", this.$slots.default)
  },
  methods: Cell
}
