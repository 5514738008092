/**
 * project: enfry-components
 * fileName: tree
 * Created by 徐俊 on 2020/07/29
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

const Tree = {
  data() {
    return {
      lazyLoadRows: []// 正在懒加载的rows
    }
  },
  methods: {

  }

};

export default Tree;
