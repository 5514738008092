<!--
 * @Description:数据映射
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-07-11 11:04:12
 * @LastEditTime: 2020-04-27 13:34:10
 -->
<template>
  <el-dialog
    title="数据映射"
    :visible="dialogVisible"
    class="data-mappingt"
    width="900px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="close"
    append-to-body
  >
    <en-table
      ref="multipleTable"
      :data="tableData"
      border
      stripe
      max-height="400"
      :edit-config="{}"
      :head-end-config="{ type: 'add' }"
    >
      <en-table-column type="index" label="序号" width="50"> </en-table-column>
      <en-table-column label="目标字段" prop="name" field="name">
        <template v-slot:edit="{ row }">
          <el-select v-model="row.source">
            <el-option
              v-for="option in options"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            ></el-option>
          </el-select>
        </template>
      </en-table-column>
      <en-table-column label="来源字段" prop="name" field="name">
        <template v-slot:edit="{ row }">
          <el-select v-model="row.source">
            <el-option
              v-for="option in options"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            ></el-option>
          </el-select>
        </template>
      </en-table-column>
      <template v-slot:hoverRow="{ row, rowIndex }">
        <en-expand-buttons
          :data="buttonData"
          @button-click="
            handleDeleteButtonClick(row, rowIndex, arguments[1], arguments[0])
          "
        />
      </template>
    </en-table>
    <div slot="footer" class="dialog-footer">
      <en-button @click="save">
        确定
      </en-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "DataMapping",
  props: {
    title: {
      type: String,
      default: ""
    },
    dialogVisible: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      options: [
        {
          label: "平铺式",
          value: 1
        },
        {
          label: "左右式",
          value: 2
        }
      ],
      tableData: [],
      color: "#000000",
      // 按钮数据
      buttonData: [
        {
          icon: "guanlianziduan",
          iconColor: "#4694df"
        },
        {
          icon: "shanchu-liebiao",
          iconColor: "#F54646"
        }
      ]
    };
  },
  methods: {
    // 删除按钮
    handleDeleteButtonClick(row, index) {
      this.tableData.pop(index);
    },
    close() {
      this.$emit("update:dialog-visible");
    }
  }
};
</script>
<style lang="scss" scoped>
.data-mapping {
  .el-select {
    width: 100%;
  }
}
</style>
