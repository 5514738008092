// 导出对象
export class ENExportObject {
  constructor(obj) {
    this.areaObjType = obj.areaObjType;
    this.dataType = obj.dataType;
    this.existSub = obj.existSub;
    this.isAsync = obj.isAsync;
    this.name = obj.name;
    this.type = obj.type;
    this.id = obj.id;
  }
}
// 导出内容->属性
export class ENExportContentAttr {
  constructor(obj) {
    this.attributeId = obj.attributeId;
    this.field = obj.field;
    this.fieldName = obj.fieldName;
    this.fieldType = obj.fieldType;
    this.objtypeId = obj.objtypeId;
    this.requrein = obj.requrein;
    this.id = obj.id;
  }
}
// 导出内容
export class ENExportContent {
  constructor(obj) {
    this.contentAttrList = [];
    this.name = obj.name;
    this.type = obj.type;
    this.id = obj.id;
  }
}
// 导出类型
export class ENExportType {
  constructor(obj) {
    this.typeAttrList = [];
    this.name = obj.name;
    this.type = obj.type;
    this.id = obj.id;
  }
}

// 导出数据
export class ENDataExport {
  constructor() {
    this.exportObject = new ENExportObject({});
    this.exportContent = new ENExportContent({});
    this.exportType = new ENExportType({});
  }
  checkExportSelectData() {
    let message = "";

    const id1 = this.exportObject.id;
    const id2 = this.exportContent.id;
    const id3 = this.exportType.id;
    const list1 = this.exportContent.contentAttrList;
    const list2 = this.exportType.typeAttrList;

    if (typeof id1 === "undefined") {
      message = "导出对象不能为空,请选择导出对象";
    } else if (typeof id2 === "undefined") {
      message = "导出内容不能为空,请选择导出内容";
    } else if (typeof id3 === "undefined") {
      message = "导出类型不能为空,请选择导出类型";
    } else if (id2 === 2 && (typeof list1 === "undefined" || list1.length === 0)) {
      message = "请至少选择一个导出内容属性";
    } else if (id3 === 2 && (typeof list2 === "undefined" || list2.length === 0)) {
      message = "请至少选择一个导出类型属性";
    }
    return message;
  }

  exportSelectData() {
    const exportData = new ENDataExport();
    exportData.exportObject = this.exportObject;
    exportData.exportContent = this.exportContent;
    exportData.exportType = this.exportType;
    return exportData;
  }
}
// /导出对象
export const dataObjectList = () => {
  const objList = [
    {
      "areaObjType": "002",
      "dataType": "000",
      "existSub": "001",
      "id": "171211134003906183",
      "isAsync": "001",
      "name": "人员",
      "type": "002"
    },
    {
      "areaObjType": "014",
      "dataType": "000",
      "existSub": "001",
      "id": "190117101033891831",
      "isAsync": "000",
      "name": "会计期间",
      "type": "014"
    },
    {
      "areaObjType": "005",
      "dataType": "000",
      "existSub": "000",
      "id": "171211134003907185",
      "isAsync": "000",
      "name": "科目",
      "type": "005"
    },
    {
      "areaObjType": "016",
      "dataType": "000",
      "existSub": "000",
      "id": "190117101033892833",
      "isAsync": "000",
      "name": "会计科目",
      "type": "016"
    },
    {
      "areaObjType": "003",
      "dataType": "000",
      "existSub": "000",
      "id": "171211134003907186",
      "isAsync": "000",
      "name": "岗位",
      "type": "003"
    },
    {
      "areaObjType": "013",
      "dataType": "000",
      "existSub": "000",
      "id": "190117101033891830",
      "isAsync": "000",
      "name": "账套",
      "type": "013"
    },
    {
      "areaObjType": "018",
      "dataType": "000",
      "existSub": "000",
      "id": "190117101033902859",
      "isAsync": "000",
      "name": "银行账户",
      "type": "018"
    },
    {
      "areaObjType": "008",
      "dataType": "000",
      "existSub": "000",
      "id": "171211134003908187",
      "isAsync": "000",
      "name": "项目",
      "type": "008"
    },
    {
      "areaObjType": "020",
      "dataType": "000",
      "existSub": "000",
      "id": "190117101033902861",
      "isAsync": "000",
      "name": "记账主体",
      "type": "020"
    },
    {
      "areaObjType": "007",
      "dataType": "000",
      "existSub": "000",
      "id": "171211134003908188",
      "isAsync": "000",
      "name": "合同",
      "type": "007"
    },
    {
      "areaObjType": "015",
      "dataType": "000",
      "existSub": "001",
      "id": "190117101033892832",
      "isAsync": "000",
      "name": "月度汇率",
      "type": "015"
    },
    {
      "areaObjType": "006",
      "dataType": "000",
      "existSub": "001",
      "id": "171211134003908189",
      "isAsync": "000",
      "name": "客商信息",
      "type": "006"
    },
    {
      "areaObjType": "001",
      "dataType": "000",
      "existSub": "000",
      "id": "171211134003907184",
      "isAsync": "000",
      "name": "组织机构",
      "type": "001"
    },
    {
      "areaObjType": "017",
      "dataType": "000",
      "existSub": "000",
      "id": "190117101033892834",
      "isAsync": "000",
      "name": "现金流量科目",
      "type": "017"
    },
    {
      "areaObjType": "004",
      "dataType": "000",
      "existSub": "000",
      "id": "171211134003909190",
      "isAsync": "000",
      "name": "职级",
      "type": "004"
    },
    {
      "areaObjType": "009",
      "dataType": "000",
      "existSub": "000",
      "id": "171211134003909191",
      "isAsync": "000",
      "name": "客商类别",
      "type": "009"
    },
    {
      "areaObjType": "010",
      "dataType": "000",
      "existSub": "000",
      "id": "171211134003909192",
      "isAsync": "000",
      "name": "关系类型",
      "type": "010"
    },
    {
      "areaObjType": "011",
      "dataType": "000",
      "existSub": "000",
      "id": "171211134003910195",
      "isAsync": "000",
      "name": "支付方式",
      "type": "011"
    },
    {
      "areaObjType": "012",
      "dataType": "000",
      "existSub": "000",
      "id": "180702143217859372",
      "isAsync": "000",
      "name": "税目",
      "type": "012"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "001",
      "id": "246529366878584832",
      "isAsync": "000",
      "name": "人员1",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "000",
      "id": "246531101564076032",
      "isAsync": "000",
      "name": "必填标识",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "001",
      "id": "247062160420634624",
      "isAsync": "000",
      "name": "科目1",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "001",
      "id": "248783509735342080",
      "isAsync": "000",
      "name": "2.10版本",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "000",
      "id": "251595203198058496",
      "isAsync": "000",
      "name": "流水",
      "type": "000"
    },
    {
      "areaObjType": "019",
      "dataType": "000",
      "existSub": "000",
      "id": "190117101033902860",
      "isAsync": "000",
      "name": "科目体系",
      "type": "019"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "001",
      "id": "252409199828402176",
      "isAsync": "000",
      "name": "计算公式",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "000",
      "id": "253414934167158784",
      "isAsync": "000",
      "name": "自动取值",
      "type": "000"
    },
    {
      "areaObjType": "021",
      "dataType": "000",
      "existSub": "000",
      "id": "190314104423315535",
      "isAsync": "000",
      "name": "魔钥资源",
      "type": "021"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "001",
      "id": "256044202313383936",
      "isAsync": "000",
      "name": "优化",
      "type": "000"
    },
    {
      "areaObjType": "022",
      "dataType": "000",
      "existSub": "000",
      "id": "190314104423315536",
      "isAsync": "000",
      "name": "魔钥物品",
      "type": "022"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "001",
      "id": "257236262938738688",
      "isAsync": "000",
      "name": "审核测试",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "000",
      "id": "258136033035550720",
      "isAsync": "000",
      "name": "会议室",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "000",
      "id": "260112217302630400",
      "isAsync": "000",
      "name": "通报公司名称",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "000",
      "id": "262940024663703552",
      "isAsync": "000",
      "name": "业务负责人",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "000",
      "id": "262945025927675904",
      "isAsync": "000",
      "name": "省份",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "000",
      "id": "262945496074551296",
      "isAsync": "000",
      "name": "卫视",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "001",
      "id": "265194657085915136",
      "isAsync": "000",
      "name": "自动赋值",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "001",
      "id": "266113991824113664",
      "isAsync": "000",
      "name": "时间差",
      "type": "000"
    },
    {
      "areaObjType": "000",
      "dataType": "000",
      "existSub": "000",
      "id": "280147459672375296",
      "isAsync": "000",
      "name": "cesss",
      "type": "000"
    }
  ];
  return objList;
};

// /明细表属性
export const attributesList = () => {
  const attrList = [
    {
      "attributeId": "246805218216378384",
      "field": "deptAndPost",
      "fieldName": "兼岗信息",
      "fieldType": "18",
      "id": "246805218216378384",
      "objtypeId": "171211134003906183",
      "requrein": "1"
    },
    {
      "attributeId": "246805218216378393",
      "field": "item15",
      "fieldName": "明细表",
      "fieldType": "11",
      "id": "246805218216378393",
      "objtypeId": "171211134003906183",
      "requrein": "1"
    },
    {
      "attributeId": "246805218216378398",
      "field": "bankInfo",
      "fieldName": "银行卡信息",
      "fieldType": "19",
      "id": "246805218216378398",
      "objtypeId": "171211134003906183",
      "requrein": "1"
    },
    {
      "attributeId": "258567383487086594",
      "field": "item37",
      "fieldName": "明细表1",
      "fieldType": "11",
      "id": "258567383487086594",
      "objtypeId": "171211134003906183",
      "requrein": "1"
    }
  ];
  return attrList;
};
