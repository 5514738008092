<template>
  <div class="third-step-contain">
    <div class="file-check-contain">
      <div class="file-desc-contain">
        <en-icon
          name="excel-wendangyangshi"
          size="24px"
          :style="{fill:'#58CB7E'}"
        ></en-icon>
        <span class="file-name">销售机会信息表.xlsx</span>
      </div>

      <el-progress
        class="file-progress"
        :color="progressColor"
        :show-text="false"
        :stroke-width="10"
        :percentage="upProgress"
      />

      <template v-if="isCheckError">
        <div class="check-error-contain">
          <div @click="clearErrorClick">
            <en-icon
              name="shibai"
              size="small"
              class="delete-icon"
              :style="{fill:'#F76B6B'}"
            ></en-icon>
          </div>

          <span class="check-text1">数据校验失败,请修正后重新校验</span>
          <span
            class="check-text2"
            @click="lookCheckLog"
          >查看错误日志</span>
        </div>
      </template>

      <template v-else>
        <div
          class="file-progress-text"
          :style="progressTextColor"
          @click="progressClick"
        >
          {{ upProgress }}%
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThirdStep",
  data() {
    return {
      myVar: "",
      upProgress: 90,
      isCheckError: false,
      progressColor: "#4694DF",
      progressTextColor: "color:#4694DF;"
    };
  },
  watch: {
    upProgress(val) {
      if (this.isCheckError) {
        this.progressColor = "#f76B6B";
      } else {
        if (val === 100) {
          this.progressColor = "#26C393";
          this.progressTextColor = "color:#26C393;";
        } else {
          this.progressColor = "#4694DF";
          this.progressTextColor = "color:#4694DF;";
        }
      }
    }
  },
  methods: {
    lookCheckLog() {
      alert("查看错误日志");
    },
    progressClick() {
      if (this.upProgress >= 100) {
        this.upProgress = 0;
      } else {
        window.clearInterval(this.myVar);
        this.myVar = "";
        const self = this;
        const myVar = window.setInterval(function() {
          self.upProgress += 1;
          if (self.upProgress >= 100) {
            self.upProgress = 100;
            window.clearInterval(myVar);
          } else if (self.upProgress === 10) {
            self.isCheckError = true;
            window.clearInterval(self.myVar);
            self.myVar = "";
          }
        }, 100);
        this.myVar = myVar;
      }
    },
    clearErrorClick() {
      this.upProgress = 85;
      this.isCheckError = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.third-step-contain {
  .file-check-contain {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    max-height: 113px;
    min-height: 100px;

    background: rgba(251, 251, 251, 1);
    border: 1px solid rgba(232, 236, 242, 1);
    border-radius: 4px;
    text-align: left;

    .file-desc-contain {
      display: flex;
      align-items: center;
      position: relative;
      margin: 22px 10px 4px 21px;
      .file-name {
        margin-left: 15px;
        height: 12px;
        line-height: 12px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        display: inline-block;
      }
    }

    .file-progress {
      position: relative;
      margin: 0px 20px 0px 60px;
      height: 10px;
      background: rgba(224, 228, 239, 1);
      border-radius: 5px;
    }

    .file-progress-text {
      position: relative;
      margin-top: 8px;
      margin-right: 21px;
      height: 12px;
      line-height: 12px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #4694df;
      text-align: right;
    }

    .check-error-contain {
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin: 14px 20px 23px;
      height: 16px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: rgba(247, 107, 107, 1);

      .delete-icon {
        height: 16px;
        width: 16px;
      }

      .check-text1 {
        display: inline-block;
        margin-left: 5px;
        height: 16px;
        line-height: 16px;
        color: #f76b6b;
      }

      .check-text2 {
        display: inline-block;
        margin-left: 8px;
        height: 16px;
        line-height: 16px;
        color: #4694df;
        text-decoration: underline;
      }
    }
  }
}
</style>
