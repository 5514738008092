/*
 * @Author: 周晴龙
 * @Date: 2020-03-13 15:34:45
 * @LastEditTime: 2020-04-22 11:13:29
 * @LastEditors: Please set LastEditors
 * @Description: 千分符带两位小数点
 * @param iconCode
 * @FilePath: \enfry-components\packages\methods\thousand.js
 */
/**
 * @param value
 */

function thousandSep(value) {
  if (value === null || value === undefined) {
    return null;
  } else if (isNaN(value)) {
    return "NaN";
  } else {
    let num = value.toFixed(2).toString();
    if (/^-?\d+\.?\d+$/.test(num)) {
      if (/^-?\d+$/.test(num)) {
        num = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      } else {
        const numarray = num.split(".");
        const numbefore = numarray[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        num = numbefore + "." + numarray[1];
      }
    }
    return num;
  }
}
export default thousandSep;
