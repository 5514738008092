<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-09-24 13:36:06
 * @LastEditTime: 2020-04-28 17:47:28
 -->
<template>
  <div class="en-notification-model">
    <el-form
      ref="form"
      :model="notificationData"
      :rules="rules"
      label-position="left"
      label-width="75px"
    >
      <el-form-item label="通知名称" prop="defaultValue.title">
        <el-input
          v-model="notificationData.defaultValue.title"
          placeholder="请输入通知名称"
          class="model-input"
        ></el-input>
        <en-icon
          v-if="shouldShowDeleteModelButton"
          name="shanchu-liebiao"
          class="delete-model"
          size="18"
          @click.native="handelDeleteModel"
        >
        </en-icon>
      </el-form-item>
      <el-form-item label="通知方式" prop="defaultValue.method">
        <el-select
          v-model="notificationData.defaultValue.method"
          multiple
          placeholder="通知方式"
          class="model-input"
        >
          <el-option label="en+" value="1"></el-option>
          <el-option label="短信" value="2"></el-option>
          <el-option label="邮箱" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="shouldShowConditionTimeAndObject" label="发送时间">
        <el-select
          v-model="notificationData.defaultValue.sendType"
          placeholder="选择发送时间"
          class="model-input"
        >
          <el-option
            v-for="option in sendTimeOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="通知内容">
        <notification-content
          v-if="notificationContentVisible"
          ref="notificationContent"
          :dialog-visible.sync="notificationContentVisible"
          :datas.sync="notificationData.defaultValue.contentConfig"
        >
        </notification-content>
        <en-image-setting
          name="tongzhineirong-liuchengshuxing"
          size="small"
          fill="#4694DF"
          :complete="notificationData.defaultValue.contentConfig.code"
          @click.native="notificationPressed"
        >
          设置
        </en-image-setting>
      </el-form-item>
      <el-form-item v-if="shouldShowConditionTimeAndObject" label="通知对象">
        <notify-object
          v-if="notificationObjectVisible"
          ref="notificationObject"
          :visible.sync="notificationObjectVisible"
          :datas.sync="notificationData.defaultValue.notifyObj"
          :type="notifyObjectType"
        >
        </notify-object>
        <en-image-setting
          name="tongzhiduixiang-liuchengshuxing"
          size="small"
          fill="#4694DF"
          complete
          @click.native="notificationObjectVisible = true"
        >
          设置
        </en-image-setting>
      </el-form-item>
      <el-form-item v-if="shouldShowConditionTimeAndObject" label="触发条件">
        <notification-condition
          v-if="notificationConditionVisible"
          ref="notificationCondition"
          :dialog-visible.sync="notificationConditionVisible"
          :datas.sync="notificationData.defaultValue.conditionConfig"
          :type="type"
        >
        </notification-condition>
        <en-image-setting
          name="chufatiaojian-liuchengshuxing"
          size="small"
          fill="#4694DF"
          :complete="notificationData.defaultValue.conditionConfig.length > 0"
          @click.native="notificationConditionVisible = true"
        >
          设置
        </en-image-setting>
      </el-form-item>
      <el-form-item v-if="couldOperate" label="操作按钮">
        <operating-buttons
          v-if="notificationOperateButtonVisible"
          ref="operatingButtons"
          :dialog-visible.sync="notificationOperateButtonVisible"
          :operate-data.sync="notificationData.defaultValue.operate"
          :type="type"
        >
        </operating-buttons>
        <en-image-setting
          name="caozuoanniu-tongzhijianmo"
          size="small"
          fill="#4694DF"
          :complete="notificationData.defaultValue.operate.name.length > 0"
          @click.native="notificationOperateButtonVisible = true"
        >
          设置
        </en-image-setting>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import NotificationContent from "./NotificationContent.vue";
import NotificationCondition from "./NotificationCondition.vue";
import OperatingButtons from "./OperatingButtons.vue";
import NotifyObject from "./notifyObject.vue";
export default {
  name: "EnNotificationModel",
  components: {
    NotificationContent,
    NotificationCondition,
    NotifyObject,
    OperatingButtons
  },
  props: {
    // 通知模型设置支持通知建模中的基础数据/业务建模/流程/hr,以及单独关联的 基础数据等
    type: {
      type: String,
      default: "business",
      invalidator: function(val) {
        return (
          [
            "notificationBasic", // 通知建模 - 基础数据
            "notificationBusiness", // 通知建模 - 业务建模
            "notificationFlow", // 通知建模 - 流程
            "notificationHR", // 通知建模 - HR
            "basic", // 基础数据
            "business", // 业务建模
            "flow", // 流程
            "HR" // HR
          ].indexOf(val) > -1
        );
      }
    },
    // 可以不可以操作,决定有没有操作按钮设置
    couldOperate: {
      type: Boolean,
      default: false
    },
    notificationData: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      rules: {
        "defaultValue.title": [
          {
            required: true,
            message: "请输入模型名称",
            trigger: "blur"
          },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur"
          }
        ],
        "defaultValue.method": [
          {
            type: "array",
            required: true,
            message: "请至少选择一种通知方式",
            trigger: "change"
          }
        ]
      },
      notificationContentVisible: false,
      notificationConditionVisible: false,
      notificationObjectVisible: false,
      notificationOperateButtonVisible: false
    };
  },
  computed: {
    // 转换通知对象需要的type
    notifyObjectType() {
      switch (this.type) {
        case "notificationBasic": // 通知建模 - 基础数据
        case "notificationBusiness": // 通知建模 - 业务建模
        case "notificationFlow": // 通知建模 - 流程
        case "notificationHR": // 通知建模 - HR:
          return "notice";
        case "flow":
          return "flow";
        case "business":
        case "basic":
          return "business";
        default:
          return "";
      }
    },
    // 是否触发条件.发送时间,通知对象,这三者目前显示的逻辑都一样,所以写一块
    shouldShowConditionTimeAndObject() {
      return this.type !== "notificationHR" && this.type !== "HR";
    },
    // 只有当支持多个的时候才需要删除按钮,HR比较特殊,不需要此按钮
    shouldShowDeleteModelButton() {
      return (
        this.type === "flow" ||
        this.type === "business" ||
        this.type === "basic"
      );
    },
    // 发送时间...
    sendTimeOptions() {
      switch (this.type) {
        case "notificationFlow": {
          return [
            {
              value: 1,
              label: "触发条件后"
            },
            {
              value: 2,
              label: "流程结束后"
            }
          ];
        }
        case "notificationBasic":
        case "notificationBusiness":
          return [
            {
              value: 1,
              label: "触发条件后"
            }
          ];
        default:
          return [];
      }
    }
  },
  watch: {
    // 切换type时,把发送时间更改过来
    type() {
      this.notificationData.defaultValue.sendType = 1;
    }
  },
  methods: {
    // 打开通知内容
    notificationPressed() {
      this.notificationContentVisible = true;
    },
    // 校验...
    checkForm() {
      let contentCheck = false;
      this.$refs.form.validate(valid => {
        if (valid) {
          contentCheck = true;
        } else {
          contentCheck = false;
        }
      });
      return contentCheck;
    },
    // 删除模板
    handelDeleteModel() {
      this.$emit("deleteModel");
    }
  }
};
</script>
<style lang="scss" scoped>
.en-notification-model {
  // 表单高度
  .el-form /deep/ .el-form-item__content {
    font-size: 12px;
    line-height: 32px;
  }
  & /deep/ .el-form-item__label {
    font-size: 12px;
  }
  & /deep/ .el-form-item__label::before {
    display: none;
  }
  .el-form-item {
    margin-bottom: 16px;
    max-width: 600px;
    font-size: 12px;
    & /deep/ .el-form-item__content {
      font-size: 12px;
      display: flex;
      position: relative;
      .delete-model {
        position: absolute;
        fill: #f76b6b;
        right: 0px;
        top: 0px;
      }
      .model-input {
        flex: 1;
        margin-right: 90px;
      }
    }
  }
  .el-form-item:nth-last-child(1) {
    margin-bottom: 0px;
  }
  .en-image-setting {
    height: 32px;
    line-height: 32px;
  }
  background: rgba(245, 247, 250, 1);
  margin-left: -20px;
  margin-right: -20px;
  padding: 16px 20px 16px 20px;
}
</style>
