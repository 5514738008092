/* eslint-disable func-names */
import Vue from "vue";
import Component from "./fun-menu.js";

const RightMenuConstructor = Vue.extend(Component);

let instance = null;

const proxyRrightMenu = (function() {
  let _instance = null;
  return function(options) {
    if (!_instance) {
      RightMenuConstructor.prototype.$closed = function() {
        if (_instance) {
          _instance = null;
        }
      };
      _instance = new RightMenuConstructor({
        propsData: options
      });
    }
    return _instance;
  };
}());

export const openRightMenu = function(options) {
  if (instance !== null && instance.vm) {
    instance.vm.close();
  }
  instance = proxyRrightMenu(options);
  instance.vm = instance.$mount();
  instance.vm.$on("closed", () => {
    instance.vm.$destroy();
    instance.$closed();
    document.body.removeChild(instance.vm.$el);
  });

  document.body.appendChild(instance.vm.$el);
  return instance.vm;
};
export const closeRightMenu = function() {
  if (instance !== null && instance.vm) {
    instance.vm.close();
  }
};
