<template>
  <div :id="domId" class="bar-stack" ref="barYStack"></div>
</template>

<script>
const elementResizeDetectorMaker = require("element-resize-detector");
export default {
  name: "BarYStack",
  props: {
    store: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      charts: {},
      seriesData: [],
      isDone: false
    };
  },
  mounted() {
    if (this.erd) {
      return;
    }
    const erd = elementResizeDetectorMaker();
    const that = this;
    this.erd = erd.listenTo(document.getElementById(this.domId), function(
      element
    ) {
      that.resizeCharts();
    });
  },
  unmounted() {
    if (this.erd) {
      this.erd.uninstall(document.getElementById(this.domId));
    }
  },
  computed: {
    domId() {
      return "bar_y_stack_" + (Math.random() * 90000000000).toFixed(0);
    }
  },
  created() {
    this.$nextTick(() => {
      this.checkApply();
      window.addEventListener("scroll", this.checkApply, true);
      window.addEventListener("resize", this.resizeCharts, true);
    });
  },
  methods: {
    /**
     * @description 检测图表是否需要生成
     */
    checkApply() {
      if (!this.isDone && this.store.showCharts(this.$refs.barYStack)) {
        this.isDone = true;
        this.initChartsInfo();
      }
    },
    /**
     * @description 初始化图表信息
     */
    initChartsInfo() {
      this.charts = window.$charts.init(document.getElementById(this.domId));
      this.dataFormat();
      this.initChartsDataInfo();
    },
    /**
     * @description 图表数据整理
     */
    dataFormat() {
      this.store.getXData.map(item => {
        this.seriesData.push({
          name: item.name,
          type: "bar",
          stack: this.store.getStackLabel,
          barMaxWidth: "20px",
          itemStyle: this.store.getYBarStyle,
          data: this.store.getDataKey ? item[this.store.getDataKey] : item
        });
      });
      // const lastObj = this.seriesData[this.seriesData.length - 1];
      // lastObj.itemStyle = this.store.getYBarStyle;
    },
    /**
     * @description 初始化图表及图表数据信息
     */
    initChartsDataInfo() {
      const _this = this;
      _this.charts.setOption(
        {
          tooltip: _this.store.getToolTip,
          title: _this.store.getTitleInfo,
          legend: _this.store.getLegendConfig,
          grid: _this.store.getGridConfig,
          color: _this.store.getColorList,
          yAxis: _this.store.getAxisConfig,
          xAxis: _this.store.getAxisValue,
          series: _this.seriesData
        },
        true
      );
      // 点击事件处理
      _this.chartsInfo.on("click", params => {
        _this.$emit("change", {
          xIndex: [params.componentIndex],
          yIndex: [params.dataIndex],
          params: params,
          totalData: _this.seriesData
        });
      });
    },
    /**
     * @description 窗口尺寸变化，重绘图表
     */
    resizeCharts() {
      if (!this.isDone) {
        return;
      }
      this.chartsInfo.resize();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkApply, false);
    window.removeEventListener("resize", this.resizeCharts, false);
  }
};
</script>

<style lang="scss"></style>
