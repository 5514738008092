<template>
  <div class="en-calendar-picker">
    <div v-if="needHead" class="en-calendar-picker-header">
      <en-icon name="fanhui" @click.native="changeMonth(-1)"> </en-icon>
      <div class="label">
        {{ currentYear + "/" + currentMonth }}
      </div>
      <en-icon name="fanhui" @click.native="changeMonth(1)"> </en-icon>
    </div>
    <div class="en-calendar-picker-week">
      <div v-for="item in weekArr" :key="item" class="en-calendar-picker-week-item">
        {{ item }}
      </div>
    </div>
    <div class="en-calendar-picker-body">
      <div
        v-for="(item, index) in list"
        :key="item.timeStamp + index"
        :class="{
          'other-month': item.isOtherMonth,
          'current-date': item.timeStamp === currentTimeStamp
        }"
        class="en-calendar-picker-item"
      >
        <div class="label">
          {{ item.date }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMonthData,
  getWholeMonthData,
  getTimeStamp
} from "../../../methods/datePickerInit";

export default {
  name: "EnCalendarPicker",
  props: {
    // 是否需要头部
    needHead: {
      type: Boolean,
      default: true
    },
    // 是否需要整个数据（包含上下月）
    otherMonth: {
      type: Boolean,
      default: true
    },
    // 每个礼拜的起始天，0是礼拜天，1是礼拜一
    startDay: {
      type: [Number, String],
      default: () => {
        return this.startDay === 1 ? 1 : 0;
      }
    }
  },
  data() {
    return {
      list: [],
      // 当前时间戳
      currentTimeStamp: "",
      // 当前年
      currentYear: null,
      // 当前月
      currentMonth: null,
      // 当前天
      currentDate: null
    };
  },
  computed: {
    // 此处由于日历面板的第一天位置需要动态变幻
    weekArr() {
      if (parseInt(this.startDay, 10) === 1) {
        return ["一", "二", "三", "四", "五", "六", "日"];
      } else {
        return ["日", "一", "二", "三", "四", "五", "六"];
      }
    }
  },
  mounted() {
    this.getDateInfo();
  },
  methods: {
    getDateInfo() {
      const d = new Date();
      this.currentYear = d.getFullYear();
      this.currentMonth = d.getMonth() + 1;
      this.currentDate = d.getDate();
      this.getListData();
      this.getTimeStamp();
    },
    /**
     * @description 获取列表数据
     */
    getListData() {
      if (this.otherMonth) {
        this.list = getWholeMonthData(
          this.currentYear,
          this.currentMonth,
          parseInt(this.startDay, 10)
        );
      } else {
        this.list = getMonthData(
          this.currentYear,
          this.currentMonth,
          parseInt(this.startDay, 10)
        );
      }
    },
    /**
     * @description 获取当前天的时间戳
     */
    getTimeStamp() {
      this.currentTimeStamp = getTimeStamp(
        this.currentYear,
        this.currentMonth,
        this.currentDate
      );
    },
    /**
     * @description 月份切换
     * @param type 1为下个月，-1为上个月
     */
    changeMonth(type) {
      if (type === 1) {
        if (this.currentMonth === 12) {
          this.currentMonth = 1;
          this.currentYear++;
        } else {
          this.currentMonth++;
        }
      } else {
        if (this.currentMonth === 1) {
          this.currentMonth = 12;
          this.currentYear--;
        } else {
          this.currentMonth--;
        }
      }
      this.getListData();
    }
  }
};
</script>

<style lang="scss" scoped>
.en-calendar-picker {
  width: 100%;
  border: 1px solid #e8ecf2;

  .en-calendar-picker-header {
    display: flex;
    justify-content: flex-start;
    line-height: 44px;
    font-size: 12px;
    background-color: #f7f9f9;
    color: #636c78;
    border-bottom: 1px solid #e8ecf2;
    cursor: pointer;

    .en-icon {
      width: 12px;
      height: 12px;
      margin: 16px 5px;

      &:nth-last-child(1) {
        transform: rotate(180deg);
      }
    }
  }

  .en-calendar-picker-week {
    display: flex;
    justify-content: flex-start;
    line-height: 48px;
    background-color: #f6fafd;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    color: #333333;
    font-size: 12px;
    position: relative;
    z-index: 2;

    .en-calendar-picker-week-item {
      width: calc(100% / 7);
      text-align: center;
      border-right: 1px solid #e8ecf2;

      &:nth-last-child(1) {
        border-right: none;
      }
    }
  }

  .en-calendar-picker-body {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .en-calendar-picker-item {
      height: 130px;
      position: relative;
      width: calc(100% / 7);
      border-right: 1px solid #e8ecf2;
      border-top: 1px solid #e8ecf2;
      cursor: pointer;

      &:hover {
        background-color: #f5f9fd;
      }

      /* 每行最后一个单元格的样式 */
      &:nth-child(7n) {
        border-right: none;
      }

      .label {
        position: absolute;
        right: 14px;
        top: 7px;
        width: 24px;
        height: 24px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        color: #333333;
        line-height: 24px;
        font-size: 12px;
        text-align: center;
      }

      /* 当前天样式 */
      &.current-date {
        .label {
          background-color: #4694df;
          color: #ffffff;
        }
      }

      /* 其他月份样式 */
      &.other-month {
        .label {
          color: #cdcdcd;
        }
      }
    }
  }
}
</style>
