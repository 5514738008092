<template>
  <component
    :is="type"
    :icon-size="iconSize"
    class="en-result"
    v-bind="this.$attrs"
  >
  </component>
</template>

<script>
import NotFound from "./notfound/not-found"// 404未找到
import NoData from "./nodata/no-data" // 无数据
import SelectTreeNoData from "./SelectTreeNoData" // 无数据
import BrokenNet from "./brokenNet/broken-net"// 断网了
import NodataAdd from "./nodataAdd/nodata-add"// 无数据提示添加
import Welcome from "./welcome/welcome"// 首页无数据
import NoTrip from "./noTrip/notrip"// 新增行程
import NoMsg from "./nomsg/no-msg"// 暂无消息
import NoApproval from "./noApproval/no-approval"// 暂无审批
import NoFile from "./noFile/no-file"// 暂无内容/文件
import NoAddress from "./noAddress/no-address"// 暂无地址
import NoImg from "./noImg/no-img"// 暂无图片
import NoPersonnel from "./noPersonnel/no-personnel"// 暂无人员/客户
import NoRecord from "./noRecord/no-record" // 暂无记录
import NoCustom from "./noCustom/custom"// 自定义无数据提示
import loading from "./loading/loading"// 白底加载动画
import noBgloading from "./noBgloading/loading"// 无底加载动画
export default {
  name: "EnResult",
  components: {
    NoData,
    SelectTreeNoData,
    NotFound,
    BrokenNet,
    NodataAdd,
    Welcome,
    NoTrip,
    NoMsg,
    NoApproval,
    NoFile,
    NoAddress,
    NoImg,
    NoPersonnel,
    NoRecord,
    NoCustom,
    loading,
    noBgloading
  },
  props: {
    type: {
      type: String,
      default: null
    },
    iconSize: {
      type: [String, Number],
      default: "normal"
    }
  }

}
</script>
<style lang="scss">
  .en-result {
    padding: 48px 32px;
    text-align: center;

    &-icon {
      margin-bottom: 15px;
      text-align: center;
    }

    .icon-img {
      margin-bottom: 15px;
    }

    .title {
      color: #91a1b7;
      font-size: 24px;
      line-height: 1.8;
      text-align: center;
    }

    .subtitle {
      color: #91a1b7;
      font-size: 14px;
      line-height: 1.6;
      text-align: center;
    }

    &-extra {
      margin-top: 32px;
      text-align: center;

      & > * {
        margin-right: 8px;
      }
    }
  }
</style>
