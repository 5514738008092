/**
 * project: enfry-components
 * fileName: index
 * Created by 徐俊 on 2020/08/10
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import component from "./src";
component.install = function(Vue) {
  Vue.component(component.name, component);
};
export default component;
