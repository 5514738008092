<template>
  <div
    class="en-card-normal"
    :class="{'disabled':disabled && disabled !== 'false'}"
    :style="{
      'width': width ? width : '220px',
      'height': height ? height : '80px'
    }"
    @mouseleave.stop="toggleMenu(false)"
  >
    <!-- 标签插槽 -->
    <div class="en-card-tag">
      <slot name="tag"></slot>
    </div>
    <!-- 图标区域 -->
    <div
      class="en-card-normal-icon"
      v-if="!withIcon && iconName && iconName !== ''"
      :style="{
        'background-color': iconBg ? iconBg : '',
         'color': iconColor
      }"
    >
      <en-icon :name="iconName"></en-icon>
    </div>
    <!-- 图标插槽 -->
    <div class="en-card-normal-icon" v-if="withIcon">
      <slot name="icon"></slot>
    </div>
    <!-- 主区域 -->
    <div class="en-card-normal-main" v-if="!withContent && !withRight">
      <!-- 显示提示文本的标题 -->
      <el-tooltip v-if="showTips && showTips !== 'false'" :content="title">
        <div class="en-card-normal-main-title">
          {{ title }}
        </div>
      </el-tooltip>
      <!-- 显示提示文本的副标题 -->
      <el-tooltip v-if="showTips && showTips !== 'false'" :content="subTitle">
        <div class="en-card-normal-main-sub-title">
          {{ subTitle }}
        </div>
      </el-tooltip>
      <!-- 不显示提示文本的标题 -->
      <div class="en-card-normal-main-title" v-if="!showTips || showTips === 'false'">
        {{ title }}
      </div>
      <!-- 不显示提示文本的副标题 -->
      <div class="en-card-normal-main-sub-title" v-if="!showTips || showTips === 'false'">
        {{ subTitle }}
      </div>
    </div>
    <!-- 主区域插槽 -->
    <div class="en-card-normal-main" v-if="withContent && !withRight">
      <slot name="content"></slot>
    </div>
    <!-- 整体区域插槽 -->
    <div class="en-card-normal-main" v-if="withRight">
      <slot name="right"></slot>
    </div>
    <!-- 下拉菜单 -->
    <div class="en-card-normal-menu" v-if="editAble && editAble !== 'false'">
      <!-- 编辑图标 -->
      <en-icon
        name="bianji1"
        class="en-card-normal-menu-icon"
        @mouseover.native.stop="toggleMenu(true)"
      >
      </en-icon>
      <en-collapse-area>
        <div
          class="en-card-normal-menu-list-container"
          v-show="showMenu"
          @mouseleave.stop="toggleMenu(false)"
        >
          <!-- 无菜单插槽时的下拉菜单 -->
          <div
            class="en-card-normal-menu-list"
            v-if="!withMenu"
          >
            <!-- 默认菜单 -->
            <div
              class="en-card-normal-menu-item"
              v-for="item in menuList"
              :key="item[menuKey]"
              @click.stop="selectMenu(item)"
            >
              {{ item[menuLabel] }}
            </div>
          </div>
          <!-- 下拉菜单插槽 -->
          <slot name="menu" v-if="withMenu"></slot>
        </div>
      </en-collapse-area>
    </div>
  </div>
</template>

<script>
export default {
  name: "normal",
  props: {
    // 图标名称
    iconName: {
      type: String,
      default: ""
    },
    // 图标颜色
    iconColor: {
      type: String,
      default: ""
    },
    // 图标底色
    iconBg: {
      type: String,
      default: ""
    },
    // 卡片宽度
    width: {
      type: [String, Number],
      default: "220px"
    },
    // 卡片高度
    height: {
      type: [String, Number],
      default: "80px"
    },
    // 卡片标题
    title: {
      type: String,
      default: ""
    },
    // 卡片副标题
    subTitle: {
      type: String,
      default: ""
    },
    // 卡片描述
    description: {
      type: String,
      default: ""
    },
    // 是否需要显示提示信息
    showTips: {
      type: [Boolean, String],
      default: true
    },
    // 卡片类型=>目前只提供 常用尺寸、大尺寸两种尺寸
    type: {
      type: String,
      default: "normal",
      validator: (val) => {
        return ["normal", "big"].indexOf(val) > -1;
      }
    },
    // 是否禁用
    disabled: {
      type: [Boolean, String],
      default: false
    },
    // 卡片数据源信息
    dataInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 是否可编辑=>与menuList搭配使用
    editAble: {
      type: [String, Boolean],
      default: false
    },
    // 下拉菜单数组
    menuList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 下拉菜单显示文本字段
    menuLabel: {
      type: String,
      default: "name"
    },
    // 下拉菜单唯一标识字段
    menuKey: {
      type: String,
      default: "id"
    }
  },
  data() {
    return {
      showMenu: false
    };
  },
  computed: {
    // 检测是否有内容区插槽
    withContent() {
      return this.$slots.content;
    },
    // 检测是否有图标插槽
    withIcon() {
      return this.$slots.icon;
    },
    // 检测是否有菜单插槽
    withMenu() {
      return this.$slots.menu;
    },
    // 检测是否有右侧整体插槽
    withRight() {
      return this.$slots.right;
    }
  },
  methods: {
    /**
     * @description 切换菜单显示状态
     * @param status Boolean
     */
    toggleMenu(status) {
      this.showMenu = status;
    },
    /**
     * @description 选中某一菜单后，触发回调并隐藏下拉菜单
     * @param menu
     */
    selectMenu(menu) {
      this.showMenu = false;
      this.$emit("change", menu, this.dataInfo);
    }
  }
};
</script>

<style lang="scss" scoped>
  .en-card-normal {
    border-radius: 4px 4px 4px;
    border: 1px solid #E8ECF2;
    background-color: #ffffff;
    cursor: pointer;
    padding: 22px 14px;
    display: flex;
    justify-content: flex-start;
    transition: .3s ease-in-out all;
    position: relative;
    // 标签区域样式
    .en-card-tag {
      position: absolute;
      top: 0;
      left: 0;
    }
    // 图标区域样式
    .en-card-normal-icon {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      padding: 9px 9px 9px;
      margin-right: 14px;

      .en-icon {
        width: 18px;
        height: 18px;
      }
    }

    // 主内容区域样式
    .en-card-normal-main {
      width: calc(100% - 50px);

      .en-card-normal-main-title {
        font-size: 12px;
        color: #333333;
        line-height: 12px;
        margin-top: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .en-card-normal-main-sub-title {
        font-size: 12px;
        color: #91a1b7;
        line-height: 12px;
        margin-top: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    // 菜单区域样式
    .en-card-normal-menu {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 2;

      .en-card-normal-menu-icon {
        width: 16px;
        height: 16px;
        color: #4694DF;
        display: none;
      }

      .en-card-normal-menu-list-container {
        position: absolute;
        right: -13px;
        top: 16px;

        .en-card-normal-menu-list {
          width: 120px;
          background-color: #ffffff;
          border: 1px solid #E8ECF2;
          box-shadow: 0 5px 5px 0 rgba(236, 236, 236, 0.75);
          border-radius: 4px;
          margin-top: 17px;

          .en-card-normal-menu-item {
            color: #636C78;
            cursor: pointer;
            line-height: 32px;
            font-size: 12px;
            padding-left: 13px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              background-color: #4694df;
              color: #ffffff;
            }
          }
        }
      }
    }

    // 悬浮状态下的样式处理
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

      .en-card-normal-menu-icon {
        display: block;
      }
    }

    // 禁用状态下的样式处理
    &.disabled {
      background-color: #FBFBFB;

      .en-card-normal-icon {
        opacity: .5;
      }

      .en-card-normal-main {
        .en-card-normal-main-title {
          color: rgba(51, 51, 51, 0.5);
        }

        .en-card-normal-main-sub-title {
          color: rgba(145, 161, 183, 0.5);
        }
      }
    }
  }
</style>
