<!--
 * @Description: 平台头像
 * @Author: 梁平贤
 * @LastEditors: 梁平贤
 * @Date: 2019-10-19 13:57:10
 * @LastEditTime: 2020-04-28 17:47:42
 -->
<template>
  <div class="en-platform-logo">
    <en-user-logo
      image-url=""
      user-name="啊哈"
      size="30px"
      class="logo"
    ></en-user-logo>
    <span class="name">管理员/用户(等待用户信息接口完善)</span>
    <en-icon
      name="zhankai"
      class="point"
      size="10px"
    >
    </en-icon>
  </div>
</template>
<script>
export default {
  name: "EnPlatformLogo",
  props: {
    // 用户信息 不需要这个  直接按照规则自己读
    userInfos: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state._userInfo;
    }
  }
};
</script>
<style lang='scss' scoped>
.en-platform-logo {
  cursor: pointer;
  .name {
    font-size: 12px;
    color: white;
    vertical-align: middle;
    margin-left: 6px;
  }
  .logo {
    vertical-align: middle;
  }
  .point {
    fill: white;
    margin-left: 10px;
    vertical-align: middle;
  }
}
</style>
