<template>
  <div class="en-title-card">
    <en-icon
      v-if="needBack || needBack === 'true'"
      name="fanhui"
      class="en-title-card-back"
      @click.native="back"
    >
    </en-icon>
    <!-- 模块名称 -->
    <div class="en-title-card-name">
      {{ name }}
    </div>
    <!-- 名称左侧组件插槽 -->
    <div class="en-title-card-left-component">
      <slot name="left"></slot>
    </div>
    <!-- 模块名称组件右侧内容区域 -->
    <div class="en-title-card-right-container">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "EnTitleCard",
  props: {
    name: {
      type: String,
      default: ""
    },
    needBack: {
      type: [Boolean, String],
      default: false
    }
  },
  methods: {
    back() {
      this.$emit("back");
    }
  }
};
</script>

<style lang="scss" scoped>
  .en-title-card {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 4px 4px 0 0;
    background-color: #ffffff;
    border-bottom: 1px solid #dce5ec;
    text-align: left;
    padding: 0 20px;
    box-sizing: border-box;
    user-select: none;

    .en-title-card-back {
      position: relative;
      color: #a9b5c6;
      cursor: pointer;
      width: 14px;
      height: 14px;

      &:hover {
        color: #4694df;
      }
    }

    .en-title-card-name {
      font-size: 14px;
      color: #333333;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      display: inline;
    }

    .en-title-card-left-component {
      display: inline;
      margin-left: 12px;

      div {
        display: inline-block;
      }
    }

    .en-title-card-right-container {
      float: right;
      padding: 9px 0;
      font-size: 12px;

      > div {
        line-height: 32px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
</style>
