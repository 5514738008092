<template>
  <div :class="['pagination','is-'+showType]" v-show="!isMoreType || (isMoreType && leaveCount() !== 0)">
    <!--左侧插槽(占个坑方便布局, 不用v-if="$slots.left")-->
    <div>
      <slot name="left"></slot>
    </div>
    <!--主区域-->
    <div class="main-box">
      <!--加载更多-->
      <template v-if="isMoreType">
        <en-button class="more-btn" type="text" :loading="disabled" @click="handlePageNoChanged(pageInfo.pageNo + 1)">{{titleMore()}}</en-button>
      </template>
      <!--上一页下一个布局-->
      <template v-else>
        <!--总条数-->
        <span class="total-count" :class="textStyle()">共{{ pageInfo.total }}条</span>
        <!--每页数量-->
        <el-select v-model="pageInfo.pageSize" :disabled="disabled" size="small" placeholder="请选择" class="page-size-box" @change="handlePageSizeChanged">
          <el-option v-for="item in pageSizeList" :key="item.pageSize" :label="showPageSize(item)" :value="item"/>
        </el-select>
        <!--第几页/总页数-->
        <span :class="textStyle()" class="page-no-count">
        {{ pageInfo.pageNo + "/" + pageInfo.totalPages }}
      </span>
        <!--上一页下一页-->
        <el-button-group class="pre-next-page-box">
          <en-button icon="icondiyiye" size="small" type="white" :disabled="pageInfo.pageNo <= 1 || disabled" @click="handlePageNoChanged(1)">
          </en-button>
          <en-button icon="iconxiayiye1" size="small" type="white" :disabled="pageInfo.pageNo <= 1 || disabled" @click="handlePageNoChanged(pageInfo.pageNo - 1)">
          </en-button>
          <en-button size="small" type="white" icon="iconxiayiye" :disabled="pageInfo.pageNo >= pageInfo.totalPages || disabled" @click="handlePageNoChanged(pageInfo.pageNo + 1)">
          </en-button>
          <en-button size="small" type="white" icon="iconzuihouyiye" :disabled="pageInfo.pageNo >= pageInfo.totalPages || disabled" @click="handlePageNoChanged(pageInfo.totalPages)">
          </en-button>
        </el-button-group>
      </template>

      <!--默认插槽-->
      <slot></slot>
    </div>
    <!--右侧插槽(只有more才有的插槽, 占个坑方便布局, 不用v-if="$slots.right") -->
    <div v-if="isMoreType">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
/**
 * @description 分页 eg.<en-pagination :page-model="pageModel" :disabled="loading"></en-pagination>
 * @author 徐俊
 * @date 2019/7/9
 */
import { pageSizeList } from "./page";

export default {
  name: "EnPagination",
  props: {
    type: String, // 类型 page(默认 上下页) /more(加载更多)
    disabled: Boolean, // 是否禁用
    pageModel: {
      // 和pageInfo取不一样的  垃圾数据蛮多
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      pageInfo: {
        // 页面信息
        pageNo: 1, // 页码
        pageSize: 10, // 分页大小
        total: 0, // 总数
        totalPages: 1 // 总页数
      },
      opType: 0, // 操作类型: 0全部 1页码 2大小
      prePageInfo: undefined, // 上一次操作的页面信息(用于报错后 复位)
      pageSizeList: pageSizeList() // 分页大小列表
    };
  },
  created() {
    this.resetPrePageInfo();
    // this.prePageInfo = new Object(this.pageInfo);
  },
  watch: {
    pageModel: {
      handler() {
        if (!this.pageModel) {
          return;
        }
        if (this.pageModel.pageNo > 0) {
          this.pageInfo.pageNo = this.pageModel.pageNo;
        }
        if (this.pageModel.pageSize > 0) {
          this.pageInfo.pageSize = this.pageModel.pageSize;
        }

        if (this.pageModel.total >= 0) {
          // 可能是undefined 所以加个判断
          this.pageInfo.total = this.pageModel.total;
        }
        if (this.pageModel.totalPages) {
          this.pageInfo.totalPages = this.pageModel.totalPages;
        }

        this.resetPrePageInfo();
      },
      deep: true,
      immediate: true
    },
    "pageInfo.pageNo": {
      handler(newVal, oldVal) {
        this.prePageInfo.pageNo = oldVal;
      },
      deep: true
    },
    "pageInfo.pageSize": {
      handler(newVal, oldVal) {
        this.prePageInfo.pageSize = oldVal;
      },
      deep: true
    }
  },
  computed: {
    // 显示类型
    showType() {
      return this.type || "page";
    },
    // 是否是更多样式
    isMoreType() {
      return this.showType === "more";
    }

  },
  methods: {
    // isSinglePage () {
    //   return this.pageInfo.totalPages <= 1;
    // },
    resetPrePageInfo() {
      this.opType = 0;
      this.prePageInfo = JSON.parse(JSON.stringify(this.pageInfo));
    },
    textStyle() {
      return this.disabled ? "disable-text" : "normal-text";
    },
    showPageSize(pageSize) {
      return "每页" + String(pageSize) + "条";
    },
    // handleGotoPageKeyChanged (e) {
    //   let keyCode = window.event ? e.keyCode : e.which;
    //   if (keyCode !== 13) {
    //     return;
    //   }
    //   alert(this.pageInfo.pageNo);
    //   // let pageNo = Number(value);
    //   // this.pageNo = pageNo;
    // },
    handlePageSizeChanged() {
      // let pageNo = this.pageInfo.pageNo;
      this.opType = 0; // 这里已经修改页码了
      this.pageInfo.pageNo = 1;
      // this.prePageInfo.pageNo = pageNo;//页码变更, 页面需要修改

      const pageModel = this.getPageModel(false);
      this.$emit("change-page-size", pageModel);
      this.$emit("change", pageModel);
    },
    handlePageNoChanged(value) {
      let pageNo = Math.floor(Number(value));

      if (pageNo <= 0 || isNaN(pageNo)) {
        pageNo = 1;
      } else if (pageNo > this.pageInfo.totalPages) {
        pageNo = this.pageInfo.totalPages;
      }

      if (pageNo === this.pageInfo.pageNo) {
        return;
      }

      this.opType = 1;
      this.pageInfo.pageNo = pageNo;
      // this.prePageInfo.pageSize = this.pageInfo.pageSize;//页面变更, 大小需要修改
      // debugger;
      // this.$message("即将跳转到第" + pageNo + "页");
      const pageModel = this.getPageModel(false);
      this.$emit("change-page-no", pageModel);
      this.$emit("change", pageModel);
    },
    reset() {
      // 对外接口
      // debugger;
      if (this.opType === 1) {
        this.pageInfo.pageNo = this.prePageInfo.pageNo;
      } else if (this.opType === 2) {
        this.pageInfo.pageSize = this.prePageInfo.pageSize;
      } else {
        this.pageInfo.pageNo = this.prePageInfo.pageNo;
        this.pageInfo.pageSize = this.prePageInfo.pageSize;
      }

      // /复位
      this.$nextTick(function() {
        this.resetPrePageInfo();
      });
    },
    getPageModel(isFirstPage) {
      // 对外使用(是否是第一页)
      return {
        pageNo: isFirstPage ? 1 : this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize
      };
    },
    // 剩余数量 (没有缓存, 主要是表格那边 用的pageConfig computed后不计算了)
    leaveCount() {
      if (this.pageModel && this.pageModel.total) {
        const count = this.pageModel.total - this.pageModel.pageNo * this.pageModel.pageSize;
        return Math.max(count, 0);
      }
      return undefined;
    },
    // 标题  (没有缓存, 主要是表格那边 用的pageConfig computed后不计算了)
    titleMore() {
      let title = "加载更多";
      const leaveCount = this.leaveCount();
      if (leaveCount !== undefined && leaveCount > 0) {
        title += "(" + leaveCount + ")";
      }
      return title
    }
  }
};
</script>

<style scoped lang="scss">
  .pagination {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    //加载更多样式
    &.is-more {
      height: 50px;
      .main-box {
        width: 100%;
        height: 100%;
        .more-btn {
          color: #aeaeae ;
          width: 100%;
          height: 100%;
        }
      }
    }

    //分页组件样式
    &.is-page {
      height: 55px;

      .main-box {
        //el-select文本样式重置
        /deep/ .el-input__inner {
          font-size: 12px;
          padding: 0 25px 0 5px;
          text-align: center;
          height: 32px !important;
        }
        //总数量
        .total-count {
          margin-right: 12px;
        }

        //分页数量
        .page-size-box {
          width: 100px;
        }

        //页码
        .page-no-count {
          margin: 0 8px;
          //min-width: 30px;
        }

        //上一页/下一页
        .pre-next-page-box {
          display: flex;
          align-items: center;
        }

        //正常文本
        .normal-text {
          color: #636c78;
          word-break: keep-all;
        }

        //禁用文本
        .disable-text {
          color: #cdcdcd;
        }
      }
    }

    //主区域
    .main-box {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-left: 15px;
      margin-right: 15px;
      /deep/.el-icon-circle-check {
        display: none;
      }
    }

    & /deep/ .el-input__suffix>.el-input__icon{
      display: none !important;
    }

  }
</style>
