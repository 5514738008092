/**
 * @Author 李煜
 * @Date 2020/7/30
 * @Description en-loading 服务方式
 */
import Vue from "vue";
import loadingVue from "./container.vue";
import { PopupManager } from "element-ui/lib/utils/popup";
import { addClass, removeClass, getStyle } from "element-ui/lib/utils/dom";
import merge from "element-ui/lib/utils/merge";
// import afterLeave from "element-ui/lib/utils/after-leave";

const LoadingConstructor = Vue.extend(loadingVue);

const defaults = {
  type: "loading",
  fullscreen: true,
  status: false,
  background: "rgba(25, 48, 70, 0.5)",
  loadingConfig: {
    subTitle: "正在加载中..."
  }
};

let fullscreenLoading;

LoadingConstructor.prototype.originalPosition = "";
LoadingConstructor.prototype.originalOverflow = "";

/**
 * 关闭全屏Loading
 */
LoadingConstructor.prototype.close = function() {
  if (this.fullscreen) {
    fullscreenLoading = undefined;
  }
  // afterLeave(this, _ => {
  const target = this.fullscreen || this.body
    ? document.body
    : this.target;
  removeClass(target, "el-loading-parent--relative");
  removeClass(target, "el-loading-parent--hidden");
  if (this.$el && this.$el.parentNode) {
    this.$el.parentNode.removeChild(this.$el);
  }
  this.$destroy();
  // }, 300);
  this.status = false;
};
/**
 * 添加样是
 * @param options
 * @param parent
 * @param instance
 */
const addStyle = (options, parent, instance) => {
  const maskStyle = {};
  if (options.fullscreen) {
    instance.originalPosition = getStyle(document.body, "position");
    instance.originalOverflow = getStyle(document.body, "overflow");
    maskStyle.zIndex = PopupManager.nextZIndex();
  } else if (options.body) {
    instance.originalPosition = getStyle(document.body, "position");
    ["top", "left"].forEach(property => {
      const scroll = property === "top" ? "scrollTop" : "scrollLeft";
      maskStyle[property] = options.target.getBoundingClientRect()[property] +
        document.body[scroll] +
        document.documentElement[scroll] +
        "px";
    });
    ["height", "width"].forEach(property => {
      maskStyle[property] = options.target.getBoundingClientRect()[property] + "px";
    });
  } else {
    instance.originalPosition = getStyle(parent, "position");
  }
  Object.keys(maskStyle).forEach(property => {
    instance.$el.style[property] = maskStyle[property];
  });
};

const Loading = (options = {}) => {
  if (Vue.prototype.$isServer) return;
  if (options && typeof options === "string") {
    options = {
      loadingConfig: {
        subTitle: options
      }
    };
  }
  options = merge({}, defaults, options);
  if (typeof options.target === "string") {
    options.target = document.querySelector(options.target);
  }
  options.target = options.target || document.body;
  if (options.target !== document.body) {
    options.fullscreen = false;
  } else {
    options.body = true;
  }
  if (options.fullscreen && fullscreenLoading) {
    return fullscreenLoading;
  }

  const parent = options.body ? document.body : options.target;
  const instance = new LoadingConstructor({
    el: document.createElement("div"),
    data: options
  });

  addStyle(options, parent, instance);
  if (instance.originalPosition !== "absolute" && instance.originalPosition !== "fixed") {
    addClass(parent, "el-loading-parent--relative");
  }
  if (options.fullscreen && options.lock) {
    addClass(parent, "el-loading-parent--hidden");
  }
  parent.appendChild(instance.$el);
  Vue.nextTick(() => {
    instance.status = true;
  });
  if (options.fullscreen) {
    fullscreenLoading = instance;
  }
  return instance;
};

export default Loading;
