<template>
  <div class="components-excel-content">
    <excel-toolbar
      :store="store"
    >
    </excel-toolbar>

    <div class="en-excel-table-container">
      <table
        cellpadding="0"
        cellspacing="0"
        border="none"
      >
        <tr v-if="store.showHead" class="en-excel-table-index">
          <td class="en-excel-row-item">
          </td>
          <td
            class="en-excel-table-index-item"
            v-for="index of parseInt(store.getColNum,10)"
            :key="index"
          >
            {{ store.getColIndexInfo(index - 1) }}
          </td>
        </tr>
        <tr
          v-for="(item,index) in store.allCells"
          :key="index"
        >
          <td class="en-excel-row-item" v-if="store.showHead">
            {{ index + 1 }}
          </td>
          <excel-cell
            v-for="el in item"
            :key="el.id"
            :data-info="el"
            :class="{
            'during': el.wasDuring
          }"
            :store="store"
            draggable="false"
            @mousedown.native="mouseDown($event,el)"
            @mousemove.native="mouseMove(el)"
            @mouseup.native="mouseUp(el)"
            @contextmenu.native.prevent="setDefineData(el)"
            @drop.native="dragEnd(el)"
            @dragover.native.prevent="dragOver(el)"
            @dragleave.native.prevent="dragLevel(el)"
            @cellClick="cellClick"
          >
          </excel-cell>
        </tr>
      </table>
    </div>
    <define-menu
      :store="store"
    >
    </define-menu>
  </div>
</template>

<script>
import excelToolbar from "./toolbar";
import excelCell from "./cell";
import defineMenu from "./defineMenu";

export default {
  name: "EnExcelContent",
  components: {
    excelToolbar,
    excelCell,
    defineMenu
  },
  props: {
    store: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return { isSelectCell: false, cellInfo: {} };
  },
  mounted() {
    const _this = this;
    window.onmouseup = (e) => {
      if (e.target.localName !== "td" && _this.isSelectCell) {
        _this.isSelectCell = false;
        _this.store.clearSelectedCells();
      }
    };
  },
  methods: {
    /**
     * @description 鼠标按键按下，记录数据信息
     * @param $event
     * @param elData
     */
    mouseDown($event, elData) {
      // 鼠标点击的时候，需要判断是左键还是右键，右键触发的是自定义菜单，左键是选中
      if ($event.button !== 2) {
        this.isSelectCell = true;
        this.store.clearSelectedCells();
        this.store.setCellPositionInfo(elData);
      }
    },
    /**
     * @description 鼠标移动，记录坐标信息
     * @param elData
     */
    mouseMove(elData) {
      if (this.isSelectCell) {
        this.store.setCellPositionInfo(elData);
      }
    },
    /**
     * @description 鼠标松开，记录最后一个位置的数据信息
     * @param elData
     */
    mouseUp(elData) {
      this.store.setCellPositionInfo(elData, true);
      this.isSelectCell = false;
    },
    /**
     * @description 右键点击，记录数据
     */
    setDefineData(elData) {
      this.store.setMenuDataInfo(elData);
    },
    /**
     * @description 拖拽结束时，设置数据到对应单元格内
     */
    dragEnd(elData) {
      elData.setDataInfo(this.store.dragData);
      this.store.clearSelectedCells();
    },
    /**
     * @description 鼠标移入单元格
     */
    dragOver(elData) {
      elData.setDuringClass();
    },
    /**
     * @description 鼠标移开了
     */
    dragLevel(elData) {
      elData.removeDuringClass();
    },
    /**
     * @description 单元格点击事件
     * @param res
     */
    cellClick(res) {
      this.$emit("cellClick", res);
    }
  }
};
</script>

<style lang="scss" scoped>
  .components-excel-content {
    .en-excel-table-container {
      overflow-x: auto;
      padding: 20px 20px 20px;

      table {
        display: inline-table;
        border-collapse: collapse;
        table-layout: fixed;
        border: none;
        min-width: 100%;

        tr {
          width: 100%;
          border-bottom: none;

          &.en-excel-table-index {
            line-height: 30px;

            .en-excel-table-index-item {
              text-align: center;
            }
          }

          td {
            min-width: 120px;

            &.en-excel-row-item {
              min-width: 40px;
              max-width: 40px;
              text-align: center;
            }
          }
        }
      }
    }
  }
</style>
