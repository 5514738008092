<template>
  <div
    class="en-top-menu"
    :class="{
      'with-divider':divider,
      'dark': type === 'dark',
      'is-user':isUser && isUser === 'user',
      'white' : type === 'white'}"
  >
    <!-- 左侧返回按钮、中间的图标、栏目的名称 -->
    <div class="en-top-menu-left">
      <!-- 返回按钮 -->
      <en-icon
        v-if="!!needBack"
        name="fanhui"
        class="back-icon"
        size="20"
        @click.native="goBack"
      >
      </en-icon>
      <!-- 中间的图标 -->
      <div
        v-if="iconName"
        class="custome-icon"
      >
        <en-icon
          :name="iconName"
          size="18"
        >
        </en-icon>
      </div>
      <!-- 要显示的栏目名称 -->
      <div class="top-menu-name">
        {{ menuName }}
      </div>
    </div>
    <!-- 中间自定义菜单 -->
    <div
      class="en-top-menu-center"
      :class="type"
    >
      <slot name="center"></slot>
    </div>
    <!-- 右侧自定义内容区域 -->
    <div class="en-top-menu-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "EnTopMenu",
  props: {
    type: {
      type: String,
      default: "dark",
      validator: function(value) {
        // 样式: 白色 or 深色
        return ["white", "dark"].indexOf(value) > -1;
      }
    },
    // 是否需要返回按钮
    needBack: {
      type: Boolean,
      default: false
    },
    // 返回的URL
    backUrl: {
      type: String,
      default: ""
    },
    // 需要返回到的pathName
    backName: {
      type: String,
      default: ""
    },
    // 需要返回到的pathName所携带的参数
    backParams: {
      type: Object,
      default: () => {
        return {};
      }
    },
    // 自定义的图标名称
    iconName: {
      type: String,
      default: ""
    },
    // 目录名称
    menuName: {
      type: String,
      default: ""
    },
    // 底部分割线
    divider: {
      type: Boolean,
      default: false
    },
    // 跳转至其它微服务
    toOtherSystem: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isUser() {
      return this.$store.state._permission;
    }
  },
  methods: {
    /**
     * @description 点击返回按钮触发
     */
    goBack() {
      if (this.toOtherSystem) {
        location.href = location.host + this.backUrl;
      } else {
        // 如果是PATH返回，直接使用传递过来的path
        if (this.backUrl !== "") {
          this.$router.push({ path: this.backUrl });
        } else if (this.backName !== "") {
          // 如果是通过pathName返回，并且需要携带返回参数，使用参数跳转
          if (this.backParams !== "") {
            this.$router.push({
              name: this.backName,
              params: this.backParams
            });
          } else {
            this.$router.push({ name: this.backName });
          }
        } else {
          if (history.length > 1) {
            history.go(-1);
          } else {
            this.$router.push({ path: "/" });
          }
        }
      }
    }
  }
};
</script>
<style lang='scss' scoped>
.en-top-menu {
  padding: 0 20px;
  position: relative;
  width: 100%;
  z-index: 5;
  box-sizing: border-box;
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  display: flex;
  user-select: none;

  &.white {
    background-color: #fff;
    color: black;
  }

  &.dark {
    background: rgba(35, 44, 61, 1);
    color: white;
  }

  &.with-divider {
    border-bottom: 1px solid rgba(50, 62, 82, 1);
    height: 61px;

    &.white {
      border-color: #d7dce6;
    }
  }

  // 用户端组件限制颜色
  &.is-user {
    background-color: #fff !important;
    color: black !important;

    &.with-divider {
      border-bottom: 1px solid #ffffff !important;
      height: 61px;
    }
  }

  .en-top-menu-left {
    text-align: left;
    flex-basis: 30%;
    display: flex;
    position: relative;

    .back-icon {
      position: relative;
      top: 19px;
      cursor: pointer;
    }

    .custome-icon {
      width: 34px;
      height: 34px;
      background: rgb(37, 59, 85);
      border-radius: 4px;
      margin: 13px 10px;
      line-height: 34px;

      .en-icon {
        color: #4694df;
        margin: 7px 7px 7px;
      }
    }

    .top-menu-name {
      font-size: 18px;
      font-weight: bold;
    }

    .goback {
      margin-left: 20px;
    }

    .left-content {
      margin-left: 9px;
    }
  }

  .en-top-menu-right {
    text-align: right;
    flex-basis: 30%;
    margin-right: 20px;
  }

  .en-top-menu-center {
    flex-basis: 40%;
    display: flex;
    justify-content: center;

    > div {
      display: flex;
      justify-content: flex-start;

      > div {
        display: flex;
        justify-content: flex-start;
        line-height: 60px;
        padding: 0 20px;
        position: relative;
        color: #abadb2;
        transition: 0.3s ease-in-out all;
        cursor: pointer;

        &.white {
          background-color: #ffffff;
        }

        &.dark {
          background-color: rgb(34, 43, 39);
        }

        .en-icon {
          width: 16px;
          height: 16px;
          margin: 22px 6px 22px 0;
        }

        &.active {
          background-color: rgb(45, 54, 69);
          color: #ffffff;
        }
      }
    }
  }

  .goback,
  .en-top-menu-left,
  .en-top-menu-center,
  .en-top-menu-right {
    line-height: 60px;
  }
}
</style>
