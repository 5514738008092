/**
 * project: enfry-components
 * fileName: object
 * Created by 徐俊 on 2020/05/29
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import XObject from "../components/en-table/src/packages/tools/src/object"

const isEmpty = XObject.isEmpty;
const valueForKey = XObject.valueForKey;
const valueForKeyPath = XObject.valueForKeyPath;

export {
  isEmpty,
  valueForKey,
  valueForKeyPath
}
