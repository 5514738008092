/**
 * project: enfry-components
 * fileName: tableMenu
 * Created by 徐俊 on 2019/10/16
 * Copyright © 2019 Enfry Ltd. All rights reserved.
 */

import { VXETableMenuType } from "../../tools/src/enum";

export const TableMenu = {

  /**
   * 剪切粘贴菜单
   * @returns {{options: *[]}} 返回菜单
   */
  getRowCutPasteMenu() {
    const menuList = [];
    menuList.push({
      code: "en-cut",
      name: "剪切",
      prefixIcon: "jianqie-jichushuju",
      visible: true,
      disabled: false,
      type: VXETableMenuType.cut
    });

    menuList.push({
      code: "en-paste-front",
      name: "粘贴前面",
      prefixIcon: "niantieziji-jichushuju",
      visible: true,
      disabled: false,
      type: VXETableMenuType.pasteFront
    });

    menuList.push({
      code: "en-paste-back",
      name: "粘贴后面",
      prefixIcon: "niantieziji-jichushuju",
      visible: true,
      disabled: false,
      type: VXETableMenuType.pasteBack
    });

    menuList.push({
      code: "en-paste-sub",
      name: "粘贴子级",
      prefixIcon: "niantieziji-jichushuju",
      visible: true,
      disabled: false,
      type: VXETableMenuType.pasteSub
    });

    return { options: [menuList] };
  },

  /**
   * 处理剪贴粘贴菜单数据
   * @param params 所有数据参数
   */
  processRowCutPasteMenu(params = {}) {
    // debugger;
    const { options, $table } = params;// 菜单列表
    const { ctxRowMoveMenuConfig } = $table;
    if (!ctxRowMoveMenuConfig) {
      return;
    }

    const { style } = ctxRowMoveMenuConfig;
    if (!style || options.length === 0) {
      return;
    }

    const { row } = params;
    const { moveRow, moveField } = ctxRowMoveMenuConfig;
    const hasCut = (moveRow !== undefined);
    const isSameRow = (hasCut && row[moveField] === moveRow[moveField]);

    for (const group of options) {
      for (const item of group) {
        if (item.type === VXETableMenuType.cut) {
          item.disabled = isSameRow;
        } else if (item.type === VXETableMenuType.pasteFront || item.type === VXETableMenuType.pasteBack) {
          // item.visible = hasCut;
          // item.disabled = isSameRow;

          item.disabled = !hasCut || isSameRow;
        } else if (item.type === VXETableMenuType.pasteSub) {
          // item.visible = (hasCut && style === 2);
          // item.disabled = isSameRow;

          item.visible = (style === 2);
          item.disabled = !hasCut || isSameRow;
        }
      }
    }
  },

  /**
   * 处理菜单点击事件
   * @param params 所有数据参数
   */
  processRowCutPasteMenuClick(params) {
    const { menu, row, $table } = params;

    if (menu.type === VXETableMenuType.cut) {
      $table.ctxRowMoveMenuConfig.moveRow = row;
    } else if (menu.type === VXETableMenuType.pasteFront || menu.type === VXETableMenuType.pasteBack || menu.type === VXETableMenuType.pasteSub) {
      params.moveRow = $table.ctxRowMoveMenuConfig.moveRow;
      $table.ctxRowMoveMenuConfig.moveRow = undefined;
    }
  }

};
