<!--
 * @Author: zql
 * @Date: 2020-03-13 15:29:14
 * @LastEditTime: 2020-06-02 14:32:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \enfry-components\packages\components\en-result\src\nodata\no-data.vue
-->
<template>
  <div ref="NoData" class="nodata">
    <img
      class="icon-img"
      src="http://cdn.enfry.com/tree_select_no_data.png"
      :alt="this.title"
    />
    <div class="title" v-html="title"></div>
    <div class="subtitle" v-html="subTitle"></div>
  </div>
</template>

<script>
export default {
  name: "SelectTreeNoData",
  props: {
    type: { type: String, default: null },
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: "暂无数据"
    }
  }
};
</script>
<style lang="scss" scoped>
.nodata {
  text-align: center;
  .icon-img {
    height: 72px;
    width: 84px;
  }
}
</style>
