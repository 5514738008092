import RightMenu from "./RightMenu.vue";

export default {
  extends: RightMenu,
  name: "rightMenu",
  props: {
  // 坐标轴
    posobj: {
      type: Object,
      default() {
        return {
          x: 0,
          y: 0
        };
      }
    },
    // 右键菜单显示的文案以及是否能选择
    menuList: {
      type: Array,
      default() {
        return [{
          abled: true,
          val: "复制"
        },
        {
          abled: true,
          val: "粘贴"
        }
        ];
      }
    },
    // 回调
    callback: {
      type: Function,
      required: true
    },
    // 当前选中的节点
    activeItem: {
      type: [Object],
      default() {
        return {};
      }
    }
  }
};
