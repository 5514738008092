/**
 * project: enfry-components
 * fileName: array
 * Created by 徐俊 on 2020/05/29
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */

import XArray from "../components/en-table/src/packages/tools/src/array"

const indexOfObject = XArray.indexOfObject;
const removeObject = XArray.removeObject;

export {
  indexOfObject,
  removeObject
}
